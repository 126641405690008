<template>
  <v-row id="summary" class="fill-height mt-0" justify="center" align="stretch">
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <div class="grid-container pb-0 pr-0">
        <outstandingTable
          class="outstanding"
          :group="group"
          @outstandingProfile="outstandingProfile"
        />
        <paidInvoicesTable
          class="paid"
          :profileIndex="0"
          :group="group"
          @paidInvoiceProfile="paidInvoiceProfile"
        />
        <dueInvoices
          class="invoices"
          v-show="displayDueInvoices"
          v-on="$listeners"
          :type="type"
          :invoices="dueInvoices"
          :title="title"
        />
        <paidInvoices
          class="invoices"
          v-show="!displayDueInvoices"
          v-on="$listeners"
          :invoices="paidInvoices"
          :title="title"
        />
        <paidInvoicesChart
          class="profile-4"
          :paidInvoicesProfile="group.paidInvoicesProfile"
          :profileIndex="4"
        />
        <paidInvoicesChart
          class="profile-3"
          :paidInvoicesProfile="group.paidInvoicesProfile"
          :profileIndex="3"
        />
        <paidInvoicesChart
          class="profile-2"
          :paidInvoicesProfile="group.paidInvoicesProfile"
          :profileIndex="2"
        />
        <paidInvoicesChart
          class="profile-1"
          :paidInvoicesProfile="group.paidInvoicesProfile"
          :profileIndex="1"
        />
        <paidInvoicesChart
          class="profile-0"
          :paidInvoicesProfile="group.paidInvoicesProfile"
          :profileIndex="0"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'

import { invoicesGql } from './paymentAnalysis.gql.js'

import outstandingTable from './outstandingTable.vue'
import dueInvoices from './dueInvoices.vue'
import paidInvoices from './paidInvoices.vue'
import paidInvoicesChart from './paidInvoicesChart.vue'
import paidInvoicesTable from './paidInvoicesTable.vue'

export default {
  name: 'paymentAnalysis',
  components: {
    outstandingTable,
    dueInvoices,
    paidInvoices,
    paidInvoicesChart,
    paidInvoicesTable,
  },

  props: {
    paymentAnalysisDef: Object,
  },

  computed: {
    group() {
      return this.paymentAnalysisDef.contactGroup
    },

    isCompany() {
      return this.group.id === 'company'
    },
  },

  watch: {
    group() {
      this.dueInvoices = []
      this.paidInvoices = []
    },
  },

  methods: {
    async outstandingProfile(profile) {
      const overDueInvoices = () =>
        db.request(
          invoicesGql,
          db.args(
            db
              .queryArgs('invoices')
              .addFilterIf(!this.isCompany, 'contactGroupId', this.group.id)
              .addFilter('paymentProfile', profile.profile)
              .addFilter('isPaid', false)
          )
        )

      const getDaysFromProfile = profile => [0, 8, 15, 22, 999999][profile]

      const dueInvoices = () =>
        db.request(
          invoicesGql,
          db.args(
            db
              .queryArgs('invoices')
              .addFilterIf(!this.isCompany, 'contactGroupId', this.group.id)
              .addFilter('daysOver', 0)
              .addFilter(
                'daysToPay',
                getDaysFromProfile(profile.profile - 1),
                'gte'
              )
              .addFilter('daysToPay', getDaysFromProfile(profile.profile), 'lt')
              .addFilter('isPaid', false)
          )
        )

      const invoices =
        profile.type === 'overdue'
          ? await overDueInvoices()
          : await dueInvoices()
      this.type = profile.type
      this.title = profile.legend
      this.dueInvoices = invoices
      this.displayDueInvoices = true
    },

    async paidInvoiceProfile(profile) {
      if (!profile.count) return
      const overdueBy = profile =>
        [
          'on time',
          '1-7 days late',
          '8-14 days late',
          '15-21 days late',
          'over 21 days late',
        ][profile]

      const title = ({ count, monthIndex, profileIndex }) => {
        return `${count} invoices due in ${pc.monthKeyName(
          'mmmm yyyy',
          this.period.monthKeys[monthIndex]
        )} paid ${overdueBy(profileIndex)}`
      }

      const invoices = await db.request(
        invoicesGql,
        db.args(
          db
            .queryArgs('invoices')
            .addFilterIf(!this.isCompany, 'contactGroupId', this.group.id)
            .addFilter('paymentProfile', profile.profileIndex)
            .addFilter('monthDueIndex', profile.monthIndex)
            .addFilter('isPaid', true)
        )
      )
      this.title = title(profile)
      this.paidInvoices = invoices
      this.displayDueInvoices = false
    },
  },

  data() {
    return {
      period: db.cached('period'),
      dueInvoices: [],
      paidInvoices: [],
      type: 'overdue',
      title: '',
      displayDueInvoices: true,

      companySummaryCardDef: pcCardDef('companySummary', false, {
        loaderType: 'card',
        elevation: '0',
      }),

      companySummaryToolbarDef: pcToolbarDef(
        'companySummaryToolbar',
        'Company summary',
        {
          dense: true,
          color: 'primary lighten-2',
        }
      ),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'outstanding outstanding invoices  invoices  invoices'
    'paid        paid        invoices  invoices  invoices'
    'profile-4   profile-3   profile-2 profile-1 profile-0';
}
/*
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas: 'statistics' 'outstanding' 'sales-chart' 'active-customers-chart' 'contact-group-chart' 'sales-category-chart';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(100px, 50%));
    grid-template-rows: repeat(3, 100%);
    grid-template-areas: 'statistics outstanding' 'sales-chart active-customers-chart' 'contact-group-chart sales-category-chart';
  }
}
@media only screen and (min-width: 1300px) {
}
.outstanding {
  grid-area: outstanding;
  overflow-y: auto;
}
*/

.outstanding {
  grid-area: outstanding;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.invoices {
  grid-area: invoices;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.paid {
  grid-area: paid;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.profile-4 {
  grid-area: profile-4;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.profile-3 {
  grid-area: profile-3;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.profile-2 {
  grid-area: profile-2;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.profile-1 {
  grid-area: profile-1;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.profile-0 {
  grid-area: profile-0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
</style>
