const _pcTooltipDef = () => ({
  id: '',
  show: false,
  contentClass: 'ma-0 pa-0',
  activatorElement: null,
  color: 'transparent',
  top: true,
  bottom: false,
  left: false,
  right: false,
  showHook: null,
  xPos: null,
  yPos: null,
})

const pcTooltipDef = function(id, showHook, position = 'top', other = {}) {
  const newTooltip = _pcTooltipDef()
  if (!id) {
    console.log('Must specify an id for a pcTooltip')
  }

  newTooltip.id = id
  newTooltip.showHook = showHook
  newTooltip.top = position === 'top'
  newTooltip.bottom = position === 'bottom'
  newTooltip.left = position === 'left'
  newTooltip.right = position === 'right'

  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(newTooltip, key)) {
      newTooltip[key] = value
    } else {
      console.log(`Invalid property: ${key} in pcTooltip id ${id}`)
    }
  })
  return newTooltip
}

export { pcTooltipDef }
