<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="none"
        allName="No contact group"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-chart :chartDef="chartDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'productSalesChart',
  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
  },

  props: {
    productSummaryDef: Object,
  },

  computed: {
    isProduct() {
      return this.product && this.product.code ? true : false
    },

    product() {
      return this.productSummaryDef.product
    },
  },

  watch: {
    productSummaryDef() {
      this.updateDisplay()
    },

    groupId() {
      this.updateDisplay()
    },
  },

  methods: {
    groupSelected(groupId) {
      this.groupId = groupId
    },

    updateDisplay() {
      const addChartGroupLine = function(groupId) {
        const contactGroupProduct = pc
          .query()
          .addFind('contactGroupId', groupId)
          .run(this.product.salesByContactGroup)

        this.chartDef.addDataset(
          pc.arrayFixed2(contactGroupProduct.salesByMonth),
          `${contactGroupProduct.contactGroupName} (Total sales £${pc.toFixed0(
            contactGroupProduct.sales
          )})`,
          getColor('purple', 3),
          [['type', 'line']]
        )
      }.bind(this)

      const addChartProductTurnoverBars = function() {
        this.chartDef.addDataset(
          pc.arrayFixed2(this.product.salesByMonth),
          'Sales',
          getColor('blue', 3)
        )
      }.bind(this)

      if (this.isProduct) {
        this.displayBoxDef.title = `Product sales £${pc.toFixed0(
          this.product.sales
        )}`
        this.chartDef.clearDatasets()
        //this.chartDef.setLegend(groupId !== 'none')
        this.groupId !== 'none' && addChartGroupLine(this.groupId)
        addChartProductTurnoverBars()
        this.chartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      groupId: '',
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Product sales', {
        contentPadding: 'pt-1 pb-1 pl-3 pr-3',
      }),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
