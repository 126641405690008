<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="none"
        allName="No contact group"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-chart :chartDef="chartDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'salesChart',
  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
  },

  methods: {
    groupSelected(groupId) {
      this.chartDef.clearDatasets()
      //this.chartDef.setLegend(groupId !== 'none')
      groupId !== 'none' && this.addGroupData(groupId)
      this.addTurnoverData()
      this.chartDef.render()
    },

    addGroupData(groupId) {
      const group = db.cached(
        'contactGroups',
        db.cachedQuery().addFind('id', groupId)
      )
      this.chartDef.addDataset(
        pc.arrayFixed2(group.salesByMonth),
        `${group.name} - £${pc.toFixed0(group.sales)}`,
        getColor('purple', 3),
        [['type', 'line']]
      )
    },

    addTurnoverData() {
      this.chartDef.addDataset(
        pc.arrayFixed2(this.period.salesByMonth),
        'Sales',
        getColor('blue', 3)
      )
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Sales', {
        title: `Company sales £${pc.toFixed0(period.sales)}`,
      }),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
