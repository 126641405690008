<template>
  <v-row
    v-if="productSummaryDef.show"
    id="productSummary"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-col d-flex pt-3 pl-6 pb-0">
      <div class="grid-container">
        <div class="details">
          <product-details :productSummaryDef="productSummaryDef" />
          <productRankingChart
            class="pt-3 "
            :productSummaryDef="productSummaryDef"
          />
        </div>
        <div class="sales-chart">
          <product-sales-chart :productSummaryDef="productSummaryDef" />
        </div>
        <div class="contact-groups-chart">
          <productContactGroupsChart :productSummaryDef="productSummaryDef" />
        </div>
        <div class="statistics">
          <product-statistics :productSummaryDef="productSummaryDef" />
        </div>
        <div class="coverage">
          <productCustomerCoverage :productSummaryDef="productSummaryDef" />
          <productCategoryCustomerCoverage
            class="pt-3"
            :productSummaryDef="productSummaryDef"
          />
        </div>
        <div class="category-chart">
          <productCategorySalesChart :productSummaryDef="productSummaryDef" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
//import pc from '@pc'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'

import productDetails from './productDetails.vue'
import productCustomerCoverage from './productCustomerCoverage.vue'
import productCategoryCustomerCoverage from './productCategoryCustomerCoverage.vue'
import productStatistics from './productStatistics.vue'
import productSalesChart from './productSalesChart.vue'
import productRankingChart from './productRankingChart.vue'
import productContactGroupsChart from './productContactGroupsChart.vue'
import productCategorySalesChart from './productCategorySalesChart.vue'

export default {
  name: 'productSummary',
  components: {
    productDetails,
    productCustomerCoverage,
    productCategoryCustomerCoverage,
    productStatistics,
    productSalesChart,
    productRankingChart,
    productContactGroupsChart,
    productCategorySalesChart,
  },

  props: {
    productSummaryDef: Object,
  },

  created() {},

  computed: {},

  watch: {},

  methods: {},

  data() {
    return {
      productSummaryCardDef: pcCardDef('productSummary', false, {
        loaderType: 'card',
        elevation: '0',
      }),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'details'
    'sales-chart'
    'statistics'
    'coverage'
    'contact-groups-chart'
    'category-chart';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'details sales-chart'
      'statistics coverage'
      'contact-groups-chart category-chart';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: repeat(2, minmax(100px, 1fr));
    grid-template-areas:
      'details sales-chart contact-groups-chart'
      'statistics coverage category-chart';
  }
}
.product-details {
  grid-area: product-details;
  overflow-y: auto;
}
.outstanding {
  grid-area: outstanding;
  overflow-y: auto;
}
.sales-chart {
  grid-area: sales-chart;
  overflow-y: auto;
}
.statistics {
  grid-area: statistics;
  overflow-y: auto;
}
.sales-by-category-chart {
  grid-area: sales-by-category-chart;
  overflow-y: auto;
}
.sales-by-category-list {
  grid-area: sales-by-category-list;
  overflow-y: auto;
}
</style>
