<template>
  <v-row
    v-if="drillDef.if"
    v-show="drillDef.show"
    id="wsCategoryDrill"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-col d-flex flex-column pt-3 pb-1">
      <pc-tooltip :tooltip-def="chartTooltipDef">
        <template v-slot:tooltip>
          <pc-spark-Chart :sparkChartDef="sparkChartTooltipDef" />
        </template>
      </pc-tooltip>

      <pc-card :card-def="cardDef" @uid="initialiseCardUid">
        <template v-slot:title>
          <pc-toolbar :toolbarDef="chartToolbarDef">
            <template v-slot:toolbarActions>
              <v-select
                :items="selectMonthItems"
                v-model="selectMonth"
                style="width: 80px"
                color="white"
                dark
                dense
                class="pt-4 pr-2"
              ></v-select>
            </template>
          </pc-toolbar>
        </template>

        <template v-slot:text>
          <pc-chart
            :chartDef="pieChartDef"
            @instance="pieChartDef.instance"
            @tooltip="sparkChartTooltip"
          />
          <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
          <pc-chart
            :chartDef="lineChartDef"
            @instance="lineChartDef.instance"
            @tooltip="sparkChartTooltip"
          />

          <pc-lister
            v-if="list.show"
            :list-def="listDef"
            :list-items="listItems"
          >
            <template v-slot:turnover>
              <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
            </template>
          </pc-lister>
        </template>

        <template v-slot:actions>
          <v-row justify="center" style="z-index: 4;">
            <v-col cols="12" class="pt-10 pb-6 pl-6 pr-6">
              <pc-breadcrumbs :breadcrumbsDef="breadcrumbsDef"></pc-breadcrumbs>
            </v-col>
          </v-row>
        </template>
      </pc-card>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { getCategoryChildren } from './getCategoryChildren.js'
import { getColor, getColors } from '../../../pcModules/pcColors.js'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcLineActionsDef } from '@pcComponents/defs/pcLineActionsDef.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { pcBreadcrumbsDef } from '@pcComponents/defs/pcBreadcrumbsDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcLister from '@pcComponents/pcLister.vue'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import pcBreadcrumbs from '@pcComponents/pcBreadcrumbs.vue'
import pcTooltip from '@pcComponents/pcTooltip.vue'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'

export default {
  name: 'wsCategoryDrill',

  components: {
    pcCard,
    pcToolbar,
    pcChart,
    pcLister,
    pcBreadcrumbs,
    pcTooltip,
    pcSparkChart,
  },

  props: {
    drillDef: Object,
  },

  created() {
    this.initialise()
    this.main('category')
  },

  computed: {
    customerId() {
      return this.drillDef.customerId
    },

    periodFromToLong() {
      const startMonth = pc.monthKeyName('mmmm yyyy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        this.period.monthKeys[this.period.monthKeys.length - 1],
        'mmmm yyyy'
      )
      return `${startMonth} - ${endMonth}`
    },

    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },

    selectedMonthNameLong() {
      const monthKey = this.selectMonth
      if (monthKey === 'total') return this.periodFromToLong
      return pc.monthKeyName('mmmm yyyy', monthKey)
    },

    selectedMonthNameShort() {
      const monthKey = this.selectMonth
      if (monthKey === 'total') return this.periodFromToShort
      return pc.monthKeyName('mmm yy', monthKey)
    },

    title() {
      let title = ''
      switch (this.displayType) {
        case 'bar':
          title = `${
            this.category.id === 'category'
              ? 'Total turnover'
              : this.category.name
          } for the period`
          break
        case 'pie':
        case 'list':
          title = `${
            this.category.id === 'category'
              ? 'Top level category analysis'
              : this.category.name
          } for ${this.total ? 'the period' : this.selectedMonthNameLong}`
          break
        case 'line':
          title = `${
            this.category.id === 'category'
              ? 'Top level category analysis'
              : this.category.name
          } for the period`
          break
      }
      return title
    },

    doughnutCenterText() {
      return this.selectMonth === 'total'
        ? this.periodFromToShort
        : this.selectedMonthNameLong
    },
  },

  watch: {
    // Recalculate and display the category if the period/month changed
    selectMonth(value) {
      this.total = value === 'total'
      this.main(this.category.id)
    },

    customerId() {
      this.category = db.cached(
        'categories',
        db.cachedQuery().addFind('id', 'category')
      )
      this.displayType = 'bar' // Set to bar chart
      this.initialiseBreadcrumbs() // Reset breadcrumb to top level
      if (this.selectMonth === 'total') {
        // Only call main() if total otherwise it is called from changing selectMonth
        this.main('category')
      } else {
        this.selectMonth = 'total' // Reset selectMonth to total - this will trigger main() to be called - see above
      }
    },

    displayType() {
      this.pieChartDef.show = this.displayType === 'pie'
      this.barChartDef.show = this.displayType === 'bar'
      this.lineChartDef.show = this.displayType === 'line'
      this.list.show = this.displayType === 'list'
      this.chartToolbarDef.title = this.title
    },
  },

  methods: {
    // Routine from pcColors to return a single color
    getColor,

    initialise() {
      this.initialiseBreadcrumbs()
      this.initialiseSelectMonth()
    },

    initialiseBreadcrumbs() {
      // See the breadcrumbs control. All options are done through a command interface
      this.breadcrumbsDef.clear()
      this.breadcrumbsDef.addItem('category', 'Top Level', {
        category: true,
        products: false,
      })
    },

    initialiseSelectMonth() {
      // Build the list of month names for the period dropdown
      const monthNames = pc.periodMonthNames('mmmm yyyy', this.period.monthKeys)
      const selectItems = this.period.monthKeys.map((monthKey, index) => ({
        text: monthNames[index],
        value: monthKey,
      }))
      selectItems.push({ text: 'Period Total', value: 'total' })
      this.selectMonthItems = selectItems.reverse()
    },

    // Called from event 'uid' of <wsCategoryDrill>
    initialiseCardUid(uid) {
      this.cardDef.uid = uid
    },

    async main(categoryId) {
      if (!categoryId.startsWith('xeroAcc-')) {
        this.category = db.cached(
          'categories',
          db.cachedQuery().addFind('id', categoryId)
        )
        this.data = await pc.pipeAsync(
          this.getCategoryChildren,
          this.updateBarChart,
          this.updatePieChart,
          this.updateLineChart,
          this.updateList,
          this.updateBreadcrumbs,
          this.callUpdateHook
        )(categoryId)
      }
    },

    async getCategoryChildren(parentId) {
      return getCategoryChildren(parentId, this.selectMonth, this.customerId)
    },

    async updateBarChart(data) {
      this.chartToolbarDef.title = this.title
      data.values = pc.arrayFixed2(
        pc.sumArraysProp(pc.prop('salesByMonth'), data.data)
      )

      const chartColors = (() => {
        const colors = pc.createArray(getColor('blue', 3), 13)
        return data.monthIndex >= 0
          ? pc.adjust(data.monthIndex, pc.always(getColor('blue', 12)), colors)
          : colors
      })()

      this.barChartDef
        .clearDatasets()
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', this.period.monthKeys))
        .addDataset(data.values, 'Sales', chartColors)
        .render()

      return data
    },

    updatePieChart(data) {
      const chartData = data.data.reduce(
        (chartData, record, index) => {
          if (!record.bought || !record.listValue) return chartData
          chartData.recordIndex.push(index)
          chartData.labels.push(
            `${record.code ? record.code + ' ' : ''}${record.name}`
          )
          chartData.values.push(record.listValue)
          return chartData
        },
        { recordIndex: [], labels: [], values: [] }
      )

      this.chartToolbarDef.title = this.title
      this.pieChartDef
        .clearDatasets()
        .setPc([
          ['isCategory', data.isCategories],
          ['isProduct', data.isProducts],
          ['recordIndex', chartData.recordIndex],
        ])
        .setLabels(chartData.labels)
        .setOptions([['elements.center.text', this.doughnutCenterText]])
        .addDataset(chartData.values, 'Turnover', this.chartColors)
        .render()

      return data
    },

    updateLineChart(data) {
      const chartData = data.data.reduce(
        (chartData, record, index) => {
          if (!record.sales || !record.listValue) return chartData
          chartData.recordIndex.push(index)
          chartData.labels.push(
            `${record.code ? record.code + ' ' : ''}${record.name}`
          )
          chartData.datasets.push(record.salesByMonth)
          return chartData
        },
        { recordIndex: [], labels: [], datasets: [] }
      )

      this.lineChartDef
        .clearDatasets()
        .setPc([
          ['isCategory', data.isCategories],
          ['isProduct', data.isProducts],
          ['recordIndex', chartData.recordIndex],
        ])
        .setLabels(pc.periodMonthNames('mmm yy', this.period.monthKeys))

      const pointRadius = (() => {
        const pointRadius = pc.createArray(3, 13)
        return data.monthIndex >= 0
          ? pc.adjust(data.monthIndex, pc.always(6), pointRadius)
          : pointRadius
      })()

      chartData.datasets.forEach((dataset, index) => {
        const graphColor = this.chartColors[index]
        this.lineChartDef.addDataset(
          dataset,
          chartData.labels[index],
          graphColor,
          [['pointRadius', pointRadius]]
        )
      })

      this.lineChartDef.render()
      return data
    },

    updateList(data) {
      if (data.isCategories) {
        this.list.categoryListItems = data.data
        this.listDef =
          this.selectMonth === 'total'
            ? this.list.categoryListPeriodDef
            : this.list.categoryListMonthDef
        this.listItems = this.list.categoryListItems
      } else {
        this.list.productListItems = data.data
        this.listDef =
          this.selectMonth === 'total'
            ? this.list.productListPeriodDef
            : this.list.productListMonthDef
        this.listItems = this.list.productListItems
      }
      return data
    },

    updateBreadcrumbs(data) {
      this.breadcrumbsDef.addItem(this.category.id, this.category.name, {
        category: true,
        product: false,
      })
      return data
    },

    callUpdateHook(data) {
      if (typeof this.drillDef.updateHook === 'function') {
        this.drillDef.updateHook(data)
      } else {
        if (pc.isArray(this.drillDef.updateHook)) {
          this.drillDef.updateHook.forEach(fn => fn(data))
        }
      }
      return data
    },

    // Pie chart callback functions -------------------------------------------

    pieChartClicked(event) {
      const payload = event.payload
      const recordIndex = this.pieChartDef.pc.recordIndex[payload.dataIndex]
      const record = this.data.data[recordIndex]
      this.pieChartDef.pc.isCategory && this.main(record.id)
      this.pieChartDef.pc.isProduct &&
        this.$emit('productClicked', this.selectMonth, record.code)
    },

    barChartClicked(event) {
      this.displayType = 'pie'
      this.selectMonth = this.period.monthKeys[event.payload.dataIndex]
    },

    lineChartClicked(event) {
      const payload = event.payload
      const recordIndex = this.lineChartDef.pc.recordIndex[payload.datasetIndex]
      const record = this.data.data[recordIndex]
      this.lineChartDef.pc.isCategory && this.main(record.id)
      this.lineChartDef.pc.isProduct &&
        this.$emit('productClicked', this.selectMonth, record.code)
    },

    // breadcrumbs clallback function -----------------------------------------

    breadcrumbClicked(crumb) {
      this.breadcrumbsDef.deleteBelow(crumb.id)
      this.main(crumb.id)
    },

    // Called from the invoice list when a line clicked and
    // gets the appropriate invoices lines to be displayed
    // in the expanded list window
    listCategoryClicked(payload) {
      this.main(payload.data.id)
    },

    listProductClicked(payload) {
      this.$emit('productClicked', this.selectMonth, payload.data.code)
    },

    async sparkChartTooltip(payload) {
      const type = payload.chartDef.chart.type
      const isPie = type === 'pie'
      const chartDef = isPie ? this.pieChartDef : this.lineChartDef
      const index = isPie ? payload.dataIndex : payload.datasetIndex
      const record = this.data.data[chartDef.pc.recordIndex[index]]
      const labels = pc.periodMonthNames('mmm', this.period.monthKeys)
      const totalSales = pc.sumArrayProp(pc.prop('sales'), this.data.data)
      const percent = pc.toFixed2(pc.percentage(record.sales, totalSales))
      const subTitle = `£${pc.toFixed2(record.sales)} (${percent}%)`
      const title = isPie ? payload.xLabel : payload.yLabel
      const titleColor = isPie
        ? payload.dataset.backgroundColor[index]
        : payload.dataset.backgroundColor

      this.sparkChartTooltipDef
        .clearDatasets()
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .setLabels(labels)
        .addDataset(record.salesByMonth, '', titleColor)

      chartDef.setSparkChart(this.sparkChartTooltipDef)

      this.sparkChartTooltipDef.render()
    },

    categoryTurnoverTooltipHandler(payload) {
      const value = pc.getProperty(payload.field.dataPath, payload.data)
      const total = pc.sumArrayProp(
        pc.prop('sales'),
        this.list.categoryListItems
      )
      const percent = pc.toFixed2(pc.percentage(value, total))
      const subTitle = `£${pc.toFixed2(value)} (${percent}%)`

      this.turnoverSparkChartDef
        .clearDatasets()
        .setTitle(payload.data.name, '#90CAF9')
        .setSubTitle(subTitle)
        .addDataset(
          Object.values(payload.data.salesByMonth).map(value =>
            Math.round(value)
          )
        )
        .setLabels(pc.periodMonthNames('mmm', this.period.monthKeys))
        .render()
    },

    productTurnoverTooltipHandler(payload) {
      const value = pc.getProperty(payload.field.dataPath, payload.data)
      const total = this.list.productListItems.reduce((accum, record) => {
        return accum + parseFloat(record.sales)
      }, 0)
      const percent = pc.toFixed2(pc.percentage(value, total))
      const subTitle = `£${pc.toFixed2(value)} (${percent}%)`

      this.turnoverSparkChartDef
        .clearDatasets()
        .setTitle(`${payload.data.code} ${payload.data.name}`, '#90CAF9')
        .setSubTitle(subTitle)
        .addDataset(pc.arrayFixed0(payload.data.salesByMonth))
        .setLabels(pc.periodMonthNames('mmm', this.period.monthKeys))
        .render()
    },

    listMonthHeading(payload) {
      const month = pc.monthKeyName('mmm yy', this.selectMonth)
      const type =
        payload.field.id === 'monthTurnover' ? 'turnover' : 'quantity'
      return `${month} ${type}`
    },

    chartToolbarAction(action) {
      this.displayType = action.id
    },

    lastInvoicedClass(payload) {
      return lastInvoicedClass(
        payload.data.lastInvoiced,
        this.period.periodEndDate
      )
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      total: true,
      category: { id: '' },
      chartColors: getColors('impact'),
      listDef: {},
      listItems: [],
      data: {},

      breadcrumbsDef: pcBreadcrumbsDef('breadcrumbs', this.breadcrumbClicked),

      // Select for Month
      selectMonth: 'total',
      selectMonthItems: [],

      displayType: 'bar',

      cardDef: pcCardDef('chartContainerCard', false, {
        cardClass: this.drillDef.cardClass,
        elevation: 0,
      }),
      chartToolbarDef: pcToolbarDef('chartToolbar', 'Chart Toolbar', {
        dense: true,
        color: 'primary lighten-2',
        actions: [
          pcToolbarActionDef(
            'bar',
            'mdi-chart-bar',
            this.chartToolbarAction,
            'View bar chart',
            'white'
          ),
          pcToolbarActionDef(
            'pie',
            'mdi-chart-pie',
            this.chartToolbarAction,
            'View pie chart'
          ),
          pcToolbarActionDef(
            'line',
            'mdi-chart-line',
            this.chartToolbarAction,
            'View line chart',
            'white'
          ),
          pcToolbarActionDef(
            'list',
            'mdi-view-list',
            this.chartToolbarAction,
            'View list'
          ),
        ],
      }),

      barChartDef: pcChartDef2('bar')
        .setChart([
          ['onClick', this.barChartClicked],
          ['containerClass', 'pt-3'],
        ])
        .setTitle('', false),

      pieChartDef: pcChartDef2('pie')
        .setChart([
          ['show', false],
          ['onClick', this.pieChartClicked],
          ['tooltipSparkChart', true],
        ])
        .setOptions([['cutout', '50%']])
        .setTitle('', false),

      lineChartDef: pcChartDef2('line')
        .setChart([
          ['show', false],
          ['onClick', this.lineChartClicked],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false),

      chartTooltipDef: pcTooltipDef('pieChartTooltip'),

      sparkChartTooltipDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      turnoverTooltipDef: pcTooltipDef('turnoverTooltip'),
      turnoverSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      list: {
        show: false,
        categoryListPeriodDef: {
          cardDef: pcCardDef('listCategory', false),
          table: pcTableDef(
            'categoryListTable',
            undefined,
            ['listValue'],
            [true],
            { rowClick: this.listCategoryClicked }
          ),
          fields: [
            pcFieldDef('name', 'text', '', 'Category'),
            pcFieldDef('ranking', 'ranking', '', 'Co. Ranking', true),
            pcFieldDef('turnover', 'currency', 'listValue', 'Turnover', true, {
              tooltipDef: pcTooltipDef(
                'turnoverTooltip',
                this.categoryTurnoverTooltipHandler
              ),
            }),
            pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
              itemClass: this.lastInvoicedClass,
            }),
          ],
          lineActions: pcLineActionsDef(),
        },
        categoryListMonthDef: {
          cardDef: pcCardDef('listCategory', false),
          table: pcTableDef(
            'categoryListTable',
            undefined,
            ['listValue'],
            [true],
            { rowClick: this.listCategoryClicked }
          ),
          fields: [
            pcFieldDef('name', 'text', '', 'Category'),
            pcFieldDef('ranking', 'ranking', '', 'Co. Ranking', true),
            pcFieldDef(
              'monthTurnover',
              'currency',
              'listValue',
              this.listMonthHeading,
              true
            ),
            pcFieldDef(
              'turnover',
              'currency',
              'sales',
              'Period turnover',
              true,
              {
                tooltipDef: pcTooltipDef(
                  'turnoverTooltip',
                  this.categoryTurnoverTooltipHandler
                ),
              }
            ),
            pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
              itemClass: this.lastInvoicedClass,
            }),
          ],
          lineActions: pcLineActionsDef(),
        },
        productListPeriodDef: {
          cardDef: pcCardDef('listProducts', false),
          table: pcTableDef('listProduct', 'itemCode', ['value'], [true], {
            headerClass: 'blue-grey lighten-5',
            fontSize: { xs: 'small', lg: 'medium' },
            rowClick: this.listProductClicked,
          }),
          fields: [
            pcFieldDef('code', 'text', '', 'Code'),
            pcFieldDef('name', 'text', '', 'Name', true, { width: '100%%' }),
            pcFieldDef('ranking', 'ranking', '', 'Co. Ranking', true),
            pcFieldDef('quantity', 'number', 'listQuantity', 'Period quantity'),
            pcFieldDef(
              'turnover',
              'currency',
              'listValue',
              'Period turnover',
              true,
              {
                tooltipDef: pcTooltipDef(
                  'turnoverTooltip',
                  this.productTurnoverTooltipHandler
                ),
              }
            ),
            pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
              itemClass: this.lastInvoicedClass,
            }),
          ],
          lineActions: pcLineActionsDef(),
        },
        productListMonthDef: {
          cardDef: pcCardDef('listProducts', false),
          table: pcTableDef('listProduct', 'itemCode', ['value'], [true], {
            headerClass: 'blue-grey lighten-5',
            fontSize: { xs: 'small', lg: 'medium' },
            rowClick: this.listProductClicked,
          }),
          fields: [
            pcFieldDef('code', 'text', '', 'Code'),
            pcFieldDef('name', 'text', '', 'Name', true, { width: '100%%' }),
            pcFieldDef('ranking', 'ranking', '', 'Co. Ranking', true),
            pcFieldDef(
              'monthQuantity',
              'number',
              'listQuantity',
              this.listMonthHeading
            ),
            pcFieldDef(
              'monthTurnover',
              'currency',
              'listValue',
              this.listMonthHeading,
              true
            ),
            pcFieldDef('quantity', 'number', '', 'Period quantity'),
            pcFieldDef(
              'turnover',
              'currency',
              'sales',
              'Period turnover',
              true,
              {
                tooltipDef: pcTooltipDef(
                  'turnoverTooltip',
                  this.productTurnoverTooltipHandler
                ),
              }
            ),
            pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
              itemClass: this.lastInvoicedClass,
            }),
          ],
          lineActions: pcLineActionsDef(),
        },
        categoryListItems: [],
        productListItems: [],
      },
    }
  },
}
</script>

<style scoped></style>
