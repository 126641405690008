import pc from '@pc'
import db from '@db'
import { productCustomersGql, categoryCustomersGql } from './getCustomers.gql'

const getBoughtCustomers = (customers, salesRecords) => {
  const addBoughtCustomer = salesRecord => {
    const customer = customers.find(
      customer => customer.id === salesRecord.customerId
    )
    return {
      ...customer,
      item: salesRecord,
    }
  }
  return salesRecords.map(addBoughtCustomer)
}

const getNotBoughtCustomers = (customers, boughtCustomers) => {
  const boughtCustomersIds = boughtCustomers.map(customer => customer.id)
  return pc
    .query()
    .addExclude('id', boughtCustomersIds)
    .run(customers)
}

export const getCustomers = async (group, item) => {
  const isProduct = pc.isProperty('code', item)
  const isContactGroup = group.id !== 'company'

  const salesRecords = isProduct
    ? await db.request(
        productCustomersGql,
        db.args(
          db
            .queryArgs('productCustomers')
            .addFilterIf(isContactGroup, 'contactGroupId', group.id)
            .addFilter('productCode', item.code)
        )
      )
    : await db.request(
        categoryCustomersGql,
        db.args(
          db
            .queryArgs('categoryCustomers')
            .addFilterIf(isContactGroup, 'contactGroupId', group.id)
            .addFilter('categoryId', item.id)
        )
      )

  const customers = db.cached(
    'customers',
    db.cachedQuery().addFilterIf(isContactGroup, 'contactGroupId', group.id)
  )

  const boughtCustomers = getBoughtCustomers(customers, salesRecords)
  const notBoughtCustomers = getNotBoughtCustomers(customers, boughtCustomers)

  return { boughtCustomers, notBoughtCustomers }
}
