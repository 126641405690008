import pc from '@pc'
import { getCategories } from './getCategories.js'
import { getProducts } from './getProducts.js'

const makeCategoryTree = (categories, products) => {
  const addProducts = category => {
    const categoryProducts = pc
      .query()
      .addFilter(`category.level${category.level}`, category.id)
      .addSort('code')
      .addClone()
      .run(products)
    category.children = categoryProducts.map(product => ({
      ...product,
      name: `${product.code} - ${product.name}`,
    }))
    return
  }

  const getChildren = category => {
    category.children = []
    if (!category.hasChildren) return addProducts(category)
    const children = pc
      .query()
      .addFilter('parentId', category.id)
      .addSort('name')
      .run(categories)
    category.children = children
    children.forEach(getChildren)
    return category
  }

  const treeRoot = categories.find(category => category.id === 'category')
  const tree = getChildren(treeRoot)
  return tree
}

export const getCategoryTree = async contactGroupId => {
  const categories = await getCategories(contactGroupId)
  const products = await getProducts(contactGroupId)
  return makeCategoryTree(categories, products)
}
