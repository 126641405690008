var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pc-card',{attrs:{"card-def":_vm.cardDef},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('pc-toolbar',{attrs:{"toolbar-def":_vm.toolbarDef}})]},proxy:true},{key:"text",fn:function(){return [_c('v-row',{staticClass:"pl-3 pt-3 fill-height",attrs:{"align":"stretch"}},[_c('v-col',{staticClass:"pc-col d-flex pt-0 pb-1",attrs:{"cols":12}},[_c('v-treeview',{staticClass:"tree",attrs:{"activatable":"","dense":"","item-key":"id","multiple-active":true,"open":_vm.openItems,"items":_vm.items,"return-object":"","active":_vm.active},on:{"update:open":function($event){_vm.openItems=$event},"update:active":[function($event){_vm.active=$event},_vm.updateActive]},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"draggable":"item.level > 1"},on:{"dragstart":function($event){return _vm.dragStart(item, $event)},"dragend":function($event){return _vm.dragEnd(item, $event)}}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1)]},proxy:true},(_vm.modified)?{key:"actions",fn:function(){return [_c('div',{staticClass:"pc-width100 d-flex justify-end pr-3 pb-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('cancelChanges', 'session')}}},on),[_vm._v("cancel this session changes")])]}}],null,false,1717502608)},[_c('span',[_vm._v("Cancel the changes made in this session"),_c('br'),_vm._v(" but retain those made earlier today.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('cancelChanges', 'all')}}},on),[_vm._v("cancel all changes")])]}}],null,false,558354870)},[_c('span',[_vm._v("Cancel all changes made in this"),_c('br'),_vm._v(" session and any made earlier today.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saveChanges')}}},on),[_vm._v("SAVE CHANGES")])]}}],null,false,3463467930)},[_c('span',[_vm._v("Save the changes. The changes will"),_c('br'),_vm._v(" be applied during tonights update.")])])],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }