import { gql } from 'graphql-tag'

export const signout = gql`
  mutation($record: SigninInput) {
    signout(record: $record) {
      id
      authenticated
      errorType
      errorMessage
    }
  }
`
