import * as R from 'ramda'
import array from './array.js'
import csv from './csv.js'
import date from './date.js'
import display from './display.js'
import func from './function.js'
import map from './map.js'
import number from './number.js'
import object from './object.js'
import set from './set.js'
import string from './string.js'
import utility from './utility.js'
import { Query } from './queryClass'
import vue from './vue.js'

const query = () => new Query()

export const pc = {
  ...R,
  ...array,
  ...csv,
  ...date,
  ...display,
  ...func,
  ...map,
  ...number,
  ...object,
  ...set,
  ...string,
  ...utility,
  query,
  ...vue,
}

export default pc
