<template>
  <v-row id="coTop10" class="fill-height mt-0" justify="center" align="stretch">
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <div class="grid-container pb-0">
        <div class="customers-chart">
          <co-top-10-chart
            recordType="customer"
            :top10Records="top10Customers"
          />
        </div>
        <div class="products-chart">
          <co-top-10-chart recordType="product" :top10Records="top10Products" />
        </div>
        <div class="categories-chart">
          <co-top-10-chart
            recordType="category"
            :top10Records="top10Categories"
          />
        </div>
        <div class="customers-list">
          <co-top-10-customers-list
            @top-10-customers="top10CustomersData"
            v-on="$listeners"
          />
        </div>
        <div class="products-list">
          <co-top-10-products-list @top-10-products="top10ProductsData" />
        </div>
        <div class="categories-list">
          <co-top-10-categories-list @top-10-categories="top10CategoriesData" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import coTop10CustomersList from '@appViews/companyDashboard/coTop10/coTop10CustomersList.vue'
import coTop10ProductsList from '@appViews/companyDashboard/coTop10/coTop10ProductsList.vue'
import coTop10CategoriesList from '@appViews/companyDashboard/coTop10/coTop10CategoriesList.vue'
import coTop10Chart from '@appViews/companyDashboard/coTop10/coTop10Chart.vue'

export default {
  name: 'coTop10',
  components: {
    coTop10CustomersList,
    coTop10ProductsList,
    coTop10CategoriesList,
    coTop10Chart,
  },

  props: {
    top10Def: Object,
  },

  methods: {
    top10CustomersData(data) {
      this.top10Customers = data
    },
    top10ProductsData(data) {
      this.top10Products = data
    },
    top10CategoriesData(data) {
      this.top10Categories = data
    },
  },

  data() {
    return {
      top10Customers: [],
      top10Products: [],
      top10Categories: [],
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'customers-chart'
    'customers-list'
    'products-chart'
    'products-list'
    'categories-chart'
    'categories-list';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, ifr);
    grid-template-areas:
      'customers-chart customers-list'
      'products-chart products-list'
      'categories-chart categories-list';
  }
}
@media only screen and (min-width: 1300px) {
  .grid-container {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: repeat(2, minmax(100px, 1fr));
    grid-template-areas:
      'customers-chart products-chart categories-chart'
      'customers-list products-list categories-list';
  }
}
.customers-chart {
  grid-area: customers-chart;
  overflow-y: auto;
}
.products-chart {
  grid-area: products-chart;
  overflow-y: auto;
}
.categories-chart {
  grid-area: categories-chart;
  overflow-y: auto;
}
.customers-list {
  grid-area: customers-list;
  overflow-y: auto;
}
.products-list {
  grid-area: products-list;
  overflow-y: auto;
}
.categories-list {
  grid-area: categories-list;
  overflow-y: auto;
}
</style>
