<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <outstanding-profile
      class="pb-4"
      :clickable="true"
      :profiles="outstandingProfiles"
      v-on="$listeners"
    />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import outstandingProfile from '@appViews/sharedComponents/outstandingProfile.vue'

export default {
  name: 'outstandingTable',

  components: {
    pcDisplayBox,
    outstandingProfile,
  },

  props: {
    group: Object,
  },

  created() {},

  watch: {
    outstandingProfiles: {
      immediate: true,
      handler(value) {
        if (value) this.main()
      },
    },
  },

  computed: {
    outstandingProfiles() {
      return this.group ? this.group.outstandingProfile : undefined
    },
  },

  methods: {
    main() {
      const profiles = this.outstandingProfiles
      if (!profiles) return 0
      const outstanding = pc.sumArrayProp(pc.getProperty('total'), [
        ...profiles.overdue,
        ...profiles.due,
      ])
      this.displayBoxDef.title = `Outstanding £${pc.toFixed2(outstanding)}`
    },
  },

  data() {
    return {
      displayBoxDef: pcDisplayBoxDef('payment', 'Outstanding'),
    }
  },
}
</script>

<style scoped>
.profile {
  width: 25px;
  height: 25px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
}
.month-name {
  height: 9px;
  width: 25px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  transform: rotate(-60deg);
}
</style>
