import { gql } from 'graphql-tag'

const customer = gql`
  query($dbQuery: DbQuery) {
    customers(dbQuery: $dbQuery) {
      id
      name
      address {
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postalCode
        country
        attentionTo
      }
      phoneNumbers {
        phoneNumber
        phoneType
      }
      contacts {
        firstName
        lastName
        emailAddress
      }
      contactGroupId
      paymentTermsDays
      paymentTermsType
      sales
      salesByDays
      salesByMonth
      lastInvoiced
      invoices
      invoicesByDays
      invoicesByMonth
      products
      productsByMonth
      productCoveragePercentage
      productCoveragePercentageByMonth
      averageMonthlySpend
      monthSpendComparison
      monthSpendComparisonPercentage
      nextDueDaysToPay
      nextDueDate
      nextDueInvoiceNumber
      nextDueAmount
      overdue
      outstanding
      dueProfile0
      dueProfile1
      dueProfile2
      dueProfile3
      dueProfile4
      paidInvoicesProfile {
        profileIndex
        months {
          count
          profileIndex
          monthIndex
        }
      }
    }
  }
`

const categoryCustomers = gql`
  query($dbQuery: DbQuery) {
    categoryCustomers(dbQuery: $dbQuery) {
      categoryId
      sales
      salesByMonth
      lastInvoiced
    }
  }
`

const productCustomers = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      productCode
      quantity
      sales
      lastInvoiced
      quantityByMonth
      salesByMonth
    }
  }
`

const productsAndProductCustomers = gql`
  query($dbQuery: DbQuery) {
    multiQuery(dbQuery: $dbQuery) {
      products(dbQuery: $dbQuery) {
        code
        name
        ranking
        tradePrice
        RRP
      }
      productCustomers(dbQuery: $dbQuery) {
        productCode
        quantity
        sales
        salesByMonth
        lastInvoiced
      }
    }
  }
`

const invoices = gql`
  query($dbQuery: DbQuery) {
    invoices(dbQuery: $dbQuery) {
      id
      monthDueIndex
      paymentProfile
    }
  }
`

const queries = {
  customer,
  categoryCustomers,
  productCustomers,
  productsAndProductCustomers,
  invoices,
}

export { queries as customerDashboard }
