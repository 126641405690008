<template>
  <pc-lister
    v-if="categoryCustomersDef.show"
    :list-def="categoryCustomersListDef"
    :list-items="categoryCustomersListItems"
  >
    <template v-slot:toolbarActions>
      <div style="width: 300px;">
        <contact-group-select
          allKey="company"
          allName="Company"
          :count="false"
          :dark="true"
          width="275px"
          :excludeNoSales="true"
          v-on:selected="groupSelected"
        />
      </div>
      <v-radio-group
        v-model="color"
        dark
        :column="false"
        height="16px"
        class="pt-6 pr-3 mb-0"
      >
        <v-radio
          v-for="n in 3"
          color="white"
          class="pr-4"
          :key="n"
          :label="['White', 'Grey', 'Black'][n - 1]"
          :value="['White', 'Grey', 'Black'][n - 1]"
        ></v-radio>
      </v-radio-group>
      <v-text-field
        class="pt-4"
        dark
        v-model="categoryCustomersListDef.table.search"
        clearable
        placeholder="Search"
        single-line
      ></v-text-field>
    </template>
    <template v-slot:totalSales>
      <pc-spark-Chart :sparkChartDef="tooltipSparkChartDef" />
    </template>
    <template v-slot:categorySales>
      <pc-spark-Chart :sparkChartDef="tooltipSparkChartDef" />
    </template>
  </pc-lister>
</template>

<script>
import pc from '@pc'
import db from '@db'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcLineActionsDef } from '@pcComponents/defs/pcLineActionsDef.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'
import contactGroupSelect from '@sharedComponents/contactGroupSelect'
import { pcLineActionDef } from '@pcComponents/defs/pcLineActionsDef.js'

import pcLister from '@pcComponents/pcLister.vue'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'

export default {
  name: 'categoryCustomers',

  components: {
    contactGroupSelect,
    pcLister,
    pcSparkChart,
  },

  props: {
    categoryCustomersDef: Object,
  },

  computed: {},

  watch: {
    categoryCustomersDef() {
      this.main()
    },

    color() {
      this.main()
    },

    contactGroupId() {
      this.main()
    },
  },

  methods: {
    // Get the initial data and display the customers
    async main() {
      if (this.categoryCustomersDef.categoryCustomers) {
        const customers = this.getCategoryCustomers(
          this.categoryCustomersDef.categoryCustomers,
          this.contactGroupId
        )

        this.categoryCustomersListItems = pc
          .query()
          .addFilterIf(this.color === 'White', 'categorySales', 0)
          .addFilterIf(this.color === 'Black', 'categorySales', 0, 'ne')
          .run(customers)
      }
    },

    getCategoryCustomers(categoryCustomers, contactGroupId) {
      const formatCustomer = (customer, index) => ({
        id: customer.id,
        categoryCustomerIndex: -1,
        name: customer.name,
        phoneNumbers: customer.phoneNumbers,
        contacts: customer.contacts,
        ranking: customer.sales ? index + 1 : 0,
        totalSales: customer.sales,
        lastActive: customer.lastInvoiced,
        categorySales: 0,
        categoryLastBought: '',
      })

      const addCategorySales = (customers, categoryCustomer, index) => {
        const customer = customers.find(
          customer => customer.id === categoryCustomer.customerId
        )
        if (customer) {
          customer.categoryCustomerIndex = index
          customer.categorySales = categoryCustomer.sales
          customer.categoryLastBought = categoryCustomer.lastInvoiced
        }
        return customers
      }

      const customers = db
        .cached(
          'customers',
          db
            .cachedQuery()
            .addFilter('sales', 0, 'ne')
            .addFilterIf(
              contactGroupId !== 'company',
              'contactGroupId',
              contactGroupId
            )
            .addSort('sales', true)
        )
        .map(formatCustomer)

      return categoryCustomers.reduce(addCategorySales, customers)
    },

    // Called from pcLister for the lastInvoiced field to
    // apply a color to the date based on the time elapesd
    lastInvoicedClass(payload) {
      return lastInvoicedClass(
        pc.getProperty(payload.field.dataPath, payload.data),
        this.period.periodEndDate
      )
    },

    // Called from pcLister for the sales field to
    // apply a color to the sales if the customer
    // has purchased the category - grey is purchased
    categorySalesItemClass(payload) {
      return pc.getProperty(payload.field.dataPath, payload.data)
        ? `chip blue-grey lighten-4`
        : ''
    },

    // Tooltip call from pcLister
    // The tooltip updates the pcSparkChart component
    // to display the tooltip
    tooltipHandler(payload) {
      const sparkChart = (title, subTitle, legend, data) => {
        this.tooltipSparkChartDef
          .clearDatasets()
          .setTitle(title)
          .setSubTitle(subTitle)
          .addDataset(pc.arrayFixed0(data), legend)
          .render()
      }
      const { data, field } = payload

      switch (field.id) {
        case 'totalSales': {
          const customer = db.cached(
            'customers',
            db.cachedQuery().addFind('id', data.id)
          )
          sparkChart(
            customer.name,
            `Total customer sales £${pc.toFixed2(customer.sales)}`,
            'Sales',
            customer.salesByMonth
          )
          break
        }
        case 'categorySales': {
          const index = data.categoryCustomerIndex
          const categoryCustomer =
            index > -1
              ? this.categoryCustomersDef.categoryCustomers[index]
              : undefined
          sparkChart(
            data.name,
            `${this.categoryCustomersDef.category.name} sales £${pc.toFixed2(
              index > -1 ? categoryCustomer.sales : 0
            )}`,
            'Category sales',
            index > -1
              ? categoryCustomer.salesByMonth
              : pc.createArray(0, this.period.monthsInPeriod)
          )
          break
        }
      }
    },

    groupSelected(groupId) {
      this.contactGroupId = groupId
    },

    showCustomerDashboard(payload) {
      this.$emit('showCustomerDashboard', {
        customerId: payload.data.id,
        from: 'categoryDashboard',
        screen: 'customers',
      })
    },

    createCSV() {
      pc.csvFromLister(
        this.categoryCustomersListDef,
        this.categoryCustomersListItems
      )
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      contactGroupId: 'company',
      contactGroupsData: {},
      color: 'Grey',

      categoryCustomersListDef: {
        cardDef: pcCardDef('categoryCustomers', false, {
          subTitleClass: 'blue lighten-4',
          elevation: '0',
        }),
        toolbarDef: pcToolbarDef('categoryCustomersToolbar', 'Customers', {
          dense: true,
          color: 'primary lighten-2',
          actions: [
            pcToolbarActionDef(
              'csv',
              'mdi-file-excel',
              this.createCSV,
              'Create CSV file',
              'white'
            ),
          ],
        }),
        table: pcTableDef('categoryCustomersTable', 'code', ['code'], [false], {
          noDataMessage: '',
        }),
        fields: [
          pcFieldDef('name', 'text', '', 'Customer name', true),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('ranking', 'number', '', 'Ranked'),
          pcFieldDef(
            'totalSales',
            'currency',
            'totalSales',
            'Total sales',
            true,
            {
              tooltipDef: pcTooltipDef('totalSales', this.tooltipHandler),
            }
          ),
          pcFieldDef('lastActive', 'ISODate', '', 'Last active', true, {
            itemClass: this.lastInvoicedClass,
          }),
          pcFieldDef(
            'categorySales',
            'currency',
            'categorySales',
            'Category sales',
            true,
            {
              itemClass: this.categorySalesItemClass,
              tooltipDef: pcTooltipDef('categorySales', this.tooltipHandler),
            }
          ),
          pcFieldDef(
            'categoryLastBought',
            'ISODate',
            '',
            'Last bought category',
            true,
            {
              itemClass: this.lastInvoicedClass,
            }
          ),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },
      categoryCustomersListItems: [],

      salesTooltipDef: pcTooltipDef('tooltip'),
      tooltipSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
