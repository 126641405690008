<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-simple-table :simpleTableDef="simpleTableDef" @tooltip="tooltip">
      <template v-slot:sales>
        <pc-spark-chart :sparkChartDef="sparkChartDef" />
      </template>
    </pc-simple-table>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { getColors } from '@pcModules/pcColors.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

export default {
  name: 'categoryContactGroupsList',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {
    categorySummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categorySummaryDef.category
    },

    contactGroupCategories() {
      return this.categorySummaryDef.contactGroupCategories
    },
  },

  watch: {
    categorySummaryDef() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.isCategory) {
        this.simpleTableDef.data = this.contactGroupCategories.map(
          (contactGroupCategory, index) => {
            const contactGroup = db.cached(
              'contactGroups',
              db
                .cachedQuery()
                .addFind('id', contactGroupCategory.contactGroupId)
            )
            return {
              tag: this.chartColors[index],
              name: contactGroup.name,
              sales: contactGroupCategory.sales,
              salesByMonth: contactGroupCategory.salesByMonth,
              percentage: pc.percentage(
                contactGroupCategory.sales,
                this.category.sales
              ),
              lastInvoiced: contactGroupCategory.lastInvoiced,
            }
          }
        )
      }
    },

    tooltip(payload) {
      const { data } = payload
      const titleColor = pc.getProperty('tag', data)

      this.sparkChartDef
        .clearDatasets()
        .setTitle(data.name, titleColor)
        .setSubTitle(
          `Sales £${pc.toFixed2(data.sales)} - ${pc.toFixed2(data.percentage)}%`
        )
        .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales', titleColor)
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      displayBoxDef: pcDisplayBoxDef(
        'categoryContactGroupList',
        'Category sales by contact group'
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('tag', 'tag', ''),
          pcSimpleTableFieldDef('name', 'text', 'Contact group'),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last<br />invoiced'
          ),
        ],
      }),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      chartColors: getColors('impact'),
    }
  },
}
</script>

<style scoped></style>
