import * as R from 'ramda'
import { toFixed0, toFixed2 } from './number.js'

/** ---------------------------------------------------------------------------
 * @function createArray
 * @summary createArray :: a -> b -> [a]
 * @desc Creates a new array of <b>length</b> filled with <b>fillWith</b>
 * @param {any} fillWith Value to fill each array element
 * @param {number} length Length of array
 * @return {array}
 */
export const createArray = R.curry((fillWith, length) =>
  new Array(length).fill(fillWith)
)

/** ---------------------------------------------------------------------------
 * @function idObjectPairArray
 * @summary idObjectPairArray :: (a -> b) -> [a] -> [[b, a]]
 * @desc Creates an new array of [id, record] pairs - useful for initialising a Map Object
 * @param {function} fn function called with array element and must return id
 * @param {array} arr containing records
 * @return {array} An array of [[id, record], [id, record]] pairs
 */
export const idObjectPairArray = R.curry((fn, arr) =>
  R.map(record => [fn(record), record], arr)
)

/** ---------------------------------------------------------------------------
 * @function oneiseArray
 * @summary oneiseArray :: (a -> Boolean) -> [a] -> [Number]
 * @desc Creates an array of 0's (false) or 1's (true) based on result of the predicate
 * @param {function} fn function called with array element and must return a Boolean
 * @param {array} arr array containing the list of to be converted
 * @return {array} an array of either 0 or 1 for each element
 */
export const oneiseArray = R.curry((fn, arr) =>
  R.map(element => (fn(element) ? 1 : 0), arr)
)

/** ---------------------------------------------------------------------------
 * @function sumArrayProp
 * @summary sumArrayProp :: (a -> Number) -> [a] -> Number
 * @desc Sums a property of objects of an array
 * @param {function} fn function to return a number value from an object to be summed
 * @param {array} arr array of objects containing the property to be summed
 * @return {Number} the result of the sumation
 */
export const sumArrayProp = R.curry((fn, arr) =>
  R.reduce(
    (accumulator, arrayElement) => R.add(accumulator, fn(arrayElement)),
    0,
    arr
  )
)

/** ---------------------------------------------------------------------------
 * @function sumArray
 * @summary sumArray :: [Number] -> Number
 * @desc Sums an array of numberic values
 * @param {array} arr array of numeric values to be summed
 * @return {Number} the result of the sumation
 */
export const sumArray = sumArrayProp(R.identity)

/** ---------------------------------------------------------------------------
 * @function sumArrays
 * @summary sumArrays :: [Number] -> [Number] -> [Number]
 * @desc Creates an array where each element is the sum of corresponding elements of array1 and array 2
 * @param {array} array1 array of numeric values to be summed
 * @param {array} array2 array of numeric values to be summed
 * @return {array} the result of the sumation
 */
export const sumArrays = R.curry((arr1, arr2) => {
  const mapIndexed = R.addIndex(R.map)
  const addElements = (arr1Element, index) => R.add(arr1Element, arr2[index])
  return mapIndexed(addElements, arr1)
})

/** ---------------------------------------------------------------------------
 * @function sumArraysProp
 * @summary sumArraysProp :: fn -> Array Object -> Array
 * @desc Sums an array property of an array of objects
 * @param {function} fn function to return the array from the objects property
 * @param {array} arr array of objects containing the array property to be summed
 * @return {array} the result of the sumation
 */
export const sumArraysProp = R.curry((fn, arr) =>
  R.reduce(
    (accumulator, arrayElement) => {
      const field = fn(arrayElement)
      !accumulator.length &&
        (() => (accumulator = createArray(0, field.length)))()
      return sumArrays(accumulator, field)
    },
    [],
    arr
  )
)

/** ---------------------------------------------------------------------------
 * @function isArray
 * @summary isArray :: a -> Boolean
 * @desc Return true if argument is an array else false
 * @param {any} obj to be checked if array
 * @return {Boolean} true is array otherwise false
 */
export const isArray = obj =>
  Object.prototype.toString.call(obj) === '[object Array]'

/** ---------------------------------------------------------------------------
 * @function arraysEqual
 * @summary arraysEqual :: [a] -> [b] -> Boolean
 * @desc Return true array a is the same length and contains the same values as array b
 * @param array1  array for comparison
 * @param array2  array for comparison
 * @return {Boolean} true is array1 is equal to array2 otherwise false
 */
export const arraysEqual = (array1, array2) => {
  if (array1.lenfth !== array2.length) return false
  return array1.reduce((equal, value, index) => {
    return equal ? value === array2[index] : false
  }, true)
}

export const arrayFixed0 = array => array.map(value => toFixed0(value))
export const arrayFixed2 = array => array.map(value => toFixed2(value))

export const arrayLastElement = array =>
  array.length ? array[array.length - 1] : undefined

// ----------------------------------------------------------------------------
export default {
  createArray,
  idObjectPairArray,
  oneiseArray,
  sumArrayProp,
  sumArray,
  sumArrays,
  sumArraysProp,
  isArray,
  arrayFixed0,
  arrayFixed2,
  arraysEqual,
  arrayLastElement,
}
