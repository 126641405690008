<template>
  <td v-if="showActions" class="pl-0 pr-0">
    <span v-if="selected[0] === props.index" class="group">
      <v-icon
        v-for="action in listDef.lineActions.actions"
        :key="action.id"
        class="px-1"
        :color="action.iconColor"
        @click.stop="lineAction(action)"
      >
        {{ action.icon }}
      </v-icon>
    </span>
  </td>
</template>

<script>
import pc from '@pc'

export default {
  name: 'pcLineActions',
  components: {},
  props: {
    listDef: Object,
    props: Object,
    selected: Array,
  },
  created() {},
  computed: {
    showActions() {
      if (!this.listDef.lineActions) return false
      return (
        this.listDef.lineActions.actions.length &&
        pc.getBreakpointValue(this.listDef.lineActions.show, this.breakpoint)
      )
    },
  },
  methods: {
    eventOrCall(item, payload) {
      if (typeof item === 'function') return item(payload)
      if (typeof item === 'string') {
        const event = item
        pc.$eventBus.$emit(event, payload)
      }
    },
    lineAction(lineAction) {
      const action = lineAction.action
      this.eventOrCall(action, {
        lineAction,
        data: this.props.item,
        props: this.props,
      })
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped></style>
