<template>
  <v-row
    v-if="contactGroupCoverageDef.show"
    id="contactGroupCoverage"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-col d-flex pb-0">
      <div class="grid-container pr-0 pt-0 pb-0">
        <div class="tree">
          <coverageTree
            :group="group"
            :treeDef="treeDef"
            @item="displayItemDetails"
          />
        </div>
        <div class="statistics">
          <div class="grid-statistics-container">
            <item-sales-chart class="sales-chart" :group="group" :item="item" />
            <div class="coverage-pie">
              <customer-coverage-pie :group="group" :item="item" />
            </div>
            <div class="coverage-bar">
              <customer-coverage-bar :group="group" :item="item" />
            </div>
          </div>
        </div>
        <div class="customers">
          <coverage-customers :group="group" :item="item" v-on="$listeners" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
//import pc from '@pc'
//import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
//import db from '@db'
import { getCategoryTree } from './getCategoryTree/getCategoryTree.js'
import coverageTree from './coverageTree.vue'
import customerCoveragePie from './customerCoveragePie.vue'
import customerCoverageBar from './customerCoverageBar.vue'
import itemSalesChart from './itemSalesChart.vue'
import coverageCustomers from './customers/coverageCustomers.vue'

export default {
  name: 'contactGroupCoverage',

  components: {
    coverageTree,
    customerCoveragePie,
    customerCoverageBar,
    itemSalesChart,
    coverageCustomers,
  },

  props: {
    contactGroupCoverageDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    group() {
      return this.contactGroupCoverageDef.contactGroup
    },
  },

  watch: {
    group() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.group) {
        const tree = await getCategoryTree(this.group.id)
        this.treeDef = tree
      }
    },

    displayItemDetails(item) {
      this.item = item
    },
  },

  data() {
    return {
      categories: [],
      products: [],
      treeDef: {},
      item: undefined,
      //   categorySummaryCardDef: pcCardDef('categorySummary', false, {
      //     loaderType: 'card',
      //     elevation: '0',
      //   }),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(1fr ifr 1fr);
  grid-template-areas:
    'tree'
    'statistic'
    'customers';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'tree'
      'statistic'
      'customers';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: 1fr minmax(350px, 0.5fr) 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'tree statistics customers';
  }
}

.grid-statistics-container {
  display: grid;
  gap: 12px 12px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'sales-chart'
    'coverage-pie'
    'coverage-bar';
}

.tree {
  grid-area: tree;
  overflow-y: scroll;
}

.statistics {
  grid-area: statistics;
  overflow-y: auto;
}

.customers {
  grid-area: customers;
  overflow-y: auto;
}

.sales-chart {
  grid-area: sales-chart;
  overflow-y: auto;
}

.coverage-pie {
  grid-area: coverage-pie;
  overflow-y: auto;
}

.coverage-bar {
  grid-area: coverage-bar;
  overflow-y: auto;
}
</style>
