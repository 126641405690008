const _pcNuggetDef = () => ({
  id: 'nuggetId',
  show: true,
  if: true,
  title: 'Nugget',
  titleClass: '',
  titleColor: 'primary',
  titleElevation: '2',

  height: '95',
  width: '120',
  class: '',
  color: 'primary lighten-2',
  rounded: 'lg',
  elevation: '4',
  bodyClass: '',
  body: '',
});

const pcNuggetDef = function(id, title, other = {}) {
  const newNugget = _pcNuggetDef();
  if (!id) {
    console.log('Must specify an id for a pcNugget');
  }

  newNugget.id = id;
  newNugget.title = title;

  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(newNugget, key)) {
      newNugget[key] = value;
    } else {
      console.log(`Invalid property: ${key} in pcNugget id ${id}`);
    }
  });
  return newNugget;
};

export { pcNuggetDef };
