<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <div v-if="telephoneNumbers.length > 0" class="mt-2">
      <v-divider />
      <div class="mt-1 ml-3 subtitle-2">
        {{ `Telephone number${telephoneNumbers.length > 1 ? 's' : ''}` }}
      </div>
      <v-simple-table dense class="grey lighten-5">
        <template v-slot:default>
          <tbody>
            <tr v-for="(telephone, index) in telephoneNumbers" :key="index">
              <td>
                {{ telephone.phoneType }}
              </td>
              <td class="pl-4">{{ telephone.phoneNumber }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div v-if="contacts.length > 0" class="mt-2">
      <v-divider />
      <div class="mt-1 ml-3 subtitle-2">
        {{ `Contact${contacts.length > 1 ? 's' : ''}` }}
      </div>
      <v-simple-table dense class="grey lighten-5">
        <template v-slot:default>
          <tbody>
            <tr v-for="(contact, index) in contacts" :key="index">
              <td>
                {{
                  `${contact.firstName ? contact.firstName : ''} ${
                    contact.lastName ? contact.lastName : ''
                  }`.trim()
                }}
              </td>
              <td class="pl-4">{{ contact.emailAddress }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-divider />
    <div class="mt-1 ml-3 pb-1 subtitle-2">Contact group</div>
    <div class="pl-3 pr-3" :class="displayBoxDef.font">{{ contactGroup }}</div>
  </pc-display-box>
</template>

<script>
import db from '@db'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'customerDetails',

  components: {
    pcDisplayBox,
  },

  props: {
    customer: Object,
  },

  created() {
    this.main()
  },

  watch: {
    customer() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.customer.id) {
        this.displayBoxDef.displayNameAndAddress(
          this.customer.name,
          this.customer.address
        )

        this.telephoneNumbers = this.customer.phoneNumbers
        this.contacts = this.customer.contacts
        this.contactGroup = this.getContactGroupName(
          this.customer.contactGroupId
        )
      }
    },

    getContactGroupName(id) {
      const contactGroup = db.cached(
        'contactGroups',
        db.cachedQuery().addFind('id', id)
      )
      if (!contactGroup) return 'none'
      if (!this.customer.sales) {
        return `${contactGroup.name} - customer turnover £0.00`
      }
      return `${
        contactGroup.name
      } - customer turnover of £${this.customer.sales.toFixed(2)} represents ${(
        (this.customer.sales / contactGroup.sales) *
        100
      ).toFixed(1)}% of contact group turnover and ${(
        (this.customer.sales / this.period.sales) *
        100
      ).toFixed(1)}% of company turnover.`
    },
  },

  data() {
    return {
      period: db.cached('period'),

      displayBoxDef: pcDisplayBoxDef('address', 'Customer details'),

      telephoneNumbers: [],
      contacts: [],
      contactGroup: '',
    }
  },
}
</script>

<style scoped></style>
