import { gql } from 'graphql-tag'

const products = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      code
      name
      sales
      salesByMonth
    }
  }
`

const productCustomers = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      productCode
      quantity
      sales
      lastInvoiced
      quantityByMonth
      salesByMonth
      customer {
        id
        name
        phoneNumbers {
          phoneType
          phoneNumber
        }
        contacts {
          firstName
          lastName
          emailAddress
        }
        contactGroupId
      }
    }
  }
`

const categoryCustomers = gql`
  query($dbQuery: DbQuery) {
    categoryCustomers(dbQuery: $dbQuery) {
      categoryId
      sales
      lastInvoiced
      salesByMonth
      customer {
        id
        name
        phoneNumbers {
          phoneType
          phoneNumber
        }
        contacts {
          firstName
          lastName
          emailAddress
        }
        contactGroupId
      }
    }
  }
`

const queries = {
  products,
  productCustomers,
  categoryCustomers,
}

export { queries as salesAnalysis }
