<template>
  <pc-card v-if="categoryTop10Def.show" :card-def="cardDef">
    <template v-slot:title>
      <pc-toolbar class="toolbar" :toolbarDef="toolbarDef">
        <template v-slot:toolbarActions>
          <div style="width: 300px;">
            <contact-group-select
              allKey="total"
              allName="Company"
              :count="false"
              :dark="true"
              width="275px"
              :excludeNoSales="true"
              v-on:selected="groupSelected"
            />
          </div>
          <div style="width: 200px;">
            <v-select
              :items="top10Periods"
              v-model="periodIndex"
              :dark="true"
              dense=""
              label="Category sales"
              hide-details=""
              single-line
            ></v-select>
          </div>
        </template>
      </pc-toolbar>
    </template>

    <template v-slot:subTitle>
      <categoryDBTop10Summary class="pb-2" :top10Data="top10Summary" />
    </template>

    <template v-slot:text>
      <v-row
        id="categoryDashboardTop10"
        class="fill-height mt-0"
        justify="center"
        align="stretch"
      >
        <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
          <div class="content pc-size100 pt-3 pl-3 pr-0">
            <div class="grid-content">
              <div class="customers-not-bought-category">
                <categoryDBCustomersNeverBoughtList
                  :top10Data="customersNeverBoughtCategory"
                  v-on="$listeners"
                />
              </div>
              <div class="customers-bought-chart">
                <categoryDBCustomersBoughtChart
                  :top10Data="customersBoughtCategory"
                />
              </div>

              <div class="customers-not-bought-in-period">
                <categoryDBCustomersNotBoughtInPeriodList
                  :top10Data="customersNotBoughtCategoryInPeriod"
                  v-on="$listeners"
                />
              </div>
              <div class="customers-bought-list">
                <categoryDBCustomersBoughtList
                  :top10Data="customersBoughtCategory"
                  v-on="$listeners"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </pc-card>
</template>

<script>
import pc from '@pc'
import db from '@db'
import pcCard from '@pcComponents/pcCard.vue'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect'
import { CategoryCustomers } from '../../productDashboard/productTop10/categoryCustomersClass.js'
import categoryDBCustomersNeverBoughtList from './categoryDBCustomersNeverBoughtList.vue'
import categoryDBCustomersNotBoughtInPeriodList from './categoryDBCustomersNotBoughtInPeriodList.vue'
import categoryDBCustomersBoughtChart from './categoryDBCustomersBoughtChart.vue'
import categoryDBCustomersBoughtList from './categoryDBCustomersBoughtList.vue'
import categoryDBTop10Summary from './categoryDBTop10Summary.vue'
import { getColors } from '@pcModules/pcColors.js'

export default {
  name: 'categoryTop10',

  components: {
    pcCard,
    pcToolbar,
    contactGroupSelect,
    categoryDBCustomersNeverBoughtList,
    categoryDBCustomersNotBoughtInPeriodList,
    categoryDBCustomersBoughtChart,
    categoryDBCustomersBoughtList,
    categoryDBTop10Summary,
  },

  props: {
    categoryTop10Def: Object,
  },

  computed: {
    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categoryTop10Def.category
    },

    categoryCustomers() {
      return this.categoryTop10Def.categoryCustomers
    },

    salesFieldName() {
      return this.periodIndex === -1
        ? 'sales'
        : `salesByDays[${this.periodIndex}]`
    },

    periodCaption() {
      return `${
        this.periodIndex === -1
          ? this.period.text
          : this.period.salesByDaysSpec.captions[this.periodIndex]
      }`
    },
  },

  watch: {
    categoryTop10Def() {
      this.main()
    },
    periodIndex() {
      this.main()
    },
    contactGroupId() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.category) {
        const categoryCustomers = new CategoryCustomers()

        await categoryCustomers.compute(
          this.category.id,
          this.contactGroupId,
          this.salesFieldName,
          this.categoryCustomers
        )

        this.customersNeverBoughtCategory = {
          period: this.period,
          periodCaption: this.periodCaption,
          category: this.category,
          customers: categoryCustomers.notBoughtAll.slice(0, 10),
        }

        this.customersNotBoughtCategoryInPeriod = {
          period: this.period,
          periodCaption: this.periodCaption,
          category: this.category,
          customers: categoryCustomers.notBoughtInSalesPeriodButBoughtPreviously.slice(
            0,
            10
          ),
        }

        this.customersBoughtCategory = {
          period: this.period,
          periodCaption: this.periodCaption,
          category: this.category,
          customers: this.formatTop10CustomersBought(categoryCustomers),
        }

        this.top10Summary = {
          period: this.period,
          periodCaption: this.periodCaption,
          salesFieldName: this.salesFieldName,
          category: this.category,
          categoryCustomers,
        }
      }
    },

    formatTop10CustomersBought(categoryCustomers) {
      const totalSales = pc.sumArrayProp(
        pc.getProperty(categoryCustomers.salesFieldName),
        categoryCustomers.boughtInSalesPeriod
      )
      const totalSalesByMonth = pc.sumArraysProp(
        pc.prop('salesByMonth'),
        categoryCustomers.boughtInSalesPeriod
      )

      const bought = categoryCustomers.boughtInSalesPeriod
        .slice(0, 10)
        .map((categoryCustomer, index) => {
          return {
            tag: getColors('impact')[index],
            customerId: categoryCustomer.customerId,
            name: categoryCustomer.customerName,
            sales: pc.getProperty(
              categoryCustomers.salesFieldName,
              categoryCustomer
            ),
            salesByMonth: categoryCustomer.salesByMonth,
            percentage: totalSales
              ? (pc.getProperty(
                  categoryCustomers.salesFieldName,
                  categoryCustomer
                ) /
                  totalSales) *
                100
              : 0,
            lastInvoiced: categoryCustomer.lastInvoiced,
          }
        })

      const boughtTop10Sales = pc.sumArrayProp(pc.prop('sales'), bought)
      const boughtTop10SalesByMonth = pc.sumArraysProp(
        pc.prop('salesByMonth'),
        bought
      )

      const allOtherCustomerSales = totalSales - boughtTop10Sales
      const allOtherCustomerSalesByMonth = totalSalesByMonth.map(
        (total, index) => total - boughtTop10SalesByMonth[index]
      )

      allOtherCustomerSales &&
        bought.push({
          tag: getColors('impact')[bought.length],
          customerId: 'All',
          name: 'All other customers',
          sales: allOtherCustomerSales,
          salesByMonth: allOtherCustomerSalesByMonth,
          percentage: totalSales
            ? (allOtherCustomerSales / totalSales) * 100
            : 0,
          lastInvoiced: '',
        })

      return bought
    },

    groupSelected(groupId) {
      this.contactGroupId = groupId
      this.updateTop10DataProp()
    },

    updateTop10DataProp() {
      this.top10DataProp = {
        top10Period: this.top10Period,
        groupId: this.groupId,
        contactGroupsData: this.contactGroupsData,
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      contactGroupId: 'total',
      customersNotBoughtCategoryInPeriod: {},
      customersNeverBoughtCategory: {},
      customersBoughtCategory: {},
      top10Summary: {},
      top10DataProp: {},
      contactGroupsData: {},

      cardDef: pcCardDef('categoryTop10', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef('top10Toolbar', 'Top 10 customers', {
        dense: true,
        color: 'primary lighten-2',
        actions: [],
      }),

      top10Periods: [
        { text: 'In the period', value: -1 },
        ...period.salesByDaysSpec.captions.reduce((data, caption, index) => {
          data.unshift({
            text: caption,
            value: index,
          })
          return data
        }, []),
      ],

      periodIndex: -1,
    }
  },
}
</script>

<style scoped>
.grid-content {
  display: grid;
  gap: 12px 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'customers-not-bought-category'
    'customers-not-bought-category-in-period'
    'customers-bought-chart'
    'customers-bought-list';
}

@media only screen and (min-width: 1024px) {
  .grid-content {
    display: grid;
    gap: 12px 12px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'customers-not-bought-product customers-not-bought-product-in-period'
      'customers-not-bought-category customers-not-bought-category-in-period'
      'customers-bought-chart customers-bought-list';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-content {
    display: grid;
    gap: 12px 12px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'customers-not-bought-category customers-bought-chart'
      'customers-not-bought-category-in-period customers-bought-list';
  }
}

.customers-not-bought-product {
  grid-area: customers-not-bought-product;
  overflow-y: auto;
}
.customers-not-bought-product-in-period {
  grid-area: customers-not-bought-product-in-period;
  overflow-y: auto;
}
.customers-bought-chart {
  grid-area: customers-bought-chart;
  overflow-y: auto;
}
.customers-not-bought-category {
  grid-area: customers-not-bought-category;
  overflow-y: auto;
}
.customers-not-bought-category-in-period {
  grid-area: customers-not-bought-category-in-period;
  overflow-y: auto;
}
.customers-bought-list {
  grid-area: customers-bought-list;
  overflow-y: auto;
}
.contact-group {
  display: flex;
  margin: 8px, 12px, 8px, 12px;
  border-style: solid;
}
</style>
