<template>
  <v-row
    v-if="categorySummaryDef.show"
    id="categorySummary"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-col d-flex pt-3 pl-6 pb-3">
      <div class="grid-container">
        <div class="ranking">
          <categoryRankingChart :categorySummaryDef="categorySummaryDef" />
          <categoryCustomerCoverage
            class="pt-3 "
            :categorySummaryDef="categorySummaryDef"
          />
        </div>
        <div class="sales-chart">
          <category-sales-chart :categorySummaryDef="categorySummaryDef" />
        </div>
        <div class="contact-groups-chart">
          <categoryContactGroupsChart
            :categorySummaryDef="categorySummaryDef"
          />
        </div>
        <div class="statistics">
          <category-statistics :categorySummaryDef="categorySummaryDef" />
        </div>
        <div class="components">
          <categoryComponents :categorySummaryDef="categorySummaryDef" />
        </div>
        <div class="contact-groups-list">
          <categoryContactGroupsList :categorySummaryDef="categorySummaryDef" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
//import pc from '@pc'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'

//import categoryDetails from './categoryDetails.vue'
import categoryCustomerCoverage from './categoryCustomerCoverage.vue'
import categoryStatistics from './categoryStatistics.vue'
import categorySalesChart from './categorySalesChart.vue'
import categoryRankingChart from './categoryRankingChart.vue'
import categoryContactGroupsChart from './categoryContactGroupsChart.vue'
import categoryContactGroupsList from './categoryContactGroupsList.vue'
import categoryComponents from './categoryComponents.vue'
// import categoryCategorySalesChart from './categoryCategorySalesChart.vue'

export default {
  name: 'categorySummary',
  components: {
    //categoryDetails,
    categoryCustomerCoverage,
    categoryStatistics,
    categorySalesChart,
    categoryRankingChart,
    categoryContactGroupsChart,
    categoryContactGroupsList,
    categoryComponents,
  },

  props: {
    categorySummaryDef: Object,
  },

  created() {},

  computed: {},

  watch: {},

  methods: {},

  data() {
    return {
      categorySummaryCardDef: pcCardDef('categorySummary', false, {
        loaderType: 'card',
        elevation: '0',
      }),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'ranking'
    'sales-chart'
    'statistics'
    'components'
    'contact-groups-chart'
    'contact-groups-list';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'ranking sales-chart'
      'statistics components'
      'contact-groups-chart contact-groups-list';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: repeat(2, minmax(100px, 1fr));
    grid-template-areas:
      'ranking sales-chart contact-groups-chart'
      'statistics components contact-groups-list';
  }
}
.ranking {
  grid-area: ranking;
  overflow-y: auto;
}

.sales-chart {
  grid-area: sales-chart;
  overflow-y: auto;
}

.contact-groups-chart {
  grid-area: contact-groups-chart;
  overflow-y: auto;
}

.statistics {
  grid-area: statistics;
  overflow-y: auto;
}

.components {
  grid-area: components;
  overflow-y: auto;
}

.contact-groups-list {
  grid-area: contact-groups-list;
  overflow-y: auto;
}
</style>
