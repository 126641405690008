<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
// import {
//   PAYMENT_PROFILE_UI_TITLE_COLOR_CLASS,
//   //PAYMENT_PROFILE_UI_TEXT_COLOR_CLASS,
// } from '@appModules/ws.js'

export default {
  name: 'paidInvoicesChart',

  components: {
    pcChart,
    pcDisplayBox,
  },

  props: {
    paidInvoicesProfile: Array,
    profileIndex: Number,
  },

  computed: {},

  watch: {
    paidInvoicesProfile: {
      immediate: true,
      handler(value) {
        if (value) this.main()
      },
    },
  },

  methods: {
    main() {
      const legend = profileIndex =>
        `% invoices paid ${
          [
            'on time',
            '1-7 days late',
            '8-14 days late',
            '15-21 days late',
            'over 21 days late',
          ][profileIndex]
        }`

      const totalPaidByMonth = this.period.monthKeys.reduce(
        (totals, monthKey, monthIndex) => {
          this.paidInvoicesProfile.forEach(profile => {
            totals[monthIndex] += profile.months[monthIndex].count
          })
          return totals
        },
        pc.createArray(0, this.period.monthsInPeriod)
      )

      const reportingProfile = this.paidInvoicesProfile.find(
        profile => profile.profileIndex === this.profileIndex
      )

      const paidPercentageByMonth = reportingProfile
        ? totalPaidByMonth.map((total, monthIndex) => {
            const paidInvoices = reportingProfile.months[monthIndex].count
            return pc.percentage(paidInvoices, total)
          })
        : pc.createArray(0, this.period.monthsInPeriod)

      this.displayBoxDef.title = legend(this.profileIndex)
      this.lineChartDef
        .clearDatasets()
        .addDataset(
          pc.arrayFixed2(paidPercentageByMonth),
          legend(this.profileIndex),
          getColors('impact')[0]
        )
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef('categoryPieChart', 'Sales by category'),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
