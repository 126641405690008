export const getCategoryAndProductUpdates = (tree, deletedCategoryItems) => {
  const formatDeletedCategory = item => ({
    id: item.id,
    name: item.name,
    level: item.level,
    grandParentId: item.parentId,
    parentId: item.parentId,
    isModified: false,
    isAdded: false,
    isDeleted: true,
  })

  const categoryUpdates = deletedCategoryItems.map(formatDeletedCategory)
  const productUpdates = []

  const processItem = item => {
    if (item.isProduct) {
      if (item.isModified)
        productUpdates.push({
          code: item.code,
          category: { ...item.category },
        })
      return
    }

    if (item.isModified || item.isAdded) {
      categoryUpdates.push({
        id: item.id,
        name: item.name,
        level: item.level,
        grandParentId: item.parentId,
        parentId: item.parentId,
        isModified: item.isModified,
        isAdded: item.isAdded,
        isDeleted: false,
      })
    }

    if (!item.children.length) return
    for (let i = 0; i < item.children.length; i++) {
      processItem(item.children[i])
    }
  }

  processItem(tree[0])
  return { categoryUpdates, productUpdates }
}
