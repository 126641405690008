import { GraphQLClient } from 'graphql-request'
import pc from '@pc'
import { queryLibrary } from './dbQueryLibraryClass'
import { Query as CachedQuery } from '@pcModules/queryClass'

function getHost() {
  const host = window.location.origin
  return host === 'http://localhost:8080'
    ? 'http://localhost:3000/'
    : host + '/'
}

function convertFindFilter(fieldPath, value, comparator) {
  const filter = { fieldPath, comparator }
  const valueKey =
    typeof value === 'number'
      ? 'nValue'
      : typeof value === 'boolean'
      ? 'bValue'
      : 'sValue'
  filter[valueKey] = value
  return filter
}

function gqlQueryName(gqlQuery) {
  try {
    return gqlQuery.definitions[0].selectionSet.selections[0].name.value
  } catch {
    return `Can't get db.request query name`
  }
}

class Query {
  constructor(gqlField) {
    this.gqlField = gqlField
  }

  addNone() {
    this.none = true
    return this
  }

  addFind(fieldPath, value, comparator = 'eq') {
    this.find = this.find || []
    this.find.push(convertFindFilter(fieldPath, value, comparator))
    return this
  }

  addInclude(fieldPath, values) {
    this.include = { fieldPath, values }
    return this
  }

  addExclude(fieldPath, values) {
    this.exclude = { fieldPath, values }
    return this
  }

  addFilter(fieldPath, value, comparator = 'eq') {
    this.filter = this.filter || []
    this.filter.push(convertFindFilter(fieldPath, value, comparator))
    return this
  }

  addFilterIf(condition, fieldPath, value, comparator = 'eq') {
    if (typeof condition === 'function' ? condition() : condition)
      this.addFilter(fieldPath, value, comparator)
    return this
  }

  addLimit(limit) {
    this.limit = { limit }
    return this
  }

  addLimitAfterSort(limit) {
    this.limitAfterSort = limit
    return this
  }

  addSort(fieldPath, descend = false) {
    this.sort = { fieldPath, descend }
    return this
  }

  addSum(fieldPathArrayOrString) {
    this.sum = this.sum || []
    if (Array.isArray(fieldPathArrayOrString)) {
      this.sum = this.sum.concat(fieldPathArrayOrString)
    } else {
      this.sum.push(fieldPathArrayOrString)
    }
    return this
  }
}

class QueryArgs {
  constructor(queryArgs) {
    this.dbQuery = {
      query: [],
    }
    if (queryArgs) this.addQueryArgs(queryArgs)
  }

  getQuery(gqlField) {
    const query = this.dbQuery.query.find(query => query.gqlField === gqlField)
    return query
  }

  addQueryArgs(query) {
    this.dbQuery.query.push(query)
    return this
  }
}

class Db {
  constructor(urlEndpoint) {
    this.urlEndpoint = urlEndpoint
    this.client = new GraphQLClient(urlEndpoint, {
      credentials: 'include',
      headers: {},
    })
    this.queryLibrary = queryLibrary
    this.cacheStore = {}
  }

  async request(gqlQuery, queryArgs) {
    try {
      // Extract the query name from the graphQl AST
      const name = gqlQuery.definitions[0].selectionSet.selections[0].name.value
      // Function to determin if only one record requested - should not return an array
      const expectOneRecord = () => {
        // Check to see if there is a query
        if (!queryArgs) return false
        if (!queryArgs.dbQuery) return false
        if (!queryArgs.dbQuery.query) return false
        if (!queryArgs.dbQuery.query.length) return false
        // Get this query, if any
        const query = queryArgs.dbQuery.query.find(
          query => query.gqlField === name
        )
        if (!query) return false
        // Only expecting one record for queries with find or sum selectors
        return pc.isProperty('find', query) || pc.isProperty('sum', query)
      }
      // Get the data from the graphQL server
      const rawData = await this.client.request(gqlQuery, queryArgs)
      // Extract the data array
      let data = rawData[name]
      // If only expecting one record remove it from the array
      // Some files may only have one record e.g. Period so check if array
      if (expectOneRecord() && pc.isArray(data)) {
        data = data.length ? data[0] : null
      }
      return data
    } catch (err) {
      if (err.response.notAuthenticated) {
        alert('Your session has expired.\nYou must login again to continue.')
        window.document.location = window.document.location.origin
      }

      console.log(
        `GraphQL ERROR: ${gqlQueryName(gqlQuery)}`,
        '\n',
        `Error: ${JSON.stringify(err, null, 2)}`
      )
      return []
    }
  }

  cache(key, data) {
    this.cacheStore[key] = data
    return data
  }

  cached(key, query) {
    const expectOneRecord = query => {
      // Check to see if there is a query
      if (!query) return false
      // Only expecting one record for queries with find or sum selectors
      if (pc.isProperty('find', query)) {
        if (query.find.length) return true
      }
      if (pc.isProperty('sum', query)) {
        if (query.sum.length) return true
      }
      return false
    }

    // Get the data from the cache
    if (pc.isProperty(key, this.cacheStore)) {
      let data = query ? query.run(this.cacheStore[key]) : this.cacheStore[key]
      // If only expecting one record remove it from the array
      // Some files may only have one record so check if array
      if (expectOneRecord(query) && pc.isArray(data))
        data = data.length ? data[0] : null
      return data
    } else {
      console.log(`Invalid db.cached key: ${key}`)
      return []
    }
  }

  cachedQuery() {
    return new CachedQuery()
  }

  deleteCache(key) {
    const data = this.cached(key)
    delete this.cacheStore[key]
    return data
  }

  async mutation() {}

  args(query) {
    return new QueryArgs(query)
  }

  queryArgs(queryName) {
    return new Query(queryName)
  }

  query(queryName) {
    return this.queryLibrary.query(queryName)
  }
}

const db = new Db(`${getHost()}graphql`)

export { db }
export default db
