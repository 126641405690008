<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-simple-table :simpleTableDef="simpleTableDef" @tooltip="tooltip">
      <template v-slot:sales>
        <pc-spark-chart :sparkChartDef="sparkChartDef" />
      </template>
    </pc-simple-table>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

export default {
  name: 'salesByCategoryList',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {
    customer: Object,
    categories: Array,
  },

  created() {
    this.main()
  },

  watch: {
    categories() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.simpleTableDef.data = this.categories
    },

    tooltip(payload) {
      const { data } = payload
      const titleColor = pc.getProperty('tag', data)

      this.sparkChartDef
        .clearDatasets()
        .setTitle(data.name, titleColor)
        .setSubTitle(
          `Sales £${pc.toFixed2(data.sales)} - ${pc.toFixed2(data.percentage)}%`
        )
        .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales', titleColor)
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      displayBoxDef: pcDisplayBoxDef(
        'salesByTopLevelCategoryList',
        'Sales by top level categories'
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('tag', 'tag', ''),
          pcSimpleTableFieldDef('name', 'text', 'Name'),
          pcSimpleTableFieldDef('ranking', 'int', 'Ranked', {
            blankIfZero: true,
          }),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last<br />invoiced'
          ),
        ],
      }),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
