import { runQuery } from './runQuery.js'

export class Query {
  constructor(query) {
    this.normalise(query)
  }

  normalise(query) {
    const normaliseValue = filter => {
      if (Object.prototype.hasOwnProperty.call(filter, 'bValue'))
        filter.value = filter.bValue
      if (Object.prototype.hasOwnProperty.call(filter, 'nValue'))
        filter.value = filter.nValue
      if (Object.prototype.hasOwnProperty.call(filter, 'sValue'))
        filter.value = filter.sValue
      return filter
    }

    query = query || {}
    if (typeof query !== 'object' || Array.isArray(query)) {
      throw 'Query constructor called with invalid query object'
    }
    this.find = query.find || []
    this.include = query.include || {}
    this.exclude = query.exclude || {}
    this.filter = query.filter || []
    this.sum = query.sum || []
    this.limit = query.limit || 0
    this.limitAfterSort = query.limitAfterSort || 0
    this.clone = query.close || false
    this.find = this.find.map(normaliseValue)
    this.filter = this.filter.map(normaliseValue)
    this.map = this.map || undefined
    this.reduce = query.reduce || undefined
    this.sort = query.sort || {}
    this.isSort = Object.prototype.hasOwnProperty.call(this.sort, 'fieldPath')
  }

  addFind(fieldPath, value, comparator = 'eq') {
    this.find.unshift({ fieldPath, comparator, value })
    return this
  }

  addInclude(fieldPath, values) {
    this.include = { fieldPath, values }
    return this
  }

  addExclude(fieldPath, values) {
    this.exclude = { fieldPath, values }
    return this
  }

  addFilter(fieldPath, value, comparator = 'eq') {
    this.filter.unshift({ fieldPath, comparator, value })
    return this
  }

  addFilterIf(condition, fieldPath, value, comparator = 'eq') {
    if (condition) this.addFilter(fieldPath, value, comparator)
    return this
  }

  addMap(mapFn) {
    this.map = mapFn
    return this
  }

  addReduce(reduceFn, initialValue) {
    this.reduce = { reduceFn, initialValue }
    return this
  }

  addLimit(limit) {
    this.limit = limit
    return this
  }

  addLimitAfterSort(limit) {
    this.limitAfterSort = limit
    return this
  }

  addSort(fieldPath, descend = false, sortFn) {
    if (!this.isSort) {
      this.sort = { fieldPath, descend, sortFn }
      this.isSort = true
    }
    return this
  }

  addSum(fieldPath) {
    this.sum.push(fieldPath)
    return this
  }

  addClone() {
    this.clone = true
    return this
  }

  run(data) {
    try {
      return runQuery(this, data)
    } catch (err) {
      console.log(err, this, data)
    }
  }
}
