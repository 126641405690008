<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChartType"
        value="pie"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Pie chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Stacked bar chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="line"
        icon="mdi-chart-line"
        small
        top
        tooltip="Line chart"
      />
      <pc-action-icon
        @click="toggleHidden"
        value=""
        icon="mdi-eye-off"
        small
        top
        tooltip="Show/Hide datasets"
      />
    </template>
    <template v-slot:default>
      <pc-chart
        :chartDef="pieChartDef"
        @instance="pieChartDef.instance"
        @tooltip="pieTooltip"
      />
      <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
      <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'categoryContactGroupChart',
  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
  },

  props: {
    categorySummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },

    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categorySummaryDef.category
    },

    contactGroupCategories() {
      return this.categorySummaryDef.contactGroupCategories
    },

    displayedChartDef() {
      if (this.pieChartDef.show) return this.pieChartDef
      if (this.barChartDef.show) return this.barChartDef
      return this.lineChartDef
    },
  },

  watch: {
    categorySummaryDef() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.isCategory) {
        this.displayChartType('pie')
        this.updateCharts()
      }
    },

    updateCharts() {
      this.updateBarAndLineChart()
      this.updatePieChart()
    },

    displayChartType(type) {
      this.pieChartDef.show = type === 'pie'
      this.barChartDef.show = type === 'bar'
      this.lineChartDef.show = type === 'line'
    },

    updatePieChart() {
      this.pieChartData = this.contactGroupCategories.reduce(
        (chartData, contactGroupCategory) => {
          chartData.categoryContactGroups.push(contactGroupCategory)
          chartData.labels.push(contactGroupCategory.contactGroupName)
          chartData.values.push(contactGroupCategory.sales)
          chartData.total += contactGroupCategory.sales
          return chartData
        },
        { categoryContactGroups: [], labels: [], values: [], total: 0 }
      )
      this.pieChartDef
        .clearDatasets()
        .setPc([
          ['categoryContactGroups', this.pieChartData.categoryContactGroups],
        ])
        .addDataset(
          this.pieChartData.values,
          'Category sales',
          this.chartColors
        )
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .setLabels(this.pieChartData.labels)
        .render()
    },

    updateBarAndLineChart() {
      this.barChartDef.clearDatasets()
      this.lineChartDef.clearDatasets()
      this.contactGroupCategories.forEach((contactGroupCategory, index) => {
        this.barChartDef.addDataset(
          pc.arrayFixed2(contactGroupCategory.salesByMonth),
          contactGroupCategory.contactGroupName,
          this.chartColors[index]
        )
        this.lineChartDef.addDataset(
          pc.arrayFixed2(contactGroupCategory.salesByMonth),
          contactGroupCategory.contactGroupName,
          this.chartColors[index]
        )
      })
      this.barChartDef.render()
      this.lineChartDef.render()
    },

    toggleHidden() {
      this.displayedChartDef.toggleHidden()
    },

    pieTooltip(payload) {
      const contactGroupCategory = this.pieChartDef.pc.categoryContactGroups[
        payload.dataIndex
      ]
      const title = contactGroupCategory.contactGroupName
      const color = this.pieChartDef.data.datasets[0].backgroundColor[
        payload.dataIndex
      ]
      const labels = pc.periodMonthNames('mmm', this.period.monthKeys)
      const percent = pc.toFixed2(
        pc.percentage(contactGroupCategory.sales, this.category.sales)
      )
      const subTitle = `£${pc.toFixed2(
        contactGroupCategory.sales
      )} (${percent}%) of total category sales`

      this.pieSparkChartDef
        .clearDatasets()
        .setTitle(title, color)
        .setSubTitle(subTitle)
        .setLabels(labels)
        .addDataset(
          pc.arrayFixed2([...contactGroupCategory.salesByMonth]),
          '',
          color
        )

      this.pieChartDef.setSparkChart(this.pieSparkChartDef)
      this.pieSparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartData: undefined,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'contactGroupChart',
        'Category sales by contact group',
        { contentPadding: 'pt-1 pb-1 pl-3 pr-3' }
      ),

      pieChartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setOptions([['cutout', '50%']])
        .setTitle('', false),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      pieSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
