import pc from '@pc'
import { getCategories } from './getCategories.js'
import { getProducts } from './getProducts.js'

export const getCategoryTree = async () => {
  const categories = await getCategories()
  const products = await getProducts()

  const addProducts = category => {
    const categoryProducts = pc
      .query()
      .addFilter(`category.level${category.level}`, category.id)
      .addSort('code')
      .addClone()
      .run(products)
      .map(product => ({ ...product, parentId: category.id }))
    category.children = categoryProducts
    category.hasProducts = categoryProducts.length > 0
    return
  }

  const getChildren = category => {
    category.children = []
    if (!category.hasChildren) return addProducts(category)
    const children = pc
      .query()
      .addFilter('parentId', category.id)
      .addSort('name')
      .run(categories)
    category.children = children
    children.forEach(getChildren)
    return category
  }

  const treeRoot = categories.find(category => category.id === 'category')
  return getChildren(treeRoot)
}
