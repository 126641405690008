import { gql } from 'graphql-tag'

export const companyGql = gql`
  query($dbQuery: DbQuery) {
    company(dbQuery: $dbQuery) {
      id
      name
      numberCustomers
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      invoices
      invoicesByDays
      invoicesByMonth
      customerAverage
      customerAverageByDays
      customerAverageByMonth
      invoiceAverage
      invoiceAverageByDays
      invoiceAverageByMonth
      outstandingProfile {
        overdue {
          type
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
        due {
          type
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
      }
      paidInvoicesProfile {
        profileIndex
        months {
          count
          profileIndex
          monthIndex
        }
      }
    }
  }
`
