<template>
  <v-row
    v-if="turnoverDef.show"
    id="companyTurnover"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <div class="grid-container pt-3 pb-0">
        <div class="chart">
          <wsCategoryDrill
            :drillDef="categoryDrillDef"
            @productClicked="productInCategoryDrillClicked"
          />
        </div>

        <div class="list">
          <pc-toolbar :toolbarDef="listToolbarDef">
            <template v-slot:toolbarActions>
              <v-select
                :items="contactGroups"
                v-model="contactGroupId"
                style="width: 80px"
                color="white"
                dark
                dense
                class="pt-4 pr-2"
              ></v-select>
            </template>
          </pc-toolbar>

          <pc-lister :list-def="boughtListDef" :list-items="bought">
            <template v-slot:subTitle>
              {{ boughtSubTitle }}<br />{{ groupBoughtSubtitle }}
            </template>
            <template v-slot:name>
              <tooltip-customer-summary :customerId="customerSummaryId" />
            </template>
            <template v-slot:listSales>
              <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
            </template>
          </pc-lister>

          <pc-lister :list-def="notBoughtListDef" :list-items="notBought">
            <template v-slot:subTitle>
              {{ notBoughtSubTitle }}
            </template>
          </pc-lister>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import {
  pcLineActionsDef,
  pcLineActionDef,
} from '@pcComponents/defs/pcLineActionsDef.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'

import pcLister from '@pcComponents/pcLister.vue'
//import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import wsCategoryDrill from '@appViews/sharedComponents/wsCategoryDrill/wsCategoryDrill.vue'
import tooltipCustomerSummary from '@appViews/sharedComponents/tooltipCustomerSummary.vue'

export default {
  name: 'turnover',

  components: {
    //pcCard,
    pcToolbar,
    pcLister,
    pcSparkChart,
    wsCategoryDrill,
    tooltipCustomerSummary,
  },

  props: {
    turnoverDef: Object,
  },

  created() {
    this.initialise()
    this.main()
  },

  computed: {
    listToolbarTitle() {
      return this.boughtListDef.cardDef.show
        ? 'Customers bought'
        : 'Customers not bought'
    },

    boughtValue() {
      if (this.showing === 'category')
        return this.total
          ? this.category.sales
          : this.category.salesByMonth[this.monthIndex]

      if (this.showing === 'product')
        return this.total
          ? this.product.sales
          : this.product.salesByMonth[this.monthIndex]
      return this.total
        ? this.period.sales
        : this.period.salesByMonth[this.monthIndex]
    },

    boughtWhat() {
      if (this.showing === 'category')
        return ` £${this.boughtValue.toFixed(2)} ${this.category.name}`
      if (this.showing === 'product')
        return ` £${this.boughtValue.toFixed(2)} ${this.product.code} ${
          this.product.name
        }`
      return ` £${this.boughtValue.toFixed(2)}`
    },

    monthName() {
      return this.monthKey === 'total'
        ? 'the period'
        : pc.monthKeyName('mmmm yyyy', this.monthKey)
    },

    bought() {
      return this.contactGroupId === 'All'
        ? this.customers.bought
        : this.customers.bought.filter(
            customer => customer.contactGroupId === this.contactGroupId
          )
    },

    notBought() {
      return this.contactGroupId === 'All'
        ? this.customers.notBought
        : this.customers.notBought.filter(
            customer => customer.contactGroupId === this.contactGroupId
          )
    },

    boughtSubTitle() {
      return `${this.customers.bought.length} customers bought${this.boughtWhat} in ${this.monthName}`
    },

    groupBoughtSubtitle() {
      if (this.contactGroupId === 'All') return ''
      const sales = this.bought.reduce((sum, record) => {
        sum += record.listSales
        return sum
      }, 0)
      const contactGroup = db
        .cached('contactGroups')
        .find(group => group.id === this.contactGroupId)
      let title = `${this.bought.length} (${(
        (this.bought.length / this.customers.bought.length) *
        100
      ).toFixed(0)}%) customers bought £${sales.toFixed(2)} (${(
        (sales / this.boughtValue) *
        100
      ).toFixed(0)}%} `
      if (this.showing === 'category') `${title} ${this.category.name} `
      if (this.showing === 'product')
        `${this.product.code} ${this.product.name} `
      return `${title} from ${contactGroup.name}`
    },

    notBoughtWhat() {
      if (this.showing === 'category') return this.category.name
      if (this.showing === 'product')
        return `${this.product.code} ${this.product.name}`
      return ''
    },

    notBoughtSubTitle() {
      return `${this.notBought.length} customers not bought ${this.notBoughtWhat} in ${this.monthName}`
    },
  },

  watch: {
    listToolbarTitle(value) {
      this.listToolbarDef.title = value
    },

    contactGroupId() {
      this.boughtListDef.cardDef.loading = true
      this.notBoughtListDef.cardDef.loading = true
    },
  },

  methods: {
    async initialise() {
      this.listToolbarDef.title = this.listToolbarTitle

      this.contactGroups = db.cached('contactGroups').reduce(
        (groups, group) => {
          groups.push({
            text: `${group.name} - ${group.numberCustomers} customers`,
            value: group.id,
          })
          return groups
        },
        [
          {
            text: `All - ${db.cached('customers').length} customers`,
            value: 'All',
          },
        ]
      )
    },

    main() {},

    async categoryDrillHook(payload) {
      this.boughtListDef.cardDef.loading = Date.now()
      this.notBoughtListDef.cardDef.loading = Date.now()
      this.total = payload.total
      this.monthKey = payload.monthKey
      this.monthIndex = payload.monthIndex
      this.categoryId = payload.categoryId
      this.category = db.cached(
        'categories',
        db.cachedQuery().addFind('id', this.categoryId)
      )
      await this.customersBoughtNotBought(
        this.contactGroupId,
        this.monthIndex,
        this.categoryId,
        ''
      )
      this.salesAnalysisContainer.cardDef.loading = false
    },

    async customersBoughtNotBought(
      contactGroupId,
      monthIndex,
      categoryId,
      productCode
    ) {
      const total = monthIndex === -1
      let bought
      if (categoryId === 'category') {
        // Getting customer based on total sales not for a specific category
        // Filter by overall non zero sales for period or month selected
        this.showing = 'total'
        bought = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter(total ? 'sales' : `salesByMonth[${monthIndex}]`, 0, 'ne')
            .addClone()
        )
      } else {
        // We arrive here if getting customers for a specific category
        // or product. In this case get the categoryCustomers or
        // productCustomer records for the period or selected month
        this.showing = categoryId ? 'category' : 'product'
        const queryArgs = db
          .queryArgs(categoryId ? 'categoryCustomers' : 'productCustomers')
          .addFilter(
            categoryId ? 'categoryId' : 'productCode',
            categoryId ? categoryId : productCode
          )
          .addFilter(total ? 'sales' : `salesByMonth[${monthIndex}]`, 0, 'ne')
        bought = await db.request(
          db.query(
            `salesAnalysis.${
              categoryId ? 'categoryCustomers' : 'productCustomers'
            }`
          ),
          db.args(queryArgs)
        )

        // Next extract the customer record from each categoryCustomer
        // or productCustomer record moving the sales related
        // fields from the categoryCustomer or productCustomer record
        bought = bought.map(record => {
          const customer = record.customer
          customer.sales = record.sales
          customer.salesByMonth = record.salesByMonth
          customer.lastInvoiced = record.lastInvoiced
          return customer
        })
      }

      // Now add required list computed fields to the customer records
      const companySales = total
        ? this.period.sales
        : this.period.salesByMonth[monthIndex]

      bought = bought.map(customer => {
        customer.listSales = total
          ? customer.sales
          : customer.salesByMonth[monthIndex]
        const contactGroup = db
          .cached('contactGroups')
          .find(contactGroup => contactGroup.id === customer.contactGroupId)
        customer.contactGroupName = contactGroup.name
        customer.contactGroupPercentage = pc.percentage(
          customer.listSales,
          total ? contactGroup.sales : contactGroup.salesByMonth[monthIndex]
        )
        customer.companyPercentage = pc.percentage(
          customer.listSales,
          companySales
        )
        return customer
      })

      // Get customers who did not buy from cache by excluding those
      // who did buy and apply
      // Sort customer by name as they are not in order
      const excludedCustomers = bought.map(customer => customer.id)
      const cachedQuery = db
        .cachedQuery()
        .addExclude('id', excludedCustomers)
        .addSort('name')
      const notBought = db.cached('customers', cachedQuery)

      this.customers.bought = bought
      this.customers.notBought = notBought
    },

    async productInCategoryDrillClicked(monthKey, code) {
      this.product = await db.request(
        db.query('salesAnalysis.products'),
        db.args(db.queryArgs('products').addFind('code', code))
      )
      this.boughtListDef.cardDef.loading = Date.now()
      this.notBoughtListDef.cardDef.loading = Date.now()
      this.customersBoughtNotBought(
        this.contactGroupId,
        this.monthIndex,
        '',
        code
      )
    },

    listToolbarActions(action) {
      if (action.id === 'csv') {
        let listDef, data
        if (this.boughtListDef.cardDef.show) {
          listDef = this.boughtListDef
          data = this.bought
        } else {
          listDef = this.notBoughtListDef
          data = this.notBought
        }
        pc.csvFromLister(listDef, data)
      } else {
        this.boughtListDef.cardDef.show = action.id === 'bought'
        this.notBoughtListDef.cardDef.show = action.id === 'notBought'
      }
    },

    lastInvoicedClass(payload) {
      return lastInvoicedClass(
        payload.data.lastInvoiced,
        this.period.periodEndDate
      )
    },

    showCustomerDashboard(payload) {
      this.$emit('showCustomerDashboard', {
        customerId: payload.data.id,
        from: 'companyDashboard',
        screen: 'turnover',
      })
    },

    // Tooltip triggered from customer name on bought list
    nameTooltipHandler(payload) {
      this.customerSummaryId = payload.data.id
    },

    salesTooltipHandler(payload) {
      const value = pc.getProperty(payload.field.dataPath, payload.data)
      const record =
        this.showing === 'total'
          ? this.period
          : this.showing === 'category'
          ? this.category
          : this.product
      const total = this.total
        ? record.sales
        : record.salesByMonth[this.monthIndex]
      const subTotalValue = `£${pc.toFixed2(value)}`
      const subTotalPeriod = this.total
        ? 'period'
        : pc.monthKeyName('mmmm yyyy', this.monthKey)
      const subTitle = (() => {
        switch (this.showing) {
          case 'total':
            return `${subTotalValue} in ${subTotalPeriod}`
          case 'category':
            return `${subTotalValue} (${pc.toFixed2(
              pc.percentage(value, total)
            )}%) of ${record.name} sales in ${subTotalPeriod}`
          case 'product':
            return `${subTotalValue} (${pc.toFixed2(
              pc.percentage(value, total)
            )}%) of ${record.code} ${record.name} sales in ${subTotalPeriod}`
          default:
            return ''
        }
      })()

      this.turnoverSparkChartDef
        .clearDatasets()
        .setTitle(payload.data.name)
        .setSubTitle(subTitle)
        .addDataset(pc.arrayFixed0(payload.data.salesByMonth), '#90CAF9')
        .setLabels(pc.periodMonthNames('mmm', this.period.monthKeys))
        .render()
    },
  },

  data: function() {
    const period = db.cached('period')
    return {
      period,
      total: true,
      customer: {},
      categoryId: '',
      category: {},
      product: {},
      showing: '', // total or category or product
      monthKey: '',
      customers: { bought: [], notBought: [] },
      contactGroups: [],
      contactGroupId: 'All',

      categoryDrillDef: {
        id: '',
        if: true,
        show: true,
        monthKey: '',
        customerId: '',
        cardClass: '',
        elevation: '0',
        updateHook: [
          this.categoryDrillHook,
          this.turnoverDef.categoryDrillHook,
        ],
      },

      salesAnalysisContainer: {
        cardDef: pcCardDef('salesAnalysis', false, { loaderType: 'card' }),
      },

      listToolbarDef: pcToolbarDef('listToolbar', '', {
        dense: true,
        color: 'primary lighten-2',
        actions: [
          pcToolbarActionDef(
            'bought',
            'mdi-account-multiple-check-outline',
            this.listToolbarActions,
            'View customers who bought',
            'white'
          ),
          pcToolbarActionDef(
            'notBought',
            'mdi-account-multiple-remove-outline',
            this.listToolbarActions,
            'View customers who have not bought',
            'white'
          ),
          pcToolbarActionDef(
            'csv',
            'mdi-file-excel',
            this.listToolbarActions,
            'Create CSV file',
            'white'
          ),
        ],
      }),

      boughtListDef: {
        cardDef: pcCardDef('whoListBought', false, {
          subTitleClass:
            'pb-2 pl-4 pr-4 primary lighten-2 caption font-weight-bold white--text',
        }),
        //toolbarDef: pcToolbarDef('whoListBoughtToolbar', 'Customer who bought', {
        //  dense: true,
        //  flat: true,
        //  color: 'primary lighten-2',
        //}),
        table: pcTableDef('whoListBought', undefined, ['sales'], [true]),
        fields: [
          pcFieldDef('name', 'text', '', 'Customer', true, {
            tooltipDef: pcTooltipDef('nameTooltip', this.nameTooltipHandler),
          }),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('contactGroupName', 'text', '', 'Group'),
          pcFieldDef('listSales', 'currency', '', 'Sales', true, {
            tooltipDef: pcTooltipDef('salesTooltip', this.salesTooltipHandler),
          }),
          pcFieldDef('contactGroupPercentage', 'number', '', 'Group%', true, {
            decimalPlaces: 2,
          }),
          pcFieldDef('companyPercentage', 'number', '', 'Co.%', true, {
            decimalPlaces: 2,
          }),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedClass,
            headingClass: 'pr-7',
          }),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },
      boughtListItems: [],
      boughtListTitle: '',

      notBoughtListDef: {
        cardDef: pcCardDef('whoListNotBought', false, {
          show: false,
          subTitleClass:
            'pb-2 pl-4 pr-4 primary lighten-2 caption font-weight-bold white--text',
        }),
        table: pcTableDef('whoListNotBought', undefined, ['name'], [false]),
        fields: [
          pcFieldDef('name', 'text', '', 'Customer'),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },
      notBoughtListItems: [],
      notBoughtListTitle: '',

      customerSummaryId: '',
      turnoverSparkChartDef: pcSparkChartDef('bar'),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  padding-top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 100%);
  grid-template-areas: 'chart' 'list';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 100%);
    grid-template-areas: 'chart' 'list';
  }
}
@media only screen and (min-width: 1300px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    grid-template-areas: 'chart list';
  }
}
.chart {
  grid-area: chart;
  overflow-y: auto;
}
.list {
  grid-area: list;
  overflow-y: auto;
}
</style>
