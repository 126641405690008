import { gql } from 'graphql-tag'

const contactGroupProducts = gql`
  query($dbQuery: DbQuery) {
    contactGroupCategories(dbQuery: $dbQuery) {
      productCode
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
    }
  }
`

const contactGroupCategories = gql`
  query($dbQuery: DbQuery) {
    contactGroupCategories(dbQuery: $dbQuery) {
      code
      save
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
    }
  }
`

const products = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      code
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
    }
  }
`

const queries = {
  contactGroupProducts,
  contactGroupCategories,
  products,
}

export { queries as contactGroupDashboard }
