<template>
  <!-- This div is needed to stop wsCategory drill from taking up space
       on other screens when show = false. It cant use v-if as
       the summary screen needs it for category list and graph data
  -->
  <div
    v-show="drillDef.show"
    :style="
      drillDef.show ? 'width: 100%; height: 100%;' : 'width: 0px; height: 0px;'
    "
  >
    <v-row
      v-show="drillDef.show"
      id="customerTurnover"
      class="fill-height mt-0"
      justify="center"
      align="stretch"
    >
      <v-col cols="12" class="pc-size100 pt-0 pb-0 pl-0">
        <div class="grid-container pt-0 pb-0">
          <div class="chart">
            <wsCategoryDrill :drillDef="drillDef" />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import wsCategoryDrill from '@appViews/sharedComponents/wsCategoryDrill/wsCategoryDrill.vue'

export default {
  name: 'customerTurnover',

  components: {
    wsCategoryDrill,
  },

  props: {
    drillDef: Object,
  },

  created() {},

  computed: {},

  methods: {},

  data: function() {
    return {}
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  padding-top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-template-rows: 1fr;
  grid-template-areas: 'turnover';
}
.chart {
  grid-area: turnover;
  overflow-y: auto;
}
</style>
