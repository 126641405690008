<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChart = true"
        value="bar"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Sales chart"
      />
      <pc-action-icon
        @click="displayChart = false"
        value="line"
        icon="mdi-view-list"
        small
        top
        tooltip="Sales figures"
      />
    </template>
    <template v-slot:default>
      <pc-chart v-if="displayChart" :chartDef="chartDef" @tooltip="tooltip" />
      <pc-simple-table
        v-else
        :simpleTableDef="simpleTableDef"
        @tooltip="tooltip"
      >
        <template v-slot:sales>
          <pc-spark-chart :sparkChartDef="sparkChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

export default {
  name: 'contactGroupTop10Products',

  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {
    group: Object,
  },

  created() {
    this.main()
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },
  },

  watch: {
    group() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.group) {
        this.getData()
        this.updateChart()
        this.updateList()
      }
    },

    getData() {
      const { top10Records, restSales, totalSales } = this.group.top10Products

      this.products = top10Records.map(product => ({
        ...product,
        percentage: pc.percentage(product.sales, totalSales),
      }))

      this.products.push({
        code: '',
        name: 'All other products',
        sales: this.group.top10Products.restSales,
        salesByMonth: this.group.top10Products.restSalesByMonth,
        percentage: pc.percentage(restSales, totalSales),
      })
    },

    updateChart() {
      this.chartDef
        .setLabels(
          this.products.map(product => `${product.code} ${product.name}`)
        )
        .clearDatasets()
        .addDataset(
          this.products.map(
            product => pc.toFixed2(product.percentage),
            'Product sales'
          )
        )
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .render()
    },

    updateList() {
      this.simpleTableDef.data = this.products
    },

    tooltip(payload) {
      const product =
        payload.event === 'tooltip'
          ? payload.data
          : this.products[payload.dataIndex]
      const titleColor =
        payload.event === 'tooltip'
          ? undefined
          : payload.dataset.backgroundColor[payload.dataIndex]
      const title = `${product.code} ${product.name}`
      const subTitle = `Sales £${product.sales.toFixed(
        2
      )} - ${product.percentage.toFixed(2)}%`

      this.sparkChartDef
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .clearDatasets()
        .addDataset(pc.arrayFixed2(product.salesByMonth), 'Sales', titleColor)

      this.chartDef.setSparkChart(this.sparkChartDef)
      this.sparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      products: [],
      displayChart: true,

      displayBoxDef: pcDisplayBoxDef(
        'top10RecordsDisplayBox',
        'Top 10 products',
        {
          height: '100%',
        }
      ),

      chartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setOptions([['cutout', '50%']])
        .setLabels(pc.periodMonthNames('mmm', period.monthKeys)),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        fields: [
          pcSimpleTableFieldDef('code', 'text', 'Code'),
          pcSimpleTableFieldDef('name', 'text', 'Product'),
          pcSimpleTableFieldDef(
            `salesByMonth[${period.monthsInPeriod - 1}]`,
            'currency',
            `${pc.monthKeyName(
              'mmm yy',
              period.monthKeys[period.monthsInPeriod - 1]
            )}`
          ),
          pcSimpleTableFieldDef('sales', 'currency', 'Total sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last<br />invoiced'
          ),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
