<template>
  <pc-lister
    v-show="productsDef.show"
    :list-def="customerProductsListDef"
    :list-items="customerProductsListItems"
  >
    <template v-slot:toolbarActions>
      <v-radio-group
        v-model="color"
        dark
        :column="false"
        height="16px"
        class="pt-6 pr-8 mb-0"
      >
        <v-radio
          v-for="n in 3"
          color="white"
          class="pr-4"
          :key="n"
          :label="['White', 'Grey', 'Black'][n - 1]"
          :value="['White', 'Grey', 'Black'][n - 1]"
        ></v-radio>
      </v-radio-group>
      <v-text-field
        class="pt-6"
        dark
        v-model="customerProductsListDef.table.search"
        clearable
        placeholder="Search"
        single-line
      ></v-text-field>
    </template>
    <template v-slot:quantity>
      <ws-product-sales-by-month :record="productSalesByMonthRecord" />
    </template>
    <template v-slot:turnover>
      <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
    </template>
    <template v-slot:expanded-item>
      <tr>
        <td :colspan="99">
          <ws-product-invoices :invoicesDef="invoicesListDef" />
        </td>
      </tr>
    </template>
  </pc-lister>
</template>

<script>
import pc from '@pc'
import db from '@db'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcLineActionsDef } from '@pcComponents/defs/pcLineActionsDef.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'

import pcLister from '@pcComponents/pcLister.vue'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import wsProductSalesByMonth from '@appViews/sharedComponents/wsProductSalesByMonth.vue'
import wsProductInvoices from '@appViews/sharedComponents/wsProductInvoices.vue'

export default {
  name: 'customerProducts',
  components: {
    pcLister,
    pcSparkChart,
    wsProductSalesByMonth,
    wsProductInvoices,
  },

  props: {
    productsDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    customerId() {
      return this.productsDef.customerId
    },
  },

  watch: {
    customerId() {
      this.main()
    },

    color() {
      this.displayProducts()
    },
  },

  methods: {
    // Get the initial data and display the products
    async main() {
      if (this.customerId) {
        this.customer = db.cached(
          'customers',
          db.cachedQuery().addFind('id', this.customerId)
        )
        this.products = await this.getProducts()
        this.displayProducts()
      }
    },

    // Get get the products and productCustomers from the db
    // then add zero sales fields to each product and lastly
    // add the customer sales values to each product which the
    // customer purchased.
    async getProducts() {
      const data = await db.request(
        db.query('customerProducts.productsAndProductCustomers'),
        db.args(
          db
            .queryArgs('productCustomers')
            .addFilter('customerId', this.customerId)
        )
      )

      const addZeroFields = product => {
        product.lastInvoiced = ''
        product.quantity = 0
        product.sales = 0
        product.salesByMonth = pc.createArray(0, this.period.monthsInPeriod)
        product.quantityByMonth = pc.createArray(0, this.period.monthsInPeriod)
        return product
      }

      const mergeCustomerSalesIntoProduct = productCustomer => {
        const product = data.products.find(
          product => product.code === productCustomer.productCode
        )
        product.lastInvoiced = productCustomer.lastInvoiced
        product.quantity = productCustomer.quantity
        product.quantityByMonth = [...productCustomer.quantityByMonth]
        product.sales = productCustomer.sales
        product.salesByMonth = [...productCustomer.salesByMonth]
      }

      data.products = data.products.map(addZeroFields)
      data.productCustomers.forEach(mergeCustomerSalesIntoProduct)
      return data.products
    },

    // Display the products based on the color selected
    // White - products not purchased by customer
    // Grey  - all products
    // Black - only customers purchased by customer
    displayProducts() {
      if (this.color === 'Grey') {
        this.customerProductsListItems = this.products
      } else {
        this.customerProductsListItems = this.products.filter(product => {
          if (this.color === 'White') return product.quantity === 0
          return product.quantity !== 0
        })
      }
    },

    createCSV() {
      pc.csvFromLister(
        this.customerProductsListDef,
        this.customerProductsListItems
      )
    },

    // This is called when a product line is clicked and updates
    // the component to display the customer invoices for the product
    viewProductInvoices(payload) {
      payload.props.expand(!payload.props.isExpanded)
      if (!payload.props.isExpanded) {
        this.invoicesListDef.customerId = this.customerId
        this.invoicesListDef.productCode = payload.data.code
        this.invoicesListDef.triggerUpdate = pc.uid()
      }
    },

    // Called from pcLister for the lastInvoiced field to
    // apply a color to the date based on the time elapesd
    lastInvoicedClass(payload) {
      return lastInvoicedClass(
        payload.data.lastInvoiced,
        this.period.periodEndDate
      )
    },

    // Called from pcLister for the quantity field to
    // apply a color to the quantity if the customer
    // has purchased the product - grey is purchased
    quantityItemClass(payload) {
      return pc.getProperty(payload.field.dataPath, payload.data)
        ? `chip blue-grey lighten-4`
        : ''
    },

    // Toolip call from pcLister for the quantity field
    // The tooltip updates the wsProductSalesByMonth
    // component prop to display the tooltip
    async productQuantityTooltipHandler(payload) {
      const product = payload.data
      this.productSalesByMonthRecord = product
    },

    // Tooltip call from pcLister for the sales field
    // The tooltip updates the pcSparkChart component
    // to display the tooltip
    productTurnoverTooltipHandler(payload) {
      const product = payload.data
      const value = pc.getProperty(payload.field.dataPath, payload.data)
      const total = this.customer.sales
      const percent = pc.toFixed2(pc.percentage(value, total))
      const subTitle = `£${pc.toFixed2(
        value
      )} (${percent}%) of customer turnover`

      this.turnoverSparkChartDef
        .clearDatasets()
        .setTitle(`${payload.data.code} ${payload.data.name}`)
        .setSubTitle(subTitle)
        .addDataset(pc.arrayFixed0(product.salesByMonth), 'Sales')
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      customer: {},
      color: 'Grey',
      period,
      zeroSalesByMonth: new Array(period.monthsInPeriod).fill(0),
      zeroQuantityByMonth: new Array(period.monthsInPeriod).fill(0),
      products: [],
      customerProductsListDef: {
        cardDef: pcCardDef('customers', false, {
          subTitleClass: 'blue lighten-4',
          elevation: '0',
        }),
        toolbarDef: pcToolbarDef('customerProductsToolbar', 'Products', {
          dense: true,
          color: 'primary lighten-2',
          actions: [
            pcToolbarActionDef(
              'csv',
              'mdi-file-excel',
              this.createCSV,
              'Create CSV file',
              'white'
            ),
          ],
        }),
        table: pcTableDef('customerProductsTable', 'code', ['code'], [false], {
          rowClick: this.viewProductInvoices,
          noDataMessage: '',
        }),
        fields: [
          pcFieldDef('code', 'text', '', 'Code', true),
          pcFieldDef('name', 'text', '', 'Name', false),
          pcFieldDef('ranking', 'ranking', '', 'Ranked'),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedClass,
          }),
          pcFieldDef('quantity', 'number', '', 'Quantity', true, {
            itemClass: this.quantityItemClass,
            tooltipDef: pcTooltipDef(
              'quantityTooltip',
              this.productQuantityTooltipHandler
            ),
          }),
          pcFieldDef('turnover', 'currency', 'sales', 'Value', true, {
            tooltipDef: pcTooltipDef(
              'turnoverTooltip',
              this.productTurnoverTooltipHandler
            ),
          }),
          pcFieldDef('tradePrice', 'currency', '', 'Trade price', false),
          pcFieldDef('RRP', 'currency', '', 'RRP', false),
        ],
        lineActionsDef: pcLineActionsDef(),
      },
      customerProductsListItems: [],

      productSalesByMonthRecord: {},

      turnoverTooltipDef: pcTooltipDef('turnoverTooltip'),
      turnoverSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      invoicesListDef: {
        show: true,
        width: '90%',
        maxWidth: '800px',
        productCode: '',
        customerId: '',
        triggerUpdate: '',
      },
    }
  },
}
</script>

<style scoped></style>
