import pc from '@pc'

/**
 * Returns an object of fields name and initial values
 * which can be injected into a components data
 * @param {Object} schema a form field schema
 * @param {Object} schema.name field name
 * @param {*} schema.name.value field default value
 * @param {Object} schema.name.valid object of validation functions e.g. required
 * @param {Function} schema.name.valid.functionName e.g. required or minValue
 * @param {String} schema.name.group name of validation group - such as all fields on a tab
 * @param {Boolean} schema.name.excluded a field not part of the record
 * @param {Boolean} schema.name.force a field to always be included in updateFields
 * @param {Object} schema.name.customErrMsg custom error messages
 * @param {String} schema.name.customErrMsg.validationName custom error message
 * @returns {Object} an object be injected into a components data
 */
function normaliseFormSchema() {
  const vc = this
  this.$set(this.formSchema, 'serverError', { initValue: '', exclude: true })

  Object.keys(this.formSchema).forEach(key => {
    vc.formSchema[key].name = key
    if (vc.formSchema[key].initValue === undefined) {
      vc.$set(vc.formSchema[key], 'initValue', '')
    }
    vc.$set(vc.formSchema[key], 'value', vc.formSchema[key].initValue)
    if (!Object.prototype.hasOwnProperty.call(vc.formSchema[key], 'present')) {
      vc.$set(vc.formSchema[key], 'present', {})
    }
    if (!Object.prototype.hasOwnProperty.call(vc.formSchema[key], 'valid')) {
      vc.$set(vc.formSchema[key], 'valid', {})
    }
    if (vc.formSchema[key].valid !== {}) {
      vc.$set(vc.formSchema[key], 'errors', [])
    }
  })
}

/**
 * Returns an object of field validations from the form field
 * schema which can then be injected in the component data
 * @param {Object} schema a form field schema
 * @returns {Object} an object to be injected into the component data
 */
function formValidations() {
  const v = {}

  Object.keys(this.formSchema).forEach(key => {
    if (this.formSchema[key].valid !== {}) {
      v[key] = { value: this.formSchema[key].valid }
    }
  })
  return { formSchema: v }
}

/**
 * Resets a field and clears error conditions.
 * @param {String|Object} caller a string field name or an event
 */
function resetField(caller) {
  const fieldName =
    typeof caller === 'string' ? caller : caller.currentTarget.name
  this.$v.formSchema[fieldName].value.$reset()
  this.formSchema.serverError.value = ''
  if (this.formSchema[fieldName].valid !== {}) {
    this.formSchema[fieldName].errors = []
  }
}

/**
 * Validates a field and and sets error conditions if errors.
 * References the form schema object for field definition
 * @param {String|Object} caller a string field name or an event
 */
function validateField(caller) {
  const fieldName =
    typeof caller === 'string' ? caller : caller.currentTarget.name
  const validateField = this.$v.formSchema[fieldName].value
  const errors = []
  const schemaField = this.formSchema[fieldName]

  if (schemaField.valid !== {}) {
    validateField.$touch()
    if (validateField.$dirty) {
      if ('required' in schemaField.valid) {
        if (!validateField.required) {
          errors.push(
            fieldcustomErrMsg(schemaField, 'required', 'This field is required')
          )
        }
      }
      if ('requiredIf' in schemaField.valid) {
        if (!validateField.requiredIf) {
          errors.push(
            fieldcustomErrMsg(
              schemaField,
              'requiredIf',
              'This field is required'
            )
          )
        }
      }
      if ('minLength' in schemaField.valid) {
        if (!validateField.minLength) {
          errors.push(
            fieldcustomErrMsg(
              schemaField,
              'minLength',
              'You must provide a custom message'
            )
          )
        }
      }
      if ('minValue' in schemaField.valid) {
        if (!validateField.minValue) {
          errors.push(
            fieldcustomErrMsg(
              schemaField,
              'minValue',
              'You must provide a custom message'
            )
          )
        }
      }
      if ('email' in schemaField.valid) {
        if (!validateField.email) {
          errors.push(
            fieldcustomErrMsg(
              schemaField,
              'email',
              'Must enter a valid email address'
            )
          )
        }
      }
      if ('sameAsPassword' in schemaField.valid) {
        if (!validateField.sameAsPassword) {
          errors.push(
            fieldcustomErrMsg(
              schemaField,
              'required',
              'Confirmation password does mot match password'
            )
          )
        }
      }
    }
    this.formSchema[fieldName].errors = errors
    if (schemaField.group && errors.length > 0) {
      this.groupValidation[schemaField.group] = true
    }
  }
}

/* INTERNAL
 * Returns the default or custom error message for a field validation
 * @param {Object} schemaField the field definition from the schema
 * @param {String} validationType the validation e.g. 'minValue'
 * @param {String} defaultError the default error message
 */
function fieldcustomErrMsg(schemaField, validationType, defaultError) {
  let fieldCustomErrors = schemaField.customErrMsg
  let customError = fieldCustomErrors ? fieldCustomErrors[validationType] : null
  return customError ? customError : defaultError
}

/**
 * Runs the validateField method for all fields with validation
 * defined in the form field schema. If there are any group
 * errors if displays the group errors.
 * @returns the a boolean indicating if the form contains errors
 */
function validateForm() {
  this.groupValidation = {}
  this.$v.$touch()
  Object.keys(this.formSchema).forEach(key => {
    if (this.formSchema[key].valid !== {}) {
      this.validateField(key)
    }
  })
  if (this.$v.$invalid) {
    if (this.groupValidation !== {}) {
      let err = 'Correct the errors on the '
      let count = 0
      Object.keys(this.groupValidation).forEach(key => {
        if (count) {
          err = err + 'and the '
        }
        err = err + key.toUpperCase() + ' tab '
        count = count + 1
      })
      this.formSchema.serverError.value = err
    }
  }
  return !this.$v.$invalid
}

/**
 * Runs the resetField method for all fields in the form field schema
 * and sets all values to the initial value.
 */
function resetFields() {
  this.groupValidation = {}
  Object.keys(this.formSchema).forEach(key => {
    this.formSchema[key].value = this.formSchema[key].initValue
    this.resetField(key)
  })
  this.$v.$reset()
}

/**
 * Returns all the fields or only those that the user has
 * altered. It does not include those field marked with
 * 'exclude: true' attribute. Fields only for control
 * purposes and not beloging to the record should be marked
 * in this way. All fields with 'force: true' attribute
 * will be written even if not altered - use for readonly fields.
 * @param {Boolean} all true for all fields, false for only changed fields
 * @returns {Object} containing the field name and value pairs of data
 */
function updatedFields(all) {
  const update = {}
  Object.keys(this.formSchema).forEach(key => {
    if (!this.formSchema[key].exclude) {
      if (
        this.$v.formSchema[key].value.$dirty ||
        this.formSchema[key].force ||
        all
      ) {
        update[key] = this.formSchema[key].value
      }
    }
  })
  return update
}

/**
 * Moves all the fields specified in the form field schema from the record
 * to the component data fields of the same name
 * @param {Object} rec the record object from which fields are extracted
 */
function moveRecordToFields(rec) {
  Object.keys(this.formSchema).forEach(key => {
    if (!this.formSchema[key].exclude) {
      if (rec[key] !== undefined) {
        this.formSchema[key].value = rec[key]
      } else {
        this.formSchema[key].value = this.formSchema[key].initValue
      }
    }
  })
}

/**
 * Callback to process custom events from components to invoke field methods.
 * Events supported 'resetField' 'updateField' 'validateField'
 *
 * @param {Object} payload in the form {name: fieldName, data: updateData}
 * @param {string} payload.name the field name
 * @param {*} payload.data the data to update the field
 */
function fieldEvent(payload) {
  switch (payload.event) {
    case 'resetField':
      this.resetField(payload.name)
      break
    case 'updateField':
      this.formSchema[payload.name].value = payload.data
      break
    case 'validateField':
      this.validateField(payload.name)
      break
  }
}

const mixinFormValidator = {
  created() {
    this.uid = pc.uid()
    pc.$eventBus.$on(this.uid + 'fieldEvent', this.fieldEvent)
    this.normaliseFormSchema()
  },
  beforeDestroy() {
    pc.$eventBus.$off(this.uid + 'fieldEvent', this.fieldEvent)
  },
  methods: {
    normaliseFormSchema,
    formValidations,
    resetField,
    resetFields,
    validateField,
    validateForm,
    updatedFields,
    moveRecordToFields,
    fieldEvent,
  },
  validations() {
    return this.formValidations()
  },
  data() {
    return {
      uid: '',
      groupValidation: {},
    }
  },
}

export { mixinFormValidator }
