import pc from '@pc'
import db from '@db'

export class CategoryCustomers {
  constructor() {
    return this
  }

  async compute(categoryId, contactGroupId, salesFieldName, records) {
    this.categoryId = categoryId
    this.contactGroupId = contactGroupId
    this.salesFieldName = salesFieldName
    this.records = await this.getCategoryCustomerRecords(records, categoryId)

    this.boughtAll = this.filterByGroup(this.records, contactGroupId)
    this.boughtInSalesPeriod = this.filterBySalesPeriod(
      this.boughtAll,
      this.salesFieldName
    )
    this.customersAll = this.filterCustomersByContactGroup(contactGroupId)
    this.customersActiveAll = this.filterCustomersBySales(this.customersAll)
    this.customersActiveInSalesPeriod = this.filterBySalesPeriod(
      this.customersActiveAll,
      salesFieldName
    )
    this.notBoughtAll = this.filterOutCustomersWhoBought(
      this.customersActiveAll,
      this.boughtAll
    )
    this.notBoughtInSalesPeriod = this.filterOutCustomersWhoBought(
      this.customersActiveAll,
      this.boughtInSalesPeriod
    )
    this.notBoughtInSalesPeriodButBoughtPreviously =
      salesFieldName === 'sales'
        ? []
        : this.filterOutCustomersWhoBought(
            this.boughtAll,
            this.boughtInSalesPeriod,
            true
          )
    return this
  }

  async getCategoryCustomerRecords(records, categoryId) {
    return pc.isArray(records)
      ? records
      : await db.request(
          db.query('categoryCustomersClass.categoryCustomers'),
          db.args(
            db
              .queryArgs('categoryCustomers')
              .addFilter('categoryId', categoryId)
          )
        )
  }

  filterByGroup(records, contactGroupId) {
    return pc
      .query()
      .addFilterIf(
        contactGroupId !== 'total' && contactGroupId !== '',
        'contactGroupId',
        contactGroupId
      )
      .addSort('sales', true)
      .run(records)
  }

  filterBySalesPeriod(records, salesFieldName) {
    return pc
      .query()
      .addFilterIf(salesFieldName !== 'sales', salesFieldName, 0, 'ne')
      .addSort(salesFieldName, true)
      .run(records)
  }

  filterCustomersByContactGroup(contactGroupId) {
    return db.cached(
      'customers',
      db
        .cachedQuery()
        .addFilterIf(
          contactGroupId !== 'total' && contactGroupId !== '',
          'contactGroupId',
          contactGroupId
        )
        .addSort('sales', true)
    )
  }

  filterCustomersBySales(customers) {
    return pc
      .query()
      .addFilter('sales', 0, 'ne')
      .addSort('sales', true)
      .run(customers)
  }

  filterOutCustomersWhoBought(
    activeCustomers,
    boughtCustomers,
    isCategoryCustomerRecords = false
  ) {
    const boughtIds = boughtCustomers.map(record => record.customerId)
    return pc
      .query()
      .addExclude(isCategoryCustomerRecords ? 'customerId' : 'id', boughtIds)
      .run(activeCustomers)
  }
}
