<template>
  <v-navigation-drawer
    :width="extendedView ? '400px' : '225px'"
    permanent
    :mini-variant.sync="mini"
  >
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-account-search</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Find customer</v-list-item-title>
      </v-list-item-content>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <pc-lister
      :list-def="
        !extendedView
          ? findCustomersDef
          : extendedType === 'sales'
          ? findCustomersExtendedDef
          : findCustomersExtendedCoverageDef
      "
      :list-items="customers"
    >
      <template v-slot:subTitle>
        <v-row>
          <v-col :cols="extendedView ? 5 : 12" class="pt-3 pb-0 pl-6 pr-6">
            <v-btn icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-checkbox
              dense=""
              v-model="extendedView"
              label="Extended view"
            ></v-checkbox>
          </v-col>
          <v-col v-if="extendedView" cols="7" class="pt-0 pb-0 pl-6 pr-6">
            <v-radio-group v-model="extendedType" row>
              <v-radio label="Sales" value="sales"></v-radio>
              <v-radio label="Prod. coverage" value="coverage"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="!contactGroupId" cols="12" class="pt-0 pb-0 pl-6 pr-6">
            <v-select
              dense=""
              :items="contactGroupItems"
              v-model="contactGroup"
              label="Contact group"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0 pl-6 pr-6">
            <v-select
              dense=""
              :items="customertCriteriaItems"
              v-model="customerCriteria"
              label="Customers"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pt-0 pb-3 pl-6 pr-6">
            <v-text-field
              dense=""
              class="pt-0"
              v-model="findCustomersDef.table.search"
              clearable
              placeholder="Search"
              single-line
            ></v-text-field>
            <div class="pb-1">
              Showing {{ customers.length }} customer{{
                customers.length !== 1 ? 's' : ''
              }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.native="createCSV">
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </template>
                Create CSV file
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>
    </pc-lister>
  </v-navigation-drawer>
</template>

<script>
import pc from '@pc'
import db from '@db'
import pcLister from '@pcComponents/pcLister.vue'

import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { LAST_INVOICED_SINCE_DAYS } from '@appModules/ws.js'

export default {
  name: 'findCustomer',

  components: {
    pcLister,
  },

  props: {
    contactGroupId: String,
    customer: Object,
  },

  created() {
    this.initialise()
  },

  computed: {},

  watch: {
    contactGroupId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.contactGroup = value
        }
      },
    },

    mini(value) {
      this.findCustomersDef.cardDef.show = !value
      this.findCustomersExtendedDef.cardDef.show = !value
      this.findCustomersExtendedCoverageDef.cardDef.show = !value
    },

    contactGroup: {
      immediate: true,
      handler() {
        this.main()
      },
    },

    customerCriteria() {
      this.main()
    },
  },

  methods: {
    initialise() {
      // If no customer open the find customer drawer
      this.mini = this.customer ? true : false
      // Set up the contact groups select
      this.contactGroupItems = db.cached('contactGroups').reduce(
        (groups, group) => {
          groups.push({
            text: `${group.name} - ${group.numberCustomers} customers`,
            value: group.id,
          })
          return groups
        },
        [
          {
            text: `All - ${db.cached('customers').length} customers`,
            value: 'company',
          },
        ]
      )
    },

    main() {
      // Build the query args based on the find customer options selected
      const dbQueryArg = db.cachedQuery()
      if (this.contactGroup !== 'company') {
        dbQueryArg.addFilter('contactGroupId', this.contactGroup)
      }

      if (this.customerCriteria !== 'company') {
        // Deal with outstanding option
        switch (this.customerCriteria) {
          case 'notBought':
            dbQueryArg.addFilter('sales', 0)
            break
          case LAST_INVOICED_SINCE_DAYS[0].toString():
          case LAST_INVOICED_SINCE_DAYS[1].toString():
          case LAST_INVOICED_SINCE_DAYS[2].toString():
            // Deal with the not bought in so many days option
            dbQueryArg.addFilter('lastInvoiced', '', 'ne')
            dbQueryArg.addFilter(
              'lastInvoiced',
              pc.dateToYMD(
                pc.dateDaysAdjust(
                  -Number(this.customerCriteria),
                  this.period.periodEndDate
                )
              ),
              'lt'
            )
            break
          case 'notBoughtThisMonth':
            dbQueryArg.addFilter('monthSpendComparisonPercentage', -100)
            break
          case 'belowAverageSpend':
            dbQueryArg.addFilter('monthSpendComparisonPercentage', -100, 'gt')
            dbQueryArg.addFilter('monthSpendComparisonPercentage', 0, 'lt')
            break
          case 'aboveAverageSpend':
            dbQueryArg.addFilter('averageMonthlySpend', 0, 'ne')
            dbQueryArg.addFilter('monthSpendComparisonPercentage', 0, 'gt')
            break
          case 'boughtFirstTime':
            dbQueryArg.addFilter('averageMonthlySpend', 0)
            dbQueryArg.addFilter('monthSpendComparisonPercentage', 100)
            break
          case 'outstanding':
            dbQueryArg.addFilter('outstanding', 0, 'ne')
            break
          case 'overdueAll':
            dbQueryArg.addFilter('paymentProfile', 0, 'gt')
            break
          case 'overdue1':
          case 'overdue2':
          case 'overdue3':
          case 'overdue4':
            dbQueryArg.addFilter(
              'paymentProfile',
              Number(this.customerCriteria.substr(7, 1))
            )
            break
        }
      }

      this.customers = db.cached('customers', dbQueryArg)
    },

    customerSelected(payload) {
      this.$emit('customer', payload.data)
    },

    findCustomerClose() {
      this.mini = !this.mini
    },

    createCSV() {
      const listDef = !this.extendedView
        ? this.findCustomersDef
        : this.extendedType === 'sales'
        ? this.findCustomersExtendedDef
        : this.findCustomersExtendedCoverageDef
      pc.csvFromLister(listDef, this.customers)
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      mini: true,
      contactGroupItems: [],
      contactGroup: 'company',

      customertCriteriaItems: [
        { text: 'All', value: 'All' },
        { text: 'Never bought', value: 'notBought' },
        {
          text: `Not bought for ${LAST_INVOICED_SINCE_DAYS[2]} days`,
          value: LAST_INVOICED_SINCE_DAYS[2].toString(),
        },
        {
          text: `Not bought for ${LAST_INVOICED_SINCE_DAYS[1]} days`,
          value: LAST_INVOICED_SINCE_DAYS[1].toString(),
        },
        {
          text: `Not bought for ${LAST_INVOICED_SINCE_DAYS[0]} days`,
          value: LAST_INVOICED_SINCE_DAYS[0].toString(),
        },
        {
          text: 'Not bought this month',
          value: 'notBoughtThisMonth',
        },
        {
          text: 'Below average spend',
          value: 'belowAverageSpend',
        },
        {
          text: 'Above average spend',
          value: 'aboveAverageSpend',
        },
        {
          text: 'Bought first time',
          value: 'boughtFirstTime',
        },

        { text: 'Outstanding', value: 'outstanding' },
        { text: 'Overdue', value: 'overdueAll' },
        { text: 'Overdue 21+ days', value: 'overdue4' },
        { text: 'Overdue 15-21 days', value: 'overdue3' },
        { text: 'Overdue 8-14 days', value: 'overdue2' },
        { text: 'Overdue 1-7 days', value: 'overdue1' },
      ],
      customerCriteria: 'All',

      customers: undefined,

      extendedView: false,
      extendedType: 'sales',

      findCustomersDef: {
        cardDef: pcCardDef('findCustomer', false, { show: false }),
        table: pcTableDef('findCustomerTable', 'id', ['name'], [false], {
          compressed: true,
          fontSize: 'medium',
          rowClick: this.customerSelected,
        }),
        fields: [
          pcFieldDef('name', 'text', '', 'Name', true, { width: '100%' }),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
        ],
      },

      findCustomersExtendedDef: {
        cardDef: pcCardDef('findCustomer', false, { show: false }),
        table: pcTableDef('findCustomerTable', 'id', ['name'], [false], {
          compressed: true,
          fontSize: 'medium',
          rowClick: this.customerSelected,
        }),
        fields: [
          pcFieldDef('name', 'text', '', 'Name', true),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('sales', 'currency', ``, 'Total sales', true),
          pcFieldDef(
            'salesThisMonth',
            'currency',
            `salesByMonth[${period.monthsInPeriod - 1}]`,
            `${pc.monthKeyName(
              'mmm yy',
              period.monthKeys[period.monthKeys.length - 1]
            )} sales`,
            true
          ),
          pcFieldDef('averageMonthlySpend', 'currency', '', 'Av. spend', true),
          pcFieldDef(
            'monthSpendComparisonPercentage',
            'number',
            '',
            '+/- % Av.Spend',
            true,
            {
              negativeClass: 'red--text',
            }
          ),
        ],
      },

      findCustomersExtendedCoverageDef: {
        cardDef: pcCardDef('findCustomer', false, { show: false }),
        table: pcTableDef('findCustomerTable', 'id', ['name'], [false], {
          compressed: true,
          fontSize: 'medium',
          rowClick: this.customerSelected,
        }),
        fields: [
          pcFieldDef('name', 'text', '', 'Name', true),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('sales', 'currency', ``, 'Total sales', true),
          pcFieldDef('products', 'number', '', 'Total prods.', true),
          pcFieldDef(
            'productCoveragePercentage',
            'number',
            '',
            'Total cov.%',
            true,
            { decimalPlaces: 2 }
          ),
          pcFieldDef(
            `productsByMonth[${period.monthsInPeriod - 1}]`,
            'number',
            '',
            `${pc.monthKeyName(
              'mmm yy',
              pc.arrayLastElement(period.monthKeys)
            )} prods.`,
            true
          ),
          pcFieldDef(
            `productCoveragePercentageByMonth[${period.monthsInPeriod - 1}]`,
            'number',
            '',
            `${pc.monthKeyName(
              'mmm yy',
              pc.arrayLastElement(period.monthKeys)
            )} cov.%`,
            true,
            { decimalPlaces: 2 }
          ),
        ],
      },
    }
  },
}
</script>
<style scoped></style>
