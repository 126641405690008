<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="none"
        allName="No contact group"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-chart :chartDef="chartDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'categorySalesChart',
  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
  },

  props: {
    categorySummaryDef: Object,
  },

  computed: {
    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categorySummaryDef.category
    },

    categoryContactGroups() {
      return this.categorySummaryDef.contactGroupCategories
    },
  },

  watch: {
    categorySummaryDef() {
      this.updateDisplay()
    },

    groupId() {
      this.updateDisplay()
    },
  },

  methods: {
    groupSelected(groupId) {
      this.groupId = groupId
    },

    updateDisplay() {
      const addChartGroupLine = function(groupId) {
        const group = db.cached(
          'contactGroups',
          db.cachedQuery().addFind('id', groupId)
        )
        const contactGroupCategory = pc
          .query()
          .addFind('contactGroupId', groupId)
          .run(this.categoryContactGroups)
        this.chartDef.addDataset(
          contactGroupCategory
            ? pc.arrayFixed2(contactGroupCategory.salesByMonth)
            : pc.createArray(0, this.period.monthsInPeriod),
          group
            ? `${group.name} (Total sales £${
                contactGroupCategory
                  ? pc.toFixed0(contactGroupCategory.sales)
                  : 0
              })`
            : 'Group not found',
          getColor('purple', 3),
          [['type', 'line']]
        )
      }.bind(this)

      const addChartCategoryTurnoverBars = function() {
        this.chartDef.addDataset(
          pc.arrayFixed2(this.category.salesByMonth),
          'Sales',
          getColor('blue', 3)
        )
      }.bind(this)

      if (this.isCategory) {
        this.displayBoxDef.title = `Category sales £${pc.toFixed0(
          this.category.sales
        )}`
        this.chartDef.clearDatasets()
        //this.chartDef.setLegend(groupId !== 'none')
        this.groupId !== 'none' && addChartGroupLine(this.groupId)
        addChartCategoryTurnoverBars()
        this.chartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      groupId: '',
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Category sales', {
        contentPadding: 'pt-1 pb-1 pl-3 pr-3',
      }),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
