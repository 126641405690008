import db from '@db'

/**
 * This function is called from the Signin component
 * immediately a user is signed in.
 */
async function initUser() {
  const data = await db.request(db.query('cache.cached'))
  const zeroSalesByMonth = new Array(data.period.monthsInPeriod).fill(0)
  db.cache('period', data.period)
  db.cache('contactGroups', data.contactGroups)
  db.cache(
    'customers',
    data.customers.map(customer => {
      if (!customer.salesByMonth.find(sales => sales !== 0)) {
        customer.salesByMonth = zeroSalesByMonth
      }
      return customer
    })
  )
  db.cache('products', data.products)
  db.cache(
    'categories',
    data.categories.map(category => {
      if (!category.salesByMonth.find(sales => sales !== 0)) {
        category.salesByMonth = zeroSalesByMonth
      }
      return category
    })
  )
}

/**
 * This function is called immediately a user is signed
 * out and should clear up any resourses used by the user
 * It is nomally called from the main component App.vue
 */
function clearUser() {
  db.deleteCache('period')
}

export { initUser, clearUser }
