<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChartType"
        value="pie"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Pie chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Stacked bar chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="line"
        icon="mdi-chart-line"
        small
        top
        tooltip="Line chart"
      />
      <pc-action-icon
        @click="toggleHidden"
        value=""
        icon="mdi-eye-off"
        small
        top
        tooltip="Show/Hide datasets"
      />
    </template>
    <template v-slot:default>
      <pc-chart
        :chartDef="pieChartDef"
        @instance="pieChartDef.instance"
        @tooltip="pieTooltip"
      />
      <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
      <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'productCategorySalesChart',
  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
  },

  props: {
    productSummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },

    isProduct() {
      return this.product && this.product.code ? true : false
    },

    category() {
      return this.product.productCategory
    },

    product() {
      return this.productSummaryDef.product
    },

    productsInCategory() {
      return this.product.productsInCategory
    },

    displayedChartDef() {
      if (this.pieChartDef.show) return this.pieChartDef
      if (this.barChartDef.show) return this.barChartDef
      return this.lineChartDef
    },
  },

  watch: {
    productSummaryDef() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.isProduct) {
        this.displayBoxDef.title = `${this.category.name} sales £${pc.toFixed0(
          this.category.sales
        )}`
        this.displayChartType('pie')
        this.updateCharts()
      }
    },

    updateCharts() {
      this.updateBarAndLineChart()
      this.updatePieChart()
    },

    displayChartType(type) {
      this.pieChartDef.show = type === 'pie'
      this.barChartDef.show = type === 'bar'
      this.lineChartDef.show = type === 'line'
    },

    updatePieChart() {
      this.pieChartData = this.productsInCategory.reduce(
        (chartData, product) => {
          if (product.sales) {
            chartData.products.push(product)
            chartData.labels.push(`${product.code} - ${product.name}`)
            chartData.values.push(product.sales)
            chartData.total += product.sales
          }
          return chartData
        },
        { products: [], labels: [], values: [], total: 0 }
      )
      this.pieChartDef
        .clearDatasets()
        .setPc([['products', this.pieChartData.products]])
        .addDataset(this.pieChartData.values, 'Product sales', this.chartColors)
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .setLabels(this.pieChartData.labels)
        .render()
    },

    updateBarAndLineChart() {
      this.barChartDef.clearDatasets()
      this.lineChartDef.clearDatasets()
      this.productsInCategory.forEach((product, index) => {
        if (product.sales) {
          this.barChartDef.addDataset(
            pc.arrayFixed2(product.salesByMonth),
            `${product.code} - ${product.name}`,
            this.chartColors[index]
          )
          this.lineChartDef.addDataset(
            pc.arrayFixed2(product.salesByMonth),
            `${product.code} - ${product.name}`,
            this.chartColors[index]
          )
        }
      })
      this.barChartDef.render()
      this.lineChartDef.render()
    },

    toggleHidden() {
      this.displayedChartDef.toggleHidden()
    },

    pieTooltip(payload) {
      const product = this.pieChartDef.pc.products[payload.dataIndex]
      const title = `${product.code} - ${product.name}`
      const color = this.pieChartDef.data.datasets[0].backgroundColor[
        payload.dataIndex
      ]
      const labels = pc.periodMonthNames('mmm', this.period.monthKeys)
      const percent = pc.toFixed2(
        pc.percentage(product.sales, this.category.sales)
      )
      const subTitle = `£${pc.toFixed2(
        product.sales
      )} (${percent}%) of category sales`

      this.pieSparkChartDef
        .clearDatasets()
        .setTitle(title, color)
        .setSubTitle(subTitle)
        .setLabels(labels)
        .addDataset(pc.arrayFixed2([...product.salesByMonth]), '', color)

      this.pieChartDef.setSparkChart(this.pieSparkChartDef)
      this.pieSparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartData: undefined,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'productCategorySales',
        'Product category sales',
        { contentPadding: 'pt-1 pb-1 pl-3 pr-3' }
      ),

      pieChartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setOptions([['cutout', '50%']])
        .setTitle('', false),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      pieSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
