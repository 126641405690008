<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="total"
        allName="Company"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-simple-table :simpleTableDef="simpleTableDef">
        <template v-slot:sales>
          <pc-sparkle-chart :sparkleChartDef="sparkleChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'summaryStatistics',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    contactGroupSelect,
  },

  methods: {
    groupSelected(groupId, groupName) {
      const group =
        groupId === 'total'
          ? this.period
          : db.cached('contactGroups', db.cachedQuery().addFind('id', groupId))

      this.displayBoxDef.title = `${groupName} sales statistics`

      this.simpleTableDef.data = this.period.monthKeys
        .map((monthKey, index) => ({
          month: pc.monthKeyName('mmm yy', this.period.monthKeys[index]),
          turnover: group.salesByMonth[index],
          activeCustomers: group.activeCustomersByMonth[index],
          averageTurnover: pc.safeDivide(
            group.salesByMonth[index],
            group.activeCustomersByMonth[index]
          ),
          numberInvoices: group.invoicesByMonth[index],
          averageInvoice: pc.safeDivide(
            group.salesByMonth[index],
            group.invoicesByMonth[index]
          ),
        }))
        .reverse()
    },
  },

  data() {
    return {
      period: db.cached('period'),

      displayBoxDef: pcDisplayBoxDef('statistics', 'Sales statistics', {
        height: '100%',
      }),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('month', 'text', 'Month'),
          pcSimpleTableFieldDef('turnover', 'currency', 'Sales', {
            decimalPlaces: 0,
          }),
          pcSimpleTableFieldDef('activeCustomers', 'int', 'Number<br />cust.'),
          pcSimpleTableFieldDef(
            'averageTurnover',
            'currency',
            'Customer<br />average'
          ),
          pcSimpleTableFieldDef(
            'numberInvoices',
            'int',
            'Number<br />invoices'
          ),
          pcSimpleTableFieldDef(
            'averageInvoice',
            'currency',
            'Invoice<br />average'
          ),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
