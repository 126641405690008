<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-chart
        :chartDef="chartDef"
        @instance="chartDef.instance"
        @tooltip="tooltip"
      />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'customerTop10Chart',

  components: {
    pcChart,
    pcDisplayBox,
  },

  props: {
    recordType: String,
    orderBy: String,
    top10Records: Array,
  },

  created() {
    this.displayBoxDef.title = this.title
  },

  computed: {
    title() {
      const name =
        this.recordType === 'category' ? 'categories' : this.recordType + 's'
      return `Top 10 ${name} bought by ${this.orderBy}`
    },
  },

  watch: {
    top10Records() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.displayBoxDef.title = this.title
      this.updateChart(this.top10Records)
    },

    async updateChart(top10Records) {
      const names = pc.pluck('name', top10Records)
      const values = pc.arrayFixed2(pc.pluck('sales', top10Records))
      const datasetLabel = `${pc.capitalise(this.recordType)} sales`

      this.chartDef
        .clearDatasets()
        .setLabels(names)
        .addDataset(values, datasetLabel, this.chartColors)
        .render()
    },

    tooltip(payload) {
      const record = this.top10Records[payload.dataIndex]
      const title =
        this.recordType === 'product'
          ? `${record.code} - ${record.name}`
          : record.name
      const titleColor = pc.getProperty('tag', record)
      const subTitle = `Sales £${pc.toFixed2(record.sales)} - ${pc.toFixed2(
        record.percentage
      )}%`
      const values = pc.arrayFixed2(record.salesByMonth)

      this.sparkChartDef
        .clearDatasets()
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .addDataset(values, 'Sales', titleColor)

      this.chartDef.setSparkChart(this.sparkChartDef)
      this.sparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef('top10RecordsDisplayBox', '', {
        height: '100%',
      }),

      chartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setLegend(false),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
