import Vue from 'vue'

export const $eventBus = new Vue()

export const forceNextTick = (callback) => {
  const doubleRequestAnimationFrame = (callback) => {
    return Vue.nextTick(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    })
  }

  return callback && typeof callback === 'function'
    ? doubleRequestAnimationFrame(callback)
    : new Promise((resolve) => {
        doubleRequestAnimationFrame(resolve)
      })
}

export const awaitForceNextTick = async () => await forceNextTick()

export const breakpoint = {
  init(vuetifyBreakpoints) {
    return {
      breakpoints: [
        'xs',
        'xsOnly',
        'sm',
        'smAndDown',
        'smAndUp',
        'md',
        'mdOnly',
        'mdAndDown',
        'mdAndUp',
        'lg',
        'lgOnly',
        'lgAndDown',
        'lgAndUp',
        'xl',
        'xlOnly',
      ],
      valid(breakpoint) {
        return this.breakpoints.find((bp) => bp === breakpoint) !== undefined
      },
      is(breakpoint) {
        if (this.valid(breakpoint)) return vuetifyBreakpoints[breakpoint]
        console.log(
          `Invalid breakpoint: ${breakpoint} in pc.breakpoint - returning true`
        )
        return true
      },
      current() {
        return vuetifyBreakpoints.name
      },
      height() {
        return vuetifyBreakpoints.height
      },
      width() {
        return vuetifyBreakpoints.width
      },
      thresholds() {
        return vuetifyBreakpoints.thresholds
      },
      scrollBarWidth() {
        return vuetifyBreakpoints.scrollBarWidth
      },
    }
  },
}

export const isBreakpointObject = (obj) =>
  typeof obj !== 'object' ? false : Object.hasOwnProperty.call(obj, 'xs')

export const getBreakpointValue = (property, breakpoint) => {
  const getDefBreakpoint = (property) => {
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    if (Object.hasOwnProperty.call(property, breakpoint)) return breakpoint
    let useBreakpoint = undefined
    const breakpointIndex = breakpoints.indexOf(breakpoint)
    for (var i = breakpointIndex; i >= 0; i--) {
      if (Object.hasOwnProperty.call(property, breakpoints[i])) {
        useBreakpoint = breakpoints[i]
        break
      }
    }
    return useBreakpoint ? useBreakpoint : 'xs'
  }

  if (!isBreakpointObject(property)) return property
  const propertyValue = property[getDefBreakpoint(property)]
  if (typeof propertyValue === 'string' && typeof property.all === 'string')
    return `${property.all} ${propertyValue}`.trim()
  return propertyValue
}

export default {
  $eventBus,
  forceNextTick,
  awaitForceNextTick,
  breakpoint,
  isBreakpointObject,
  getBreakpointValue,
}
