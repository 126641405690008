<template>
  <v-row
    id="categoryDashboard"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
      <preferences
        :openDialog="preferencesDialog"
        v-on:closed="preferencesDialogClosed"
      ></preferences>

      <v-tabs class="pc-size100" height="0px">
        <v-tabs-items v-model="tab" class="pc-col">
          <v-tab-item class="pc-col mt-0">
            <pc-card :card-def="dashboardCardDef">
              <template v-slot:title>
                <pc-toolbar :toolbar-def="toolbarDef" />
              </template>

              <template v-slot:text>
                <v-row
                  class="fill-height mt-0"
                  justify="center"
                  align="stretch"
                >
                  <categoryTree
                    class="pl-3"
                    @categorySelected="categorySelected"
                  />
                  <pc-nav-drawer
                    :navDrawerDef="navDrawerDef"
                    v-on:selected="menuItem => (menuIdSelected = menuItem.id)"
                  />
                  <v-col class="pc-col d-flex flex-column pl-0 pt-0 pb-0">
                    <categorySummary :categorySummaryDef="categorySummaryDef" />
                    <categoryCustomers
                      :categoryCustomersDef="categoryCustomersDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <category-top-10
                      :categoryTop10Def="categoryTop10Def"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                  </v-col>
                </v-row>
              </template>
            </pc-card>
          </v-tab-item>
          <v-tab-item class="pc-col pt-3">
            <customer-dashboard
              :dashboardCustomer="customer"
              @exit="exitCustomerDashboard"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import db from '@db'
import { LocalStorage } from '@pcModules/localStorage.js'
import { whitespaceLocalStorageSchema } from '@appModules/ws.js'
import { categoryDashboardComputeData } from './categoryDashboardComputeData.js'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import categorySummary from '@appViews/categoryDashboard/categorySummary/categorySummary.vue'
import categoryCustomers from '@appViews/categoryDashboard/categoryCustomers/categoryCustomers.vue'
import categoryTop10 from '@appViews/categoryDashboard/categoryTop10/categoryTop10.vue'
import preferences from '@appViews/categoryDashboard/preferences'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
//import { lastInvoicedClass } from '@appModules/ws.js'
import {
  pcNavDrawerDef,
  pcNavDrawerItem,
} from '@pcComponents/defs/pcNavDrawerDef.js'

import categoryTree from '@appViews/categoryDashboard/categoryTree.vue'
import pcNavDrawer from '@pcComponents/pcNavDrawer.vue'
import customerDashboard from '@appViews/customerDashboard/customerDashboard.vue'

export default {
  name: 'categoryDashboard',

  components: {
    pcCard,
    pcToolbar,
    pcNavDrawer,
    categorySummary,
    categoryCustomers,
    categoryTop10,
    preferences,
    categoryTree,
    customerDashboard,
  },

  async created() {
    this.initialise()
    this.main()
  },

  watch: {
    category() {
      if (this.category && this.category.id !== 'category') this.main()
    },

    menuIdSelected(value) {
      this.categorySummaryDef = {
        ...this.categorySummaryDef,
        show: value === 'summary',
      }
      this.categoryTop10Def = {
        ...this.categoryTop10Def,
        show: value === 'top10',
      }
      this.categoryCustomersDef = {
        ...this.categoryCustomersDef,
        show: value === 'customers',
      }
    },
  },

  methods: {
    initialise() {
      const whitespaceLocalStorage = new LocalStorage(
        'whitespace',
        whitespaceLocalStorageSchema
      )
      this.openingWindow = whitespaceLocalStorage.getProperty(
        'categoryDashboard.openingWindow'
      )
      this.menuIdSelected = this.openingWindow || 'summary'
    },

    async main() {
      if (this.category) {
        const data = await categoryDashboardComputeData(this.category)
        this.toolbarDef.title = `Category dashboard - ${data.category.name}`

        this.categorySummaryDef = { ...this.categorySummaryDef, ...data }
        this.categoryTop10Def = { ...this.categoryTop10Def, ...data }
        this.categoryCustomersDef = { ...this.categoryCustomersDef, ...data }
      } else {
        this.menuIdSelected = this.openingWindow || 'summary'
      }
    },

    groupSelected(contactGroupId) {
      this.contactGroupId = contactGroupId
    },

    categorySelected(category) {
      this.category = category
    },

    exitDashboard() {
      this.$emit('exit')
    },

    preferences() {
      this.preferencesDialog = true
    },

    preferencesDialogClosed(openingWindow) {
      this.preferencesDialog = false
      this.openingWindow = openingWindow
    },

    showCustomerDashboard(payload) {
      this.customer = db.cached(
        'customers',
        db.cachedQuery().addFind('id', payload.customerId)
      )
      this.tab = 1
    },

    exitCustomerDashboard() {
      this.tab = 0
    },
  },

  data() {
    return {
      tab: 0,
      period: db.cached('period'),
      category: undefined,
      categories: {},
      categoryId: '',
      customer: undefined,

      contactGroupId: 'total',
      resetGroupSelect: 0,
      preferencesDialog: false,
      openingWindow: '',
      menuIdSelected: '',

      dashboardCardDef: pcCardDef('categoryDashboard', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef(
        'categoryDashboardToolbar',
        'Category dashboard',
        {},
        [
          pcToolbarActionDef(
            'preferences',
            'mdi-cog-outline',
            this.preferences,
            'Set preferences'
          ),
        ]
      ),

      navDrawerDef: pcNavDrawerDef(
        'optionsNavDrawer',
        [
          pcNavDrawerItem('summary', 'mdi-view-dashboard', 'Summary'),
          pcNavDrawerItem('customers', 'mdi-account', 'Customers'),
          pcNavDrawerItem('top10', 'mdi-sort-descending', 'Top 10'),
        ],
        { width: '200px', expandOnHover: true, miniVariant: true }
      ),

      dashboardExitActionDef: pcToolbarActionDef(
        'back',
        'mdi-arrow-left',
        this.exitDashboard,
        'Exit dashboard'
      ),

      categorySummaryDef: {
        show: false,
      },

      categoryTop10Def: {
        show: false,
      },

      categoryCustomersDef: {
        show: false,
      },
    }
  },
}
</script>

<style scoped></style>
