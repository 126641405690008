<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-simple-table :simpleTableDef="simpleTableDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

export default {
  name: 'contactGroupStatistics',

  components: {
    pcDisplayBox,
    pcSimpleTable,
  },

  props: {
    group: Object,
  },

  watch: {
    group: {
      immediate: true,
      handler(group) {
        if (group && group.id) {
          this.simpleTableDef.data = this.period.monthKeys
            .map((monthKey, index) => ({
              month: pc.monthKeyName('mmm yy', this.period.monthKeys[index]),
              turnover: group.salesByMonth[index],
              activeCustomers: group.activeCustomersByMonth[index],
              customerAverage: group.customerAverageByMonth[index],
              numberInvoices: group.invoicesByMonth[index],
              invoiceAverage: group.invoiceAverageByMonth[index],
            }))
            .reverse()
        }
      },
    },
  },

  methods: {},

  data() {
    return {
      period: db.cached('period'),

      displayBoxDef: pcDisplayBoxDef('statistics', 'Sales statistics', {
        height: '100%',
      }),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('month', 'text', 'Month'),
          pcSimpleTableFieldDef('turnover', 'currency', 'Sales', {
            decimalPlaces: 0,
          }),
          pcSimpleTableFieldDef('activeCustomers', 'int', 'Number<br />cust.'),
          pcSimpleTableFieldDef(
            'customerAverage',
            'currency',
            'Customer<br />average'
          ),
          pcSimpleTableFieldDef(
            'numberInvoices',
            'int',
            'Number<br />invoices'
          ),
          pcSimpleTableFieldDef(
            'invoiceAverage',
            'currency',
            'Invoice<br />average'
          ),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
