export default {
  namespaced: true,
  state: {
    contentWindow: {
      size: undefined,
      height: 1,
      width: 1,
    }
  },
  mutations: {
    mutContentWindowSize(state, payload) {state.contentWindow.size = payload},
    mutContentWindowHeight(state, payload) {state.contentWindow.height = payload},
    mutContentWindowWidth(state, payload) {state.contentWindow.width = payload}
  },
  actions: {},
  getters: {
    getContentWindowSize(state) {return state.contentWindow.size},
    getContentWindowHeight(state) {return state.contentWindow.height},
    getContentWindowWidth(state) {return state.contentWindow.width}
  }
}