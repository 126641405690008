//import * as R from 'ramda'

import { arraysEqual } from './array.js'

export const wait = ms =>
  new Promise(resolve => {
    setTimeout(() => resolve(0), ms)
  })

export const runIn = (func, ms = 50) => setTimeout(() => func(), ms)

export const debounce = (fn, interval) => {
  let timer
  return function debounced(...args) {
    clearTimeout(timer)
    timer = setTimeout(function() {
      fn(...args)
    }, interval)
  }
}

export const pipeSync = (...functions) =>
  functions.reduce((a, b) => data => b(a(data)))

export const pipeAsync = (...functions) => input =>
  functions.reduce((chain, func) => chain.then(func), Promise.resolve(input))

export const pipeSyncInject = (...functions) => (...inject) =>
  functions.reduce((a, b) => data => b(a(data, ...inject), ...inject))

export async function forEachAsync(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const memorizeOneSync = fn => {
  let runOnce = false
  let lastArguments = []
  let result

  return function() {
    if (runOnce && arraysEqual(arguments, lastArguments)) return result
    lastArguments = arguments
    result = fn(...arguments)
    runOnce = true
    return result
  }
}

export const memorizeOneAsync = fn => {
  let runOnce = false
  let lastArguments = []
  let result

  return async function() {
    if (runOnce && arraysEqual(arguments, lastArguments)) return result
    lastArguments = arguments
    result = await fn(...arguments)
    runOnce = true
    return result
  }
}

export const ifTrue = (isTrueFn, runThisFn) => (value, ...args) => {
  if (isTrueFn(value)) return runThisFn(args)
  return value
}

export const tryCatch = (tryFn, catchFn) => (...args) => {
  try {
    return tryFn(...args)
  } catch (originalError) {
    console.clear()
    if (catchFn) {
      try {
        return catchFn(...args, originalError)
      } catch (err) {
        console.clear()
        console.trace(
          '\n',
          `Catch: ${catchFn.name} - ${err}`,
          '\n',
          `Try: ${tryFn.name} - ${originalError}`,
          '\n',
          `Args: ${JSON.stringify(args, null, 2)}`
        )
      }
    } else {
      console.clear()
      console.trace(
        '\n',
        `Try: ${tryFn.name} - ${originalError}`,
        '\n',
        `Args: ${JSON.stringify(args, null, 2)}`
      )
    }
    throw new Error(
      `in pc.tryCatch(${tryFn.name}${catchFn ? ' ,' + catchFn.name : ''})`
    )
  }
}

export const nop = () => {}

export default {
  wait,
  runIn,
  debounce,
  pipeSync,
  pipeAsync,
  pipeSyncInject,
  forEachAsync,
  memorizeOneSync,
  memorizeOneAsync,
  tryCatch,
  nop,
}
