<template>
  <v-row
    id="customerCategories"
    v-show="categoriesDef.show"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0 pl-0">
      <pc-tooltip :tooltip-def="categoryTooltipDef">
        <template v-slot:tooltip>
          <pc-spark-Chart :sparkChartDef="categorySparkChartDef" />
        </template>
      </pc-tooltip>
      <div class="grid-container pt-0 pb-0">
        <div class="categories">
          <pc-card :cardDef="customerCategoriesCardDef">
            <template v-slot:title>
              <pc-toolbar :toolbarDef="toolbarDef">
                <template v-slot:toolbarActions>
                  <v-radio-group
                    v-model="color"
                    dark
                    :column="false"
                    height="16px"
                    class="pt-6 pr-8 mb-0"
                  >
                    <v-radio
                      v-for="n in 3"
                      color="white"
                      class="pr-4"
                      :key="n"
                      :label="['White', 'Grey', 'Black'][n - 1]"
                      :value="['White', 'Grey', 'Black'][n - 1]"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </pc-toolbar>
            </template>

            <template v-slot:text>
              <v-row justify="center" align="start">
                <v-col
                  cols="5"
                  class="pc-col d-flex flex-column pb-0 pt-3 pr-1"
                >
                  <div class="tree-heading blue-grey lighten-5 pt-4">
                    <span class="pr-7">Turnover</span>
                    <span class="pr-6">Last invoiced</span>
                  </div>
                  <div class="tree">
                    <v-treeview
                      activatable
                      dense
                      :open.sync="openCategoryItems"
                      :items="categories"
                      return-object
                      :active.sync="active"
                    >
                      <template v-slot:label="{ item }">
                        <span
                          :class="{ 'chip blue-grey lighten-4': item.isBought }"
                          >{{ item.name }}</span
                        >
                      </template>
                      <template v-slot:append="{ item }">
                        <span
                          class="pr-2"
                          v-on:mouseover="
                            categoryTurnoverTooltipHandler($event, item)
                          "
                          >{{ item.printTurnover }}</span
                        >
                        <span
                          class="pl-3 mr-2"
                          :class="item.lastInvoicedClass"
                          >{{ item.lastInvoiced }}</span
                        >
                      </template>
                    </v-treeview>
                  </div>
                </v-col>

                <v-col cols="7" class="pc-col d-flex pt-3 pb-1 pl-0">
                  <pc-lister
                    :list-def="productsListDef"
                    :list-items="productsListItems"
                  >
                    <template v-slot:quantity>
                      <ws-product-sales-by-month
                        :record="productSalesByMonthRecord"
                      />
                    </template>
                    <template v-slot:turnover>
                      <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
                    </template>
                    <template v-slot:expanded-item>
                      <tr>
                        <td :colspan="99">
                          <ws-product-invoices :invoicesDef="invoicesListDef" />
                        </td>
                      </tr>
                    </template>
                  </pc-lister>
                </v-col>
              </v-row>
            </template>
          </pc-card>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcLineActionsDef } from '@pcComponents/defs/pcLineActionsDef.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'

import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import pcLister from '@pcComponents/pcLister.vue'
import pcTooltip from '@pcComponents/pcTooltip.vue'
import wsProductInvoices from '@appViews/sharedComponents/wsProductInvoices'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import wsProductSalesByMonth from '@appViews/sharedComponents/wsProductSalesByMonth.vue'

export default {
  name: 'customerCategories',
  components: {
    pcCard,
    pcToolbar,
    pcLister,
    pcTooltip,
    wsProductInvoices,
    pcSparkChart,
    wsProductSalesByMonth,
  },

  // properties are:
  //  customerId
  //  show
  props: {
    categoriesDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    customerId() {
      return this.categoriesDef.customerId
    },
  },

  watch: {
    customerId() {
      this.main()
    },

    active() {
      this.viewChange()
    },
    color() {
      this.viewChange()
    },
  },

  methods: {
    async main() {
      const getData = async customerId => {
        const customer = db.cached(
          'customers',
          db.cachedQuery().addFind('id', customerId)
        )
        const categories = db.cached('categories', db.cachedQuery().addClone())
        const { categoryCustomers, productCustomers } = await db.request(
          db.query('customerCategories.categoryCustomersAndProductCustomers'),
          db
            .args()
            .addQueryArgs(
              db
                .queryArgs('categoryCustomers')
                .addFilter('customerId', this.customerId)
            )
            .addQueryArgs(
              db
                .queryArgs('productCustomers')
                .addFilter('customerId', this.customerId)
            )
        )

        return {
          customerId,
          customer,
          categories,
          customerCategories: categoryCustomers,
          customerProducts: productCustomers,
        }
      }

      const mergeCustomerSales = data => {
        const { categories, customerCategories } = data
        const period = db.cached('period')
        const customerBought = category => ({
          isBought: true,
          turnover: category.sales,
          printTurnover: `£${pc.toFixed2(category.sales)}`,
          lastInvoiced: pc.dateToDMY(category.lastInvoiced),
          lastInvoicedClass: lastInvoicedClass(
            category.lastInvoiced,
            this.period.periodEndDate
          ),
        })
        const customerNotBought = () => ({
          sales: 0,
          salesByMonth: pc.createArray(0, period.monthsInPeriod),
          lastInvoiced: '',
          isBought: false,
          turnover: 0,
          printTurnover: '',
          lastInvoicedClass: '',
        })
        data.categories = pc.joinRecords(
          pc.prop('id'),
          pc.prop('categoryId'),
          customerBought,
          customerNotBought,
          categories,
          customerCategories
        )
        return data
      }

      const makeCategoryTree = data => {
        const { categories } = data
        const getChildren = category => {
          category.children = []
          if (!category.hasChildren) return
          const children = pc
            .query()
            .addFilter('parentId', category.id)
            .addSort('name')
            .run(categories)
          category.children = children
          children.forEach(getChildren)
          return category
        }

        const treeRoot = categories.find(category => category.id === 'category')
        data.categoryTree = getChildren(treeRoot)
        return data
      }

      if (this.customerId) {
        const data = await pc.pipeAsync(
          getData,
          mergeCustomerSales,
          makeCategoryTree
        )(this.customerId)

        this.customer = data.customer
        this.categories = [data.categoryTree]
        if (!this.openCategoryItems.length) {
          this.openCategoryItems = this.categories
        }
        this.customerCategories = data.customerCategories
        this.customerProducts = data.customerProducts
        this.viewChange()
      }
    },

    async viewChange() {
      if (this.active.length) {
        const category = this.active[0]

        const productCategoryField = category.level
          ? `category.level${category.level}`
          : 'category.level1'

        const products = await db.request(
          db.query('customerCategories.products'),
          db.args(
            db
              .queryArgs('products')
              .addFilter(productCategoryField, category.id)
          )
        )

        const listThisProduct = (productList, product) => {
          const customerProduct = this.customerProducts.find(
            customerProduct => customerProduct.productCode === product.code
          )
          if (this.color === 'White' && customerProduct) return productList
          if (this.color === 'Black' && !customerProduct) return productList
          productList.push({
            productCode: product.code,
            name: product.name,
            ranking: product.ranking,
            tradePrice: product.tradePrice,
            RRP: product.RRP,
            quantity: customerProduct ? customerProduct.quantity : 0,
            sales: customerProduct ? customerProduct.sales : 0,
            lastInvoiced: customerProduct ? customerProduct.lastInvoiced : '',
            quantityByMonth: customerProduct
              ? customerProduct.quantityByMonth
              : this.zeroQuantityByMonth,
            salesByMonth: customerProduct
              ? customerProduct.salesByMonth
              : this.zeroSalesByMonth,
          })
          return productList
        }

        this.productsListItems = products.reduce(listThisProduct, [])
      }
    },

    categoryTurnoverTooltipHandler(event, item) {
      if (!item.isBought) return

      const value = item.sales
      const total = this.customer.sales
      const percent = pc.toFixed2(pc.percentage(value, total))
      const subTitle = `${item.printTurnover} (${percent}%) of customer turnover`
      const salesByMonth = pc.arrayFixed2(item.salesByMonth)

      this.categorySparkChartDef
        .clearDatasets()
        .setTitle(item.name)
        .setSubTitle(subTitle)
        .addDataset(salesByMonth, 'Sales')
        .render()

      this.categoryTooltipDef.activatorElement = event.currentTarget
      this.categoryTooltipDef.show = true
    },

    viewProductInvoices(payload) {
      payload.props.expand(!payload.props.isExpanded)
      if (!payload.props.isExpanded) {
        this.invoicesListDef.customerId = this.customerId
        this.invoicesListDef.productCode = payload.data.productCode
        this.invoicesListDef.triggerUpdate = pc.uid()
      }
    },

    lastInvoicedClass(payload) {
      return lastInvoicedClass(
        payload.data.lastInvoiced,
        this.period.periodEndDate
      )
    },

    quantityItemClass(payload) {
      return pc.getProperty(payload.field.dataPath, payload.data)
        ? `chip blue-grey lighten-4`
        : ''
    },

    productQuantityTooltipHandler(payload) {
      this.productSalesByMonthRecord = payload.data
    },

    productTurnoverTooltipHandler(payload) {
      const value = pc.getProperty(payload.field.dataPath, payload.data)
      const total = this.customer.sales
      const percent = pc.toFixed2(pc.percentage(value, total))
      const title = `${payload.data.productCode} ${payload.data.name}`
      const subTitle = `£${pc.toFixed2(
        value
      )} (${percent}%) of customer turnover`
      const salesByMonth = pc.arrayFixed2(payload.data.salesByMonth)

      this.turnoverSparkChartDef
        .clearDatasets()
        .setTitle(title)
        .setSubTitle(subTitle)
        .addDataset(salesByMonth, 'Sales')
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      color: 'Grey',
      period,
      zeroSalesByMonth: new Array(period.monthsInPeriod).fill(0),
      zeroQuantityByMonth: new Array(period.monthsInPeriod).fill(0),
      customer: {},
      customerCategories: [],
      customerProducts: [],
      categories: [],
      active: [],
      openCategoryItems: [],
      categoriesBought: [],

      customerCategoriesCardDef: pcCardDef('customerCategories', false, {
        loaderType: 'card',
        elevation: '0',
      }),

      toolbarDef: pcToolbarDef('customerCategoriesToolbar', 'Categories', {
        dense: true,
        color: 'primary lighten-2',
      }),

      productsListDef: {
        cardDef: pcCardDef('customerProducts', false, {
          subTitleClass: 'blue lighten-4',
          elevation: '0',
        }),
        toolbarDef: undefined,
        table: pcTableDef(
          'customerProductsTable',
          'productCode',
          ['code'],
          [false],
          { rowClick: this.viewProductInvoices }
        ),
        fields: [
          pcFieldDef('productCode', 'text', '', 'Code', true),
          pcFieldDef('name', 'text', '', 'Name', false),
          pcFieldDef('ranking', 'ranking', '', 'Ranked'),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedClass,
          }),
          pcFieldDef('quantity', 'number', '', 'Quantity', true, {
            itemClass: this.quantityItemClass,
            tooltipDef: pcTooltipDef(
              'quantityTooltip',
              this.productQuantityTooltipHandler
            ),
          }),
          pcFieldDef('turnover', 'currency', 'sales', 'Value', true, {
            tooltipDef: pcTooltipDef(
              'turnoverTooltip',
              this.productTurnoverTooltipHandler
            ),
          }),
          pcFieldDef('tradePrice', 'currency', '', 'Trade price', false),
          pcFieldDef('RRP', 'currency', '', 'RRP', false),
        ],
        lineActionsDef: pcLineActionsDef(),
      },
      productsListItems: [],

      productSalesByMonthRecord: {},

      turnoverTooltipDef: pcTooltipDef('turnoverTooltip'),
      turnoverSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      categoryTooltipDef: pcTooltipDef('categoryTooltip'),
      categorySparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      invoicesListDef: {
        show: true,
        width: '90%',
        maxWidth: '800px',
        productCode: '',
        customerId: '',
        triggerUpdate: '',
      },
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  padding-top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-template-rows: 1fr;
  grid-template-areas: 'categories';
}
.categories {
  grid-area: categories;
  overflow-y: auto;
}
.tree-heading {
  height: 48px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}
.tree {
  height: 100%;
  width: 100%;
  border-right: 1px solid black;
  overflow-y: scroll;
}
.chip {
  display: inline-block;
  padding: 0 12px;
  border-radius: 25px;
  background-color: green;
}
</style>
