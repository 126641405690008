export const SetObject = (name, content = []) => {
  let _set = new Set(content)
  const api = {
    [name + 'Add']: (value) => _set.add(value),
    [name + 'Has']: (value) => _set.has(value),
    [name + 'Delete']: (value) => _set.delete(value),
    [name + 'Clear']: () => _set.clear(),
    [name + 'Entries']: () => _set.entries(),
    [name + 'ForEach']: (fn) => _set.forEach(fn),
    [name + 'Values']: () => _set.values(),
    [name + 'Size']: () => _set.size,
    [name + 'Assign']: (content) => (_set = new Set(content)),
    [name + 'Array']: () => Array.from(_set.values()),
    [name + 'Set']: () => _set,
    [name + 'Api']: () => api,
  }
  return api
}

export default {
  SetObject,
}
