<template>
  <v-text-field
    :id="field.name"
    :name="field.name"
    :disabled="disabled"
    :class="field.present.class"
    :label="field.present.label"
    :placeholder="field.present.placeholder"
    v-model="model"
    :type="textType"
    @focus="resetField"
    @blur="validateField"
    :error-messages="field.errors"
  ></v-text-field>
</template>

<script>
import pc from '@pc'

export default {
  name: 'pc-text',
  props: {
    parentId: String,
    field: Object,
    disabled: Boolean,
  },
  computed: {
    textType() {
      let type = this.field.present.email ? 'email' : 'text'
      type = this.field.present.password ? 'password' : type
      return type
    },
    model: {
      get: function() {
        return this.field.value
      },
      set: function(newValue) {
        this.inputValue = newValue
        if (this.field.present.uppercase) {
          this.inputValue = this.inputValue.toUpperCase()
        }
        this.updateField()
      },
    },
  },
  methods: {
    resetField() {
      pc.$eventBus.$emit(this.parentId + 'fieldEvent', {
        event: 'resetField',
        from: 'pcText',
        name: this.field.name,
        data: '',
      })
    },
    updateField() {
      pc.$eventBus.$emit(this.parentId + 'fieldEvent', {
        event: 'updateField',
        from: 'pcText',
        name: this.field.name,
        data: this.inputValue,
      })
      this.validateField()
    },
    validateField() {
      pc.$eventBus.$emit(this.parentId + 'fieldEvent', {
        event: 'validateField',
        from: 'pcText',
        name: this.field.name,
        data: '',
      })
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
}
</script>

<style scoped></style>
