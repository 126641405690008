import { gql } from 'graphql-tag'

export const contactGroupGql = gql`
  query($dbQuery: DbQuery) {
    contactGroups(dbQuery: $dbQuery) {
      id
      name
      numberCustomers
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      invoices
      invoicesByDays
      invoicesByMonth
      customerAverage
      customerAverageByDays
      customerAverageByMonth
      invoiceAverage
      invoiceAverageByDays
      invoiceAverageByMonth
      outstandingProfile {
        overdue {
          type
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
        due {
          type
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
      }
      paidInvoicesProfile {
        profileIndex
        months {
          count
          profileIndex
          monthIndex
        }
      }
      top10Customers {
        top10Records {
          id
          name
          sales
          salesByMonth
          lastInvoiced
        }
        top10Sales
        top10SalesByMonth
        restSales
        restSalesByMonth
        totalSales
      }
      top10Products {
        top10Records {
          code
          name
          sales
          salesByMonth
          lastInvoiced
        }
        top10Sales
        top10SalesByMonth
        restSales
        restSalesByMonth
        totalSales
      }
      salesByCategory(dbQuery: $dbQuery) {
        categoryId
        categoryName
        sales
        salesByDays
        salesByMonth
      }
      salesByProduct(dbQuery: $dbQuery) {
        productCode
        productName
        sales
        salesByMonth
      }
    }
  }
`

export const companyGql = gql`
  query($dbQuery: DbQuery) {
    company(dbQuery: $dbQuery) {
      id
      name
      numberCustomers
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      invoices
      invoicesByDays
      invoicesByMonth
      customerAverage
      customerAverageByDays
      customerAverageByMonth
      invoiceAverage
      invoiceAverageByDays
      invoiceAverageByMonth
      outstandingProfile {
        overdue {
          type
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
        due {
          type
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
      }
      paidInvoicesProfile {
        profileIndex
        months {
          count
          profileIndex
          monthIndex
        }
      }
      top10Customers {
        top10Records {
          id
          name
          sales
          salesByMonth
          lastInvoiced
        }
        top10Sales
        top10SalesByMonth
        restSales
        restSalesByMonth
        totalSales
      }
      top10Products {
        top10Records {
          code
          name
          sales
          salesByMonth
          lastInvoiced
        }
        top10Sales
        top10SalesByMonth
        restSales
        restSalesByMonth
        totalSales
      }
      salesByCategory(dbQuery: $dbQuery) {
        categoryId
        categoryName
        sales
        salesByDays
        salesByMonth
      }
      salesByProduct(dbQuery: $dbQuery) {
        productCode
        productName
        sales
        salesByMonth
      }
    }
  }
`
