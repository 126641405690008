<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-chart
        :chartDef="chartDef"
        @instance="chartDef.instance"
        @tooltip="tooltip"
      />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'categoryDBCustomersBoughtChart',

  components: {
    pcChart,
    pcDisplayBox,
  },

  props: {
    top10Data: Object,
  },

  computed: {
    title() {
      return `Top 10 customers - ${this.top10Data.periodCaption}`
    },
  },

  watch: {
    top10Data() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.displayBoxDef.title = this.title
      this.updateChart()
    },

    async updateChart() {
      const names = pc.pluck('name', this.top10Data.customers)
      const values = pc.arrayFixed2(pc.pluck('sales', this.top10Data.customers))
      const datasetLabel = `Sales`

      this.chartDef
        .clearDatasets()
        .setLabels(names)
        .addDataset(values, datasetLabel, this.chartColors)
        .render()
    },

    tooltip(payload) {
      const record = this.top10Data.customers[payload.dataIndex]
      const title = record.name
      const titleColor = pc.getProperty('tag', record)
      const subTitle = `Sales £${pc.toFixed2(record.sales)} - ${pc.toFixed2(
        record.percentage
      )}%`
      const values = pc.arrayFixed2(record.salesByMonth)

      this.sparkChartDef
        .clearDatasets()
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .addDataset(values, 'Sales', titleColor)

      this.chartDef.setSparkChart(this.sparkChartDef)
      this.sparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef('top10RecordsDisplayBox', '', {
        height: '100%',
      }),

      chartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setLegend(false),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
