<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-chart :chartDef="chartDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'contactGroupSalesChart',

  props: {
    group: Object,
  },

  components: {
    pcChart,
    pcDisplayBox,
  },

  watch: {
    group: {
      immediate: true,
      handler(group) {
        if (group && group.id) {
          this.displayBoxDef.title = `Sales £${pc.toFixed0(group.sales)}`
          this.chartDef
            .clearDatasets()
            .addDataset(
              pc.arrayFixed2(group.salesByMonth),
              'Sales',
              getColor('blue', 3)
            )
          this.chartDef.render()
        }
      },
    },
  },

  methods: {},

  data() {
    const period = db.cached('period')
    return {
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Sales', {
        title: `Sales`,
      }),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
