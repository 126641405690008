<template>
  <div class="nugget-tooltip">
    <v-row>
      <v-col cols="6" class="pr-0">
        <pc-nugget :nuggetDef="invoicesNuggetDef" />
      </v-col>
      <v-col cols="6" class="pl-2">
        <pc-nugget :nuggetDef="trendNuggetDef" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pb-0 pr-0">
        <pc-nugget :nuggetDef="nextDueNuggetDef" />
      </v-col>
      <v-col cols="6" class="pb-0 pl-2">
        <pc-nugget :nuggetDef="overdueNuggetDef" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcNuggetDef } from '@pcComponents/defs/pcNuggetDef.js'

import pcNugget from '@pcComponents/pcNugget.vue'

export default {
  name: 'tooltipCustomerSummary',

  components: {
    pcNugget,
  },

  props: {
    customerId: String,
  },

  created() {
    this.main()
  },

  watch: {
    customerId() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.customerId) {
        const customer = await db.request(
          db.query('tooltip.customerSummary'),
          db.args(db.queryArgs('customers').addFind('id', this.customerId))
        )

        this.averageInvoiceNugget(customer)
        this.salesTrendNugget(customer)
        this.nextDueNugget(customer)
        this.overDueNugget(customer)
      }
    },

    averageInvoiceNugget(customer) {
      this.invoicesNuggetDef.body = `£${Number(
        customer.sales / customer.invoices
      ).toFixed(2)}<br>from ${customer.invoices}<br>Invoices`
    },

    salesTrendNugget(customer) {
      const getMonthSales = offset =>
        customer.salesByMonth[this.period.monthsInPeriod + offset - 1]

      const getMonthName = offset =>
        pc.monthKeyName(
          'mmm',
          this.period.monthKeys[this.period.monthsInPeriod + offset - 1]
        )

      if (this.period.monthsInPeriod >= 7) {
        const last3Months =
          getMonthSales(-1) + getMonthSales(-2) + getMonthSales(-3)
        const previous3Months =
          getMonthSales(-4) + getMonthSales(-5) + getMonthSales(-6)
        const difference = last3Months - previous3Months
        const percentage = previous3Months
          ? Number((difference / previous3Months) * 100).toFixed(2) + '%'
          : last3Months
          ? '100.00%'
          : 'no sales'
        const from = `${getMonthName(-3)} - ${getMonthName(-1)}`
        const over = `${getMonthName(-6)} - ${getMonthName(-4)}`
        this.trendNuggetDef.body = `<span class="${
          difference < 0 || percentage === 'no sales'
            ? 'red--text'
            : 'white--text'
        }">${percentage}</span><br>${from}<br>${over}`
      } else if (this.period.monthsInPeriod >= 3) {
        const lastMonth = getMonthSales(-1)
        const previousMonth = getMonthSales(-2)
        const difference = lastMonth - previousMonth
        const percentage = previousMonth
          ? Number((difference / previousMonth) * 100).toFixed(2) + '%'
          : lastMonth
          ? '100.00%'
          : 'no sales'
        const from = getMonthName(-1)
        const over = getMonthName(-2)
        this.trendNuggetDef.body = `<span class="${
          difference < 0 || percentage === 'no sales'
            ? 'red--text'
            : 'white--text'
        }">${percentage}</span><br>${from}<br>${over}`
      }
    },

    nextDueNugget(customer) {
      if (customer.nextDueDaysToPay) {
        this.nextDueNuggetDef.body = `£${Number(customer.nextDueAmount).toFixed(
          2
        )}<br>${customer.nextDueInvoiceNumber}<br>${pc.dateToDMY(
          customer.nextDueDate
        )}`
      } else {
        this.nextDueNuggetDef.body = `<br>None`
      }
    },

    overDueNugget(customer) {
      this.overdueNuggetDef.class = 'white--text'
      this.overdueNuggetDef.titleClass = 'white--text'
      if (customer.daysOver) {
        this.overdueNuggetDef.body = `£${Number(customer.overdue).toFixed(
          2
        )}<br>By ${customer.daysOver} days<br>${pc.dateToDMY(customer.dueDate)}`
        switch (customer.paymentProfile) {
          case 1:
            this.overdueNuggetDef.class = 'grey--text text--darken-3'
            this.overdueNuggetDef.titleClass = 'grey--text text--darken-3'
            this.overdueNuggetDef.color = 'yellow lighten-2'
            this.overdueNuggetDef.titleColor = 'yellow'
            this.overdueNuggetDef.bodyClass = 'grey--text text--darken-1'
            break
          case 2:
            this.overdueNuggetDef.color = 'orange lighten-2'
            this.overdueNuggetDef.titleColor = 'orange'
            break
          case 3:
            this.overdueNuggetDef.class = 'grey--text text--darken-3'
            this.overdueNuggetDef.titleClass = 'grey--text text--darken-3'
            this.overdueNuggetDef.color = 'pink lighten-4'
            this.overdueNuggetDef.titleColor = 'pink lighten-2'
            break
          case 4:
            this.overdueNuggetDef.color = 'red lighten-2'
            this.overdueNuggetDef.titleColor = 'red'
            break
        }
      } else {
        this.overdueNuggetDef.body = `<br>None`
        this.overdueNuggetDef.color = 'green lighten-2'
        this.overdueNuggetDef.titleColor = 'green'
      }
    },
  },

  data: function() {
    return {
      period: db.cached('period'),

      invoicesNuggetDef: pcNuggetDef('invoicesNugget', 'Av. Invoice', {
        color: 'cyan lighten-2',
        titleColor: 'cyan',
      }),

      trendNuggetDef: pcNuggetDef('trendNugget', 'Sales trend', {
        color: 'blue lighten-2',
        titleColor: 'blue',
      }),

      nextDueNuggetDef: pcNuggetDef('trendNugget', 'Next due', {
        color: 'green lighten-2',
        titleColor: 'green',
      }),
      overdueNuggetDef: pcNuggetDef('overdueNugget', 'Overdue'),
    }
  },
}
</script>

<style scoped></style>
