<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChartType"
        value="pie"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Pie chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Stacked bar chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="line"
        icon="mdi-chart-line"
        small
        top
        tooltip="Line chart"
      />
      <pc-action-icon
        @click="toggleHidden"
        value=""
        icon="mdi-eye-off"
        small
        top
        tooltip="Show/Hide datasets"
      />
    </template>
    <template v-slot:default>
      <pc-chart
        :chartDef="pieChartDef"
        @instance="pieChartDef.instance"
        @tooltip="pieTooltip"
      />
      <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
      <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'contactGroupChart',
  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
  },

  created() {
    this.main()
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },

    displayedChartDef() {
      if (this.pieChartDef.show) return this.pieChartDef
      if (this.barChartDef.show) return this.barChartDef
      return this.lineChartDef
    },
  },

  methods: {
    async main() {
      this.displayChartType('pie')
      this.updateCharts()
    },

    updateCharts() {
      this.updateBarAndLineChart()
      this.updatePieChart()
    },

    displayChartType(type) {
      this.pieChartDef.show = type === 'pie'
      this.barChartDef.show = type === 'bar'
      this.lineChartDef.show = type === 'line'
    },

    updatePieChart() {
      this.pieChartData = db
        .cached(
          'contactGroups',
          db
            .cachedQuery()
            .addFilter('sales', 0, 'ne')
            .addSort('sales', true)
        )
        .reduce(
          (chartData, contactGroup) => {
            if (contactGroup.sales) {
              chartData.ids.push(contactGroup.id)
              chartData.labels.push(contactGroup.name)
              chartData.values.push(contactGroup.sales)
              chartData.total += contactGroup.sales
            }
            return chartData
          },
          { ids: [], labels: [], values: [], total: 0 }
        )
      this.pieChartDef
        .setPc([['ids', this.pieChartData.ids]])
        .addDataset(this.pieChartData.values, 'Turnover', this.chartColors)
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .setLabels(this.pieChartData.labels)
        .render()
    },

    updateBarAndLineChart() {
      db.cached(
        'contactGroups',
        db
          .cachedQuery()
          .addFilter('sales', 0, 'ne')
          .addSort('sales', true)
      ).forEach((contactGroup, index) => {
        if (contactGroup.sales) {
          this.barChartDef.addDataset(
            pc.arrayFixed2(contactGroup.salesByMonth),
            contactGroup.name,
            this.chartColors[index]
          )
          this.lineChartDef.addDataset(
            pc.arrayFixed2(contactGroup.salesByMonth),
            contactGroup.name,
            this.chartColors[index]
          )
        }
      })
      this.barChartDef.render()
      this.lineChartDef.render()
    },

    toggleHidden() {
      this.displayedChartDef.toggleHidden()
    },

    pieTooltip(payload) {
      const groupId = this.pieChartDef.pc.ids[payload.dataIndex]
      const group = db.cached(
        'contactGroups',
        db.cachedQuery().addFind('id', groupId)
      )
      const title = group.name
      const color = this.pieChartDef.data.datasets[0].backgroundColor[
        payload.dataIndex
      ]
      const labels = pc.periodMonthNames('mmm', this.period.monthKeys)
      const percent = pc.toFixed2(pc.percentage(group.sales, this.period.sales))
      const subTitle = `£${pc.toFixed2(
        group.sales
      )} (${percent}%) of total sales`

      this.pieSparkChartDef
        .clearDatasets()
        .setTitle(title, color)
        .setSubTitle(subTitle)
        .setLabels(labels)
        .addDataset(pc.arrayFixed2([...group.salesByMonth]), '', color)

      this.pieChartDef.setSparkChart(this.pieSparkChartDef)
      this.pieSparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartData: undefined,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'contactGroupChart',
        'Sales by contact group'
      ),

      pieChartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setOptions([['cutout', '50%']])
        .setTitle('', false),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      pieSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
