import pc from '@pc'
import db from '@pcModules/db'

export function lastInvoicedClass(lastInvoicedDate, periodEndDate) {
  return `chip ${lastInvoicedColor(lastInvoicedDate, periodEndDate)}`
}

export function lastInvoicedColor(lastInvoicedDate, periodEndDate = '') {
  if (!periodEndDate) {
    periodEndDate = db.cached('period').periodEndDate
  }
  let color = LAST_INVOICED_COLORS[4]
  if (lastInvoicedDate && lastInvoicedDate !== '0000-00-00') {
    const daysSince = pc.dateDiffDays(
      new Date(lastInvoicedDate),
      new Date(periodEndDate)
    )
    if (daysSince <= LAST_INVOICED_SINCE_DAYS[0])
      color = LAST_INVOICED_COLORS[0]
    if (
      daysSince > LAST_INVOICED_SINCE_DAYS[0] &&
      daysSince <= LAST_INVOICED_SINCE_DAYS[1]
    )
      color = LAST_INVOICED_COLORS[1]
    if (
      daysSince > LAST_INVOICED_SINCE_DAYS[1] &&
      daysSince <= LAST_INVOICED_SINCE_DAYS[2]
    )
      color = LAST_INVOICED_COLORS[2]
    if (daysSince > LAST_INVOICED_SINCE_DAYS[2]) color = LAST_INVOICED_COLORS[3]
  }
  return color
}

export const LAST_INVOICED_SINCE_DAYS = [30, 60, 90]

export const LAST_INVOICED_COLORS = [
  'transparent',
  'yellow',
  'amber lighten-1',
  'red lighten-4',
  'red lighten-1',
]

export const PAYMENT_PROFILE_LEGEND = [
  'Due by end of month',
  '1-7 days overdue',
  '8-14 days overdue',
  '15-21 days overdue',
  '21+ days overdue',
]

export const PAYMENT_PROFILE_CHART_COLORS = [
  'green',
  'yellow',
  'orange',
  'pink',
  'red',
]
export const PAYMENT_PROFILE_LIST_COLORS = [
  'white',
  'yellow',
  'orange',
  'pink lighten-4',
  'red',
]

export const PAYMENT_PROFILE_UI_TITLE_COLOR_CLASS = [
  'green',
  'yellow',
  'orange',
  'pink lighten-2',
  'red',
]
export const PAYMENT_PROFILE_UI_BODY_COLOR_CLASS = [
  'green lighten-2',
  'yellow lighten-2',
  'orange lighten-2',
  'pink lighten-4',
  'red lighten-2',
]
export const PAYMENT_PROFILE_UI_TEXT_COLOR_CLASS = [
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
]

export const OUTSTANDING_COLOR_CLASS = [
  'red lighten-2',
  'pink lighten-4',
  'orange lighten-2',
  'yellow lighten-2',
  'green lighten-5',
  'green lighten-4',
  'green lighten-3',
  'green lighten-2',
]

export const OUTSTANDING_TEXT_COLOR_CLASS = [
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
  'grey--text text--darken-3',
]

export const whitespaceLocalStorageSchema = {
  companyDashboard: {
    openingWindow: 'summary',
  },
  customerDashboard: {
    openingWindow: 'turnover',
  },
  productDashboard: {
    openingWindow: 'summary',
  },
}

export function daysOverClass(paymentProfile) {
  const color =
    paymentProfile >= 0 && paymentProfile < 5
      ? PAYMENT_PROFILE_LIST_COLORS[paymentProfile]
      : ''
  return `chip ${color}`
}
