<template>
  <td :class="cellTypeClass" v-on:click.stop="fieldClicked">
    <span :class="itemClass" v-on="events" v-html="formatField"></span>
  </td>
</template>

<script>
import pc from '@pc'

export default {
  name: 'pcField',
  created() {
    const eventWrapper = function(eventName, callbackFn, entity) {
      return function(event) {
        event.stopPropagation()
        callbackFn({
          entity,
          event,
          eventName,
          field: this.field,
          data: this.props.item,
          props: this.props,
        })
      }.bind(this)
    }.bind(this)

    Object.entries(this.field.events).forEach(event => {
      this.events[event[0]] = eventWrapper(
        event[0],
        this.handler,
        event[1].entity
      )
    })
  },
  components: {},
  props: {
    field: Object,
    listDef: Object,
    props: Object,
    compressed: Boolean,
    fontSize: String,
    handler: Function,
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
    cellTypeClass() {
      let classes = this.field.type ? this.field.type.toLowerCase() : ''
      if (this.field.click || this.field.dropdownDef)
        classes = `pointer ${classes}`
      if (this.compressed) {
        classes = `${classes} ${this.props.index === 0 ? 'pl-4' : 'pl-2'}`
        classes = `${classes} ${
          this.props.index === this.listDef.table.fields.length - 1
            ? 'pr-4'
            : 'pr-2'
        }`
      }
      return `${classes} ${this.stringOrCall(this.field.cellClass, {
        field: this.field,
        data: this.props.item,
        props: this.props,
      })}`
    },
    itemClass() {
      let itemClass = this.stringOrCall(this.field.itemClass, {
        field: this.field,
        data: this.props.item,
        props: this.props,
      })
      if (this.field.blankZero && !this.getFieldValue()) return ''
      if (this.field.blankIfNot) {
        if (typeof this.field.blankIfNot === 'function') {
          if (
            !this.field.blankIfNot({
              field: this.field,
              data: this.props.item,
              props: this.props,
            })
          )
            return ''
        } else {
          if (!pc.getProperty(this.field.blankIfNot, this.props.item)) return ''
        }
      }
      if (this.field.type === 'number' || this.field.type === 'currency') {
        if (this.field.negativeClass) {
          if (this.getFieldValue() < 0) {
            itemClass = `${itemClass} ${this.field.negativeClass}`
          }
        }
      }
      return `${itemClass} ${
        this.field.fontSize ? this.field.fontSize : this.fontSize
      }`
    },
    formatField() {
      let fieldValue = this.getFieldValue()
      let value = fieldValue
      if (typeof this.field.formatFn === 'function') {
        return this.field.formatFn({
          field: this.field,
          data: this.props.item,
          props: this.props,
        })
      }
      if (!this.field.type) return fieldValue
      switch (this.field.type) {
        case 'currency':
          if (this.field.decimalPlaces) {
            fieldValue = Number(fieldValue).toFixed(this.field.decimalPlaces)
          } else {
            fieldValue = Number(fieldValue).toFixed(2)
          }
          fieldValue = `£${fieldValue}`
          if (this.field.blankZero && !value) fieldValue = ''
          break
        case 'number':
          fieldValue = Number(fieldValue)
          if (this.field.decimalPlaces !== undefined) {
            fieldValue = fieldValue.toFixed(this.field.decimalPlaces)
          }
          fieldValue = `${
            this.field.currencySymbol ? this.field.currencySymbol : ''
          }${fieldValue}`
          if (this.field.blankZero && !value) fieldValue = ''
          break
        case 'ranking':
          fieldValue = pc.toFixed0(Number(fieldValue))
          fieldValue = `${fieldValue === '99999' ? 'none' : fieldValue}`
          break
        case 'ISODate':
          if (fieldValue) {
            fieldValue = `${fieldValue.substr(8, 2)}/${fieldValue.substr(
              5,
              2
            )}/${fieldValue.substr(0, 4)}`
          }
          break
        case 'quartile':
          if (fieldValue) {
            if (fieldValue >= 'Q1' && fieldValue <= 'Q4') {
              let star = '<i class="mdi mdi-star"></i>'
              star =
                star +
                `<i class="mdi mdi-star${
                  fieldValue < 'Q2' ? '-outline' : ''
                }"></i>`
              star =
                star +
                `<i class="mdi mdi-star${
                  fieldValue < 'Q3' ? '-outline' : ''
                }"></i>`
              star =
                star +
                `<i class="mdi mdi-star${
                  fieldValue < 'Q4' ? '-outline' : ''
                }"></i>`
              fieldValue = star
            }
          } else {
            fieldValue = '<i class="mdi mdi-star-outline"></i>'.repeat(4)
          }
          break
      }
      if (this.field.blankIfNot) {
        if (typeof this.field.blankIfNot === 'function') {
          if (
            !this.field.blankIfNot({
              field: this.field,
              data: this.props.item,
              props: this.props,
            })
          )
            fieldValue = ''
        } else {
          if (!Number(pc.getProperty(this.field.blankIfNot, this.props.item)))
            fieldValue = ''
        }
      }
      return fieldValue
    },
  },
  methods: {
    getFieldValue() {
      if (typeof this.field.dataPath === 'string')
        return pc.getProperty(this.field.dataPath, this.props.item)
      return this.field.dataPath(this.field, this.props.item)
    },

    stringOrCall(item, payload = {}) {
      if (typeof item === 'string') return item
      if (typeof item === 'function') return item(payload)
      if (typeof item === 'object') {
        if (!Object.hasOwnProperty.call(item, 'xs')) return ''
        const bpItem = pc.getBreakpointValue(item, this.breakpoint)
        if (typeof bpItem === 'string') return bpItem
        if (typeof bpItem === 'function') return bpItem({ payload })
        return ''
      }
      return ''
    },

    eventOrCall(item, payload = {}) {
      if (typeof item === 'function') return item(payload)
      if (typeof item === 'string') {
        const event = `${this.listDef.cardDef.id}${item}`
        pc.$eventBus.$emit(event, payload)
      }
    },

    payload(event, entity) {
      return {
        entity,
        event,
        eventName: event.type,
        field: this.field,
        data: this.props.item,
        props: this.props,
      }
    },

    rowClicked(event) {
      const action = this.listDef.table.rowClick
      if (action) this.eventOrCall(action, this.payload(event))
    },

    fieldClicked(event) {
      if (this.field.dropdownDef) {
        this.handler(this.payload(event, 'dropdown'))
      } else {
        const action = this.field.click
        if (action) {
          this.eventOrCall(action, this.payload(event))
        } else {
          this.rowClicked(event)
        }
      }
    },
  },
  data() {
    return {
      events: {},
    }
  },
}
</script>

<style scoped>
.text {
  text-align: left;
}
.quartile {
  text-align: center;
}
.number {
  text-align: right;
}
.ranking {
  text-align: right;
}
.currency {
  text-align: right;
}
.isodate {
  text-align: right;
}
.small {
  font-size: 10px;
}
.medium {
  font-size: 12px;
}
.large {
  font-size: 14px;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.chip {
  display: inline-block;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 25px;
  background-color: green;
}
.yellow {
  background-color: gold;
}
.orange {
  background-color: orange;
}
.pink {
  background-color: pink;
}
.red {
  background-color: red;
}
</style>
