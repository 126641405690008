import { gql } from 'graphql-tag'

const customerTable = gql`
  query($dbQuery: DbQuery) {
    customers(dbQuery: $dbQuery) {
      id
      name
      contactGroupId
      thisMonth
      month1
      month2
      month3
      monthsOlder
      sales
      qtrAverage
      onQtr
      lastInvoiced
      overdue
      daysOver
    }
  }
`

const queries = {
  customerTable,
}

export { queries as whitespace }
