import { gql } from 'graphql-tag'

const categoryCustomers = gql`
  query($dbQuery: DbQuery) {
    categoryCustomers(dbQuery: $dbQuery) {
      categoryId
      categoryLevel
      sales
      lastInvoiced
      salesByMonth
    }
  }
`
const productCustomers = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      productCode
      quantity
      sales
      lastInvoiced
      quantityByMonth
      salesByMonth
    }
  }
`
const products = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      code
      name
      ranking
      category {
        level1
        level2
        level3
      }
      quantity
      quantityByMonth
      sales
      salesByMonth
      lastInvoiced
    }
  }
`

const productInvoiceLines = gql`
  query($dbQuery: DbQuery) {
    invoiceLines(dbQuery: $dbQuery) {
      invoiceNumber
      date
      quantity
      price
      goods
    }
  }
`

const records = gql`
  query($dbQuery: DbQuery) {
    multiQuery(dbQuery: $dbQuery) {
      products(dbQuery: $dbQuery) {
        code
        name
        ranking
        category {
          level1
          level2
          level3
        }
        quantity
        quantityByMonth
        sales
        salesByMonth
        lastInvoiced
      }
      categoryCustomers(dbQuery: $dbQuery) {
        categoryId
        categoryLevel
        sales
        lastInvoiced
        salesByMonth
      }
      productCustomers(dbQuery: $dbQuery) {
        productCode
        quantity
        sales
        lastInvoiced
        quantityByMonth
        salesByMonth
      }
    }
  }
`

const queries = {
  records,
  categoryCustomers,
  productCustomers,
  products,
  productInvoiceLines,
}

export { queries as categoryDrill }
