import pc from '@pc'
import db from '@db'

export async function getCategoryChildren(
  categoryId,
  monthKey = 'total',
  customerId
) {
  // Initialise the results
  const initialise = result => {
    const period = db.cached('period')
    result.monthsInPeriod = period.monthsInPeriod
    result.monthIndex = period.monthKeys.indexOf(result.monthKey)
    result.total = result.monthIndex === -1
    result.category = db.cached(
      'categories',
      db
        .cachedQuery()
        .addFind('id', categoryId)
        .addClone()
    )
    result.isCategories = result.category.hasChildren
    result.isProducts = !result.isCategories
    return result
  }

  // Get the categories children categories or products
  const getChildrenAndCustomerRecords = async result => {
    const dbArgs = db.args()
    let categories = []

    if (result.isCategories) {
      // Get the categories from the cache
      categories = db.cached(
        'categories',
        db
          .cachedQuery()
          .addFilter('parentId', categoryId)
          .addSort('ranking')
          .addClone()
      )

      if (result.customerId) {
        dbArgs.addQueryArgs(
          db
            .queryArgs('categoryCustomers')
            .addFilter('customerId', result.customerId)
            .addFilter('categoryLevel', result.category.level + 1)
        )
      } else {
        dbArgs.addQueryArgs(db.queryArgs('categoryCustomers').addNone())
      }
    } else {
      dbArgs.addQueryArgs(db.queryArgs('categoryCustomers').addNone())
    }

    if (result.isProducts) {
      dbArgs.addQueryArgs(
        db
          .queryArgs('products')
          .addFilter(`category.level${result.category.level}`, categoryId)
          .addSort('ranking')
      )

      if (result.customerId) {
        dbArgs.addQueryArgs(
          db.queryArgs('productCustomers').addFilter('customerId', customerId)
        )
      } else {
        dbArgs.addQueryArgs(db.queryArgs('productCustomers').addNone())
      }
    } else {
      dbArgs.addQueryArgs(db.queryArgs('products').addNone())
      dbArgs.addQueryArgs(db.queryArgs('productCustomers').addNone())
    }

    const data = await db.request(db.query('categoryDrill.records'), dbArgs)

    if (result.customerId) {
      result.customerRecords = result.isCategories
        ? data.categoryCustomers
        : data.productCustomers
    }

    result.data = result.isCategories ? categories : data.products
    return result
  }

  // If the drill is against a customer the get the values from
  // either the categoryCustomer or productCustomer records
  const updateRecordsWithCustomerSales = async result => {
    const clearSalesValues = () => {
      const salesByMonth = new Array(result.monthsInPeriod).fill(0)
      result.data = result.data.map(record => {
        if (result.isProducts) record.quantity = 0
        record.sales = 0
        record.salesByMonth = salesByMonth
        record.quantityByMonth = salesByMonth
        record.lastInvoiced = '0000-00-00'
        record.bought = false
        record.listValue = 0
        record.listQuantity = 0
        return record
      })
    }

    const updateResultDataWithCustomerSales = customerRecords => {
      customerRecords.forEach(record => {
        const resultRecord = result.data.find(resultRecord => {
          if (result.isCategories) {
            return resultRecord.id === record.categoryId
          } else {
            return resultRecord.code === record.productCode
          }
        })
        // Customer made sales of this category or product so
        // move the sales into the record
        if (resultRecord) {
          if (result.isProducts) resultRecord.quantity = record.quantity
          resultRecord.sales = record.sales
          resultRecord.salesByMonth = record.salesByMonth
          resultRecord.lastInvoiced = record.lastInvoiced || '0000-00-00'
          if (result.total) {
            resultRecord.listValue = record.sales
            resultRecord.listQuantity = record.quantity
          } else {
            resultRecord.listValue = record.salesByMonth[result.monthIndex]
            resultRecord.listQuantity = result.isProducts
              ? record.quantityByMonth[result.monthIndex]
              : 0
          }
          resultRecord.bought = resultRecord.listValue !== 0
        }
      })
    }

    if (result.customerId) {
      clearSalesValues()
      updateResultDataWithCustomerSales(result.customerRecords)
    }
    return result
  }

  // If no customer update the list fields
  const updateRecordsWhenNoCustomer = result => {
    const updateRecordWithListFields = record => {
      if (result.total) {
        record.listValue = record.sales
        record.listQuantity = record.quantity
      } else {
        record.listValue = record.salesByMonth[result.monthIndex]
        record.listQuantity = result.isProducts
          ? record.quantityByMonth[result.monthIndex]
          : 0
      }
      record.bought = record.listValue !== 0
      return record
    }

    if (!result.customerId) {
      result.data = result.data.map(updateRecordWithListFields) // no customer
    }
    return result
  }

  return await pc.pipeAsync(
    initialise,
    getChildrenAndCustomerRecords,
    updateRecordsWithCustomerSales,
    updateRecordsWhenNoCustomer
  )({
    monthsInPeriod: 0,
    isCategories: false,
    isProducts: false,
    categoryId,
    category: {},
    customerId: customerId,
    monthKey,
    monthIndex: 0,
    total: false,
    customerRecords: [],
    data: [],
  })
}
