<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChart = true"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Sales bar chart"
      />
      <pc-action-icon
        @click="displayChart = false"
        value="line"
        icon="mdi-view-list"
        small
        top
        tooltip="Sales figures"
      />
    </template>
    <template v-slot:default>
      <pc-chart v-if="displayChart" :chartDef="salesBarChartDef" />
      <pc-simple-table v-else :simpleTableDef="simpleTableDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'salesChart',
  components: {
    pcChart,
    pcDisplayBox,
    pcSimpleTable,
    pcActionIcon,
  },

  props: {
    customer: Object,
  },

  created() {
    this.main()
  },

  computed: {
    title() {
      return this.customer.id
        ? `Sales total £${pc.toFixed0(this.customer.sales)}`
        : ''
    },
  },

  watch: {
    customer() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.customer.id) {
        this.displayBoxDef.title = this.title
        this.updateSalesBarChart()
        this.updateSalesList()
      }
    },

    async updateSalesBarChart() {
      this.salesBarChartDef
        .clearDatasets()
        .addDataset(
          pc.arrayFixed2(this.customer.salesByMonth),
          'Sales',
          getColor('blue', 3)
        )
        .render()
    },

    async updateSalesList() {
      this.simpleTableDef.data = this.period.monthKeys
        .map((monthKey, index) => ({
          month: pc.monthKeyName('mmm yy', monthKey),
          sales: this.customer.salesByMonth[index],
          numberInvoices: this.customer.invoicesByMonth[index],
          averageInvoice: pc.safeDivide(
            this.customer.salesByMonth[index],
            this.customer.invoicesByMonth[index]
          ),
          numberProducts: this.customer.productsByMonth[index],
          productCoverage: this.customer.productCoveragePercentageByMonth[
            index
          ],
        }))
        .reverse()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      displayChart: true,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Sales'),

      salesBarChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('month', 'text', 'Month'),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            blankIfZero: true,
          }),
          pcSimpleTableFieldDef(
            'numberInvoices',
            'int',
            'Number<br />invoices',
            { blankIfZero: true }
          ),
          pcSimpleTableFieldDef(
            'averageInvoice',
            'currency',
            'Invoice<br />average',
            { blankIfZero: true }
          ),
          pcSimpleTableFieldDef(
            'numberProducts',
            'int',
            'Number<br />Products',
            { blankIfZero: true }
          ),
          pcSimpleTableFieldDef(
            'productCoverage',
            'number',
            'Product<br />coverage %',
            { blankIfZero: true }
          ),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
