import * as R from 'ramda'

const _quantile = R.curry((q, arr) => {
  const sorted = [...arr].sort((a, b) => a - b)
  const pos = (sorted.length - 1) * q
  const base = Math.floor(pos)
  const rest = pos - base
  return sorted[base + 1] !== undefined
    ? sorted[base] + rest * (sorted[base + 1] - sorted[base])
    : sorted[base]
})

const _q1 = _quantile(0.25)
const _q2 = _quantile(0.5)
const _q3 = _quantile(0.75)

export const quartile = (dataArray, value) =>
  value < _q1(dataArray)
    ? 'Q1'
    : value >= _q1(dataArray) && value < _q2(dataArray)
    ? 'Q2'
    : value >= _q2(dataArray) && value < _q3(dataArray)
    ? 'Q3'
    : 'Q4'

export const ordinal = number => {
  if (number === undefined || number === Infinity) return ''
  const english_ordinal_rules = new Intl.PluralRules('en', {
    type: 'ordinal',
  })
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  }
  const suffix = suffixes[english_ordinal_rules.select(number)]
  return number + suffix
}
export const plural = (word, number) => {
  return number > 1 ? word + 's' : number ? word : 'no'
}

export const safeDivide = (value, divider) => (divider ? value / divider : 0)

export const toFixed = R.curry((decimalPlaces, number) =>
  typeof number === 'number'
    ? number.toFixed(decimalPlaces)
    : Number.parseFloat(0).toFixed(decimalPlaces)
)

export const percentage = (value, total) => safeDivide(value, total) * 100

export const toFixed0 = toFixed(0)
export const toFixed2 = toFixed(2)

export const oneise = value => (value ? 1 : 0)

export default {
  quartile,
  ordinal,
  plural,
  safeDivide,
  percentage,
  toFixed,
  toFixed0,
  toFixed2,
  oneise,
}
