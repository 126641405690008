import db from '@db'
import { signup } from '../signup.gql.js'
import { signin } from '../signin.gql.js'
import { signout } from '../signout.gql.js'

/* Mutations ------------------------------------------------------------- */
function SIGNIN_USER(state, payload) {
  state.user = { id: payload.uid }
}

function SIGNOUT_USER(state) {
  state.user = null
  state.isUser = false
}

function IS_USER(state, payload) {
  state.isUser = payload
}
/* End Mutations ========================================================= */

/* Actions --------------------------------------------------------------- */
function setIsUser({ commit }, payload) {
  commit('IS_USER', payload)
}

async function signupUser({ commit }, payload) {
  const { email, password } = payload.data
  const user = await db.request(signup, { record: { email, password } })
  if (user.authenticated) await commit('SIGNIN_USER', user)
  return user
}

async function signinUser({ commit }, payload) {
  let user
  const { email, password } = payload.data
  try {
    user = await db.request(signin, { record: { email, password } })
  } catch (err) {
    console.log('signinUser errror:', err)
  }
  console.log('User is user.signinUser', user)
  if (user.authenticated) await commit('SIGNIN_USER', user)
  return user
}

async function signoutUser({ commit }, payload) {
  const { email, password } = payload.data
  const user = await db.request(signout, { record: { email, password } })
  await commit('SIGNOUT_USER', user)
  return user
}
/* End Actions============================================================ */

/* Getters --------------------------------------------------------------- */
function user(state) {
  return state.user
}

function isUser(state) {
  return state.isUser
}
/* End Getters =========================================================== */

/* Interface ------------------------------------------------------------- */
export default {
  state: {
    user: null,
    isUser: false,
  },
  mutations: {
    SIGNIN_USER,
    SIGNOUT_USER,
    IS_USER,
  },
  actions: {
    signupUser,
    signinUser,
    signoutUser,
    setIsUser,
  },
  getters: {
    user, // null is not logged in else user object
    isUser, // False if no user logged in else True
  },
}
/* End Interface ========================================================= */

/* Documentation --------------------------------------------------------- */
