<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <div class="ml-3 pb-1 subtitle-2">Payment terms</div>
    <div class="pl-3 pr-3 pb-2" :class="displayBoxDef.font">{{ terms }}</div>
    <v-divider />
    <div
      class="mt-1 ml-3 subtitle-2"
      :class="`${outstandingBalance ? 'mb-1' : ''}`"
    >
      {{
        outstandingBalance
          ? `Total outstanding £${outstandingBalance.toFixed(2)}`
          : 'Outstanding'
      }}
    </div>
    <v-simple-table
      dense
      class="grey lighten-5"
      :class="`${outstandingBalance ? 'mb-2' : ''}`"
    >
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(profile, index) in outstanding"
            :key="index"
            :class="`${profile.backgroundColor} ${profile.textColor}`"
          >
            <td :class="`${profile.outstanding ? 'font-weight-bold' : ''}`">
              {{ profile.legend }}
            </td>
            <td class="pl-4 text-right font-weight-bold">
              {{
                profile.outstanding ? `£${profile.outstanding.toFixed(2)}` : ''
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div v-if="outstandingBalance !== 0">
      <v-divider />
      <div class="mt-1 ml-3 subtitle-2">Next invoice due</div>
      <v-simple-table dense class="grey lighten-5">
        <template v-slot:default>
          <tbody>
            <tr v-for="(nextDue, index) in nextInvoiceDue" :key="index">
              <td>
                {{ nextDue.legend }}
              </td>
              <td class="pl-4 text-right">{{ nextDue.value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-divider />
    <paid-invoices-profile :profiles="paidInvoicesProfile" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import {
  PAYMENT_PROFILE_LEGEND,
  PAYMENT_PROFILE_UI_TITLE_COLOR_CLASS,
  PAYMENT_PROFILE_UI_TEXT_COLOR_CLASS,
} from '@appModules/ws.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import paidInvoicesProfile from '@appViews/sharedComponents/paidInvoicesProfile.vue'

export default {
  name: 'outstanding',

  components: {
    pcDisplayBox,
    paidInvoicesProfile,
  },

  props: {
    customer: Object,
  },

  created() {
    this.main()
  },

  watch: {
    customer() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.customer.id) {
        this.outstandingBalance = this.customer.outstanding
        this.terms = this.readableTerms(
          this.customer.paymentTermsType,
          this.customer.paymentTermsDays
        )
        this.outstanding = this.getOutstanding()
        this.nextInvoiceDue = this.getNextDueInvoice()
        this.paidInvoicesProfile = this.customer.paidInvoicesProfile
      }
    },

    readableTerms(type, days) {
      let readable = ''
      switch (type) {
        case 'DAYSAFTERBILLDATE':
          readable = `${days} days after the invoice date`
          break
        case 'DAYSAFTERBILLMONTH':
          readable = `${days} days after the end of the invoice month`
          break
        case 'OFCURRENTMONTH':
          readable = `${pc.ordinal(days)} of the current month`
          break
        case 'OFFOLLOWINGMONTH':
          readable = `${pc.ordinal(days)} of the following month`
          break
        default:
          readable = 'No terms specified'
      }
      return readable
    },

    getOutstanding() {
      if (!this.outstandingBalance) {
        return [
          {
            legend: 'Nothing outstanding',
            outstanding: 0,
            backgroundColor: '',
            textColor: '',
          },
        ]
      }
      return PAYMENT_PROFILE_LEGEND.reduce((data, legend, index) => {
        const outstanding = this.customer[`dueProfile${index}`]
        if (outstanding) {
          data.push({
            legend,
            outstanding,
            backgroundColor: PAYMENT_PROFILE_UI_TITLE_COLOR_CLASS[index],
            textColor: PAYMENT_PROFILE_UI_TEXT_COLOR_CLASS[index],
          })
        }
        return data
      }, []).reverse()
    },

    getNextDueInvoice() {
      if (this.customer.outstanding - this.customer.overdue > 0) {
        return [
          {
            legend: 'Invoice number',
            value: this.customer.nextDueInvoiceNumber,
          },
          {
            legend: 'Invoice due date',
            value: pc.dateToDMY(this.customer.nextDueDate),
          },
          {
            legend: 'Days until invoice due',
            value: this.customer.nextDueDaysToPay,
          },
          {
            legend: 'Invoice amount',
            value: '£' + this.customer.nextDueAmount.toFixed(2),
          },
        ]
      }
      return [{ legend: 'None due', value: '' }]
    },
  },

  data() {
    return {
      period: db.cached('period'),
      displayBoxDef: pcDisplayBoxDef('payment', 'Payments & outstanding'),

      outstandingBalance: 0,
      terms: '',
      outstanding: [],
      nextInvoiceDue: [],
      paidInvoicesProfile: [],
    }
  },
}
</script>

<style scoped>
.profile {
  width: 25px;
  height: 25px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
}
.month-name {
  height: 9px;
  width: 25px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  transform: rotate(-60deg);
}
</style>
