import { gql } from 'graphql-tag'

const category = gql`
  query($dbQuery: DbQuery) {
    categories(dbQuery: $dbQuery) {
      id
      salesByCustomer(dbQuery: $dbQuery) {
        categoryId
        customerId
        customerName
        contactGroupId
        categoryName
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
      salesByProduct(dbQuery: $dbQuery) {
        code
        name
        RRP
        tradePrice
        categories {
          id
          name
        }
        ranking
        rankingByDays
        rankingByMonth
        lastInvoiced
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
      }
      salesByContactGroup(dbQuery: $dbQuery) {
        contactGroupId
        contactGroupName
        sales
        salesByDays
        salesByMonth
        activeCustomers
        activeCustomersByDays
        activeCustomersByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
    }
  }
`

const queries = {
  category,
}

export { queries as categoryDashboard }
