var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pc-card',{attrs:{"card-def":_vm.cardDef},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('pc-toolbar',{attrs:{"toolbar-def":_vm.toolbarDef}})]},proxy:true},{key:"text",fn:function(){return [_c('v-row',{staticClass:"pl-3 pt-3 fill-height",attrs:{"align":"stretch"}},[_c('v-col',{staticClass:"pc-col d-flex pt-0 pb-0",attrs:{"cols":12}},[_c('v-treeview',{staticClass:"tree",attrs:{"activatable":"","dense":"","item-key":"id","open":_vm.openItems,"items":_vm.items,"return-object":"","active":_vm.active},on:{"update:open":function($event){_vm.openItems=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': _vm.isValidDropTarget(item) },on:{"dragenter":function($event){return _vm.dragenter(item, $event)},"dragover":function($event){return _vm.dragover(item, $event)},"dragleave":function($event){return _vm.dragleave(item, $event)},"drop":function($event){return _vm.drop(item, $event)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.activeId)?_c('div',[_c('v-btn',{staticClass:"mr-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.action({ action: 'edit', item: item })}}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-pencil-outline")])],1),(item.level < 3 && !item.hasProducts)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0",class:{ 'mr-0': item.level > 0 },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.action({ action: 'add', item: item })}}},on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add sub category in "+_vm._s(item.name))])]):_vm._e(),(item.level && !item.children.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.action({ action: 'delete', item: item })}}},on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete category "+_vm._s(item.name))])]):_vm._e()],1):_vm._e()]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }