<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-simple-table :simpleTableDef="simpleTableDef" @tooltip="tooltip">
        <template v-slot:sales>
          <pc-spark-chart :sparkChartDef="sparkChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import { getColors } from '@pcModules/pcColors.js'

export default {
  name: 'categoryComponents',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {
    categorySummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categorySummaryDef.category
    },

    products() {
      return this.categorySummaryDef.products
    },

    subCategories() {
      return this.categorySummaryDef.subCategories
    },
  },

  watch: {
    categorySummaryDef() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.isCategory) {
        this.category.hasChildren
          ? this.displaySubCategories()
          : this.displayProducts()
      }
    },

    displaySubCategories() {
      const formatSubCategory = subCategory => ({
        name: subCategory.name,
        sales: subCategory.sales,
        percentage: pc.percentage(subCategory.sales, this.category.sales),
        lastInvoiced: subCategory.lastInvoiced,
        salesByMonth: subCategory.salesByMonth,
      })

      this.simpleTableDef = this.subCategoriesSimpleTableDef
      this.simpleTableDef.data = this.subCategories.map(formatSubCategory)
    },

    displayProducts() {
      const formatProduct = product => ({
        code: product.code,
        name: product.name,
        quamtity: product.quantity,
        sales: product.sales,
        percentage: pc.percentage(product.sales, this.category.sales),
        lastInvoiced: product.lastInvoiced,
        quantityByMonth: product.quantityByMonth,
        salesByMonth: product.salesByMonth,
      })

      this.simpleTableDef = this.productsSimpleTableDef
      this.simpleTableDef.data = this.products.map(formatProduct)
    },

    tooltip(payload) {
      const { data } = payload
      const title = this.category.hasChildren
        ? data.name
        : `${data.code} ${data.name}`

      this.sparkChartDef
        .setTitle(title)
        .setSubTitle(
          `Sales £${pc.toFixed2(data.sales)} - ${pc.toFixed2(
            data.percentage
          )}%)`
        )
        .clearDatasets()
        .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales')
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'categoryComponents',
        'Category components'
      ),

      simpleTableDef: pcSimpleTableDef(''),

      subCategoriesSimpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        fields: [
          pcSimpleTableFieldDef('name', 'text', 'Sub category'),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last invoiced'
          ),
        ],
      }),

      productsSimpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        fields: [
          pcSimpleTableFieldDef('code', 'text', 'Code'),
          pcSimpleTableFieldDef('name', 'text', 'Product'),
          //pcSimpleTableFieldDef('quantity', 'number', 'Quantity', {
          //  tooltipSlot: 'sales',
          //}),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last invoiced'
          ),
        ],
      }),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
