const _pcBreadcrumbsDef = () => ({
  id: '',
  show: true,
  stacked: false,
  color: 'primary',
  xSmall: false,
  small: false,
  large: false,
  xLarge: false,
  onClick: undefined,
  items: [],
  // Methods
  size(size) {
    this.xSmall = false;
    this.small = false;
    this.large = false;
    this.xLarge = false;
    const index = ['xSmall', 'small', 'large', 'xLarge'].indexOf(size);
    if (index > -1) {
      this[size] = true;
    }
  },
  addItem(id, text, data) {
    const exists = this.items.find((item) => item.id === id);
    if (!exists) this.items.push({ id, text, data });
  },
  deleteItem(id) {
    this.items = this.items.filter((item) => item.id !== id);
  },
  deleteBelow(id) {
    var keep = true;
    this.items = this.items.filter((item) => {
      const keepThis = keep;
      if (item.id === id) keep = false;
      return keepThis;
    });
  },
  clear() {
    this.items = [];
  },
});

const pcBreadcrumbsDef = function(id, click, stacked = false, buttonSize = 'small', color = 'primary') {
  const newBreadcrumbs = _pcBreadcrumbsDef();
  if (!id) {
    console.log('Must specify an id for a pcBreadcrumbs');
  }

  newBreadcrumbs.id = id;
  newBreadcrumbs.onClick = click;
  newBreadcrumbs.stacked = stacked;
  newBreadcrumbs.size(buttonSize);
  newBreadcrumbs.color = color;
  return newBreadcrumbs;
};

export { pcBreadcrumbsDef };