const productCategoryMap = category => {
  const ids = [category.grandParentId, category.parentId, category.id].reduce(
    (ids, id) => {
      if (id && id !== 'category') ids.push(id)
      return ids
    },
    []
  )

  return {
    level1: ids[0],
    level2: ids.length > 1 ? ids[1] : '',
    level3: ids.length > 2 ? ids[2] : '',
  }
}

export const updateProductFields = category => product => {
  product.parentId = category.id
  product.category = productCategoryMap(category)
  product.isModified = true
  return product
}
