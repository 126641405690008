/**
 * @property {object} pcToolbarDef
 * An object containing properties to define the characteristics of an instance
 * of the pcToolbar component. The object is normally defined in the parent
 * components data and is constructed using the two helper functions
 * pcToolbarDef and pcToolbarActionDef.
 *
 * @property {String} pcToolbarDef.id
 * The id of the pcToolbar. The id is attached to the toolbar dom element
 *
 * @property {Boolean|Object} [pcToolbarDef.show=true]
 * Show or hide the toolbar. Either a boolean or a breakpoint object
 *
 * @property {String|Object} [pcToolbarDef.color="primary lighten-1"]
 * The color of the pcToolbar. Either a string or a breakpoint object
 *
 * @property {String|Object} [pcToolbarDef.title=""]
 * The title displayed on the toolbar. Either a string or a breakpoint object
 *
 * @property {String} [pcToolbarDef.class=""]
 * Class names to be applied to the pcToolbar
 *
 * @property {String} [pcToolbarDef.titleClass=""]
 * Class names to be applied to the pcToolbar title
 *
 * @property {Boolean} [pcToolbarDef.dense=false]
 * Reduces the height of the toolbar
 *
 * @property {boolean} [pcToolbarDef.flat=false]
 * Removes the toolbar box-shadow
 *
 * @property {Object} [pcToolbarDef.actions=[]]
 * Action icons can be added to the right end of the pcToolbar.
 * The actions array contains action objects which define each action.
 * Actions can be added to the pcToolbarDef using the pcToolbarActionDef
 * helper fuction
 *
 * @property {String} pcToolbarDef.actions.action.id=""
 * The id of the action.
 *
 * @property {String} pcToolbarDef.actions.action.icon=""
 * Name of the icon for the action
 *
 * @property {Function} pcToolbarDef.actions.action.action
 * The function to be called when the icon is clicked
 *
 * @property {String} pcToolbarDef.actions.action.tooltip=""
 * Tooltip to be displayed when the mouse pointer hovers over the action icon
 *
 * @property {String} pcToolbarDef.actions.action.iconColor="white"
 * The color of the action icon
 *
 * @property {Boolean} pcToolbarDef.actions.action.disabled=false
 * Enable or disable the action
 *
 * @
 *
 * How to use
 * @example
 * <template>
 *  <pc-toolbar :toolbarDef="myToolbarDef"></pc-toolbar>
 * </template>
 *
 * <script>
 * import pcToolbar from '../pcComponents/pcToolbar.vue';
 * import { pcToolbarDef, pcToolbarActionDef } from '../pcComponents/defs/pcToolbarDef.js';
 *
 * export default {
 *  name: 'toolBarExample',
 *   components: {
 *      pcToolbar,
 *   },
 *  data() {
 *    return {
 *       myToolbarDef: pcToolbarDef('myToolbarID', 'My toolbar title',
 *        {
 *          dense: true,
 *          flat: true
 *        },
 *        [pcToolbarActionDef('back', 'mdi-arrow-left', 'wsCustomer', 'Back to customer list'),]
 *       ),
 *     };
 *   },
 * };
 * </script>
 */

const _pcToolbarDef = () => ({
  id: '',
  show: true, // Can use breakpoint object
  color: 'primary lighten-1', // Can use breakpoint object
  title: '', // Can use breakpoint object
  class: '',
  titleClass: 'white--text',
  dense: true,
  flat: false,
  actions: [],
})

const pcToolbarDef = function(id, title = '', other = {}, actions = []) {
  const newToolbar = _pcToolbarDef()
  if (!id) {
    console.log('Must specify an id for a pcToolbar')
  }

  newToolbar.id = id
  newToolbar.title = title
  newToolbar.actions = actions

  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(newToolbar, key)) {
      newToolbar[key] = value
    } else {
      console.log(`Invalid property: ${key} in pcToolbar id ${id}`)
    }
  })
  return newToolbar
}

const pcToolbarActionDef = function(
  id,
  icon,
  action,
  tooltip = '',
  iconColor = 'white',
  disabled = false
) {
  return {
    id,
    icon,
    action,
    tooltip,
    iconColor,
    disabled,
  }
}

export { pcToolbarDef, pcToolbarActionDef }
