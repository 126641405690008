<template>
  <pc-card v-if="productTop10Def.show" :card-def="cardDef">
    <template v-slot:title>
      <pc-toolbar class="toolbar" :toolbarDef="toolbarDef">
        <template v-slot:toolbarActions>
          <div style="width: 300px;">
            <contact-group-select
              allKey="company"
              allName="Company"
              :count="false"
              :dark="true"
              width="275px"
              :excludeNoSales="true"
              v-on:selected="groupSelected"
            />
          </div>
          <div style="width: 200px;">
            <v-select
              :items="top10Periods"
              v-model="periodIndex"
              :dark="true"
              dense=""
              label="Products sales"
              hide-details=""
              single-line
            ></v-select>
          </div>
        </template>
      </pc-toolbar>
    </template>

    <template v-slot:subTitle>
      <top-10-summary class="pb-2" :top10Data="top10Data" />
    </template>

    <template v-slot:text>
      <v-row
        id="productDashboard"
        class="fill-height mt-0"
        justify="center"
        align="stretch"
      >
        <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
          <div class="content pc-size100 pt-3 pl-3 pr-0">
            <div class="grid-content">
              <div class="customers-not-bought-product">
                <product-customers-never-bought-list
                  :top10Data="top10Data"
                  v-on="$listeners"
                />
              </div>
              <div class="customers-not-bought-product-in-period">
                <productCustomersNotBoughtProductInPeriodList
                  v-on="$listeners"
                  :top10Data="top10Data"
                />
              </div>
              <div class="customers-bought-chart">
                <product-customers-bought-chart
                  :top10Data="top10Data"
                  v-on="$listeners"
                />
              </div>

              <div class="customers-not-bought-category">
                <category-customers-never-bought-list
                  :top10Data="top10Data"
                  v-on="$listeners"
                />
              </div>
              <div class="customers-not-bought-category-in-period">
                <categoryCustomersNotBoughtCategoryInPeriodList
                  :top10Data="top10Data"
                  v-on="$listeners"
                />
              </div>
              <div class="customers-bought-list">
                <product-customers-bought-list
                  :top10Data="top10Data"
                  v-on="$listeners"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </pc-card>
</template>

<script>
//import pc from '@pc'
import db from '@db'
import pcCard from '@pcComponents/pcCard.vue'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect'
import { getTop10Data } from './productTop10.fn.js'
import categoryCustomersNeverBoughtList from './categoryCustomersNeverBoughtList.vue'
import categoryCustomersNotBoughtCategoryInPeriodList from './categoryCustomersNotBoughtCategoryInPeriodList.vue'
import productCustomersBoughtChart from './productCustomersBoughtChart.vue'
import productCustomersBoughtList from './productCustomersBoughtList.vue'
import productCustomersNeverBoughtList from './productCustomersNeverBoughtList.vue'
import productCustomersNotBoughtProductInPeriodList from './productCustomersNotBoughtProductInPeriodList.vue'
import top10Summary from './top10Summary.vue'
//import { getColors } from '@pcModules/pcColors.js'

export default {
  name: 'productTop10',
  components: {
    pcCard,
    pcToolbar,
    contactGroupSelect,
    productCustomersNeverBoughtList,
    productCustomersNotBoughtProductInPeriodList,
    categoryCustomersNeverBoughtList,
    categoryCustomersNotBoughtCategoryInPeriodList,
    productCustomersBoughtList,
    productCustomersBoughtChart,
    top10Summary,
  },

  props: {
    productTop10Def: Object,
  },

  created() {
    this.main
  },

  computed: {
    product() {
      return this.productTop10Def.product
    },

    category() {
      return this.product.productCategory
    },

    salesFieldName() {
      return this.periodIndex === -1
        ? 'sales'
        : `salesByDays[${this.periodIndex}]`
    },

    activeCustomersFieldName() {
      return this.periodIndex === -1
        ? 'activeCustomers'
        : `activeCustomersByDays[${this.periodIndex}]`
    },

    periodCaption() {
      return `${
        this.periodIndex === -1
          ? this.period.text
          : this.period.salesByDaysSpec.captions[this.periodIndex]
      }`
    },
  },

  watch: {
    productTop10Def() {
      this.main()
    },
    periodIndex() {
      this.main()
    },
    contactGroupId() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.product) {
        const { productCustomers, categoryCustomers } = getTop10Data(
          this.product,
          this.contactGroupId,
          this.salesFieldName
        )

        this.contactGroup =
          this.contactGroupId === 'company'
            ? this.period
            : db.cached(
                'contactGroups',
                db.cachedQuery().addFind('id', this.contactGroupId)
              )
        this.productCustomers = productCustomers
        this.categoryCustomers = categoryCustomers

        this.top10Data = {
          period: this.period,
          periodCaption: this.periodCaption,
          salesFieldName: this.salesFieldName,
          activeCustomersFieldName: this.activeCustomersFieldName,
          product: this.product,
          category: this.category,
          productCustomers: this.productCustomers,
          categoryCustomers: this.categoryCustomers,
          contactGroupId: this.contactGroupId,
          contactGroup: this.contactGroup,
        }
      }
    },

    groupSelected(groupId) {
      this.contactGroupId = groupId
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      periodIndex: -1,
      contactGroupId: 'company',
      contactGroup: {},
      top10Data: {
        period: this.period,
        periodCaption: this.periodCaption,
        salesFieldName: this.salesFieldName,
        activeCustomersFieldName: this.activeCustomersFieldName,
        product: this.product,
        category: this.category,
        productCustomers: { neverBought: [], previouslyBought: [], bought: [] },
        categoryCustomers: {
          neverBought: [],
          previouslyBought: [],
          bought: [],
        },
        contactGroupId: this.contactGroupId,
        contactGroup: this.contactGroup,
      },
      productCustomers: {
        neverBought: [],
        previouslyBought: [],
        bought: [],
      },
      categoryCustomers: {
        neverBought: [],
        previouslyBought: [],
        bought: [],
      },

      cardDef: pcCardDef('productTop10', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef('top10Toolbar', 'Top 10 customers', {
        dense: true,
        color: 'primary lighten-2',
        actions: [],
      }),

      top10Periods: [
        { text: 'In the period', value: -1 },
        ...period.salesByDaysSpec.captions.reduce((data, caption, index) => {
          data.unshift({
            text: caption,
            value: index,
          })
          return data
        }, []),
      ],
    }
  },
}
</script>

<style scoped>
.grid-content {
  display: grid;
  gap: 12px 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'customers-not-bought-product'
    'customers-not-bought-product-in-period'
    'customers-not-bought-category'
    'customers-not-bought-category-in-period'
    'customers-bought-chart'
    'customers-bought-list';
}

@media only screen and (min-width: 1024px) {
  .grid-content {
    display: grid;
    gap: 12px 12px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'customers-not-bought-product customers-not-bought-product-in-period'
      'customers-not-bought-category customers-not-bought-category-in-period'
      'customers-bought-chart customers-bought-list';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-content {
    display: grid;
    gap: 12px 12px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'customers-not-bought-product customers-not-bought-product-in-period customers-bought-chart'
      'customers-not-bought-category customers-not-bought-category-in-period customers-bought-list';
  }
}

.customers-not-bought-product {
  grid-area: customers-not-bought-product;
  overflow-y: auto;
}
.customers-not-bought-product-in-period {
  grid-area: customers-not-bought-product-in-period;
  overflow-y: auto;
}
.customers-bought-chart {
  grid-area: customers-bought-chart;
  overflow-y: auto;
}
.customers-not-bought-category {
  grid-area: customers-not-bought-category;
  overflow-y: auto;
}
.customers-not-bought-category-in-period {
  grid-area: customers-not-bought-category-in-period;
  overflow-y: auto;
}
.customers-bought-list {
  grid-area: customers-bought-list;
  overflow-y: auto;
}
.contact-group {
  display: flex;
  margin: 8px, 12px, 8px, 12px;
  border-style: solid;
}
</style>
