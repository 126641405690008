import { gql } from 'graphql-tag'

export const productUpdates = gql`
  query {
    productUpdates {
      products {
        code
        category {
          level1
          level2
          level3
        }
      }
    }
  }
`
export const updateProductUpdates = gql`
  mutation($record: ProductUpdateInput) {
    updateProductUpdates(record: $record) {
      products {
        code
        category {
          level1
          level2
          level3
        }
      }
    }
  }
`

export const categoryUpdates = gql`
  query {
    categoryUpdates {
      categories {
        id
        name
        level
        grandParentId
        parentId
        isModified
        isAdded
        isDeleted
      }
    }
  }
`

export const updateCategoryUpdates = gql`
  mutation($record: CategoryUpdateInput) {
    updateCategoryUpdates(record: $record) {
      categories {
        id
        name
        level
        grandParentId
        parentId
        isModified
        isAdded
        isDeleted
      }
    }
  }
`
