<template>
  <v-row
    v-if="customerInvoicesDef.show"
    id="customerTurnover"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0 pl-0">
      <div class="grid-container pt-0 pb-0">
        <div class="chart">
          <wsCustomerInvoices :invoicesDef="customerInvoicesDef" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import wsCustomerInvoices from '@appViews/sharedComponents/wsCustomerInvoices'

export default {
  name: 'customerTurnover',

  components: {
    wsCustomerInvoices,
  },

  props: {
    customerInvoicesDef: Object,
  },

  created() {},

  computed: {},

  methods: {},

  data: function() {
    return {}
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  padding-top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-template-rows: 1fr;
  grid-template-areas: 'turnover';
}
.chart {
  grid-area: turnover;
  overflow-y: auto;
}
</style>
