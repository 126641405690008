<template>
  <pc-card v-if="show" :cardDef="chartDef.card">
    <!-- Pass on all named slots -->
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
    <template v-slot:text>
      <v-tooltip
        top=""
        absolute=""
        v-model="tooltipDef.show"
        :position-x="tooltipDef.xPos"
        :position-y="tooltipDef.yPos"
        color="transparent"
      >
        <template v-slot:activator="{}">
          <div
            :id="`chart-container${chartDef.chart.domId}`"
            class="d-flex flex-grow-1 chart-container"
            :class="chartDef.chart.containerClass"
            :style="containerStyle"
          >
            <canvas :id="`chart-canvas${chartDef.chart.domId}`"></canvas>
          </div>
        </template>
        <pc-spark-chart
          v-if="chartDef.chart.tooltipSparkChart"
          :sparkChartDef="chartDef.sparkChart"
        />
        <slot name="tooltip"></slot>
      </v-tooltip>
    </template>
  </pc-card>
</template>

<script>
import pc from '@pc'
import Chart from 'chart.js/auto'
import pcCard from '@pcComponents/pcCard.vue'
import piePlugins from '@pcComponents/modules/piePlugins.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'

const _onResize = pc.debounce((gradient, fn) => gradient && fn(), 100)

export default {
  name: 'pcChart',

  components: {
    pcCard,
    pcSparkChart,
  },

  props: {
    chartDef: Object,
  },

  created() {
    this.chartDef.setOptions([
      ['onClick', this.onClick],
      ['onResize', this.onResize],
      //['plugins.legend.onHover', this.onLegendHover],
      //['plugins.legend.onLeave', this.onLegendLeave],
    ])
    if (
      this.chartDef.chart.customTooltip ||
      this.chartDef.chart.tooltipSparkChart
    ) {
      this.chartDef.setOptions([
        ['plugins.tooltip.enabled', false],
        ['plugins.tooltip.external', this.customTooltip],
      ])
    }
    pc.$eventBus.$on(`chart-${this.chartDef.chart.domId}`, this.processEvent)
  },

  mounted() {
    pc.forceNextTick(() => {
      this.createChart()
    })
  },

  beforeDestroy() {
    if (this.chart && typeof this.chart.destroy === 'function') {
      this.chart.destroy()
    }
    pc.$eventBus.$off(`chart-${this.chartDef.chart.domId}`, this.processEvent)
  },

  computed: {
    show() {
      return this.chartDef.chart.show
    },
    containerStyle() {
      let style = ''
      if (this.chartDef.chart.width)
        style = `width: ${this.chartDef.chart.width};`
      if (this.chartDef.chart.height)
        style = ` ${style} height: ${this.chartDef.chart.height};`
      return style
    },
  },

  watch: {
    show(value) {
      pc.forceNextTick(() => {
        if (value) {
          this.createChart()
        } else {
          if (this.chart) this.chart.destroy()
          this.chart = undefined
        }
      })
    },
  },

  methods: {
    createChart() {
      if (this.chartDef.chart.show) {
        const canvas = pc.getElementById(
          `chart-canvas${this.chartDef.chart.domId}`
        )
        if (canvas) {
          const ctx = canvas.getContext('2d')

          this.chart = new Chart(ctx, {
            type: this.chartDef.chart.type,
            plugins: [this.plugins[this.chartDef.chart.type]],
            data: [],
            options: this.chartDef.options,
          })
          this.render()
          //pc.runIn(this.render, 25)
        }
      }
    },

    onResize() {
      _onResize(this.chartDef.chart.gradient, this.render)
    },

    colorChart() {
      //this.chart.data = pc.clone(this.chartDef.data)
      if (this.chartDef.chart.gradient) {
        this.chart.data.datasets.forEach(dataset => {
          if (pc.isArray(dataset.backgroundColor)) {
            dataset.backgroundColor = dataset.backgroundColor.map(color =>
              this.createGradient(this.chart, this.chartDef.chart.type, [
                pc.lightenDarkenColor(color, 40),
                pc.lightenDarkenColor(color, 20),
                color,
              ])
            )
          } else {
            dataset.backgroundColor = this.createGradient(
              this.chart,
              this.chartDef.chart.type,
              [
                pc.lightenDarkenColor(dataset.backgroundColor, 40),
                pc.lightenDarkenColor(dataset.backgroundColor, 20),
                dataset.backgroundColor,
              ]
            )
          }
        })
      }
    },

    processEvent(event) {
      switch (event.event) {
        case 'render':
          this.render()
          break
        case 'update':
          this.update()
          break
        case 'hideDataset': {
          this.hideDataset(event.data)
          break
        }
        default: {
          console.log('Invalid chart event: ', event.string())
        }
      }
    },

    render() {
      if (this.chart) {
        this.chart.data = pc.clone(this.chartDef.data)
        this.chart.options = pc.clone(this.chartDef.options)
        this.tooltipDef.show = false
        if (this.chart) {
          this.colorChart()

          //if (typeof this.chartDef.options.tooltip.custom === 'function') {
          //this.chartDef.options.tooltip.custom({ opacity: 0 }) // To hide custom tooltip
          //}
          this.chart.options.plugins.title.text = this.chartDef.options.plugins.title.text
          if (this.chartDef.chart.type === 'pie') {
            const centerTitle = this.chartDef.options.elements.center.text
            if (centerTitle) {
              this.chart.options.elements.center.text = this.chartDef.options.elements.center.text
            }
          }
          this.update()
        }
      }
    },

    update() {
      if (this.chart.update) {
        this.chart.update()
        this.$emit('instance', this.chart)
      }
    },

    hasHidden() {
      const pie = () => !!Object.keys(this.chart._hiddenIndices).length
      const other = () =>
        !!this.chart.data.datasets.find(dataset => dataset.hidden)
      return this.chartDef.type === 'pie' ? pie() : other()
    },

    hideDataset(eventData) {
      if (this.chartDef.chart.type === 'pie') {
        this.chart._hiddenIndices = eventData.indices
        //const meta = this.chart.getDatasetMeta(0)
        //meta.data[eventData.datasetIndex].hidden = true
      } else {
        this.chartDef.data.datasets[eventData.datasetIndex].hidden =
          eventData.hidden
      }
    },

    onClick(evt) {
      const fn = this.chartDef.chart.onClick
      if (typeof fn === 'function') {
        //let activePoint = {} //this.chart.getElementAtEvent(evt)[0]
        let activePoint = this.chart.getElementsAtEventForMode(
          evt,
          'nearest',
          { intersect: true },
          false
        )[0]
        if (activePoint) {
          let domId = this.chartDef.chart.domId
          let type = this.chartDef.chart.type
          let datasetIndex = activePoint.datasetIndex
          let dataIndex = activePoint.index
          let data = this.chartDef.data
          let dataset = this.chartDef.data.datasets[datasetIndex]
          let xLabel = this.chartDef.data.labels[dataIndex]
          let yLabel = dataset.label
          let value = dataset.data[dataIndex]
          setTimeout(function() {
            fn({
              event: 'click',
              payload: {
                domId,
                type,
                datasetIndex,
                dataIndex,
                data,
                dataset,
                xLabel,
                yLabel,
                value,
              },
            })
          }, 50)
        }
      }
    },

    customTooltip(payload) {
      const tooltipModel = payload.tooltip
      if (tooltipModel.dataPoints) {
        if (tooltipModel.opacity) {
          const datasetIndex = tooltipModel.dataPoints[0].datasetIndex
          const dataIndex = tooltipModel.dataPoints[0].dataIndex
          const dataset = this.chartDef.data.datasets[datasetIndex]
          const position = this.calculateTooltipPosition(tooltipModel)
          const payload = {
            domId: this.chartDef.chart.domId,
            type: this.chartDef.chart.type,
            datasetIndex,
            dataIndex,
            data: this.chartDef.data,
            dataset,
            xLabel: this.chartDef.data.labels[dataIndex],
            yLabel: dataset.label,
            value: dataset.data[dataIndex],
            tooltipModel,
            chartDef: this.chartDef,
            position,
          }
          this.tooltipDef.xPos = position.x
          this.tooltipDef.yPos = position.y

          if (typeof this.chartDef.chart.customTooltip === 'function') {
            this.chartDef.chart.customTooltip(payload)
          } else {
            this.$emit('tooltip', payload)
          }
        } else {
          if (typeof this.chartDef.customToolipClose === 'function') {
            this.chartDef.customTooltipClose()
          }
        }
      }
      pc.forceNextTick(
        () => (this.tooltipDef.show = tooltipModel.opacity === 1)
      )
    },

    calculateTooltipPosition(tooltipModel) {
      const position = { x: 0, y: 0 }
      const cardTextElement = pc.getElementById(
        `chart-container${this.chartDef.chart.domId}`
      )
      const cardTextElementRect = cardTextElement.getBoundingClientRect()
      if (tooltipModel.xAlign === 'right') {
        position.x = cardTextElementRect.x + tooltipModel.caretX - 100
      } else {
        position.x = cardTextElementRect.x + tooltipModel.caretX + 100
      }
      position.y = cardTextElementRect.y + tooltipModel.y
      return position
    },

    createGradient(chart, chartType, gradientColors) {
      const isLinear = chartType === 'bar' || chartType === 'line'
      const chartArea = chart.chartArea
      if (!chartArea) {
        // This case happens on initial chart load
        return null
      }

      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top
      if (this.chartWidth !== chartWidth || this.chartHeight !== chartHeight) {
        this.gradientCache.clear()
        this.chartWidth = chartWidth
        this.chartHeight = chartHeight
      }

      const gradientCacheKey =
        this.chartDef.chart.type +
        ('' + gradientColors[0]) +
        ('' + gradientColors[1]) +
        ('' + gradientColors[2])
      let gradient = this.gradientCache.get(gradientCacheKey)

      if (!gradient) {
        if (isLinear) {
          gradient = chart.ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          )
        } else {
          const centerX = (chartArea.left + chartArea.right) / 2
          const centerY = (chartArea.top + chartArea.bottom) / 2
          const r = Math.min(
            (chartArea.right - chartArea.left) / 2,
            (chartArea.bottom - chartArea.top) / 2
          )
          gradient = chart.ctx.createRadialGradient(
            centerX,
            centerY,
            0,
            centerX,
            centerY,
            r
          )
        }
        gradient.addColorStop(0, gradientColors[0])
        gradient.addColorStop(0.5, gradientColors[1])
        gradient.addColorStop(1, gradientColors[2])
        this.gradientCache.set(gradientCacheKey, gradient)
      }

      return gradient
    },
  },

  data() {
    return {
      gradientCache: new Map(),
      chart: {},
      chartWidth: 0,
      chartHeight: 0,
      plugins: {
        pie: piePlugins,
        bar: [],
        line: [],
      },
      tooltipDef: pcTooltipDef(`chartTooltip${pc.uid()}`),
    }
  },
}
</script>

<style scoped>
.chart-container {
  position: relative;
  min-width: 0;
  overflow-y: scroll;
}
</style>
