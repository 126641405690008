<template>
  <pc-nugget :nuggetDef="nuggetDef">
    <pc-simple-table :simpleTableDef="simpleTableDef" />
  </pc-nugget>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcNuggetDef } from '@pcComponents/defs/pcNuggetDef.js'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

import pcNugget from '@pcComponents/pcNugget.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'

export default {
  name: 'wsProductSalesByMonth',

  components: {
    pcNugget,
    pcSimpleTable,
  },

  directives: {},

  props: {
    record: Object,
    title: String,
  },

  created() {
    this.main()
  },

  mounted() {},

  watch: {
    record() {
      this.main()
    },
  },

  methods: {
    main() {
      this.nuggetDef.title = this.title
      if (this.record && pc.isProperty('salesByMonth', this.record)) {
        const title = this.title
          ? this.title
          : `${this.record.code ? this.record.code : this.record.productCode} ${
              this.record.name ? this.record.name : this.record.productName
            }`
        const items = this.period.monthKeys
          .reduce((items, key, index) => {
            items.push({
              month: pc.monthKeyName('mmmm yyyy', key),
              quantity: this.record.quantityByMonth[index] || '',
              goods: this.record.salesByMonth[index]
                ? `£${Number(this.record.salesByMonth[index]).toFixed(2)}`
                : '',
            })
            return items
          }, [])
          .reverse()

        this.nuggetDef.title = title
        this.simpleTableDef.data = items
      }
    },
  },

  data() {
    return {
      period: db.cached('period'),
      nuggetDef: pcNuggetDef('productSalesByMonthNugget', 'Sales by month', {
        width: '350px',
        height: '500px',
        titleColor: 'blue lighten-3',
        color: 'blue lighten-5',
      }),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'blue lighten-5',
        fields: [
          pcSimpleTableFieldDef('month', 'text', 'Month', {
            headingClass: 'text-left font-weight-regular',
          }),
          pcSimpleTableFieldDef('quantity', 'text', 'Quantity', {
            headingClass: 'text-right font-weight-regular',
            fieldClass: 'text-right',
          }),
          pcSimpleTableFieldDef('goods', 'text', 'Purchased', {
            headingClass: 'text-right font-weight-regular',
            fieldClass: 'text-right',
          }),
        ],
      }),

      simpleTableItems: [],
    }
  },
}
</script>

<style scoped></style>
