import { gql } from 'graphql-tag'

const productsAndProductCustomers = gql`
  query($dbQuery: DbQuery) {
    multiQuery(dbQuery: $dbQuery) {
      products(dbQuery: $dbQuery) {
        code
        name
        ranking
        tradePrice
        RRP
      }
      productCustomers(dbQuery: $dbQuery) {
        productCode
        quantity
        quantityByMonth
        sales
        salesByMonth
        lastInvoiced
      }
    }
  }
`

const queries = {
  productsAndProductCustomers,
}

export { queries as customerProducts }
