<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="total"
        allName="No contact group"
        :count="false"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-chart :chartDef="chartDef" />
    </template>
  </pc-display-box>
</template>

<script>
//import pc from '@pc'
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor, getColors } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'sales30DayChart',

  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
  },

  props: {
    sales30DayData: Object,
    contactGroupSelect,
  },

  watch: {
    sales30DayData(value) {
      if (value) this.main()
    },
  },

  methods: {
    main() {
      const isGroup = this.groupId !== 'total'
      const company = this.sales30DayData.groups.find(
        group => group.id === 'total'
      )
      this.displayBoxDef.title = `Company sales in 30 days £${pc.toFixed0(
        company.total
      )}`

      this.chartDef
        .clearDatasets()
        .setLegend(isGroup)
        .setLabels([...company.days].reverse().map(day => day.date))

      if (this.groupId !== 'total') {
        const group = this.sales30DayData.groups[this.groupIndex]
        const total = this.groupHasSales ? group.total : 0
        const days = group.days
        this.chartDef.addDataset(
          this.groupHasSales
            ? [...days].reverse().map(day => pc.toFixed2(day.total))
            : pc.createArray(0, 30),
          `${this.groupName} Sales (£${pc.toFixed0(total)})`,
          getColor('purple', 3),
          [['type', 'line']]
        )
      }

      this.chartDef
        .addDataset(
          [...company.days].reverse().map(day => pc.toFixed2(day.total)),
          'Company sales',
          isGroup
            ? getColor('blue', 3)
            : [...company.days]
                .reverse()
                .map(day => this.chartColors[day.dayOfWeek])
        )
        .render()
    },

    groupSelected(groupId, groupName) {
      this.groupId = groupId
      this.groupName = groupName
      this.groupHasSales = false
      if (this.sales30DayData) {
        this.groupHasSales = this.sales30DayData.groups.find((group, index) => {
          if (group.id === groupId) {
            this.groupIndex = index
            return true
          }
          return false
        })
        this.main()
      }
    },
  },

  data() {
    return {
      period: db.cached('period'),
      chartColors: getColors('pastel'),
      groupIndex: 0,
      groupId: '',
      groupName: '',
      groupHasSales: true,

      displayBoxDef: pcDisplayBoxDef('sales30DaysChart', 'Sales', {
        height: '100%',
      }),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false),
    }
  },
}
</script>

<style scoped></style>
