<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <v-simple-table dense class="grey lighten-5">
      <template v-slot:default>
        <tbody>
          <tr v-for="(statistic, index) in statistics" :key="index">
            <td>
              {{ statistic.legend }}
            </td>
            <td class="pl-4 text-right">
              <span :class="statistic.class">{{ statistic.value }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider />
    <div class="mt-2 mb-1 ml-3 subtitle-2">
      Customer ranking
    </div>
    <v-simple-table dense class="grey lighten-5">
      <template v-slot:default>
        <tbody>
          <tr v-for="(ranking, index) in rankings" :key="index">
            <td>
              {{ ranking.legend }}
            </td>
            <td>
              <pc-horizontal-guage :horizontalGuageDef="ranking.guageDef" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { lastInvoicedColor, LAST_INVOICED_COLORS } from '@appModules/ws.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import { pcHorizontalGuageDef } from '@pcComponents/defs/pcHorizontalGuageDef.js'
import pcHorizontalGuage from '@pcComponents/pcHorizontalGuage.vue'

export default {
  name: 'statistics',

  components: {
    pcDisplayBox,
    pcHorizontalGuage,
  },

  props: {
    customer: Object,
  },

  created() {
    this.main()
  },

  watch: {
    customer() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.customer.id) {
        this.statistics = this.getStatistics()
        this.rankings = this.getRankings()
      }
    },

    getStatistics() {
      const numberOfInvoices = this.customer.invoices
      const lastInvoiced = this.customer.lastInvoiced || '0000-00-00'

      const lastInvoicedLegend = () => {
        if (lastInvoiced !== '0000-00-00') {
          const daysSinceInvoiced = pc.dateDiffDays(
            new Date(lastInvoiced),
            new Date(this.period.periodEndDate)
          )
          return `Last invoiced ${daysSinceInvoiced} day${
            daysSinceInvoiced !== 1 ? 's' : ''
          } ago`
        } else {
          return 'Never invoiced'
        }
      }

      // Get the last invoiced date color based but if its
      // in the first range e.g. last 30 days remove
      // color to leave displayBox background color
      const lastInvoicedValueClass = () => {
        const color = lastInvoicedColor(lastInvoiced, this.period.periodEndDate)
        return color !== LAST_INVOICED_COLORS[0] ? `chip ${color}` : ''
      }

      return [
        {
          legend: lastInvoicedLegend(),
          class: lastInvoicedValueClass(),
          value: pc.dateToDMY(lastInvoiced),
        },
        {
          legend: `Sales this period (Products: ${
            this.customer.products
          }, coverage: ${pc.toFixed2(
            this.customer.productCoveragePercentage
          )}%)`,
          class: '',
          value: '£' + pc.toFixed2(this.customer.sales),
        },
        {
          legend: `Sales this month (Products: ${pc.arrayLastElement(
            this.customer.productsByMonth
          )}, coverage: ${pc.toFixed2(
            pc.arrayLastElement(this.customer.productCoveragePercentageByMonth)
          )}%)`,
          class: '',
          value:
            '£' +
            pc.toFixed2(
              this.customer.salesByMonth[this.period.monthsInPeriod - 1]
            ),
        },
        {
          legend: 'Average monthly spend',
          class: '',
          value: '£' + pc.toFixed2(this.customer.averageMonthlySpend),
        },
        {
          legend: `${
            this.customer.monthSpendComparisonPercentage < 0
              ? 'Decrease'
              : 'Increase'
          } of ${
            this.customer.monthSpendComparisonPercentage
          }% on average spend`,
          class: `${
            this.customer.monthSpendComparisonPercentage < 0 ? 'red--text' : ''
          }`,
          value: '£' + pc.toFixed2(this.customer.monthSpendComparison),
        },
        {
          legend: `Invoice average from ${numberOfInvoices} invoice${
            numberOfInvoices !== 1 ? 's' : ''
          }`,
          class: '',
          value:
            '£' +
            `${pc.toFixed2(
              pc.safeDivide(this.customer.sales, this.customer.invoices)
            )}`,
        },
        {
          legend: `Monthly average over ${this.period.monthsInPeriod -
            1} months`,
          class: '',
          value:
            '£' +
            pc.toFixed2(
              pc.safeDivide(
                this.customer.sales -
                  this.customer.salesByMonth[this.period.monthsInPeriod - 1],
                this.period.monthsInPeriod - 1
              )
            ),
        },
      ]
    },

    getRankings() {
      const data = []
      data.push(this.getMonthContactGroupRanking())
      data.push(this.getPeriodContactGroupRanking())
      data.push(this.getMonthCompanyRanking())
      data.push(this.getPeriodCompanyRanking())

      return data
    },

    getMonthContactGroupRanking() {
      let ranking = 0
      const contactGroup = db.cached(
        'contactGroups',
        db.cachedQuery().addFind('id', this.customer.contactGroupId)
      )

      if (this.customer.salesByMonth[this.period.monthsInPeriod - 1]) {
        const groupCustomers = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter('contactGroupId', this.customer.contactGroupId)
            .addFilter(
              `salesByMonth[${this.period.monthsInPeriod - 1}]`,
              0,
              'ne'
            )
            .addSort(`salesByMonth[${this.period.monthsInPeriod - 1}]`, true)
        )
        ranking =
          groupCustomers.findIndex(
            customer => customer.id === this.customer.id
          ) + 1
      }

      const legend = `${pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthsInPeriod - 1]
      )} group ranking`

      return this.dataEntry(
        legend,
        ranking,
        contactGroup.activeCustomersByMonth[this.period.monthsInPeriod - 1]
      )
    },

    dataEntry(legend, ranking, maxEntries) {
      return {
        legend,
        guageDef: pcHorizontalGuageDef(
          pc.uid(),
          ranking ? `${ranking} of ${maxEntries}` : 'No ranking',
          {
            padding: 'pt-2 pb-2',
            value: ranking ? maxEntries - ranking + 1 : 0,
            maxValue: maxEntries,
          }
        ),
      }
    },

    getPeriodContactGroupRanking() {
      let ranking = 0
      const contactGroup = db.cached(
        'contactGroups',
        db.cachedQuery().addFind('id', this.customer.contactGroupId)
      )

      if (this.customer.sales) {
        const groupCustomers = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter('contactGroupId', this.customer.contactGroupId)
            .addFilter('sales', 0, 'ne')
            .addSort('sales', true)
        )
        ranking =
          groupCustomers.findIndex(
            customer => customer.id === this.customer.id
          ) + 1
      }

      return this.dataEntry(
        'Period group ranking',
        ranking,
        contactGroup.activeCustomers
      )
    },

    getMonthCompanyRanking() {
      let ranking = 0
      if (this.customer.salesByMonth[this.period.monthsInPeriod - 1]) {
        const customers = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter(
              `salesByMonth[${this.period.monthsInPeriod - 1}]`,
              0,
              'ne'
            )
            .addSort(`salesByMonth[${this.period.monthsInPeriod - 1}]`, true)
        )
        ranking =
          customers.findIndex(customer => customer.id === this.customer.id) + 1
      }

      const legend = `${pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthsInPeriod - 1]
      )} company ranking`

      return this.dataEntry(
        legend,
        ranking,
        this.period.activeCustomersByMonth[this.period.monthsInPeriod - 1]
      )
    },

    getPeriodCompanyRanking() {
      let ranking = 0

      if (this.customer.sales) {
        const customers = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter('sales', 0, 'ne')
            .addSort('sales', true)
        )
        ranking =
          customers.findIndex(customer => customer.id === this.customer.id) + 1
      }

      return this.dataEntry(
        'Period company ranking',
        ranking,
        this.period.activeCustomers
      )
    },
  },

  data() {
    return {
      period: db.cached('period'),
      statistics: [],
      rankings: [],

      displayBoxDef: pcDisplayBoxDef('statistics', 'Sales statistics'),
    }
  },
}
</script>

<style scoped>
.chip {
  display: inline-block;
  margin-right: -8px;
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 25px;
}
</style>
