<template>
  <div :style="divStyle" v-resize.initial:debounce="setControlHeight">
    <v-simple-table
      :height="computedHeight"
      :width="simpleTableDef.width"
      :fixed-header="simpleTableDef.fixedHeader"
      :dense="simpleTableDef.dense"
      :class="simpleTableDef.class"
    >
      <template v-slot:default>
        <thead
          v-if="simpleTableDef.headers && simpleTableDef.rows.length === 0"
        >
          <tr>
            <template v-for="(field, headingIndex) in simpleTableDef.fields">
              <pc-simple-table-heading
                :key="headingIndex"
                :simpleTableDef="simpleTableDef"
                :index="headingIndex"
              >
              </pc-simple-table-heading>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="simpleTableDef.rows.length === 0">
            <template v-for="(item, rowIndex) in simpleTableDef.data">
              <tr :key="rowIndex" v-on:click.stop="onRowClick($event, item)">
                <template v-for="(field, fieldIndex) in simpleTableDef.fields">
                  <slot
                    v-if="field.type === 'slot'"
                    :name="field.path"
                    :field="field"
                    :item="item"
                  ></slot>
                  <pcSimpleTableField
                    v-else
                    :key="fieldIndex"
                    :simpleTableDef="simpleTableDef"
                    :item="item"
                    :index="fieldIndex"
                    :rowIndex="rowIndex"
                    :expandedRowIndex="expandedRowIndex"
                    @[field.isClickEvent]="onFieldClick"
                    @[field.isExpandEvent]="onExpand"
                    @[field.isTooltipEvent]="onTooltip"
                  >
                    <template
                      v-if="field.tooltipSlot"
                      v-slot:[field.tooltipSlot]
                    >
                      <slot :name="field.tooltipSlot"></slot>
                    </template>
                  </pcSimpleTableField>
                </template>
              </tr>
              <tr
                v-if="expandedRowIndex === rowIndex"
                :key="`expand${rowIndex}`"
              >
                <td colspan="99">
                  <slot name="expand"></slot>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <template v-for="(item, rowIndex) in simpleTableDef.data">
              <tr :key="rowIndex" v-on:click.stop="onRowClick($event, item)">
                <template
                  v-for="(field, fieldIndex) in simpleTableDef.rows[rowIndex]"
                >
                  <slot
                    v-if="field.type === 'slot'"
                    :name="field.path"
                    :field="field"
                    :item="item"
                  ></slot>
                  <pcSimpleTableField
                    v-else
                    :key="fieldIndex"
                    :simpleTableDef="simpleTableDef"
                    :item="item"
                    :index="fieldIndex"
                    :rowIndex="rowIndex"
                    :expandedRowIndex="expandedRowIndex"
                    @[field.isClickEvent]="onFieldClick"
                    @[field.isExpandEvent]="onExpand"
                    @[field.isTooltipEvent]="onTooltip"
                  >
                    <template
                      v-if="field.tooltipSlot"
                      v-slot:[field.tooltipSlot]
                    >
                      <slot :name="field.tooltipSlot"></slot>
                    </template>
                  </pcSimpleTableField>
                </template>
              </tr>
              <tr
                v-if="expandedRowIndex === rowIndex"
                :key="`expand${rowIndex}`"
              >
                <td colspan="99">
                  <slot name="expand"></slot>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
/* eslint-disable vue/require-v-for-key */
import pcSimpleTableHeading from '@pcComponents/pcSimpleTable/pcSimpleTableHeading.vue'
import pcSimpleTableField from '@pcComponents/pcSimpleTable/pcSimpleTableField.vue'
import resize from 'vue-resize-directive'

export default {
  name: 'pcSimpleTable',

  components: {
    pcSimpleTableHeading,
    pcSimpleTableField,
  },

  directives: {
    resize,
  },

  props: {
    simpleTableDef: {
      type: Object,
      required: true,
    },
  },

  computed: {
    computedHeight() {
      let height = this.controlHeight

      if (this.simpleTableDef.maxHeight > 0) {
        if (height > this.simpleTableDef.maxHeight) {
          height = this.simpleTableDef.maxHeight
        }
      }

      return height ? height : ''
    },

    divStyle() {
      const width = this.simpleTableDef.width
        ? `width: ${this.simpleTableDef.width}; `
        : 'width: 100%;'
      const height =
        this.simpleTableDef.height === '100%' ? 'height: 100%;' : ``
      return `${width} ${height}`
    },

    tableData() {
      return this.simpleTableDef.data
    },
  },

  watch: {
    tableData() {
      this.expandedRowIndex = -1
    },
  },

  methods: {
    toggleExpand(item) {
      this.expandedItem = this.expandedItem === item ? null : item
    },

    onRowClick(event, item) {
      if (this.simpleTableDef.rowClick) {
        const payload = { event, data: item }
        this.$emit('rowclick', payload)
      }
    },

    onFieldClick(payload) {
      this.$emit('fieldclick', payload)
    },

    onExpand(payload) {
      if (payload.rowIndex === this.expandedRowIndex) {
        this.expandedRowIndex = -1
      } else {
        this.expandedRowIndex = payload.rowIndex
        this.$emit('expand', payload)
      }
    },

    onTooltip(payload) {
      this.$emit('tooltip', payload)
    },

    setControlHeight(element) {
      this.controlHeight = element.clientHeight
    },
  },

  data() {
    return {
      controlHeight: 0,
      expandedRowIndex: -1,
    }
  },
}
</script>

<style scoped></style>
