class PcDisplayBoxDef {
  constructor(id, title, other) {
    this.id = id
    this.show = true
    this.if = true

    this.width = ''
    this.minWidth = '100px'
    this.height = '100%'
    this.minHeight = '80px'
    this.maxHeight = ''
    this.color = 'grey lighten-5'
    this.textColor = ''
    this.rounded = 'rounded-lg'
    this.elevation = 'elevation-1'
    this.margin = ''
    this.padding = ''
    this.font = 'body2'
    this.class = ''

    this.title = title
    this.titlePadding = 'pt-1 pl-3 pr-3'
    this.titleColor = 'grey lighten-3'
    this.titleTextColor = ''
    this.titleRounded = 'rounded-lg'
    this.titleElevation = 'elevation-1'
    this.titleFont = 'subtitle-1 font-weight-bold'
    this.titleClass = ''

    this.contentPadding = 'pa-3'
    this.contentClass = ''

    this.contentLines = ''
    this.data = []

    if (!id) {
      console.log('Must specify an id for a pcDisplayBox')
    }

    Object.entries(other).forEach(([key, value]) => {
      if (Object.hasOwnProperty.call(this, key)) {
        this[key] = value
      } else {
        console.log(`Invalid property: ${key} in pcDisplayBox id ${id}`)
      }
    })
  }

  displayLines(linesArray, removeBlanks = false) {
    if (typeof linesArray === 'string') {
      linesArray = [linesArray]
    }
    const lines = linesArray.reduce((lines, line) => {
      if (line || removeBlanks === false) {
        lines = `${lines}<br>${line}`
      }
      return lines
    })
    this.contentLines = lines
  }

  displayNameAndAddress(name, address, includeCountry = false) {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      city,
      region,
      postalCode,
      country,
    } = address
    this.displayLines(
      [
        name,
        addressLine1,
        addressLine2,
        addressLine3,
        addressLine4,
        city,
        region,
        postalCode,
        includeCountry ? country : '',
      ],
      true
    )
  }
}

const pcDisplayBoxDef = function(id, title, other = {}) {
  return new PcDisplayBoxDef(id, title, other)
}

export { pcDisplayBoxDef }
