const crlf = String.fromCharCode(13) + String.fromCharCode(10)

export const csvFromLister = async (listDef, data) => {
  const fields = listDef.fields
  const isPhoneNumbers =
    fields.filter(field => field.type === 'phonenumbers').length > 0
  const isContacts =
    fields.filter(field => field.type === 'contacts').length > 0

  const fieldNames = fields.reduce(
    (row, field, index) =>
      (row += `${index ? ',' : ''}"${field.id}"${
        index + 1 === fields.length ? crlf : ''
      }`),

    ''
  )

  const processFields = (record, extraLine = false, lineNumber = 1) =>
    fields.reduce(
      (row, field, index) =>
        (row += `${index ? ',' : ''}${field.csv(
          record,
          extraLine,
          lineNumber
        )}${index + 1 === fields.length ? crlf : ''}`),
      ''
    )

  const processRecord = (csv, record) => {
    csv = csv + processFields(record)
    if (isPhoneNumbers || isContacts) {
      const phoneNumbers =
        record.phoneNumbers || record.customer.phoneNumbers || []
      const contacts = record.contacts || record.customer.contacts || []
      if (phoneNumbers.length > 1 || contacts.length > 1)
        csv = csv + processFields(record, true, 2)
      if (isContacts) {
        if (contacts.length > 2) csv = csv + processFields(record, true, 3)
        if (contacts.length > 3) csv = csv + processFields(record, true, 4)
      }
    }
    return csv
  }

  const csv = data.reduce(processRecord, fieldNames)

  const options = {
    types: [
      {
        description: 'CSV file',
        accept: {
          'text/plain': ['.csv'],
        },
      },
    ],
  }

  const handle = await window.showSaveFilePicker(options)
  const writable = await handle.createWritable()

  await writable.write(csv)
  await writable.close()
}

export default {
  csvFromLister,
}
