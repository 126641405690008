import { gql } from 'graphql-tag'

export const productsGql = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      id
      code
      name
      category {
        level1
        level2
        level3
      }
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      lastInvoiced
    }
  }
`

export const contactGroupProductsGql = gql`
  query($dbQuery: DbQuery) {
    contactGroupProducts(dbQuery: $dbQuery) {
      productCode
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      lastInvoiced
    }
  }
`
