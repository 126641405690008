class PcHorizontalGuageDef {
  constructor(id, caption, other) {
    this.id = id
    this.show = true
    this.if = true

    this.width = ''
    this.minWidth = '125px'
    this.height = ''
    this.minHeight = '5px'
    this.backgroundColor = 'grey lighten-2'
    this.elevation = 'elevation-1'
    this.margin = ''
    this.padding = 'pa-3'
    this.class = ''

    this.color = 'green'
    this.guageHeight = '5px'
    this.captionFont = ''
    this.captionColor = ''
    this.caption = caption
    this.value = 0
    this.maxValue = 100

    if (!id) {
      console.log('Must specify an id for a pcHorizontalGuage')
    }

    Object.entries(other).forEach(([key, value]) => {
      if (Object.hasOwnProperty.call(this, key)) {
        this[key] = value
      } else {
        console.log(`Invalid property: ${key} in pcHorizontalGuage id ${id}`)
      }
    })
  }
}

const pcHorizontalGuageDef = function(id, caption, other = {}) {
  return new PcHorizontalGuageDef(id, caption, other)
}

export { pcHorizontalGuageDef }
