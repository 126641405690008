import { gql } from 'graphql-tag'

export const productCustomersGql = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      customerId
      sales
      salesByDays
      salesByMonth
      lastInvoiced
    }
  }
`

export const categoryCustomersGql = gql`
  query($dbQuery: DbQuery) {
    categoryCustomers(dbQuery: $dbQuery) {
      customerId
      sales
      salesByDays
      salesByMonth
      lastInvoiced
    }
  }
`
