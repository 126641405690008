//import pc from '@pc'

//  validDropTraget -----------------------------------------------
const hasChildrenOfChildren = category =>
  category.children.reduce((isChildren, child) => {
    if (child.hasChildren) isChildren = true
    return isChildren
  }, false)

const getCategoryLevels = category => {
  if (category.level === 3 || !category.hasChildren) return 1

  if (category.level === 2) return category.hasChildren ? 2 : 1

  if (!category.hasChildren) return 1

  return hasChildrenOfChildren(category) ? 3 : 2
}

export const validDropTarget = (dropItem, dragItem) => {
  if (dropItem.isProduct) return false

  if (dropItem.id === dragItem.parentId) return false
  if (dropItem.id === dragItem.id) return false

  if (dragItem.isProduct) {
    if (dropItem.hasChildren) return false
    return true
  }

  if (dragItem.isCategory) {
    if (dropItem.hasProducts) return false
    if (dropItem.level + getCategoryLevels(dragItem) > 3) return false
    return true
  }

  return false
}
