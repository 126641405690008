const _pcTableDef = () => ({
  id: '',
  fixedHeader: true,
  tableClass: '',
  headerClass: '',
  depth: 1,
  dense: { xs: false },
  compressed: { xs: false },
  fontSize: { xs: 'medium' },
  multiSort: false,
  singleExpand: true,
  itemKey: 'name',
  sortBy: [],
  sortDesc: [],
  rowClick: null,
  noDataMessage: 'No data to display',
  expanded: [],
  headers: [],
  fields: [],
})

const pcTableDef = function(
  id,
  itemKey = 'name',
  sortBy = [],
  sortDesc = [],
  other = {}
) {
  const newTable = _pcTableDef()
  if (!id) {
    console.log('Must specify an id for a pcTable')
  }

  newTable.id = id
  newTable.itemKey = itemKey
  newTable.sortBy = sortBy
  newTable.sortDesc = sortDesc

  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(newTable, key)) {
      newTable[key] = value
    } else {
      console.log(`Invalid property: ${key} in pcTable id ${id}`)
    }
  })
  return newTable
}

export { pcTableDef }
