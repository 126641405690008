const _pcCardDef = () => ({
  id: '',
  if: true,             // Can use breakpoint object
  show: true,           // Can use breakpoint object
  disabled: false,      // Can use breakpoint object
  loading: true,
  loaderType: 'table-heading, table-tbody@4',
  expand: false,
  color: null,     // Can use breakpoint object
  elevation: {xs: '1'}, // Can use breakpoint object
  outlined: false,      // Can use breakpoint object
  flat: false,          // Can use breakpoint object
  cardClass: {all: '', xs: ''},
  cardStyle: {all: '', xs: ''},
  titleClass: {all: '', xs: ''},
  titleStyle: {all: '', xs: ''},
  subTitleClass: {all: '', xs: ''},
  subTitleStyle: {all: '', xs: ''},
  textClass: {all: '', xs: ''},
  textStyle: {all: '', xs: ''},
  actionClass: {all: '', xs: ''},
  actionStyle: {all: '', xs: ''},
  height: {xs: '100%'},
  maxHeight: {xs: ''},
  width: {xs: '100%'},  // % or px
  maxWidth: {xs: ''},   // px
  fnTextResize: null,
});

const loaderTypes = {
  list: 'table-heading, table-tbody@4',
  card: 'card-heading, image@6'
}

const pcCardDef = function (id, loading = false, other = {}) {
  const newCard = _pcCardDef();
  if (!id) {
    console.log('Must specify an id for a pcCard');
  }

  newCard.id = id;
  newCard.loading = loading;

  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(newCard, key)) {
      if (key === 'loaderType') {
        newCard[key] = loaderTypes[value];
      } else {
        newCard[key] = value;
      }
    } else {
      console.log(`Invalid property: ${key} in pcCard id ${id}`);
    }
  })
  return newCard;
}


export { pcCardDef };