<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-simple-table :simpleTableDef="simpleTableDef" @tooltip="tooltip">
        <template v-slot:sales>
          <pc-spark-chart :sparkChartDef="sparkChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import { getColors } from '@pcModules/pcColors.js'

export default {
  name: 'coTop10CategoriesList',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {},

  created() {
    this.main()
  },

  watch: {},

  methods: {
    async main() {
      const data = await this.getTop10Categories()
      this.simpleTableDef.data = data
      this.$emit('top-10-categories', data)
      return
    },

    async getTop10Categories() {
      const data = db.cached(
        'categories',
        db
          .cachedQuery()
          .addFilter('hasChildren', false)
          .addSort('sales', true)
          .addLimitAfterSort(10)
          .addClone()
      )

      // const totalCategory = db.cached(
      //   'categories',
      //   db.cachedQuery().addFind('id', 'category')
      // )

      const allOtherSales =
        this.period.sales -
        data.reduce((sum, category) => sum + category.sales, 0)

      const allOtherSalesByMonth = this.period.salesByMonth.map(
        (totalSales, index) => {
          return (
            totalSales -
            data.reduce(
              (sum, category) => sum + category.salesByMonth[index],
              0
            )
          )
        }
      )

      data.push({
        name: 'All other categories',
        sales: allOtherSales,
        salesByMonth: allOtherSalesByMonth,
        lastInvoiced: '',
      })

      return data.map((category, index) => {
        category.tag = this.chartColors[index]
        category.percentage = (category.sales / this.period.sales) * 100
        return category
      })
    },

    tooltip(payload) {
      const { data } = payload
      const titleColor = pc.getProperty('tag', data)
      this.sparkChartDef
        .setTitle(data.name, titleColor)
        .setSubTitle(
          `Sales £${pc.toFixed2(data.sales)} - ${pc.toFixed2(data.percentage)}%`
        )
        .clearDatasets()
        .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales', titleColor)
        .render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'top10CategoriesList',
        'Top 10 categories'
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('tag', 'tag', ''),
          pcSimpleTableFieldDef('name', 'text', 'Name'),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last invoiced'
          ),
        ],
      }),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
