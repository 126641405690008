<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <div>
        <span class="text-caption font-weight-bold pr-4">Sales</span>
        <span class="text-caption font-weight-bold pr-4"> Last invoiced</span>
      </div>
    </template>

    <template v-slot:default>
      <div class="tree">
        <v-treeview
          activatable
          dense
          :open.sync="openItems"
          :items="tree"
          return-object
          :active.sync="active"
        >
          <template v-slot:label="{ item }">
            <span>{{ item.name }}</span>
          </template>

          <template v-slot:append="{ item }">
            <span class="pr-2">{{ `£${pc.toFixed0(item.sales)}` }}</span>
            <span
              class="pl-3 mr-2"
              :class="lastInvoicedClass(item.lastInvoiced)"
              >{{ pc.dateToDMY(item.lastInvoiced) }}</span
            >
          </template>
        </v-treeview>
      </div>
    </template>
  </pc-display-box>

  <!--
  <div>
    <div class="tree-heading blue-grey lighten-5 pt-4">
      <span class="pr-7">Turnover</span>
      <span class="pr-6">Last invoiced</span>
    </div>
    <div class="tree">
      <v-treeview
        activatable
        dense
        :open.sync="openItems"
        :items="tree"
        return-object
        :active.sync="active"
      >
        <template v-slot:label="{ item }">
          <span>{{ item.name }}</span>
        </template>

        <template v-slot:append="{ item }">
          <span class="pr-2">{{ `£${pc.toFixed0(item.sales)}` }}</span>
          <span
            class="pl-3 mr-2"
            :class="lastInvoicedClass(item.lastInvoiced)"
            >{{ pc.dateToDMY(item.lastInvoiced) }}</span
          >
        </template>
      </v-treeview>
    </div>
  </div>
  -->
</template>

<script>
import pc from '@pc'
import { lastInvoicedClass } from '@appModules/ws.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'

export default {
  name: 'coverageTree',

  components: {
    pcDisplayBox,
  },

  props: {
    group: Object,
    treeDef: Object,
  },

  created() {
    this.main()
  },

  watch: {
    group() {
      this.active = []
    },

    treeDef() {
      this.main()
    },

    active() {
      if (this.active.length > 1) {
        this.active = this.this.active = [this.active[0]]
      } else if (this.active.length) {
        this.$emit('item', this.active[0])
      } else {
        this.$emit('item', undefined)
      }
    },
  },

  methods: {
    main() {
      this.tree = [this.treeDef]
      this.openItems = this.tree
    },
  },

  data() {
    return {
      pc,
      lastInvoicedClass,
      tree: [],
      active: [],
      openItems: [],

      displayBoxDef: pcDisplayBoxDef('categoryTree', 'Categories and products'),
    }
  },
}
</script>

<style scoped>
.tree-heading {
  height: 48px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}

.tree {
  height: 100%;
  border-right: 1px solid black;
  overflow-y: scroll;
}

.chip {
  display: inline-block;
  padding: 0 12px;
  border-radius: 25px;
  background-color: green;
}
</style>
