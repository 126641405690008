<template>
  <pc-lister
    v-if="invoicesDef.show"
    :list-def="invoiceListDef"
    :list-items="invoiceListItems"
  />
</template>

<script>
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcLineActionsDef } from '@pcComponents/defs/pcLineActionsDef.js'

import pcLister from '@pcComponents/pcLister.vue'

export default {
  name: 'wsProductInvoices',
  components: {
    pcLister,
  },

  // Properties in invoicesDef
  // show
  // width
  // maxWidth
  // ProductCode
  // customerId   Only if listing customer product invoices
  // updateTrigger    Change to trigger update eg use pc.uid()
  props: {
    invoicesDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    productCode() {
      return this.invoicesDef.productCode
    },
    customerId() {
      return this.invoicesDef.customerId
    },
  },

  watch: {
    'invoicesDef.triggerUpdate': function() {
      this.invoiceListItems = []
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.productCode) {
        const queryArgs = db
          .queryArgs('invoiceLines')
          .addFilter('productCode', this.productCode)
          .addSort('date', true)
        if (this.customerId) queryArgs.addFilter('customerId', this.customerId)
        const invoiceLines = await db.request(
          db.query('invoice.invoiceLineDrillDown'),
          db.args(queryArgs)
        )
        this.invoiceListItems = invoiceLines
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      invoiceListDef: {
        cardDef: pcCardDef('productInvoices', false, {
          expand: true,
          elevation: '0',
          width: this.invoicesDef.width,
          maxWidth: this.invoicesDef.maxWidth,
        }),
        table: pcTableDef('invoicesTable', 'invoiceNumber', ['date'], [true], {
          depth: 2,
        }),
        fields: [
          pcFieldDef('invoiceNumber', 'text', '', 'Invoice No.'),
          pcFieldDef('date', 'ISODate', '', 'Invoice date'),
          pcFieldDef('quantity', 'number', '', 'Quantity', false),
          pcFieldDef('price', 'currency', '', 'Price', false),
          pcFieldDef('goods', 'currency', '', 'Line amount', false),
        ],
        listActions: pcLineActionsDef(),
      },
      invoiceListItems: [],
    }
  },
}
</script>

<style scoped></style>
