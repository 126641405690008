<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="company"
        allName="Company"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <v-row class="pt-1 fill-height" justify="center" align="stretch">
        <v-col :cols="6" class="pc-col d-flex pt-0 pb-0">
          <pc-chart :chartDef="pieChartDef" />
        </v-col>
        <v-col :cols="6" class="pc-col d-flex pt-0 pb-0">
          <pc-chart :chartDef="barChartDef" />
        </v-col>
      </v-row>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'categoryCustomerCoverage',
  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
  },

  props: {
    categorySummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categorySummaryDef.category
    },

    groups() {
      return this.categorySummaryDef.groups
    },
  },

  watch: {
    categorySummaryDef() {
      this.main()
    },

    groupId() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.updateDisplay()
    },

    groupSelected(groupId) {
      this.groupId = groupId
    },

    updateDisplay() {
      if (this.isCategory) {
        const group = this.groups.find(group => group.id === this.groupId)
        const active = group.activeCustomers
        const bought = group.bought
        const notBought = active - bought

        const boughtLabel = `Bought category ${bought} (${pc.toFixed2(
          pc.percentage(bought, active)
        )}%)`

        const notBoughtLabel = `Not bought ${notBought} (${pc.toFixed2(
          pc.percentage(notBought, active)
        )}%)`

        this.pieChartDef
          .clearDatasets()
          .setLabels([boughtLabel, notBoughtLabel])
          .addDataset([bought, notBought], '', [
            getColor('purple', 3),
            getColor('grey', 5),
          ])
          .render()

        this.barChartDef
          .clearDatasets()
          .setLegend(true)
          .addDataset(
            group.boughtByMonth,
            'Bought category',
            getColor('purple', 3),
            [['type', 'line']]
          )
          .addDataset(
            group.activeCustomersByMonth,
            'Active customers',
            getColor('blue', 3),
            [['type', 'bar']]
          )

        this.barChartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      groupId: 'company',
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Sales', {
        height: '50%',
        contentPadding: 'pt-3 pb-0 pl-3 pr-3',
        title: `Customer coverage`,
      }),

      pieChartDef: pcChartDef2('pie')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
