<template>
  <div
    v-if="listDef.cardDef.if"
    v-show="listDef.cardDef.show"
    class="pc-size100"
  >
    <pc-tooltip :tooltip-def="tooltipDef">
      <template v-slot:tooltip>
        <slot :name="tooltipSlotName"></slot>
      </template>
    </pc-tooltip>

    <pc-dropdown
      :dropdownDef="dropdownDef"
      @close="() => (this.dropdownDef.show = false)"
    />

    <pc-card :card-def="listDef.cardDef">
      <template v-if="showToolbar" v-slot:title>
        <pc-toolbar :toolbar-def="listDef.toolbarDef">
          <template v-slot:toolbarActions>
            <slot name="toolbarActions"></slot>
          </template>
        </pc-toolbar>
      </template>
      <template v-else v-slot:title>
        <slot name="title"></slot>
      </template>

      <template v-slot:subTitle>
        <slot name="subTitle"></slot>
      </template>

      <template v-slot:text>
        <v-data-table
          class="pc-size100"
          :class="listDef.table.tableClass"
          :id="`${listDef.cardDef.id}Table`"
          :dense="dense"
          :fixed-header="listDef.table.fixedHeader"
          :multi-sort="listDef.table.multiSort"
          :single-expand="listDef.table.singleExpand"
          :item-key="listDef.table.itemKey ? listDef.table.itemKey : 'name'"
          :sort-by="listDef.table.sortBy"
          :sort-desc="listDef.table.sortDesc"
          :search="listDef.table.search"
          :headers="listDef.table.headers"
          :height="tableHeight"
          :items="tableListItems"
          :value="selected"
          :expanded.sync="listDef.table.expanded"
          @pagination="pageCount = Date.now()"
          mobile-breakpoint="200"
          disable-pagination
          hide-default-footer
        >
          <!-- Pass on all named slots -->
          <template v-for="(index, name) in $slots" v-slot:[name]>
            <slot :name="name" />
          </template>

          <template v-slot:no-data>
            <v-alert
              v-if="!Array.isArray(listItems && listDef.table.noDataMessage)"
              class="mt-8"
              :value="true"
              type="info"
              >{{ listDef.table.noDataMessage }}</v-alert
            >
          </template>

          <template slot="item" slot-scope="props">
            <tr v-on:mouseover="selected = [props.index]">
              <pcField
                v-for="field in listDef.table.fields.filter(
                  field => !field.csvOnly
                )"
                :key="field.id"
                :field="field"
                :listDef="listDef"
                :props="props"
                :compressed="compressed"
                :fontSize="fontSize"
                :handler="handler"
              />
              <pcLineActions
                :listDef="listDef"
                :props="props"
                :selected="selected"
              />
            </tr>
          </template>
        </v-data-table>
      </template>

      <template v-slot:actions>
        <slot name="actions"></slot>
      </template>
    </pc-card>
  </div>
</template>

<script>
/** DOCUMENTATION - listDef
 * @module pcLister - Vue Component
 * @description A table displayed in a pcCard powered by a schema
 *
 * @vue-prop {Object} listDef
 *  The schema describing the list behavour and data etc
 *
 * @property {Object} listDef.cardDef
 *  Object specifying the attributes of the pcCard containing the table
 * @property {Object} listDef.table
 *  Object specifying the attributes of the table
 * @property {Object[]} listDef.fields
 *  An array of field objects defining the values to be displayed in the table
 * @property {Object[]} listDef.lineActions
 *  An array of action objects defining the actions displayed on the selected
 *  table line
 *
 * @property {String} card.id
 *  Identifies the card instance and is prepended to any event names defined in
 *  the card definition
 * @property {Boolean} [card.loading=false]
 *  Set to true to briefly display a  Skeleton Loader
 * @property {String} [card.loaderType=table-heading, table-tbody@4]
 *  See vuetify API for full list. A string of comma seperated loader types.
 * @property {String|Object} [card.titleClass={all: '', xs: ''}]
 *  If a string it is always applied. If a breakpoint object the all
 *  property is applied to all breakpoints followed by the specific
 *  breakpoint value.
 * @property {String|undefined} [card.containerId=undefined]
 *  An id of the containing element or the id of an element to which the card
 *  height is to be matched. If no containingId is provided the system
 *  defaults to the window content area.
 * @property {String|Object|undefind|Function} [card.height={xs: 'fill'}]
 *  The overall height of the control. Set to undefined if the height is to be
 *  automatically set to the size of the content. The default is {xs: 'fill'} which
 *  will fill the height of the size of the container specified in the property
 *  containerId or the window content area. Otherwise provide a height in either
 *  pixels e.g. '800px', as a percentage e.g. 80%, the word 'match' to match the
 *  size of the container specified in containerId property or a function returning
 *  any of the above. A different height can be set for each breakpoint.
 * @property {String|Object|undefined|Function} [card.maxHeight={xs: undefined}]
 *  Can use all the same methods as specified for the height property above.
 * @property {Function|undefined} [card.resize=undefined]
 *  A function to be called when the card has been resized. The function
 *  receives an object parameter of:
 *  {cardHeight: nnn, cardMaxHeight: nnn, cardTextHeight: nnn} where nnn is height in pixels
 *
 * @property {Object} listDef.table
 *  The object describes the table attributes
 * @property {Boolean} [table.fixedHeader=true]
 *  Fix headers and only scroll body lines
 * @property {String} [table.headerClass='']
 *  Class to be applied to each column header unless a specfic class is
 *  specified in the field.header.class
 * @property {Boolean} [table.dense=false]
 *  Reduces the hight of each table row
 *  An object overriding the default configuration of false for all breakpoints.
 *  If the current screen breakpoint is not specified the system will default to false.
 * @property {Object} [table.compressed={xs:false}]
 *  Reduces the space between each column (field).
 *  An object overriding the default configuration of false for all breakpoints.
 *  If the current screen breakpoint is not specified the system will default to false.
 * @property {Object} [table.fontSize={xs:'medium}]
 *  Available optiond are 'small', 'medium', 'large'
 *  An object overriding the default configuration of 'medium' for all breakpoints.
 *  If the current screen breakpoint is not specified the system will default to 'medium'.
 * @property {Boolean} [table.multiSort=false]
 *  Allow sorting on multiple columns
 * @property {Boolean} [table.singleExpand=true]
 *  Allow only one expanded row at a time. Set to false to allow multiple rows
 * @property {String} [table.itemKey=name]
 *  Reference to a record field which must result in a unique value for each record
 * @property {Object} [table.options={}]
 *  Use to present certain properties - as follows
 * @property {String[]} table.options.sortBy
 *  List of record field name to sort by
 * @property {Boolean[]} [table.options.sortDesc=[]]
 *  List of Boolean values for the sortBy property. Set to true to sort in
 *  descending order
 * @property {(String|Function)} [table.rowClick=false]
 *  Provide a string to emit an event when the row is clicked. The event name
 *  will be listDef.id + listDef.toolbar.rowClicked the event payload will be the
 *  the toolbar object. For a function the toolbar object will be passed as a parameter
 * @property {String} [table.noDataMessage=No data to display]
 *  Message to be displayed if there are no items to display
 * @property {String} table.search
 *  Used to store search string entered by user.
 * @property {Object[]}  table.headers
 *  Automatically maintained by pcLister - do not update this property
 *
 * @property {Object[]} listDef.fields - An array of field objects - each
 *  one defines a column of the list
 * @property {String} field.id
 *  A id to uniquely identify the field within the field object array
 * @property {String} field.path
 *  The path.name of the field in the record
 * @property {String} field.type
 *  The type of field 'text', 'number', 'currency', 'ISODate'
 * @property {String[]} [field.notShownOn[]=[]]
 *  An array of strings specifing which display sizes the field is not to
 *  be shown on. Options are: 'xs' (small to large handset <600px),
 *  'sm' (small to medium tablet 600px> <960px),
 *  'md' (large tablet to laptop 960px> <1264px),
 *  'lg' (desktop 1264px> <1904px), 'xl' (4k and ultra-wide >12904px)
 * @property {String} [field.fontSize=table.fontSize]
 *  Font size for field if different from table default. 'small', 'medium', 'large'
 * @property {Number} [field.decimalPlaces]
 *  Defaults to 2dp for currency type and 0dp for number type
 * @property {String} [field.currencySymbol='']
 *  Only applies to 'number' type. £ is automatically added to 'currency' type
 * @property {String|Function} [field.style='']
 *  Applied to the td tag of the data table. If a function it must return a string
 *  and is passed an object containing {field, data}
 * @property {String|Function} [field.fieldClass='']
 *  Applied to the td tag of the field. If a function it must return a string
 *  and is passed an object containing {field, data}. The class is appended
 *  to the normal field class alignment which it may override.
 * @property {String|Function} [field.class='']
 *  Applied to the span tag of the field. If a function it must return a string
 *  and is passed an object containing {field, data}
 * @property {String|Function} [field.negativeClass='red--text']
 *  The class to apply to negative 'number' and 'currency' values
 * @property {Boolean} [field.blankZero=false]
 *  Suppress the printing to zero value 'number' and 'currency' types
 * @property {String|Function} [field.blankIfNot='']
 *  A string path.name of a numeric field within the data to check and if it evaluates to
 *  zero the current field is not displayed. If function it should return true or
 *  false and is passed an object containing {field, data}
 * @property {String|Function} [field.click=false]
 *  Provide a string to emit an event when the field is clicked. The event name
 *  will be listDef.id + field.click the event payload will be the
 *  the an object containing {field, data, props} For a function the
 *  same object will be passed as a parameter
 * @property {Funtion} [field.format='undefined']
 *  Function to format the field and return the printable result as a string. The
 *  function is passed an object containing {field, data}
 * @property {Object} field.header
 *  An object containing properties for the column heading
 * @property {(String|Function)} field.header.text
 *  A string containing the text to display in the heading. A function to return
 *  a string of the text to be displayed in the heading. The function is
 *  passed the field object as a parameter
 * @property {String|Number} [field.header.width='']
 *  Eg '100%', '50px'
 * @property {String|Function} [field.header.class=table.headerClass]
 *  A string containing the class names to applied to the header. A function
 *  returning a string of class names. The function will be passed the field
 *  object as a parameter
 * @property {String} [field.header.align=type of field alignment]
 *  A string conatining one of the three alignments 'start', 'center', 'end'
 *  If the property is omitted the alignment will defauklt to that used for the
 *  field type
 * @property {Boolean} [field.header.sortable=false]
 *  Set to true to allow the list to be sorted by the column (field)
 *
 * @property {Object} [listDef.lineActions]
 *  An array of action objects for each icon action to be added to the end of the
 *  table data row. These button are only visible on the selected row
 * @property {Object} action.header
 *  An object containing properties for the column heading
 * @property {String[]} [action.header.notShownOn[]=[]]
 *  An array of strings specifing which display sizes the field is not to
 *  be shown on. Options are: 'xs' (small to large handset <600px),
 *  'sm' (small to medium tablet 600px> <960px),
 *  'md' (large tablet to laptop 960px> <1264px),
 *  'lg' (desktop 1264px> <1904px), 'xl' (4k and ultra-wide >12904px)
 * @property {(String|Function)} [action.header.text=Actions]
 *  A string containing the text to display in the heading.
 * @property {String|Number} [action.header.width='']
 *  Eg '100%', '50px'
 * @property {String|Function} [action.header.class=table.headerClass]
 *  A string containing the class names to applied to the header. A function
 *  returning a string of class names. The function will be passed the header
 *  object as a parameter
 * @property {String} [action.header.align='center']
 *  A string conatining one of the three alignments 'start', 'center', 'end'
 * @property {Object[]} action.actions
 *  An array of action objects describing icon buttons to be displayed on the right end of the
 *  data row
 * @property {String} action.name
 *  Name of the action. Must be unique within the line actions
 * @property {String} action.icon
 *  The icon name
 * @property {(String|Function)} [action.iconColor=primary]
 *  Defaults to primary lighten-1" if not supplied. If function it must return a string
 *  and receives the action object as a parameter
 * @property {(String|Function)} [action.tooltip]
 *  The tooltip for the action. If "" or not provided no tooltip is included.
 *  If function it must return a string and receives the action object as a parameter
 * @property {(String|Function)} action.action
 *  Provide a string to emit an event when the action is clicked. The event name
 *  will be listDef.id + action.action.action the event payload will be
 *  an object containing {action, data, props). For a function the same object will
 *  be passed as a parameter
 **/

import pc from '@pc'
import resize from 'vue-resize-directive'
import pcCard from '@pcComponents/pcCard'
import pcToolbar from '@pcComponents/pcToolbar'
import pcField from '@pcComponents/pcField'
import pcLineActions from '@pcComponents/pcLineActions'
import pcTooltip from '@pcComponents/pcTooltip'
import pcDropdown from '@pcComponents/pcDropdown'

export default {
  name: 'pcLister',
  components: {
    pcCard,
    pcToolbar,
    pcField,
    pcLineActions,
    pcTooltip,
    pcDropdown,
  },
  directives: {
    resize,
  },
  props: {
    listDef: Object,
    listItems: Array,
  },
  created() {
    this.listDef.cardDef.fnTextResize = this.tableResize
    this.headers()
    this.updateListItems()
  },
  computed: {
    loading() {
      return this.listDef.cardDef.loading
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
    showToolbar() {
      return !this.listDef.toolbarDef ? false : true
    },
    showActions() {
      if (!this.listDef.lineActions) return false
      return (
        this.listDef.lineActions.actions.length &&
        pc.getBreakpointValue(this.listDef.lineActions.show, this.breakpoint)
      )
    },
    skeletonStyle() {
      return `width: ${pc.getBreakpointValue(
        this.listDef.cardDef.width,
        this.breakpoint
      )}; maxWidth: ${pc.getBreakpointValue(
        this.listDef.cardDef.maxWidth,
        this.breakpoint
      )};`
    },
  },
  watch: {
    breakpoint() {
      this.listDef.headers = this.headers()
    },
    listDef() {
      this.headers()
    },
    listItems() {
      this.isUserData = true
      this.updateListItems()
      pc.runIn(() => (this.pageCount = Date.now()), 1000) // If user clicks same data no pagination event is triggered so this will switch off loading
    },
    pageCount(pages) {
      if (pages && this.isUserData) {
        this.listDef.cardDef.loading = false
        //this.listDef.cardDef.show = true;
        this.pageCount = 0
      }
    },
  },
  methods: {
    updateListItems() {
      const updateItems = function() {
        this.tableListItems = this.listItems
        if (
          this.listItems.length === 0 &&
          this.listDef.cardDef.loading &&
          this.isUserData
        )
          this.pageCount = Date.now() // Now data turn off loading
      }.bind(this)

      pc.runIn(updateItems)
    },
    stringOrCall(item, payload = {}) {
      if (typeof item === 'string') return item
      if (typeof item === 'function') return item(payload)
      if (typeof item === 'object') {
        if (!Object.hasOwnProperty.call(item, 'xs')) return ''
        const bpItem = pc.getBreakpointValue(item, this.breakpoint)
        if (typeof bpItem === 'string') return bpItem
        if (typeof bpItem === 'function') return bpItem({ payload })
        return ''
      }
      return ''
    },
    headers() {
      const dense = pc.getBreakpointValue(
        this.listDef.table.dense,
        this.breakpoint
      )
      const compressed = pc.getBreakpointValue(
        this.listDef.table.compressed,
        this.breakpoint
      )
      const fontSize = pc.getBreakpointValue(
        this.listDef.table.fontSize,
        this.breakpoint
      )
      const depthColorClass = this.listDef.table.depth
        ? `blue-grey lighten-${6 - this.listDef.table.depth}`
        : ''
      const headers = this.listDef.fields
        .filter(
          field =>
            !field.csvOnly && pc.getBreakpointValue(field.show, this.breakpoint)
        )
        .map(
          function(field, index, array) {
            let classes = ''
            if (compressed) {
              classes = `${classes} ${index === 0 ? 'pl-4' : 'pl-2'}`
              classes = `${classes} ${
                index === array.length - 1 ? 'pr-4' : 'pr-2'
              }`
            }
            return {
              text: this.stringOrCall(field.heading, { field }),
              value: field.dataPath,
              align: field.headingAlign ? field.headingAlign : field.align,
              sortable: field.sort,
              class: `${depthColorClass} ${this.listDef.table.headerClass} ${field.headingClass} ${classes}`,
              width: field.width,
            }
          }.bind(this)
        )
      if (this.showActions) {
        headers.push({
          text: this.listDef.lineActions.heading,
          value: '',
          align: this.listDef.lineActions.align,
          sortable: false,
          class: `${depthColorClass} ${this.listDef.table.headerClass} ${this.listDef.lineActions.headingClass}`,
          width: this.listDef.lineActions.width,
        })
      }
      this.dense = dense
      this.compressed = compressed
      this.fontSize = fontSize
      this.listDef.table.headers = headers
      this.listDef.table.fields = this.listDef.fields.filter(
        field =>
          !field.csvOnly && pc.getBreakpointValue(field.show, this.breakpoint)
      )
    },
    tableResize(payload) {
      if (this.listDef.cardDef.expand) {
        this.tableHeight = undefined
      } else {
        this.tableHeight = `${payload.height}px`
      }
    },
    handler(payload) {
      const openDropdown = function(payload) {
        this.dropdownDef = payload.field.dropdownDef
        this.dropdownDef.activatorElement = payload.event.target
        this.dropdownDef.payload = payload
        this.dropdownDef.show = true
      }.bind(this)

      if (payload.entity === 'dropdown') {
        // Close an expansion if one is open
        payload.props.expand(false)
        // Close an existing open dropdown before opening new one
        this.dropdownDef.show = false
        pc.forceNextTick(() => openDropdown(payload))
      }

      if (payload.entity === 'tooltip') {
        let keyindex
        this.tooltipDef = payload.field.tooltipDef
        this.tooltipSlotName = payload.field.id
        switch (payload.eventName) {
          case 'mouseover':
            this.tooltipDef.activatorElement = payload.event.currentTarget
            payload.event.currentTarget.focus()
            if (typeof payload.field.events.mouseover.callback === 'function')
              payload.field.events.mouseover.callback(payload)
            this.tooltipDef.show = true
            break
          case 'mouseleave':
            this.tooltipDef.show = false
            break
          case 'keydown':
            //keyindex = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(payload.event.key);
            keyindex = ['a', 'b', 'c', 'd'].indexOf(payload.event.key)
            if (keyindex !== -1) {
              this.tooltipDef.top = keyindex === 0
              this.tooltipDef.bottom = keyindex === 1
              this.tooltipDef.left = keyindex === 2
              this.tooltipDef.right = keyindex === 3
            }
            break
        }
      }
    },
  },
  data() {
    return {
      isUserData: false,
      pageCount: -1,
      dense: false,
      compressed: false,
      fontSize: 'medium',
      tableHeight: undefined,
      selected: [],
      tableListItems: undefined,
      dropdownDef: {},
      tooltipDef: {},
      tooltipSlotName: 'default',
    }
  },
}
</script>

<style scoped>
.text {
  text-align: left;
}
.quartile {
  text-align: center;
}
.number {
  text-align: right;
}
.currency {
  text-align: right;
}
.isodate {
  text-align: right;
}
.small {
  font-size: 10px;
}
.medium {
  font-size: 12px;
}
.large {
  font-size: 14px;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.chip {
  display: inline-block;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 25px;
  background-color: green;
}
.yellow {
  background-color: gold;
}
.orange {
  background-color: orange;
}
.pink {
  background-color: pink;
}
.red {
  background-color: red;
}
</style>
