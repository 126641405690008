import * as R from 'ramda'

const _isDark = hexColor => {
  const hex = hexColor.replace('#', '')
  const c_r = parseInt(hex.substr(0, 2), 16)
  const c_g = parseInt(hex.substr(2, 2), 16)
  const c_b = parseInt(hex.substr(4, 2), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness < 125
}

const lightenDarkenColor = (color, percent) => {
  const num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt

  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

export const isDarkColor = hexColor => _isDark(hexColor)

export const getElementById = id => document.getElementById(id)

export const getElementSize = id => {
  const element = getElementById(id)
  return element
    ? R.pick(
        [
          'clientHeight',
          'clientWidth',
          'clientTop',
          'clientLeft',
          'offsetHeight',
          'offsetWidth',
          'offsetTop',
          'offsetLeft',
          'scrollHeight',
          'scrollWidth',
          'scrollTop',
          'scrollLeft',
        ],
        element
      )
    : {}
}

export default {
  lightenDarkenColor,
  isDarkColor,
  getElementById,
  getElementSize,
}
