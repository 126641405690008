<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions> </template>
    <template v-slot:default>
      <v-simple-table dense class="grey lighten-5"> </v-simple-table>
      <pc-chart :chartDef="chartDef">
        <template v-slot:subTitle>
          <table class="pc-size100">
            <tbody>
              <tr>
                <td class="pl-3" style="width: 180px;">
                  {{ rankingCaption }}
                </td>
                <td>
                  <pc-horizontal-guage :horizontalGuageDef="guageDef" />
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-slot:tooltip>
          <pc-card :cardDef="tooltipCardDef">
            <template v-slot:title>
              {{ tooltipTitle }}
            </template>
            <template v-slot:subTitle>
              {{ tooltipSubTitle }}
            </template>
            <template v-slot:text>
              <table class="pc-size100">
                <tbody>
                  <tr>
                    <td>
                      <pc-horizontal-guage
                        :horizontalGuageDef="tooltipGuageDef"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </pc-card>
        </template>
      </pc-chart>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import { pcHorizontalGuageDef } from '@pcComponents/defs/pcHorizontalGuageDef.js'
import pcHorizontalGuage from '@pcComponents/pcHorizontalGuage.vue'
import PcCard from '@pcComponents/pcCard.vue'

export default {
  name: 'productRankingChart',
  components: {
    pcChart,
    pcDisplayBox,
    pcHorizontalGuage,
    PcCard,
  },

  props: {
    productSummaryDef: Object,
  },

  created() {
    this.numberRankedProductsPeriod = this.rankedProducts()
    this.main()
  },

  computed: {
    isProduct() {
      return this.product && this.product.code ? true : false
    },

    product() {
      return this.productSummaryDef.product
    },
  },

  watch: {
    productSummaryDef() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.isProduct) {
        this.displayRanking(
          '',
          this.product.ranking,
          this.numberRankedProductsPeriod
        )

        const rankings = this.product.rankingByMonth.map(ranking =>
          ranking === 99999 ? NaN : ranking
        )

        this.chartDef
          .clearDatasets()
          .addDataset(rankings, 'Ranking', [getColor('purple', 3)])
          .render()
      }
    },

    rankedProducts(monthKey) {
      const monthIndex = monthKey => this.period.monthKeys.indexOf(monthKey)
      const fieldName = monthKey =>
        monthKey ? `salesByMonth[${monthIndex(monthKey)}]` : 'sales'

      return db.cached(
        'products',
        db.cachedQuery().addFilter(fieldName(monthKey), 0, 'ne')
      ).length
    },

    displayRanking(monthKey, ranking, numberRankedProducts) {
      this.rankingCaption = `Product ranking ${
        monthKey ? pc.monthKeyName('mmm yyyy', monthKey) : 'in period'
      }`
      this.guageDef.value =
        ranking < 99999 ? numberRankedProducts - ranking + 1 : 0
      this.guageDef.maxValue = numberRankedProducts
      this.guageDef.caption =
        ranking < 99999 ? `${ranking} of ${numberRankedProducts}` : 'No ranking'
    },

    chartTooltip(payload) {
      const monthIndex = payload.dataIndex
      const ranking = this.product.rankingByMonth[monthIndex]
      const activeProducts = this.period.activeProductsByMonth[monthIndex]
      this.tooltipTitle = `${this.product.code} ${this.product.name}`
      this.tooltipSubTitle = `Ranking for ${payload.xLabel}`
      this.tooltipGuageDef.caption =
        ranking < 99999 ? `${ranking} of ${activeProducts}` : 'No ranking'
      this.tooltipGuageDef.value =
        ranking < 99999 ? activeProducts - ranking + 1 : 0
      this.tooltipGuageDef.maxValue = activeProducts
    },

    chartTooltipClose() {
      this.displayRanking(
        '',
        this.product.ranking,
        this.numberRankedProductsPeriod
      )
    },
  },

  data() {
    const period = db.cached('period')
    return {
      rankingCaption: '',
      numberRankedProductsPeriod: 0,

      period,

      displayBoxDef: pcDisplayBoxDef('rankingChart', 'Product ranking', {
        height: '50%',
        contentPadding: 'pt-3 pb-0 pl-3 pr-3',
      }),

      guageDef: pcHorizontalGuageDef(pc.uid(), '', {
        padding: 'pt-0 pb-0 pl-3 pr-3',
      }),

      chartDef: pcChartDef2('line')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['customTooltip', this.chartTooltip],
          //['customTooltipClose', this.chartTooltipClose],
        ])
        .setCard([['subTitleClass', 'mt-0 pt-0 grey lighten-5']])
        .setOptions([['scales.y.reverse', true]])
        .setOptions([['scales.y.suggestedMin', 1]])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      tooltipCardDef: pcCardDef(`chartTooltip${pc.uid()}`, false, {
        elevation: 4,
        titleClass:
          'body-2 font-weight-bold justify-center pl-2 pr-2 blue lighten-2',
        subTitleClass: 'caption font-weight-bold justify-center pl-2 pr-2',
        width: '300px',
        height: '100px',
        cardClass: 'grey lighten-5 pb-1',
        //chartClass: 'pl-1 pr-1 pb-1 grey lighten-5',
      }),

      tooltipTitle: '',
      tooltipSubTitle: '',
      tooltipRankingCaption: '',
      tooltipGuageDef: pcHorizontalGuageDef(pc.uid(), '', {
        width: '100%',
      }),
    }
  },
}
</script>

<style scoped></style>
