<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-chart :chartDef="chartDef">
        <template v-slot:tooltip>
          <pc-card :cardDef="tooltipCardDef">
            <template v-slot:title>
              {{ tooltipTitle }}
            </template>
            <template v-slot:subTitle>
              {{ tooltipSubTitle }}
            </template>
            <template v-slot:text>
              <pc-simple-table :simpleTableDef="tooltipSimpleTableDef" />
            </template>
          </pc-card>
        </template>
      </pc-chart>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'

import pcCard from '@pcComponents/pcCard.vue'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'

export default {
  name: 'contactGroupChart',

  components: {
    pcCard,
    pcChart,
    pcDisplayBox,
    pcSimpleTable,
  },

  props: {
    sales30DayData: Object,
  },

  watch: {
    sales30DayData() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.updateChart(
        pc
          .query()
          .addSort('total', true)
          .run(this.sales30DayData.groups)
      )
    },

    async updateChart(groups) {
      this.chartDef
        .setLabels(
          groups.reduce((groups, group, index) => {
            if (index) groups.push(group.name)
            return groups
          }, [])
        )
        .addDataset(
          groups.reduce((groups, group, index) => {
            if (index) groups.push(group.total.toFixed(0))
            return groups
          }, []),
          'Sales'
        )
        .render()

      // Update group table
      this.contactGroups = this.chartDef.data.labels.map((name, index) => ({
        name,
        sales: this.chartDef.data.datasets[0].data[index],
        color: this.chartDef.data.datasets[0].backgroundColor[index],
      }))
    },

    tooltip(payload) {
      const group = this.sales30DayData.groups[payload.dataIndex + 1]
      const numberInvoices = pc.sumArrayProp(pc.prop('numberInvoices'), group)
      const titleColor = `background-color: ${
        this.chartColors[payload.dataIndex]
      };`
      const titleTextColor = pc.isDarkColor(titleColor)
        ? 'font-weight: bold; color: white; '
        : 'font-weight: bold; '
      this.tooltipCardDef.titleStyle = titleColor
      this.tooltipCardDef.titleClass =
        'body-2 justify-center pl-2 pr-2 ' + titleTextColor
      this.tooltipTitle = group.name
      this.tooltipSubTitle = `${group.days.length} days and ${numberInvoices} invoices`
      this.tooltipSimpleTableDef.data = [
        { text: 'Total sales', value: group.total },
        { text: 'Daily average', value: group.total / group.days.length },
        { text: 'Invoice average', value: group.total / numberInvoices },
      ]
    },
  },

  data() {
    return {
      period: db.cached('period'),
      contactGroups: [],

      displayBoxDef: pcDisplayBoxDef(
        'contactGroupDisplayBox',
        'Total sales by contact group',
        {
          height: '100%',
        }
      ),

      chartDef: pcChartDef2('pie')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),

      tooltipCardDef: pcCardDef('weekdayCard', false, {
        elevation: '2',
        cardClass: '',
        titleClass: 'body-2 justify-center pl-2 pr-2',
        subTitleClass: 'caption font-weight-bold justify-center pl-2 pr-2',
        width: '225px',
        height: '',
      }),

      tooltipTitle: '',
      tooltipSubTitle: '',

      tooltipSimpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        headers: false,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('text', 'text', ''),
          pcSimpleTableFieldDef('value', 'currency', '', {
            decimalPlaces: 2,
          }),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
