<template>
  <v-app id="whitespace" class="pc-scrollable pc-size100">
    <v-app-bar app clipped-right color="blue-grey" dark>
      <v-app-bar-nav-icon v-if="isUser" @click.stop="drawer = !drawer" />
      <v-toolbar-title>White Space</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          v-for="item in visibleMenuItems"
          :key="item.title"
          :to="item.to"
          @click="menuItemClicked(item)"
          >{{ item.title }}</v-btn
        >
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list dense class="pt-0">
        <v-list-item
          router
          v-for="item in visibleMenuItems"
          :key="item.title"
          :to="item.to"
          @click="menuItemClicked(item)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pc-scrollable" :style="contentStyle" v-resize="onResize">
      <v-container d-flex fill-height align="stretch" justify="center" fluid>
        <router-view v-on="$listeners"></router-view>
      </v-container>
    </v-main>

    <v-footer app color="blue-grey" class="white--text">
      <span>White Space from program coders - version: 1.03</span>
      <v-spacer />
      <span>&copy; 2022</span>
    </v-footer>
  </v-app>
</template>

<script>
/* ----------------------------- CHANGE HISTORY ----------------------------- */
/* ------------------------------ Version 1.01 ------------------------------ */
// Fixes Customer dashboard initial screen always showing turnover
// Fixes Customer dashboard when changing customer on category screen not updating list
/* ------------------------------ Version 1.02 ------------------------------ */
// Move firebase to server and add authentification
/* ------------------------------ Version 1.03 ------------------------------ */
// Added CSV export

import { mapGetters } from 'vuex'
import { clearUser } from '@appModules/user.js'
import pc from '@pc'

export default {
  name: 'App',

  created() {
    this.onResize()
  },

  beforeDestroy() {},

  computed: {
    visibleMenuItems() {
      return this.items.filter(item => item.show())
    },
    ...mapGetters(['isUser']),
  },

  methods: {
    onResize() {
      this.contentStyle = `height: ${window.innerHeight}px`
      pc.forceNextTick()
    },

    clearUser,
    menuItemClicked: function(item) {
      if (item.click) {
        this[item.click]()
      }
    },

    signout: function() {
      this.$emit('logout')
      if (this.isUser) {
        this.$store
          .dispatch('signoutUser', {
            meta: {},
            data: {
              email: '',
              password: '',
            },
          })
          .then(() => {
            this.clearUser()
          })
      }
      return true
    },
  },

  data() {
    return {
      contentStyle: '',
      drawer: false,
      clipped: false,
      fixed: false,
      items: [
        {
          show: () => this.isUser,
          icon: 'mdi-view-dashboard',
          title: 'Company',
          to: '/companyDashboard',
        },
        {
          show: () => this.isUser,
          icon: 'mdi-view-dashboard',
          title: 'Customer',
          to: '/customerDashboard',
        },
        {
          show: () => this.isUser,
          icon: 'mdi-view-dashboard',
          title: 'Product',
          to: '/productDashboard',
        },
        {
          show: () => this.isUser,
          icon: 'mdi-view-dashboard',
          title: 'Category',
          to: '/categoryDashboard',
        },
        {
          show: () => this.isUser,
          icon: 'mdi-view-dashboard',
          title: 'Contact Group',
          to: '/contactGroupDashboard',
        },
        {
          show: () => this.isUser,
          icon: 'mdi-file-tree',
          title: 'System',
          to: '/system',
        },
        {
          show: () => this.isUser,
          icon: 'mdi-logout',
          title: 'Logout',
          to: '/signout',
          click: 'signout',
        },
      ],
      miniVariant: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      showAppContent: true,
    }
  },
}
</script>

<style scoped>
.app-container {
  overflow: scroll;
}
</style>
