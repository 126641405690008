import pc from '@pc'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'

import { getColors } from '@pcModules/pcColors'

const pieOptions = () => ({
  cutout: '0%',
  elements: {
    center: {
      text: '',
      //color: '#FF6384', // Default is #000000
      fontStyle: 'Roboto', // Default is Arial
      sidePadding: 20, // Default is 20 (as a percentage)
      minFontSize: 15, // Default is 20 (in px), set to false and text will not wrap.
      lineHeight: 25, // Default is 25 (in px), used for when text wraps
    },
  },
})

const barOptions = () => ({
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
})

const lineOptions = () => ({
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
})

const chartTypeOptions = chartType => {
  const index = ['pie', 'bar', 'line'].indexOf(chartType)
  return index === -1 ? {} : [pieOptions, barOptions, lineOptions][index]()
}

const getChartDef = type => {
  const domId = pc.uid()
  return {
    chart: {
      type: type,
      show: true,
      domId: pc.uid(),
      containerClass: '',
      width: '100%',
      height: '100%',
      onClick: null,
      tooltipCallbacks: '',
      tooltipSparkChart: false,
      customTooltip: null,
      customTooltipClose: null,
      customTooltipSlotName: '',
      gradient: false,
    },
    card: {
      ...pcCardDef(`chart-card${domId}`, false, {
        elevation: 0,
      }),
    },
    sparkChart: {},
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          font: {
            size: 18,
          },
          text: '',
        },
        legend: {
          display: true,
          onHover: null,
        },
        tooltip: {
          enabled: true,
          external: null,
          callbacks: {},
          backgroundColor: '#808080',
          titleColor: '#FFFFFF',
          titleFont: {
            size: 16,
          },
          bodyColor: '#FFFFFF',
          bodyFont: {
            size: 14,
          },
        },
      },
      ...chartTypeOptions(type),
    },
    pc: {},
  }
}

/** ---------------------------------------------------------------------------
 * Class representing a chart
 * @param {string} type chart type ('bar', 'line', 'pie')
 * @return {chartDef}
 */
class ChartDef {
  constructor(type) {
    const def = getChartDef(type)
    this.chart = def.chart
    this.card = def.card
    this.sparkChart = def.sparkChart
    this.data = def.data
    this.options = def.options
    this.pc = def.pc
    this.chart.gradient = def.chart.gradient
    this.chartInstance = undefined
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setProperties :: (string, [[string, a]]) -> class
   * @desc Sets properties in the chartDef class
   * @param {string} section Section of the chartDef to be updated ('chart', 'card', 'sparkChart', 'options', 'data')
   * @param {array[]} properties Array of properties to set
   * @param {array[]} properties.property A property be to set
   * @param {string} properties.property.path Property path e.g. 'data.datasets'
   * @param {*} properties.property.value Value to set the property
   * @return {class} chartDef instance to chain next action
   */
  setProperties(section, properties) {
    this[section] = pc.setProperties(properties, this[section])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setTitle :: (string, boolean, number, array) -> class
   * @desc Sets the title properties and optionally other title properties
   * @param {string} title Chart title
   * @param {boolean} [show=true] Show the title
   * @param {number} [fontSize=18] Fontsize of the titel
   * @param {array[]} [properties=[]] Array of properties to set
   * @param {array[]} properties.property A property be to set
   * @param {string} properties.property.path Property path e.g. property name without 'plugins.title'
   * @param {*} properties.property.value Value to set the label property
   * @return {class} chartDef instance to chain next action
   */
  setTitle(title, show = true, fontSize = 18, properties = []) {
    this.setOptions([
      ...[
        ['plugins.title.text', title],
        ['plugins.title.display', show],
        ['plugins.title.font.size', fontSize],
      ],
      ...properties.map(property => {
        const path = `plugins.title.${property[0]}`
        return [path, property[1]]
      }),
    ])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setLegend :: (string, boolean) -> class
   * @desc Sets the legend display property
   * @param {boolean} [show=true] Show the title
   * @return {class} chartDef instance to chain next action
   */
  setLegend(show) {
    this.setOptions([['plugins.legend.display', show]])
    return this
  }
  /** ---------------------------------------------------------------------------
   * @summary setLabels :: (array) -> class
   * @desc Sets the chart labels
   * @param {array[]} labels An array of label strings
   * @return {class} chartDef instance to chain next action
   */
  setLabels(labels) {
    this.setData([['labels', labels]])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary dataset :: (object) -> class
   * @desc Pushes a new dataset object to the chart datasets
   * @param {object} dataset A dataset object - see newDataset
   * @return {class} chartDef instance to chain next action
   */
  dataset(dataset) {
    this.data.datasets.push(dataset)
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setChart :: (array) -> class
   * @desc Sets properties in the 'chart' section
   * @param {array} properties An array of properties to set
   * @return {class} chartDef instance to chain next action
   */
  setChart(properties) {
    return this.setProperties('chart', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setCard :: (array) -> class
   * @desc Sets properties in the 'card' section
   * @param {array} properties An array of properties to set
   * @return {class} chartDef instance to chain next action
   */
  setCard(properties) {
    return this.setProperties('card', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setSparkChart :: (array) -> class
   * @desc Sets properties in the 'sparkChart' section
   * @param {array} properties An array of properties to set
   * @return {class} chartDef instance to chain next action
   */
  setSparkChart(sparkChartDef) {
    this.sparkChart = sparkChartDef
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setData :: (array) -> class
   * @desc Sets properties in the 'data' section
   * @param {array} properties An array of properties to set
   * @return {class} chartDef instance to chain next action
   */
  setData(properties) {
    return this.setProperties('data', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setOptions :: (array) -> class
   * @desc Sets properties in the 'optionst' section
   * @param {array} properties An array of properties to set
   * @return {class} chartDef instance to chain next action
   */
  setOptions(properties) {
    return this.setProperties('options', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setPc :: (array) -> class
   * @desc Sets properties in the 'pc' section - this section is for general data
   * @param {array} properties An array of properties to set
   * @return {class} chartDef instance to chain next action
   */
  setPc(properties) {
    return this.setProperties('pc', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary show ::  -> boolean - returns or sets the chart show status
   */
  get show() {
    return this.chart.show
  }

  set show(visible) {
    this.setChart([['show', !!visible]])
    this.setCard([['show', !!visible]])
  }

  /** ---------------------------------------------------------------------------
   * @summary clearDatasets :: () -> class
   * @desc Clears all the datasets
   * @return {class} chartDef instance to chain next action
   */
  clearDatasets() {
    this.setData([['datasets', []]])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary newDataset :: () -> object
   * @desc Returns a dataset object - add to the chart with the datset method
   * @return {object} Datset object
   */
  newDataset() {
    return {
      type: this.chart.type,
      label: '',
      backgroundColor: [],
      data: [],
      fill: false,
    }
  }

  /** ---------------------------------------------------------------------------
   * @summary addDataset :: (data, label, colors, properties) -> object
   * @desc Creates and adds a new dataset to the datasets array
   * @param {array} data Dataset data
   * @param {string} [label=''] Label for dataset
   * @param {number|array} [colors=impact] A single color code, gradient of array or either
   * @param {array} [properties=[]] Other dataset properties
   * @return {class} chartDef instance to chain next action
   */
  addDataset(data, label = '', colors = getColors('impact'), properties = []) {
    this.data.datasets.push(
      pc.setProperties(
        [
          ...[
            ['data', data],
            ['label', label],
            ['backgroundColor', colors],
            ['borderColor', colors],
          ],
          ...properties,
        ],
        this.newDataset
      )
    )
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary getDataset :: (number) -> object
   * @desc Returns the indexed dataset
   * @param {number} index Dataset index
   * @return {object} The dataset object
   */
  getDataset(index) {
    return this.data.datasets[index]
  }

  get hiddenDatasets() {
    return !!this.data.datasets.find(dataset => dataset.hidden)
  }

  instance = function(chartInstance) {
    this.chartInstance = chartInstance
  }.bind(this)

  hasHidden() {
    const pie = () => !!Object.keys(this.chartInstance._hiddenIndices).length
    const other = () =>
      !!this.chartInstance.data.datasets.find(dataset => dataset.hidden)
    return this.chart.type === 'pie' ? pie() : other()
  }

  toggleHidden() {
    if (this.chart.type === 'pie') {
      const hide = () =>
        this.data.datasets[0].data.reduce((hiddenIndices, _, index) => {
          hiddenIndices['' + index] = true
          return hiddenIndices
        }, {})
      const show = () => ({})
      this.chartInstance._hiddenIndices = this.hasHidden() ? show() : hide()
      this.update()
    } else {
      const hidden = this.hasHidden()
      this.data.datasets.map(dataset => (dataset.hidden = !hidden))
      this.render()
    }
  }

  /** ---------------------------------------------------------------------------
   * @summary render :: () -> object
   * @desc Emits a render event to the chart
   * @return {class} chartDef instance to chain next action
   */
  render() {
    pc.$eventBus.$emit(`chart-${this.chart.domId}`, {
      event: 'render',
      data: {},
    })
    return this
  }

  update() {
    pc.$eventBus.$emit(`chart-${this.chart.domId}`, {
      event: 'update',
      data: {},
    })
    return this
  }
}

export const pcChartDef2 = type => new ChartDef(type)
