<template>
  <v-menu
    v-if="dropdownDef.show"
    content-class="ma-0 pa-0"
    :activator="dropdownDef.activatorElement"
    :top="dropdownDef.top"
    :bottom="dropdownDef.bottom"
    :left="dropdownDef.left"
    :right="dropdownDef.right"
    v-model="isActive"
  >
    <v-list :dense="dropdownDef.dense">
      <v-list-item v-for="(item, index) in dropdownDef.items" :key="index" @click="item.callback(item.id, dropdownDef.payload)">
        <v-list-item-icon class="mr-3" v-if="item.icon !== ''">
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'pcDropdown',
  props: {
    dropdownDef: Object,
  },
  computed: {
    defShow() {
      return this.dropdownDef.show; // Only required so we can watch for changes
    },
  },
  watch: {
    defShow(value) {
      this.isActive = value;
    },
    isActive(value) {
      if (!value) this.$emit('close'); // update dropdownDef
    },
  },
  data: () => ({
    isActive: false,
  }),
};
</script>

<style></style>
