<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-simple-table
        :simpleTableDef="simpleTableDef"
        @tooltip="tooltip"
        @fieldclick="showCustomerDashboard"
      >
        <template v-slot:sales>
          <pc-spark-chart :sparkChartDef="sparkChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import { getColors } from '@pcModules/pcColors.js'

export default {
  name: 'productCustomersNotBoughtProductInPeriodList',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {
    top10Data: Object,
  },

  created() {
    this.main()
  },

  computed: {
    title() {
      return `Top 10 bought product but not in ${this.top10Data.periodCaption}`
    },
  },

  watch: {
    top10Data() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.displayBoxDef.title = this.title
      this.simpleTableDef.data = pc
        .query()
        .addLimit(10)
        .run(this.top10Data.productCustomers.previouslyBought)
    },

    tooltip(payload) {
      const { data } = payload
      const titleColor = pc.getProperty('tag', data)

      this.sparkChartDef
        .setTitle(data.customerName)
        .setSubTitle(`Previous sales £${pc.toFixed2(data.sales)}`)
        .clearDatasets()
        .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales', titleColor)
        .render()
    },

    showCustomerDashboard(payload) {
      this.$emit('showCustomerDashboard', {
        customerId: payload.data.customerId,
        from: 'productDashboard',
        screen: 'top10',
      })
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'productCustomersNotBoughtProductInPeriodList',
        ''
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        fields: [
          pcSimpleTableFieldDef('customerName', 'text', 'Customer'),
          pcSimpleTableFieldDef('sales', 'currency', 'Previous sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last invoiced'
          ),
          pcSimpleTableFieldDef('', 'action', '', {
            icon: 'mdi-view-dashboard',
            click: 'click',
            tooltipText: 'View customer dashboard',
            tooltipPosition: 'left',
          }),
        ],
      }),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
