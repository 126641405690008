import { gql } from 'graphql-tag'

const categoryCustomers = gql`
  query($dbQuery: DbQuery) {
    categoryCustomers(dbQuery: $dbQuery) {
      categoryId
      sales
      lastInvoiced
      salesByMonth
    }
  }
`

const productCustomers = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      productCode
      quantity
      sales
      lastInvoiced
      quantityByMonth
      salesByMonth
    }
  }
`

const categoryCustomersAndProductCustomers = gql`
  query($dbQuery: DbQuery) {
    multiQuery(dbQuery: $dbQuery) {
      categoryCustomers(dbQuery: $dbQuery) {
        categoryId
        sales
        lastInvoiced
        salesByMonth
      }
      productCustomers(dbQuery: $dbQuery) {
        productCode
        quantity
        sales
        lastInvoiced
        quantityByMonth
        salesByMonth
      }
    }
  }
`

const products = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      code
      name
      ranking
      tradePrice
      RRP
      category {
        level1
        level2
        level3
      }
    }
  }
`

const productInvoiceLines = gql`
  query($dbQuery: DbQuery) {
    invoiceLines(dbQuery: $dbQuery) {
      invoiceNumber
      date
      quantity
      price
      goods
    }
  }
`

const queries = {
  categoryCustomers,
  productCustomers,
  categoryCustomersAndProductCustomers,
  products,
  productInvoiceLines,
}

export { queries as customerCategories }
