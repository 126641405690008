<template>
  <pc-card :card-def="cardDef">
    <template v-slot:title>
      <pc-toolbar :toolbar-def="toolbarDef" />
    </template>

    <template v-slot:text>
      <v-row class="pl-3 pt-3 fill-height" align="stretch">
        <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
          <v-treeview
            class="tree"
            activatable
            dense
            item-key="id"
            :open.sync="openItems"
            :items="items"
            return-object
            :active.sync="active"
          >
            <template v-slot:label="{ item }">
              <span
                :class="{ 'font-weight-bold': isValidDropTarget(item) }"
                v-on:dragenter="dragenter(item, $event)"
                v-on:dragover="dragover(item, $event)"
                v-on:dragleave="dragleave(item, $event)"
                v-on:drop="drop(item, $event)"
              >
                {{ item.name }}
              </span>
            </template>
            <template v-slot:append="{ item }">
              <div v-if="item.id === activeId">
                <v-btn
                  class="mr-0"
                  icon
                  @click.stop="action({ action: 'edit', item })"
                >
                  <v-icon color="accent">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-tooltip v-if="item.level < 3 && !item.hasProducts" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ml-0"
                      :class="{ 'mr-0': item.level > 0 }"
                      icon
                      @click.stop="action({ action: 'add', item })"
                    >
                      <v-icon color="accent">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add sub category in {{ item.name }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.level && !item.children.length" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ml-0"
                      icon
                      @click.stop="action({ action: 'delete', item })"
                    >
                      <v-icon color="accent">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete category {{ item.name }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-treeview>
        </v-col>
      </v-row>
    </template>
  </pc-card>
</template>

<script>
//import pc from '@pc'
import { validDropTarget } from './validDropTarget.js'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
export default {
  name: 'categoryDropTree',

  components: {
    pcCard,
    pcToolbar,
  },

  props: {
    categoryTree: Array,
    dragstart: Object,
    dragend: Object,
  },

  created() {
    this.initialise()
  },

  watch: {
    active(newValue) {
      this.activeId =
        newValue.length && newValue[0].isCategory && !newValue[0].isSystem
          ? newValue[0].id
          : ''
    },

    dragstart() {
      this.isDragging = true
    },

    dragend() {
      this.isDragging = false
    },

    categoryTree() {
      this.initialise()
    },
  },

  methods: {
    initialise() {
      this.active = []
      this.items = this.categoryTree
      this.openItems = [this.categoryTree[0]]
    },

    main() {},

    isValidDropTarget(item) {
      return this.isDragging
        ? validDropTarget(item, this.dragstart.dragItem)
        : false
    },

    // Triggered on drop zone
    dragenter: function(item, e) {
      e.preventDefault()
    },

    // Triggered on drop zone
    dragleave: function(item, e) {
      e.preventDefault()
    },

    // Triggered on drop zone
    dragover: function(item, e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'move'
    },

    // Triggered on drop zone
    drop: function(dropItem, e) {
      e.preventDefault()
      if (!this.isDragging) return
      this.isDragging = false
      if (!validDropTarget(dropItem, this.dragstart.dragItem)) return
      this.$emit('processDrag', {
        dropItem,
        dragItem: this.dragstart.dragItem,
        activeItems: this.dragstart.activeItems,
      })
    },

    action(payload) {
      this.$emit('action', payload)
    },
  },

  data() {
    return {
      items: [],
      active: [],
      openItems: [],
      open: ['category'],
      activeId: '',
      isDragging: false,

      cardDef: pcCardDef('drop', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef('dropToolbar', 'Drop zone', {
        dense: true,
        color: 'primary lighten-2',
        actions: [],
      }),
    }
  },
}
</script>

<style scoped>
.tree {
  height: 100%;
  width: 100%;
  border-right: 1px solid black;
  overflow-y: scroll;
}
</style>
