import * as R from 'ramda'

const _ramdaPath = path =>
  path
    .replaceAll('[', '.')
    .split('.')
    .map(prop => (prop.charAt(prop.length - 1) === ']' ? parseInt(prop) : prop))

export const object = (name, content = {}) => {
  let _object = { ...content }
  const api = {
    [name + 'SetProp']: (propertyPath, value) =>
      setProperty(propertyPath, value, _object),
    [name + 'GetProp']: propertyPath => getProperty(propertyPath, _object),
    [name + 'HasProp']: propertyPath => isProperty(propertyPath, _object),
    [name + 'DeleteProp']: propertyPath =>
      console.log(`${propertyPath} - objectDelete not implemented`),
    [name + 'Entries']: () => _object.entries(),
    [name + 'ForEach']: fn =>
      _object.entries.forEach((property, index) => {
        fn(property[0], property[1], index, _object)
      }),
    [name + 'Keys']: () => _object.keys(),
    [name + 'Values']: () => _object.values(),
    [name + 'Merge']: content => (_object = { ..._object, ...content }),
    [name + 'Assign']: (content = {}) => (_object = { ...content }),
    [name + 'Object']: () => _object,
    [name + 'Api']: () => api,
  }
  return api
}

/** ---------------------------------------------------------------------------
 * @function isProperty
 * @summary isProperty :: string -> object -> boolean
 * @desc returns true if the property path exists, false otherwise
 * @param {string} propertyPath e.g 'graph.options.color'
 * @param {object} object The data object containing the property
 * @returns {boolean} true if path exists, otherwise false
 */
export const isProperty = R.curry((path, object) => {
  if (path && typeof path === 'string') {
    let obj = object
    return path.split('.').reduce((result, property) => {
      if (result) {
        result = Object.prototype.hasOwnProperty.call(obj, property)
        if (result) obj = obj[property]
      }
      return result
    }, true)
  } else {
    console.log(
      `pc.isProperty received invalid path: ${path} for object: ${object.stringify}`
    )
    return false
  }
})

/** ---------------------------------------------------------------------------
 * @function getProperty
 * @summary getProperty :: string -> object -> a
 * @desc returns the value from the object at the property path
 * @param {string} propertyPath e.g 'graph.options.color' - used Ramda so can reference arrays - see Ramda path
 * @param {object} object The data object containing the property
 * @returns {any} the value at the path or undefined if path does not exist
 */
export const getProperty = R.curry((propertyPath, object) =>
  R.path(_ramdaPath(propertyPath), object)
)

/** ---------------------------------------------------------------------------
 * @function setProperty
 * @summary setProperty :: string -> a -> object -> object
 * @desc returns a copy of the object with the property set
 * @param {string} propertyPath e.g graph.options.data.3.color - uses Ramda lens
 * @param {object} data The data object containing the property
 * @returns {object} a copy of object with property set
 */
export const setProperty = R.curry((propertyPath, value, object) => {
  const lens = R.lensPath(_ramdaPath(propertyPath))
  return R.set(lens, value, R.clone(object))
})

/** ---------------------------------------------------------------------------
 * @function setProperties
 * @summary setProperty :: [[string, a]] -> object -> object
 * @desc returns a copy of the object with each of the properties set
 * @param {array} properties [[propertyPath, value} properties e.g graph.options.data.3.color (uses Ramda lens) and value
 * @param {object} data The data object containing the property
 * @returns {object} a copy of object with properties set
 */
export const setProperties = R.curry((properties, object) =>
  properties.reduce(
    (obj, property) =>
      R.set(R.lensPath(property[0].split('.')), property[1], obj),
    R.clone(object)
  )
)

/** ---------------------------------------------------------------------------
 * @function mutSetProperty
 * @summary mutSetProperty :: string -> a -> object
 * @desc returns the object with the property set
 * @param {string} property property e.g graph.options.data.3.color (non Ramda lens)
 * @param {any} value The property value
 * @returns {object} object with properties set
 */
export const mutSetProperty = R.curry((propertyPath, value, object) => {
  const [head, ...rest] = propertyPath.split('.')

  !rest.length
    ? (object[head] = value)
    : mutSetProperty(rest.join('.'), value, object[head])
  return object
})

/** ---------------------------------------------------------------------------
 * @function mutSetProperties
 * @summary mutSetProperty :: [[string, a]] -> object -> object
 * @desc returns a the object with each of the properties set
 * @param {array} properties [[propertyPath, value} properties e.g graph.options.data.3.color (uses Ramda lens) and value
 * @param {object} data The data object containing the property
 * @returns {object} the object with properties set
 */
export const mutSetProperties = R.curry((properties, object) => {
  properties.forEach(property => {
    mutSetProperty(property[0], property[1], object)
  })
  return object
})

/** ---------------------------------------------------------------------------
 * @function pickAndRename
 * @summary pickAndRename :: array[string] -> object -> object
 * @desc returns an object of the picked and, optionally renamed, properties from the object
 * @param {array} of property paths and or paths and rename in format property:renamedProperty - Ramda spec
 * @param {object} object The object to extract the properties
 * @returns {object} tthe object containing the picked properties
 */
export const pickAndRename = R.curry((properties, object) =>
  properties.reduce((result, property) => {
    const properties = property.split(':')
    return setProperty(
      R.trim(properties.length === 1 ? properties[0] : properties[1]),
      getProperty(R.trim(properties[0]), object),
      result
    )
  }, {})
)

/** ---------------------------------------------------------------------------
 * @function cloneJSON
 * @summary cloneJSON :: object -> object
 * @desc returns a cloned copy of the object using Ramda (no longer JSON) cloning
 * @param {object} object The object to be cloned
 * @returns {object} the cloned copy
 */
export const cloneJSON = obj => R.clone(obj)

/** ---------------------------------------------------------------------------
 * @function objectFromArray
 * @summary objectFromArray :: ((a, b) -> c) [d] -> {c: d}
 * @desc Creates an object where each propery is an element of the array. Property names being supplied by fn
 * @param {function} fn function that receivies the array element and index and returns the property name
 * @param {array} arr array to be lifted into the object
 * @return {object} The result of lifting the array into the object
 */
export const objectFromArray = R.curry((fn, arr) =>
  arr.reduce((object, element, index) => {
    object[fn(element, index)] = element
    return object
  }, {})
)

export default {
  object,
  isProperty,
  getProperty,
  setProperty,
  setProperties,
  mutSetProperty,
  mutSetProperties,
  pickAndRename,
  objectFromArray,
  cloneJSON,
}
