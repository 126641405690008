<template>
  <v-row
    v-if="summaryDef.show"
    id="summary"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-3 pl-6 pb-0">
      <div class="grid-container pb-0">
        <div class="statistics">
          <contact-group-statistics :group="group" />
        </div>
        <div class="sales-chart">
          <contact-group-sales-chart :group="group" />
        </div>
        <div class="active-customers-chart">
          <contact-group-top-10-customers :group="group" v-on="$listeners" />
        </div>
        <div class="contact-group-chart">
          <contact-group-sales-by-category-chart :group="group" />
        </div>
        <div class="outstanding">
          <contact-group-outstanding :group="group" />
        </div>
        <div class="sales-category-chart">
          <contact-group-top-10-products :group="group" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'

import contactGroupStatistics from './contactGroupStatistics.vue'
import contactGroupSalesChart from './contactGroupSalesChart.vue'
import contactGroupSalesByCategoryChart from './contactGroupSalesByCategoryChart.vue'
import contactGroupOutstanding from './contactGroupOutstanding.vue'
import ContactGroupTop10Customers from './contactGroupTop10Customers.vue'
import ContactGroupTop10Products from './contactGroupTop10Products.vue'

export default {
  name: 'contactGroupSummary',

  components: {
    contactGroupStatistics,
    contactGroupSalesChart,
    contactGroupSalesByCategoryChart,
    contactGroupOutstanding,
    ContactGroupTop10Customers,
    ContactGroupTop10Products,
  },

  props: {
    summaryDef: Object,
  },

  computed: {
    group() {
      return this.summaryDef.contactGroup
    },
  },

  watch: {},

  data() {
    return {
      companySummaryCardDef: pcCardDef('companySummary', false, {
        loaderType: 'card',
        elevation: '0',
      }),

      companySummaryToolbarDef: pcToolbarDef(
        'companySummaryToolbar',
        'Company summary',
        {
          dense: true,
          color: 'primary lighten-2',
        }
      ),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas: 'statistics' 'outstanding' 'sales-chart' 'active-customers-chart' 'contact-group-chart' 'sales-category-chart';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(100px, 50%));
    grid-template-rows: repeat(3, 100%);
    grid-template-areas: 'statistics outstanding' 'sales-chart active-customers-chart' 'contact-group-chart sales-category-chart';
  }
}
@media only screen and (min-width: 1300px) {
  .grid-container {
    grid-template-columns: 475px repeat(2, minmax(100px, 1fr));
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'statistics sales-chart active-customers-chart'
      'outstanding contact-group-chart sales-category-chart';
  }
}
.statistics {
  grid-area: statistics;
  overflow-y: auto;
}
.sales-chart {
  grid-area: sales-chart;
  overflow-y: auto;
}
.active-customer-chart {
  grid-area: active-customers-chart;
  overflow-y: auto;
}
.outstanding {
  grid-area: outstanding;
  overflow-y: auto;
}
.contact-group-chart {
  grid-area: contact-group-chart;
  overflow-y: auto;
}
.sales-category-chart {
  grid-area: sales-category-chart;
  overflow-y: auto;
}
</style>
