<template>
  <v-navigation-drawer width="300px" permanent :mini-variant.sync="mini">
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-file-find-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Find product</v-list-item-title>
      </v-list-item-content>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <pc-lister :list-def="findProductDef" :list-items="products">
      <template v-slot:subTitle>
        <v-row>
          <v-col cols="12" class="pt-3 pb-0 pl-6 pr-6">
            <v-select
              :items="productCriteriaItems"
              v-model="productCriteria"
              label="Products"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0 pl-6 pr-6">
            <v-text-field
              class="pt-0"
              v-model="findProductDef.table.search"
              clearable
              placeholder="Search"
              single-line
            ></v-text-field>
            <div class="pb-6">
              Showing {{ products.length }} product{{
                products.length !== 1 ? 's' : ''
              }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.native="createCSV">
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </template>
                Create CSV file
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>
    </pc-lister>
  </v-navigation-drawer>
</template>

<script>
import pc from '@pc'
import db from '@db'
import pcLister from '@pcComponents/pcLister.vue'

import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { LAST_INVOICED_SINCE_DAYS } from '@appModules/ws.js'

export default {
  name: 'findProduct',

  components: {
    pcLister,
  },

  props: {
    product: Object,
  },

  created() {
    this.initialise()
    this.main()
  },

  computed: {
    isProduct() {
      return this.product.code
    },
  },

  watch: {
    mini(value) {
      this.findProductDef.cardDef.show = !value
    },

    productCriteria() {
      this.main()
    },
  },

  methods: {
    initialise() {
      // If no product open then find product drawer
      this.mini = this.isProduct ? true : false
    },

    main() {
      // Build the query args based on the find product options selected
      const dbQueryArg = db.cachedQuery()

      if (this.productCriteria !== 'All') {
        // Deal with the not bought options
        if (this.productCriteria === 'notBought') {
          dbQueryArg.addFilter('lastInvoiced', '')
        } else {
          const calcLastInvoicedDate = new Date(this.period.periodEndDate)
          calcLastInvoicedDate.setDate(
            calcLastInvoicedDate.getDate() - Number(this.productCriteria)
          )
          dbQueryArg.addFilter('lastInvoiced', '', 'ne')
          dbQueryArg.addFilter(
            'lastInvoiced',
            calcLastInvoicedDate.toISOString(),
            'lt'
          )
        }
      }

      this.products = db.cached('products', dbQueryArg)
    },

    productSelected(payload) {
      this.$emit('product', payload.data)
    },

    findProductClose() {
      this.mini = !this.mini
    },

    createCSV() {
      pc.csvFromLister(this.findProductDef, this.products)
    },
  },

  data() {
    return {
      period: db.cached('period'),
      mini: true,
      contactGroupItems: [],
      contactGroup: 'All',

      productCriteriaItems: [
        { text: 'All', value: 'All' },
        {
          text: `not bought for ${LAST_INVOICED_SINCE_DAYS[2]} days`,
          value: LAST_INVOICED_SINCE_DAYS[2].toString(),
        },
        {
          text: `not bought for ${LAST_INVOICED_SINCE_DAYS[1]} days`,
          value: LAST_INVOICED_SINCE_DAYS[1].toString(),
        },
        {
          text: `not bought for ${LAST_INVOICED_SINCE_DAYS[0]} days`,
          value: LAST_INVOICED_SINCE_DAYS[0].toString(),
        },
      ],
      productCriteria: 'All',

      products: undefined,

      findProductDef: {
        cardDef: pcCardDef('findProduct', false, { show: false }),
        table: pcTableDef('findProductTable', 'id', ['code'], [false], {
          compressed: true,
          fontSize: 'medium',
          rowClick: this.productSelected,
        }),
        fields: [
          pcFieldDef('code', 'text', '', 'Code', true),
          pcFieldDef('name', 'text', '', 'Name', true),
          pcFieldDef('ranking', 'ranking', ``, 'Ranking', false, {
            csvOnly: true,
          }),
          pcFieldDef('quantity', 'number', ``, 'Total quantity', false, {
            csvOnly: true,
          }),
          pcFieldDef('sales', 'currency', ``, 'Total sales', false, {
            csvOnly: true,
          }),
          pcFieldDef(
            'activeCustomers',
            'number',
            ``,
            'Total active customers',
            false,
            { csvOnly: true }
          ),
          pcFieldDef('lastInvoiced', 'ISODate', ``, 'Last invoiced', false, {
            csvOnly: true,
          }),
        ],
      },
    }
  },
}
</script>
<style scoped></style>
