import pc from '@pc'
import db from '@db'

async function getData(category) {
  const categorySales = await db.request(
    db.query('categoryDashboard.category'),
    db.args(db.queryArgs('categories').addFind('id', category.id))
  )

  const products = categorySales.salesByProduct
  const contactGroupCategories = categorySales.salesByContactGroup
  const categoryCustomers = categorySales.salesByCustomer

  const subCategories = db.cached(
    'categories',
    db
      .cachedQuery()
      .addFilter('parentId', category.id)
      .addSort('sales', true)
  )

  return {
    period: db.cached('period'), // The period record
    groups: db.cached('contactGroups', db.cachedQuery().addClone()), // contactGroup records
    category, // The category being viewed
    products, // The products relating to the cateegory
    contactGroupCategories, // ContactGroup category sales records
    categoryCustomers, // categoryCustomer sales records
    subCategories, // The sub categories relating to the category
  }
}

function addCompanyRecordToGroups(data) {
  const { period, category, groups } = data

  const company = {
    id: 'company',
    name: 'Company',
    sales: category.sales,
    salesByDays: [...category.salesByDays],
    salesByMonth: [...category.salesByMonth],
    activeCustomers: period.activeCustomers,
    activeCustomersByDays: [...period.activeCustomersByDays],
    activeCustomersByMonth: [...period.activeCustomersByMonth],
    bought: category.activeCustomers,
    boughtByDays: [...category.activeCustomersByDays],
    boughtByMonth: [...category.activeCustomersByMonth],
    invoices: category.invoices,
    invoicesByDays: [...category.invoicesByDays],
    invoicesByMonth: [...category.invoicesByMonth],
  }

  return {
    ...data,
    groups: [company, ...groups],
  }
}

function updateGroupsWithContactGroupCategoryRecords(data) {
  const { period, groups, contactGroupCategories } = data

  const replaceSalesWithContactGroup = (group, contactGroupCategory) => {
    return {
      ...group,
      sales: contactGroupCategory.sales,
      salesByDays: [...contactGroupCategory.salesByDays],
      salesByMonth: [...contactGroupCategory.salesByMonth],
      bought: contactGroupCategory.activeCustomers,
      boughtByDays: [...contactGroupCategory.activeCustomersByDays],
      boughtByMonth: [...contactGroupCategory.activeCustomersByMonth],
      invoices: contactGroupCategory.invoices,
      invoicesByDays: [...contactGroupCategory.invoicesByDays],
      invoicesByMonth: [...contactGroupCategory.invoicesByMonth],
    }
  }

  const replaceSalesWithZero = (group, period) => ({
    ...group,
    sales: 0,
    salesByDays: pc.createArray(0, 5),
    salesByMonth: pc.createArray(0, period.monthsInPeriod),
    activeCustomers: 0,
    activeCustomersByDays: pc.createArray(0, 5),
    activeCustomersByMonth: pc.createArray(0, period.monthsInPeriod),
    bought: 0,
    boughtByDays: pc.createArray(0, 5),
    boughtByMonth: pc.createArray(0, period.monthsInPeriod),
    invoices: 0,
    invoicesByDays: pc.createArray(0, 5),
    invoicesByMonth: pc.createArray(0, period.monthsInPeriod),
  })

  const updateSalesFromContactGroupCategory = group => {
    if (group.id === 'company') return group
    const contactGroupCategory = contactGroupCategories.find(
      contactGroupCategory => contactGroupCategory.contactGroupId === group.id
    )
    return contactGroupCategory
      ? replaceSalesWithContactGroup(group, contactGroupCategory)
      : replaceSalesWithZero(group, period)
  }

  return {
    ...data,
    groups: groups.map(updateSalesFromContactGroupCategory),
  }
}

function addDerivedFieldsToGroups(data) {
  const { groups } = data

  const addFields = group => ({
    ...group,
    customerAverage: pc.safeDivide(group.sales, group.activeCustomers),
    customerAverageByDays: group.salesByDays.map((sales, index) =>
      pc.safeDivide(sales, group.activeCustomersByDays[index])
    ),
    customerAverageByMonth: group.salesByMonth.map((sales, index) =>
      pc.safeDivide(sales, group.activeCustomersByMonth[index])
    ),
    invoiceAverage: pc.safeDivide(group.sales, group.invoices),
    invoiceAverageByDays: group.salesByDays.map((sales, index) =>
      pc.safeDivide(sales, group.invoicesByDays[index])
    ),
    invoiceAverageByMonth: group.salesByMonth.map((sales, index) =>
      pc.safeDivide(sales, group.invoicesByMonth[index])
    ),
    notBought: group.activeCustomers - group.bought,
    boughtPercentage: pc.percentage(group.bought, group.activeCustomers),
    notBoughtPercentage: pc.percentage(
      group.activeCustomers - group.bought,
      group.activeCustomers
    ),
  })

  return {
    ...data,
    groups: groups.map(addFields),
  }
}

function addStatisticsToGroups(data) {
  const { period, groups } = data

  const addStatisticsToGroup = group => {
    const addMonthsStatisticsToGroup = (monthKey, monthIndex) => ({
      month: pc.monthKeyName('mmm yy', monthKey),
      sales: group.salesByMonth[monthIndex],
      numberCustomers: group.boughtByMonth[monthIndex],
      customerAverage: group.customerAverageByMonth[monthIndex],
      numberInvoices: group.invoicesByMonth[monthIndex],
      invoiceAverage: group.invoiceAverageByMonth[monthIndex],
    })

    return {
      ...group,
      statistics: period.monthKeys.map(addMonthsStatisticsToGroup).reverse(),
    }
  }

  return {
    ...data,
    groups: groups.map(addStatisticsToGroup),
  }
}

export async function categoryDashboardComputeData(category) {
  const result = await pc.pipeAsync(
    getData,
    updateGroupsWithContactGroupCategoryRecords,
    addCompanyRecordToGroups,
    //addCustomersWhoBoughtToGroups,
    addDerivedFieldsToGroups,
    addStatisticsToGroups
  )(category)
  return result
}
