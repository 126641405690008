<template>
  <div
    v-if="horizontalGuageDef.if"
    v-show="horizontalGuageDef.show"
    :class="controlClass"
    :style="controlStyle"
  >
    <div
      v-if="showCaption"
      class="caption"
      :class="captionClass"
      style="line-height: 10px;"
      :style="captionStyle"
    >
      {{ horizontalGuageDef.caption }}
    </div>

    <div
      v-if="showCaption"
      style="line-height: 7px;"
      :style="captionArrowStyle"
    >
      <v-icon x-small>mdi-arrow-down-thick</v-icon>
    </div>

    <div :class="guageClass" style="width: 100%;" :style="guageStyle">
      <div :class="guageReadingClass" :style="guageReadingStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pcHorizontalGuage',

  props: {
    horizontalGuageDef: Object,
  },

  created() {},

  computed: {
    showCaption() {
      return this.horizontalGuageDef.caption
    },
    controlStyle() {
      return (
        this.styleProperty('width') +
        this.styleProperty('minWidth') +
        this.styleProperty('height') +
        this.styleProperty('minHeight')
      )
    },

    controlClass() {
      return this.classProperty('padding') + this.classProperty('margin')
    },

    captionClass() {
      const textAlign = this.normalisedValue < 51 ? ' text-left' : ' text-right'
      return (
        this.classProperty('captionColor') +
        this.classProperty('captionFont') +
        textAlign
      )
    },

    captionStyle() {
      return this.captionMargin
    },

    captionArrowStyle() {
      return this.captionArrowMargin
    },

    guageClass() {
      return (
        this.classProperty('backgroundColor') + this.classProperty('elevation')
      )
    },

    guageStyle() {
      return this.styleProperty('guageHeight', 'height')
    },

    guageReadingClass() {
      return this.classProperty('color')
    },

    guageReadingStyle() {
      return (
        this.styleProperty('guageHeight', 'height') +
        `width: ${this.normalisedValue}%;`
      )
    },

    normalisedMaxValue() {
      let maxValue = this.horizontalGuageDef.maxValue
      if (maxValue !== 100) {
        maxValue = Math.round(maxValue / (maxValue / 100))
      }
      return maxValue
    },

    normalisedValue() {
      let value = this.horizontalGuageDef.value
      let maxValue = this.horizontalGuageDef.maxValue
      if (value >= maxValue) value = maxValue
      if (maxValue !== 100) {
        if (value) value = Math.round(value / (maxValue / 100))
      }
      return value
    },

    captionMargin() {
      if (this.normalisedValue < 51) {
        return `margin-left: calc(${this.normalisedValue}% - 4px);`
      } else {
        return `margin-right: calc(${100 - this.normalisedValue}% - 4px);`
      }
    },

    captionArrowMargin() {
      return `margin-left: calc(${this.normalisedValue}% - 6px);`
    },
  },

  watch: {},

  methods: {
    classProperty(property) {
      const className = this.horizontalGuageDef[property]
      return className ? ` ${className}` : ''
    },

    styleProperty(property, cssName = '') {
      const style = this.horizontalGuageDef[property]
      cssName = cssName || property
      return style ? ` ${cssName}: ${style};` : ''
    },
  },

  data() {
    return {}
  },
}
</script>

<style scoped></style>
