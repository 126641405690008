<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChartType"
        value="pie"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Pie chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Stacked bar chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="line"
        icon="mdi-chart-line"
        small
        top
        tooltip="Line chart"
      />
      <pc-action-icon
        @click="toggleHidden"
        value=""
        icon="mdi-eye-off"
        small
        top
        tooltip="Show/Hide datasets"
      />
    </template>
    <template v-slot:default>
      <pc-chart
        :chartDef="pieChartDef"
        @instance="pieChartDef.instance"
        @tooltip="pieTooltip"
      />
      <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
      <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { getColors } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'salesByCategoryChart',

  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
  },

  props: {
    customer: Object,
    categories: Array,
  },

  created() {
    this.main()
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },

    displayedChartDef() {
      if (this.pieChartDef.show) return this.pieChartDef
      if (this.barChartDef.show) return this.barChartDef
      return this.lineChartDef
    },
  },

  watch: {
    categories() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.displayChartType('pie')
      this.updatePieChart(this.categories)
      this.updateBarAndLineChart(this.categories)
    },

    async updatePieChart(data) {
      const chartData = this.categories.reduce(
        (chartData, record) => {
          if (record.bought) {
            chartData.labels.push(record.name)
            chartData.values.push(record.listValue)
          }
          return chartData
        },
        { ids: [], labels: [], values: [] }
      )
      this.pieChartDef
        .clearDatasets()
        .setLabels(chartData.labels)
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .setPc([
          ['isCategory', data.isCategories],
          ['isProduct', data.isProducts],
          ['ids', chartData.ids],
        ])
        .addDataset(
          pc.arrayFixed2(chartData.values),
          'Turnover',
          this.chartColors
        )
        .render()
    },

    updateBarAndLineChart() {
      this.categories.forEach((category, index) => {
        if (category.sales) {
          this.barChartDef.addDataset(
            pc.arrayFixed2(category.salesByMonth),
            category.name,
            this.chartColors[index]
          )
          this.lineChartDef.addDataset(
            pc.arrayFixed2(category.salesByMonth),
            category.name,
            this.chartColors[index]
          )
        }
      })
    },

    displayChartType(type) {
      this.pieChartDef.show = type === 'pie'
      this.barChartDef.show = type === 'bar'
      this.lineChartDef.show = type === 'line'
    },

    toggleHidden() {
      this.displayedChartDef.toggleHidden()
    },

    pieTooltip(payload) {
      const data = this.categories[payload.dataIndex]
      const title = data.name
      const titleColor = pc.getProperty('tag', data)
      const subTitle = `Sales £${pc.toFixed2(data.sales)} - ${pc.toFixed2(
        data.percentage
      )}%`
      const values = data.salesByMonth

      this.sparkChartDef
        .clearDatasets()
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .addDataset(values, 'Sales', titleColor)

      this.pieChartDef.setSparkChart(this.sparkChartDef)
      this.sparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef(
        'salesByCategoryChart',
        'Sales by top level categories'
      ),

      pieChartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setOptions([['cutout', '50%']]),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
