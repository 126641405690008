<template>
  <v-app id="whitespace" class="pc-scrollable pc-size100">
    <landing v-if="isLanding" @login="login" />
    <whitespace v-if="isWhitespace" @logout="logout" />
  </v-app>
</template>

<script>
import pc from '@pc'
import Landing from '@appViews/landing/Landing.vue'
import Whitespace from '@appViews/whitespace.vue'
import { mapGetters } from 'vuex'

const checkLeave = vueData => {
  window.onbeforeunload = function() {
    return vueData.protectUnload ? vueData.protectUnloadMessage : undefined
  }
}

export default {
  name: 'App',

  components: {
    Landing,
    Whitespace,
  },

  created() {
    pc.$eventBus.$on('protectUnloadOn', this.protectUnLoadOn)
    pc.$eventBus.$on('protectUnloadOff', this.protectUnLoadOff)
    checkLeave(this.$data)
    this.$router.beforeEach(this.checkNavigation)
    // Replace root incase someone tries to start
    // from an old link such as /companyDashboard
    if (this.$route.path !== '/') this.$router.replace('/')
  },

  beforeDestroy() {
    pc.$eventBus.$on('protectUnloadOn', this.protectUnLoadOn)
    pc.$eventBus.$on('protectUnloadOff', this.protectUnLoadOff)
  },

  computed: {
    ...mapGetters(['isUser']),
  },

  methods: {
    checkNavigation(to, from, next) {
      //console.log(to.path, from.path)
      if (to.path === '/') return next(this.logout(false))
      if (!this.isUser) {
        if (to.path === '/signin' || to.path === '/signup')
          return next(this.login(false))
      }
      if (to.path === '/signin' || to.path === '/signup') return next(false)
      return next(true)
    },

    login(pushPath = true) {
      this.viewWhitespace()
      pushPath && this.$router.push('/signin')
      return true
    },

    logout(pushPath = true) {
      this.viewLanding()
      pushPath && this.$router.push('/')
      return true
    },

    viewLanding() {
      this.isWhitespace = false
      this.isLanding = true
    },

    viewWhitespace() {
      this.isWhitespace = true
      this.isLanding = false
    },

    protectUnLoadOn(message) {
      this.protectUnload = true
      this.protectUnloadMessage = message
    },

    protectUnLoadOff() {
      this.protectUnload = false
      this.protectUnloadMessage = ''
    },
  },

  data() {
    return {
      isLanding: true,
      isWhitespace: false,

      protectUnload: false,
      protectUnloadMessage: '',
    }
  },
}
</script>

<style>
.pc-width100 {
  width: 100%;
}
pc-height100 {
  height: 100%;
}
.pc-size100 {
  width: 100%;
  height: 100%;
}
.pc-flex {
  min-width: 0px;
  overflow-y: hidden;
}
.pc-col {
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}
.pc-scrollable {
  overflow: scroll;
}
.pointer {
  cursor: pointer;
}
.click-pointer {
  cursor: pointer;
}
</style>

<style scoped>
.app-container {
  overflow: scroll;
}
</style>
