<template>
  <div
    v-if="displayBoxDef.if"
    v-show="displayBoxDef.show"
    :class="controlClass"
    :style="controlStyle"
  >
    <div class="control-spacer"></div>
    <div class="body" :class="bodyClass">
      <div class="control-spacer"></div>
      <div class="title d-flex" :class="titleClass">
        <span>{{ displayBoxDef.title }}</span>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
      </div>
      <div class="content" :class="contentClass">
        <div class="pl-3 pr-3">
          <span v-html="displayBoxDef.contentLines"></span>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pcDisplayBox',

  props: {
    displayBoxDef: Object,
  },

  created() {},

  computed: {
    controlStyle() {
      return (
        this.styleProperty('width') +
        this.styleProperty('minWidth') +
        this.styleProperty('height') +
        this.styleProperty('minHeight')
      )
    },

    controlClass() {
      return this.classProperty('padding')
    },

    bodyClass() {
      return (
        this.classProperty('color') +
        this.classProperty('textColor') +
        this.classProperty('rounded') +
        this.classProperty('elevation') +
        this.classProperty('font') +
        this.classProperty('class')
      )
    },

    titleClass() {
      return (
        this.classProperty('titlePadding') +
        this.classProperty('titleColor') +
        this.classProperty('titleTextColor') +
        this.classProperty('titleRounded') +
        this.classProperty('titleElevation') +
        this.classProperty('titleFont') +
        this.classProperty('titleClass')
      )
    },

    contentClass() {
      return (
        this.classProperty('contentPadding') +
        this.classProperty('contentClass')
      )
    },
  },

  watch: {},

  methods: {
    classProperty(property) {
      const className = this.displayBoxDef[property]
      return className ? ` ${className}` : ''
    },

    styleProperty(property, cssName = '') {
      const style = this.displayBoxDef[property]
      cssName = cssName || property
      return style ? ` ${cssName}: ${style};` : ''
    },
  },

  data() {
    return {}
  },
}
</script>

<style scoped>
.control-spacer {
  width: 100%;
  height: 18px;
}
.title {
  width: calc(100% - 28px);
  height: 36px;
  margin-top: -36px;
  margin-left: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.body {
  height: calc(100% - 20px);
  width: 100%;
}
.content {
  width: 100%;
  height: calc(100% - 24px);
  overflow: scroll;
}
</style>
