import Vue from 'vue'
import VueRouter from 'vue-router'

import SignIn from '@appViews/SignIn.vue'
import SignUp from '@appViews/SignUp.vue'
import CompanyDashboard from '@appViews/companyDashboard/companyDashboard.vue'
import CustomerDashboard from '@appViews/customerDashboard/customerDashboard.vue'
import ProductDashboard from '@appViews/productDashboard/productDashboard.vue'
import CategoryDashboard from '@appViews/categoryDashboard/categoryDashboard.vue'
import ContactGroupDashboard from '@appViews/contactGroupDashboard/contactGroupDashboard.vue'
import CategoryMaintenance from '@appViews/system/categoryMaintenance/categoryMaintenance.vue'

//import Test from '@appViews/Test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: CompanyDashboard,
  },
  {
    path: '/companyDashboard',
    name: 'CompanyDashboard',
    component: CompanyDashboard,
  },
  {
    path: '/customerDashboard',
    name: 'CustomerDashboard',
    component: CustomerDashboard,
  },
  {
    path: '/productDashboard',
    name: 'ProductDashboard',
    component: ProductDashboard,
  },
  {
    path: '/categoryDashboard',
    name: 'CategoryDashboard',
    component: CategoryDashboard,
  },
  {
    path: '/contactGroupDashboard',
    name: 'ContactGroupDashboard',
    component: ContactGroupDashboard,
  },
  {
    path: '/system',
    name: 'system',
    component: CategoryMaintenance,
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ '../../appViews/About.vue'),
  // },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: Test,
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
