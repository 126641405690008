import { gql } from 'graphql-tag'

const category = gql`
  query {
    wsCategory {
      id
      parentId
      grandParentId
      name
      level
      children {
        id
        parentId
        grandParentId
        name
        level
        children {
          id
          parentId
          grandParentId
          name
          level
          children {
            id
            parentId
            grandParentId
            name
            level
            children {
              id
            }
          }
        }
      }
    }
  }
`
const queries = {
  category,
}

export { queries as ws }
