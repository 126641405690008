import { gql } from 'graphql-tag'

export const contactGroupCategories = gql`
  query($dbQuery: DbQuery) {
    contactGroupCategories(dbQuery: $dbQuery) {
      categoryId
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      lastInvoiced
    }
  }
`
