import { gql } from 'graphql-tag'

const productAndProductCustomersAndProductContactGroups = gql`
  query($dbQuery: DbQuery) {
    multiQuery(dbQuery: $dbQuery) {
      products(dbQuery: $dbQuery) {
        code
        name
        RRP
        tradePrice
        categories {
          id
          name
        }
        ranking
        rankingByDays
        rankingByMonth
        lastInvoiced
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
      }
      productCustomers(dbQuery: $dbQuery) {
        contactGroupId
        customerId
        customerName
        customer {
          sales
          salesByDays
          salesByMonth
        }
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
      contactGroupProducts(dbQuery: $dbQuery) {
        contactGroupId
        contactGroupName
        productCode
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
      contactGroupCategories(dbQuery: $dbQuery) {
        contactGroupId
        contactGroupName
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
      categoryCustomers(dbQuery: $dbQuery) {
        categoryId
        customerId
        customerName
        contactGroupId
        categoryName
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
    }
  }
`

const queries = {
  productAndProductCustomersAndProductContactGroups,
}

export { queries as productDashboard }
