<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <outstanding-profile class="pb-4" :profiles="outstanding" />
    <v-divider />
    <paid-invoices-profile :profiles="paidInvoices" />
  </pc-display-box>
</template>

<script>
// eslint-disable-next-line
import pc from '@pc'
import db from '@db'
import { companyGql } from './outstanding.gql'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import paidInvoicesProfile from '@appViews/sharedComponents/paidInvoicesProfile.vue'
import outstandingProfile from '@appViews/sharedComponents/outstandingProfile.vue'

export default {
  name: 'outstanding',

  components: {
    pcDisplayBox,
    paidInvoicesProfile,
    outstandingProfile,
  },

  created() {
    this.main()
  },

  computed: {},

  watch: {},

  methods: {
    initialise() {},

    async main() {
      const company = await db.request(companyGql)
      const outstanding = pc.sumArrayProp(pc.getProperty('total'), [
        ...company.outstandingProfile.overdue,
        ...company.outstandingProfile.due,
      ])
      this.displayBoxDef.title = `Outstanding £${pc.toFixed2(
        outstanding
      )} and Payment profile`
      this.outstanding = company.outstandingProfile
      this.paidInvoices = company.paidInvoicesProfile
    },
  },

  data() {
    return {
      company: undefined,
      outstanding: undefined,
      paidInvoices: undefined,
      displayBoxDef: pcDisplayBoxDef('payment', 'Payments & outstanding'),
    }
  },
}
</script>

<style scoped></style>
