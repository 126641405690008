<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="company"
        allName="Company"
        :count="false"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-simple-table :simpleTableDef="simpleTableDef">
        <template v-slot:sales>
          <pc-sparkle-chart :sparkleChartDef="sparkleChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'productStatistics',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    contactGroupSelect,
  },

  props: {
    productSummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    isProduct() {
      return this.product && this.product.code ? true : false
    },

    product() {
      return this.productSummaryDef.product
    },
  },

  watch: {
    productSummaryDef() {
      this.main()
    },

    groupId() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.isProduct) {
        this.salesByContactGroup = [...this.product.salesByContactGroup]

        const contactGroupProduct = pc
          .query()
          .addFind('contactGroupId', this.groupId)
          .run(this.salesByContactGroup)

        this.simpleTableDef = {
          ...this.simpleTableDef,
          data: this.formatStatistics(contactGroupProduct),
        }
      }
    },

    formatStatistics(contactGroupProduct) {
      return contactGroupProduct.salesByMonth
        .map((sales, index) => ({
          month: this.months[index],
          quantity: contactGroupProduct.quantityByMonth[index],
          sales,
          activeCustomers: contactGroupProduct.activeCustomersByMonth[index],
          customerAverage: contactGroupProduct.customerAverageByMonth[index],
          invoices: contactGroupProduct.invoicesByMonth[index],
          invoiceAverage: contactGroupProduct.invoiceAverageByMonth[index],
        }))
        .reverse()
    },

    groupSelected(groupId) {
      this.groupId = groupId
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      groupId: 'company',
      months: pc.periodMonthNames('mmm yy', period.monthKeys),
      salesByContactGroup: [],

      displayBoxDef: pcDisplayBoxDef('statistics', 'Product sales statistics', {
        height: '100%',
      }),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('month', 'text', 'Month'),
          pcSimpleTableFieldDef('quantity', 'int', 'Quantity'),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            decimalPlaces: 0,
          }),
          pcSimpleTableFieldDef(
            'activeCustomers',
            'int',
            'Number<br />customers'
          ),
          pcSimpleTableFieldDef(
            'customerAverage',
            'currency',
            'Customer<br />average'
          ),
          pcSimpleTableFieldDef('invoices', 'int', 'Number<br />invoices'),
          pcSimpleTableFieldDef(
            'invoiceAverage',
            'currency',
            'Invoice<br />average'
          ),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
