const _pcLineActionsDef = () => ({
  heading: '',
  show: {xs: true},
  width: '',
  headingClass: '',
  align: 'center',
  actions: [],
});

const _pcLineActionDef = () => ({
  id: '',
  class: 'mx',
  icon: '',
  tooltip: '',
  iconColor: '',
  action: '',
});

const pcLineActionsDef = function (heading = 'Actions', other = {}, actions = []) {
  const lineActions = _pcLineActionsDef();

  lineActions.heading = heading;
  lineActions.actions = actions;
  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(lineActions, key)) {
      lineActions[key] = value;
    } else {
      console.log(`Invalid property: ${key} in line actions}`);
    }
  })
  return lineActions;
}

const pcLineActionDef = function field(id, icon, action, tooltip = '', iconColor = 'primary') {
  const lineAction = _pcLineActionDef();

  lineAction.id = id;
  lineAction.icon = icon;
  lineAction.iconColor = iconColor;
  lineAction.tooltip = tooltip;
  lineAction.action = action;
  return lineAction;
}

export { pcLineActionsDef, pcLineActionDef };
