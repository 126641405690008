<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="createCSV"
        value="pie"
        icon="mdi-file-excel"
        small
        top
        tooltip="Create CSV file"
      />
    </template>
    <pc-lister
      :list-def="type === 'overdue' ? overdueInvoicesDef : dueInvoicesDef"
      :list-items="invoices"
    >
      <template v-slot:expanded-item>
        <tr>
          <td colspan="99">
            <pc-lister
              :list-def="invoiceProductsDef"
              :list-items="invoiceProducts"
            >
            </pc-lister>
          </td>
        </tr>
      </template>
    </pc-lister>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { daysOverClass } from '@appModules/ws.js'

import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

import pcLister from '@pcComponents/pcLister.vue'
import {
  pcLineActionsDef,
  pcLineActionDef,
} from '@pcComponents/defs/pcLineActionsDef.js'

export default {
  name: 'dueInvoices',

  components: {
    pcDisplayBox,
    pcActionIcon,
    pcLister,
  },

  props: {
    type: String,
    title: String,
    invoices: Array,
  },

  created() {},

  computed: {},

  watch: {
    title() {
      this.displayBoxDef.title = this.title
    },
  },

  methods: {
    async main() {},

    invoiceDaysOverFieldClass(payload) {
      return daysOverClass(pc.getProperty('paymentProfile', payload.data))
    },

    async viewInvoiceProducts(payload) {
      payload.props.expand(!payload.props.isExpanded)
      if (!payload.props.isExpanded) {
        if (!payload.data.id) {
          this.wsInvoiceProductsData = [] // Credit note with no id for lines
        } else {
          this.invoiceProducts = await db.request(
            db.query('invoice.invoiceLineDrillDown'),
            db.args(
              db
                .queryArgs('invoiceLines')
                .addFilter('invoiceId', payload.data.id)
                .addSort('productCode')
            )
          )
        }
      }
    },

    showCustomerDashboard(payload) {
      this.$emit('showCustomerDashboard', {
        customerId: payload.data.customerId,
        from: 'shared',
        screen: 'paymentAnalysis',
      })
    },

    createCSV() {
      pc.csvFromLister(
        this.type === 'overdue' ? this.overdueInvoicesDef : this.dueInvoicesDef,
        this.invoices
      )
    },
  },

  data() {
    return {
      invoiceLines: [],

      overdueInvoicesDef: {
        cardDef: pcCardDef('wsInvoices', false, {
          elevation: '0',
        }),
        toolbarDef: undefined,
        table: pcTableDef(
          'wsInvoicesTable',
          'invoiceNumber',
          ['customerName', 'dueDate'],
          [false, false],
          {
            depth: 2,
            rowClick: this.viewInvoiceProducts,
            tableClass: 'grey lighten-5',
            noDataMessage:
              'Click on an Outstanding line or Paid Invoice profile for a list of invoices',
          }
        ),
        fields: [
          pcFieldDef('customerName', 'text', '', 'Customer'),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'customer.phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'customer.contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'customer.contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'customer.contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('invoiceNumber', 'text', '', 'Invoice no.'),
          pcFieldDef('date', 'ISODate', '', 'Invoice date', true),
          pcFieldDef('goods', 'currency', '', 'Goods', true, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('vat', 'currency', 'tax', 'VAT', true, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('total', 'currency', '', 'Total', true, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('due', 'currency', '', 'Due', false, {}),
          pcFieldDef('dueDate', 'ISODate', '', 'Due by', true, {}),
          pcFieldDef('daysOver', 'number', '', 'Days over', true, {
            itemClass: this.invoiceDaysOverFieldClass,
          }),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },

      dueInvoicesDef: {
        cardDef: pcCardDef('wsInvoices', false, {
          elevation: '0',
        }),
        toolbarDef: undefined,
        table: pcTableDef(
          'wsInvoicesTable',
          'invoiceNumber',
          ['daysToPay'],
          [false],
          {
            depth: 2,
            rowClick: this.viewInvoiceProducts,
            tableClass: 'grey lighten-5',
            noDataMessage:
              'Click on an Outstanding line or Paid Invoice profile for a list of invoices',
          }
        ),
        fields: [
          pcFieldDef('customerName', 'text', '', 'Customer'),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'customer.phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'customer.contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'customer.contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'customer.contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('invoiceNumber', 'text', '', 'Invoice no.'),
          pcFieldDef('date', 'ISODate', '', 'Invoice date', true),
          pcFieldDef('goods', 'currency', '', 'Goods', true, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('vat', 'currency', 'tax', 'VAT', true, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('total', 'currency', '', 'Total', true, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('due', 'currency', '', 'Due', false, {}),
          pcFieldDef('dueDate', 'ISODate', '', 'Due by', true, {}),
          pcFieldDef('daysToPay', 'number', '', 'Days to pay', true, {}),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },

      invoiceProducts: [],

      displayBoxDef: pcDisplayBoxDef('invoices', 'Invoices'),

      invoiceProductsDef: {
        cardDef: pcCardDef('wsInvoiceProducts', false, {
          width: '80%',
          expand: true,
        }),
        table: pcTableDef(
          'wsInvoiceProducts',
          'itemCode',
          ['itemCode'],
          [false],
          { depth: 3 }
        ),
        fields: [
          pcFieldDef('productCode', 'text', '', 'Code'),
          pcFieldDef('productName', 'text', '', 'Name', true, {
            width: '100%',
          }),
          pcFieldDef('quantity', 'number', '', 'Quantity', false, {
            negativeClass: 'red--text',
          }),
          pcFieldDef('price', 'currency', '', 'Price', false),
          pcFieldDef('goods', 'currency', '', 'Value', false, {
            negativeClass: 'red--text',
          }),
        ],
      },
    }
  },
}
</script>

<style scoped></style>
