import { cache } from './queries/cache'
import { ws } from './queries/ws'
import { tooltip } from './queries/tooltip'
import { invoice } from './queries/invoice'
import { whitespace } from './queries/whitespace'
import { companyDashboard } from './queries/companyDashboard'
import { customerDashboard } from './queries/customerDashboard'
import { productDashboard } from './queries/productDashboard'
import { categoryDashboard } from './queries/categoryDashboard'
import { contactGroupDashboard } from './queries/contactGroupDashboard'
import { customerProducts } from './queries/customerProducts'
import { customerCategories } from './queries/customerCategories'
import { categoryDrill } from './queries/categoryDrill'
import { salesAnalysis } from './queries/salesAnalysis'
import { paidInvoicesProfile } from './queries/paidInvoicesProfile'

import { productCustomersClass } from './queries/productCustomersClass'

const queries = {
  cache,
  ws,
  tooltip,
  invoice,
  whitespace,
  companyDashboard,
  customerDashboard,
  productDashboard,
  categoryDashboard,
  contactGroupDashboard,
  customerProducts,
  customerCategories,
  categoryDrill,
  salesAnalysis,
  paidInvoicesProfile,
  productCustomersClass,
}

export { queries }
