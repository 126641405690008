<template>
  <v-row
    id="customerDashboard"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
      <preferences
        :openDialog="preferencesDialog"
        v-on:closed="preferencesDialogClosed"
      ></preferences>

      <pc-card :card-def="dashboardCardDef">
        <template v-slot:title>
          <pc-toolbar :toolbar-def="toolbarDef"></pc-toolbar>
        </template>

        <template v-slot:text>
          <v-row class="fill-height mt-0" justify="center" align="stretch">
            <v-col
              id="customerDashboardContent"
              :cols="12"
              class="pc-col d-flex pl-3 pr-0 pt-0 pb-0"
            >
              <div v-show="!dashboardCustomer">
                <find-customer
                  :contactGroupId="contactGroupId"
                  :customer="customer"
                  v-on:customer="customerSelected"
                />
              </div>
              <div>
                <pc-nav-drawer
                  :navDrawerDef="navDrawerDef"
                  v-on:selected="menuItem => (menuIdSelected = menuItem.id)"
                />
              </div>
              <customerSummary
                :customerSummaryDef="customerSummaryDef"
                :categoryDrillData="summaryCategoryDrillData"
              />
              <customerTurnover :drillDef="categoryDrillDef" />
              <customerInvoices :customerInvoicesDef="customerInvoicesDef" />
              <customerCategories :categoriesDef="customerCategoriesDef" />
              <customerProducts :productsDef="customerProductsDef" />
              <customer-rankings
                :show="menuIdSelected === 'ranking'"
                :customerId="customer ? customer.id : ''"
              />
              <customer-top-10
                :show="menuIdSelected === 'top10'"
                :customerId="customer ? customer.id : ''"
              />
            </v-col>
          </v-row>
        </template>
      </pc-card>
    </v-col>
  </v-row>
</template>

<script>
import db from '@db'
import { LocalStorage } from '@pcModules/localStorage.js'
import { whitespaceLocalStorageSchema } from '@appModules/ws.js'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import customerSummary from '@appViews/customerDashboard/customerSummary/customerSummary.vue'
import customerTurnover from '@appViews/customerDashboard/customerTurnover'
import customerInvoices from '@appViews/customerDashboard/customerInvoices'
import customerCategories from '@appViews/customerDashboard/customerCategories'
import customerRankings from '@appViews/customerDashboard/customerRanking'
import customerTop10 from '@appViews/customerDashboard/customerTop10/customerTop10'
import customerProducts from '@appViews/customerDashboard/customerProducts'
import preferences from '@appViews/customerDashboard/preferences'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'
import {
  pcNavDrawerDef,
  pcNavDrawerItem,
} from '@pcComponents/defs/pcNavDrawerDef.js'

import findCustomer from '@appViews/customerDashboard/findCustomer.vue'
import pcNavDrawer from '@pcComponents/pcNavDrawer.vue'

export default {
  name: 'customerDashboard',
  components: {
    pcCard,
    pcToolbar,
    pcNavDrawer,
    customerSummary,
    customerTurnover,
    customerInvoices,
    customerCategories,
    customerProducts,
    customerRankings,
    customerTop10,
    preferences,
    findCustomer,
  },

  directives: {},

  props: {
    contactGroupId: String,
    dashboardCustomer: Object,
  },

  async created() {
    await this.initialise()
    this.main()
    this.customer = this.dashboardCustomer
  },

  watch: {
    dashboardCustomer(value) {
      this.customer = value
    },

    customer() {
      this.dashboardCardDef.loading = true
      this.toolbarDef.title = this.customer
        ? this.customer.name
        : 'Customer dashboard'
      this.main()
    },

    menuIdSelected: {
      immediate: true,
      handler(value) {
        this.customerSummaryDef.show = value === 'summary'
        this.categoryDrillDef.show = value === 'turnover'
        this.customerInvoicesDef.show = value === 'invoices'
        this.customerCategoriesDef.show = value === 'categories'
        this.customerProductsDef.show = value === 'products'
      },
    },
  },

  methods: {
    async initialise() {
      // If dashboard not called direct from menu add a back action to the toolbar
      if (this.dashboardCustomer) {
        this.toolbarDef.actions = [this.dashboardExitActionDef]
      }
      // Modify customer dashboard toolbar if called from contactGroup Dashboard
      if (this.contactGroupId) {
        this.toolbarDef.actions = []
        this.toolbarDef.dense = true
        this.toolbarDef.color = 'primary lighten-3'
      }

      this.customer = this.dashboardCustomer
      const whitespaceLocalStorage = new LocalStorage(
        'whitespace',
        whitespaceLocalStorageSchema
      )
      this.openingWindow = whitespaceLocalStorage.getProperty(
        'customerDashboard.openingWindow'
      )
    },

    async main() {
      if (this.customer) {
        // Only reset to specified opening screen if being called from outside
        if (!this.customerSelectedInternally) {
          this.menuIdSelected = this.openingWindow || 'summary'
        }
        this.customerSummaryDef.customerId = this.customer.id
        this.categoryDrillDef.customerId = this.customer.id
        this.customerInvoicesDef.customerId = this.customer.id
        this.customerCategoriesDef.customerId = this.customer.id
        this.customerProductsDef.customerId = this.customer.id
        this.dashboardCardDef.loading = false
      } else {
        this.menuIdSelected = this.openingWindow || 'summary'
      }
      this.customerSelectedInternally = false
    },

    updateSummaryPieChart(data) {
      if (
        data.monthIndex === -1 &&
        data.total &&
        data.categoryId === 'category' &&
        data.customerId
      ) {
        this.summaryCategoryDrillData = data
      }
    },

    lastInvoicedClass(payload) {
      return lastInvoicedClass(
        payload.data.lastInvoiced,
        this.period.periodEndDate
      )
    },

    customerSelected(customerRecord) {
      this.customerSelectedInternally = true
      this.customer = customerRecord
    },

    exitDashboard() {
      this.$emit('exit')
    },

    preferences() {
      this.preferencesDialog = true
    },

    preferencesDialogClosed(openingWindow) {
      this.preferencesDialog = false
      this.openingWindow = openingWindow
    },
  },

  data() {
    return {
      period: db.cached('period'),
      customer: undefined,
      customerSelectedInternally: false,
      preferencesDialog: false,
      openingWindow: '',
      menuIdSelected: 'summary',
      summaryCategoryDrillData: {},

      textHeight: '',
      textWidth: '',

      dashboardCardDef: pcCardDef('customerDashboard', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef(
        'customerDashboardToolbar',
        'Customer dashboard',
        {},
        [
          pcToolbarActionDef(
            'preferences',
            'mdi-cog-outline',
            this.preferences,
            'Set preferences'
          ),
        ]
      ),

      navDrawerDef: pcNavDrawerDef(
        'optionsNavDrawer',
        [
          pcNavDrawerItem('summary', 'mdi-view-dashboard', 'Summary'),
          pcNavDrawerItem('turnover', 'mdi-chart-bar', 'Turnover'),
          pcNavDrawerItem('invoices', 'mdi-receipt', 'Invoices'),
          pcNavDrawerItem('categories', 'mdi-shape', 'Categories'),
          pcNavDrawerItem('products', 'mdi-package-variant', 'Products'),
          pcNavDrawerItem('ranking', 'mdi-chevron-triple-up', 'Ranking'),
          pcNavDrawerItem('top10', 'mdi-sort-descending', 'Top 10'),
        ],
        { width: '200px', expandOnHover: true, miniVariant: true }
      ),

      dashboardExitActionDef: pcToolbarActionDef(
        'back',
        'mdi-arrow-left',
        this.exitDashboard,
        'Exit dashboard'
      ),

      customerSummaryDef: {
        show: false,
        customerId: '',
      },

      categoryDrillDef: {
        id: '',
        if: true,
        show: true,
        customerId: '',
        cardClass: '',
        elevation: '0',
        updateHook: this.updateSummaryPieChart,
      },

      customerInvoicesDef: {
        show: false,
        title: 'Invoices',
        customerId: '',
      },

      customerCategoriesDef: {
        show: false,
        customerId: '',
      },

      customerProductsDef: {
        show: false,
        customerId: '',
      },
    }
  },
}
</script>

<style scoped></style>
