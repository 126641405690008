import pc from '@pc'
import db from '@db'
import { contactGroupCategories } from './getCategories.gql.js'

export const getCategories = async contactGroupId => {
  const period = db.cached('period')

  const updateCategorySales = (category, contactGroupCategory) => ({
    ...category,
    sales: contactGroupCategory.sales,
    salesByDays: contactGroupCategory.salesByDays,
    salesByMonth: contactGroupCategory.salesByMonth,
    activeCustomers: contactGroupCategory.activeCustomers,
    activeCustomersByDays: contactGroupCategory.activeCustomersByDays,
    activeCustomersByMonth: contactGroupCategory.activeCustomersByMonth,
    lastInvoiced: contactGroupCategory.lastInvoiced,
  })

  const zeroCategorySales = category => ({
    ...category,
    sales: 0,
    salesByDays: pc.createArray(0, 4),
    salesByMonth: pc.createArray(0, period.monthsInPeriod),
    activeCustomers: 0,
    activeCustomersByDays: pc.createArray(0, 4),
    activeCustomersByMonth: pc.createArray(0, period.monthsInPeriod),
    lastInvoiced: '00/00/0000',
  })

  const updateWithContactGroupSales = async (categories, contactGroupId) => {
    const contactGroupSalesByCategory = await db.request(
      contactGroupCategories,
      db.args(
        db
          .queryArgs('contactGroupCategories')
          .addFilter('contactGroupId', contactGroupId)
      )
    )

    return categories.map(category => {
      const contactGroupCategory = contactGroupSalesByCategory.find(
        contactGroupSales => contactGroupSales.categoryId === category.id
      )
      return contactGroupCategory
        ? updateCategorySales(category, contactGroupCategory)
        : zeroCategorySales(category)
    })
  }

  const categories = db.cached('categories', db.cachedQuery().addClone())
  return contactGroupId === 'company'
    ? categories
    : await updateWithContactGroupSales(categories, contactGroupId)
}
