<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-chart :chartDef="barChartDef" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'CustomerCoverageBar',
  components: {
    pcChart,
    pcDisplayBox,
  },

  props: {
    group: Object,
    item: Object,
  },

  computed: {},

  watch: {
    item() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.item) {
        this.barChartDef
          .clearDatasets()
          .addDataset(
            this.item.activeCustomersByMonth,
            'Bought category',
            getColor('purple', 3),
            [['type', 'line']]
          )
          .addDataset(
            pc.arrayFixed2(this.group.activeCustomersByMonth),
            'Active customers',
            getColor('blue', 3),
            [['type', 'bar']]
          )

        this.barChartDef.render()
      } else {
        this.barChartDef.clearDatasets()
        this.barChartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,

      displayBoxDef: pcDisplayBoxDef(
        'customerCoverageBar',
        'Active customers coverage by month'
      ),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
