import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import createEasyAccess from 'vuex-easy-access'
import { getStoreModules, getStoreDbPlugins } from './getModulesPlugins'

const modules = getStoreModules();
const plugins = getStoreDbPlugins(firebase)
plugins.push(createEasyAccess({ vuexEasyFirestore: true }))

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins,
})

export default store;