<template>
  <div class="text-center" v-resize:throttle.100="updateWidth">
    <div class="profile-container">
      <div
        class="text-subtitle-2 mt-2 mb-1"
        :class="isLarge ? 'text-subtitle-2 font-weight-bold mt-0 mb-2' : ''"
      >
        Payment profile of paid invoices by month
      </div>
      <div
        v-for="(profile, index) in displayProfiles.profiles"
        :key="`profile${index}`"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <div v-if="isLegend" class="legend">
          <span>{{ getLegend(profile.profileIndex) }}</span>
        </div>
        <div
          v-for="(month, index) in profile.months"
          :key="`month${index}`"
          class="rounded-circle d-inline-block"
          :class="
            `${isLarge ? 'large-profile' : 'profile'} ${
              clickable ? 'click-pointer ' : ''
            }${month.count ? month.color + ' ' + month.textColor : ''}`
          "
          v-on:click="$emit('paidInvoiceProfile', month)"
        >
          {{ month.count ? month.count : '-' }}
        </div>
      </div>
      <div v-if="isLegend" class="legend"></div>
      <div
        v-for="(monthName, index) in displayProfiles.monthNames"
        :key="`montName${index}`"
        class="d-inline-block"
        :class="`${isLarge ? 'large-month-name' : 'month-name'}`"
      >
        {{ monthName }}
      </div>
    </div>
  </div>
</template>

<script>
import pc from '@pc'
import db from '@db'
import {
  PAYMENT_PROFILE_UI_TITLE_COLOR_CLASS,
  PAYMENT_PROFILE_UI_TEXT_COLOR_CLASS,
} from '@appModules/ws.js'
import resize from 'vue-resize-directive'

export default {
  name: 'paidInvoicesProfile',

  created() {
    this.main()
  },

  components: {},

  directives: {
    resize,
  },

  props: {
    clickable: {
      default: false,
      type: Boolean,
    },
    large: {
      default: false,
      type: Boolean,
    },
    legend: {
      default: false,
      type: Boolean,
    },
    profiles: Array,
  },

  computed: {
    isLarge() {
      return this.large ? this.width >= 620 : false
    },

    isLegend() {
      return this.legend ? this.width >= 490 : false
    },
  },

  watch: {
    profiles() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.profiles) this.displayProfiles = this.getDisplayProfiles()
    },

    updateWidth(element) {
      this.width = element.clientWidth
    },

    getDisplayProfiles() {
      const addColor = profile => ({
        ...profile,
        months: profile.months.map(month => ({
          ...month,
          color: PAYMENT_PROFILE_UI_TITLE_COLOR_CLASS[month.profileIndex],
          textColor:
            PAYMENT_PROFILE_UI_TEXT_COLOR_CLASS[month.profileIndexndex],
        })),
      })

      return {
        profiles: this.profiles.map(addColor),
        monthNames: pc.periodMonthNames('mmm', this.period.monthKeys),
      }
    },

    getLegend(profileIndex) {
      return [
        'Paid on time',
        '1-7 days late',
        '8-14 days late',
        '15-21 days late',
        'Over 21 days late',
      ][profileIndex]
    },
  },

  data() {
    return {
      period: db.cached('period'),
      displayProfiles: [],
      width: 620,
    }
  },
}
</script>

<style scoped>
.profile-container {
  display: inline-block;
}
.legend {
  display: inline-block;
  width: 120px;
  text-align: left;
}
.profile {
  width: 26px;
  height: 26px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
.month-name {
  height: 9px;
  width: 26px;
  margin-top: 10px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
  font-size: 12px;
  line-height: 11px;
  transform: rotate(-60deg);
}
.large-profile {
  width: 36px;
  height: 36px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 8px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
.large-month-name {
  height: 9px;
  width: 26px;
  margin-top: 10px;
  margin-left: 6px;
  margin-right: 6px;
  padding-top: 1px;
  text-align: center;
  font-size: 12px;
  line-height: 11px;
  transform: rotate(-60deg);
}
</style>
