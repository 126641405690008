<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="$emit('order-by', 'ranking')"
        icon="mdi-chevron-triple-up"
        small
        top
        tooltip="Order by ranking"
      />
      <pc-action-icon
        @click="$emit('order-by', 'sales')"
        value="sales"
        icon="mdi-currency-gbp"
        small
        top
        tooltip="Order by customer sales"
      />
    </template>
    <pc-simple-table :simpleTableDef="simpleTableDef" @tooltip="tooltip">
      <template v-slot:sales>
        <pc-spark-chart :sparkChartDef="sparkChartDef" />
      </template>
    </pc-simple-table>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'customerTop10CategoriesBought',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcSparkChart,
    pcActionIcon,
  },

  props: {
    customer: Object,
    categories: Array,
  },

  watch: {
    categories(value) {
      this.simpleTableDef.data = value
    },
  },

  methods: {
    async main() {},

    tooltip(payload) {
      const { data } = payload
      const titleColor = pc.getProperty('tag', data)

      try {
        this.sparkChartDef
          .clearDatasets()
          .setTitle(data.name, titleColor)
          .setSubTitle(
            `Sales £${pc.toFixed2(data.sales)} - ${pc.toFixed2(
              data.percentage
            )}%)`
          )
          .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales', titleColor)
          .update()
      } catch (err) {
        console.log(err)
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      displayBoxDef: pcDisplayBoxDef(
        'top10CategoriesBought',
        'Top 10 categories bought'
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('tag', 'tag', ''),
          pcSimpleTableFieldDef('name', 'text', 'Name'),
          pcSimpleTableFieldDef('ranking', 'int', 'Ranked', {
            blankIfZero: true,
          }),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last<br />invoiced'
          ),
        ],
      }),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
