<template>
  <pcLister
    v-if="show"
    :list-def="customerRankingsDef"
    :list-items="customerRankings"
  />
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcLineActionsDef } from '@pcComponents/defs/pcLineActionsDef.js'

import pcLister from '@pcComponents/pcLister.vue'

export default {
  name: 'customerRanking',
  components: {
    pcLister,
  },

  props: {
    customerId: String,
    show: Boolean,
  },

  async created() {
    this.main()
  },

  watch: {
    customerId() {
      this.main()
    },
    show(value) {
      this.customerRankingsDef.cardDef.if = value
    },
  },

  methods: {
    async main() {
      this.customerRankings = []

      if (this.customerId) {
        this.customerRankings = await this.calculateCustomerRankings()
      }
    },

    async calculateCustomerRankings() {
      const calculateMonth = async (monthKey, monthIndex) => {
        const getCustomerIndex = (customerId, customers) =>
          customers.findIndex(customer => customer.id === customerId)

        const formatRanking = (customerIndex, activeCustomers) =>
          customerIndex !== -1
            ? `${customerIndex + 1}/${activeCustomers}`
            : 'None'

        const calcQuartile = (customerSales, customers) => {
          const customerSalesArray = customers.map(
            customer => customer.salesByMonth[monthIndex]
          )
          return pc.quartile(customerSalesArray, customerSales)
        }

        this.customer = db.cached(
          'customers',
          db.cachedQuery().addFind('id', this.customerId)
        )

        // Company numbers
        const companyCustomers = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter(`salesByMonth[${monthIndex}]`, 0, 'ne')
            .addSort(`salesByMonth[${monthIndex}]`, true)
        )
        const companyActiveCustomers = companyCustomers.length
        const customerIndex = getCustomerIndex(
          this.customer.id,
          companyCustomers
        )
        const companyRanking = formatRanking(
          customerIndex,
          companyActiveCustomers
        )
        const customerMonthSales = this.customer.salesByMonth[monthIndex]

        const companyQuartile =
          customerIndex === -1
            ? ''
            : calcQuartile(customerMonthSales, companyCustomers)

        // Contact Group numbers
        const groupCustomers = db.cached(
          'customers',
          db
            .cachedQuery()
            .addFilter('contactGroupId', this.customer.contactGroupId)
            .addFilter(`salesByMonth[${monthIndex}]`, 0, 'ne')
            .addSort(`salesByMonth[${monthIndex}]`, true)
        )
        const groupActiveCustomers = groupCustomers.length
        const groupCustomerIndex = getCustomerIndex(
          this.customer.id,
          groupCustomers
        )
        const groupRanking = formatRanking(
          groupCustomerIndex,
          groupActiveCustomers
        )

        const groupQuartile =
          customerIndex === -1
            ? ''
            : calcQuartile(customerMonthSales, groupCustomers)

        return {
          monthKey,
          monthIndex,
          month: pc.monthKeyName('mmm yyyy', monthKey),
          total: this.period.salesByMonth[monthIndex],
          sales: customerMonthSales,
          upDown: 0,
          companyQuartile,
          companyActiveCustomers,
          companyRanking,
          groupActiveCustomers,
          groupRanking,
          groupQuartile,
        }
      }

      const calculateUpDown = (record, index, data) => {
        if (index + 1 < data.length) {
          record.upDown = record.sales - data[index + 1].sales
        }
        return record
      }

      const data = await Promise.all(
        this.period.monthKeys.map(async (monthKey, index) =>
          calculateMonth(monthKey, index)
        )
      )

      return data.reverse().map(calculateUpDown)
    },
  },

  data() {
    return {
      period: db.cached('period'),
      customer: {},
      customerRankingsDef: {
        cardDef: pcCardDef('customerRankings', false),
        toolbarDef: pcToolbarDef('customerRankingsToolbar', 'Rankings', {
          dense: true,
          color: 'primary lighten-2',
        }),
        table: pcTableDef('customerRankingsTable', 'monthKey', [], [], {
          fontSize: { xs: 'small', sm: 'medium', xl: 'large' },
          noDataMessage: '',
        }),
        fields: [
          pcFieldDef('month', 'text', '', 'Month', false),
          pcFieldDef('sales', 'currency', '', 'Sales', false),
          pcFieldDef('upDown', 'currency', '', 'Up/Down', false, {
            blankZero: true,
          }),
          pcFieldDef(
            'companyQuartile',
            'quartile',
            '',
            'Company quartile',
            false
          ),
          pcFieldDef('companyRanking', 'text', '', 'Company ranking', false),
          pcFieldDef('groupQuartile', 'quartile', '', 'Group quartile', false),
          pcFieldDef('groupRanking', 'text', '', 'Group ranking', false),
        ],
        lineActions: pcLineActionsDef(),
      },
      customerRankings: [],
    }
  },
}
</script>

<style scoped></style>
