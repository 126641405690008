<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-simple-table :simpleTableDef="simpleTableDef" />
  </pc-display-box>
</template>

<script>
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

export default {
  name: 'customerTop10CategoriesNotBought',

  components: {
    pcDisplayBox,
    pcSimpleTable,
  },

  props: {
    customer: Object,
    categories: Array,
  },

  methods: {
    async main() {},
  },

  watch: {
    categories(value) {
      this.simpleTableDef.data = value
    },
  },

  data() {
    return {
      displayBoxDef: pcDisplayBoxDef(
        'top10CategoriesNotBought',
        'Top 10 categories not bought'
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('name', 'text', 'Name'),
          pcSimpleTableFieldDef('ranking', 'int', 'Ranked'),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
