import { gql } from 'graphql-tag'

export const productQuery = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      code
      name
      RRP
      tradePrice
      categories {
        id
        name
      }
      ranking
      rankingByDays
      rankingByMonth
      quantity
      quantityByDays
      quantityByMonth
      sales
      salesByDays
      salesByMonth
      activeCustomers
      activeCustomersByDays
      activeCustomersByMonth
      invoices
      invoicesByDays
      invoicesByMonth
      customerAverage
      customerAverageByDays
      customerAverageByMonth
      invoiceAverage
      invoiceAverageByDays
      invoiceAverageByMonth
      lastInvoiced
      salesByCustomer(dbQuery: $dbQuery) {
        contactGroupId
        customerId
        customerName
        customer {
          sales
          salesByDays
          salesByMonth
        }
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
      salesByContactGroup(dbQuery: $dbQuery) {
        contactGroupId
        contactGroupName
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        activeCustomers
        activeCustomersByDays
        activeCustomersByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        customerAverage
        customerAverageByDays
        customerAverageByMonth
        invoiceAverage
        invoiceAverageByDays
        invoiceAverageByMonth
        lastInvoiced
      }
      productCategory(dbQuery: $dbQuery) {
        id
        name
        sales
        salesByDays
        salesByMonth
        activeCustomers
        activeCustomersByDays
        activeCustomersByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        customerAverage
        customerAverageByDays
        customerAverageByMonth
        invoiceAverage
        invoiceAverageByDays
        invoiceAverageByMonth
        lastInvoiced
        salesByCustomer {
          categoryId
          customerId
          contactGroupId
          sales
          salesByDays
          salesByMonth
          lastInvoiced
          customerName
        }
        salesByContactGroup {
          contactGroupId
          contactGroupName
          sales
          salesByDays
          salesByMonth
          activeCustomers
          activeCustomersByDays
          activeCustomersByMonth
          invoices
          invoicesByDays
          invoicesByMonth
          customerAverage
          customerAverageByDays
          customerAverageByMonth
          invoiceAverage
          invoiceAverageByDays
          invoiceAverageByMonth
          lastInvoiced
        }
      }
      productsInCategory(dbQuery: $dbQuery) {
        code
        name
        quantity
        quantityByDays
        quantityByMonth
        sales
        salesByDays
        salesByMonth
        lastInvoiced
      }
    }
  }
`
