import pc from '@pc'

export class LocalStorage {
  constructor(namespace, schema) {
    this.namespace = namespace
    this.schema = schema
    this.ls = this.getStorage()
  }

  getProperty(propertyPath) {
    this.ls = this.getStorage()
    if (pc.isProperty(propertyPath, this.schema) === undefined) {
      console.log(
        `Local storage path: ${propertyPath} in getProperty for namespace: ${this.namespace} is invalid`
      )
      return undefined
    }
    return pc.getProperty(propertyPath, this.ls)
  }

  setProperty(propertyPath, propertyValue) {
    this.ls = this.getStorage()
    if (pc.isProperty(propertyPath, this.schema) === undefined) {
      console.log(
        `Local storage path: ${propertyPath} in setProperty for namespace: ${this.namespace} is invalid`
      )
    }
    this.ls = pc.setProperty(propertyPath, propertyValue, this.ls)
    this.setStorage()
  }

  getStorage() {
    const json = window.localStorage.getItem(this.namespace)
    if (!json) {
      this.ls = pc.cloneJSON(this.schema)
    } else {
      this.ls = this.normaliseToSchema(JSON.parse(json))
    }
    this.setStorage()
    return this.ls
  }

  setStorage() {
    window.localStorage.setItem(this.namespace, JSON.stringify(this.ls))
  }

  // Makes the ls the same structure as the schema so that changes to
  // the schema can be made over time.
  normaliseToSchema(ls) {
    const normalise = (schema, ls) => {
      Object.keys(schema).forEach(key => {
        if (!ls[key]) {
          ls[key] = schema[key]
        } else {
          if (typeof ls[key] !== typeof schema[key]) {
            ls[key] = schema[key]
          } else {
            if (typeof schema[key] === Object) normalise(schema[key], ls[key])
          }
        }
      })
      return ls
    }

    return normalise(pc.cloneJSON(this.schema), ls)
  }
}
