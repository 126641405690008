<template>
  <pc-card :card-def="cardDef">
    <template v-slot:title>
      <pc-toolbar :toolbar-def="toolbarDef" />
    </template>

    <template v-slot:text>
      <v-row class="pl-3 pt-3 fill-height" align="stretch">
        <v-col :cols="12" class="pc-col d-flex pt-0 pb-1">
          <v-treeview
            class="tree"
            activatable
            dense
            item-key="id"
            :multiple-active="true"
            :open.sync="openItems"
            :items="items"
            return-object
            :active.sync="active"
            @update:active="updateActive"
          >
            <template v-slot:label="{ item }">
              <span
                draggable="item.level > 1"
                v-on:dragstart="dragStart(item, $event)"
                v-on:dragend="dragEnd(item, $event)"
                >{{ item.name }}</span
              >
            </template>
          </v-treeview>
        </v-col>
      </v-row>
    </template>

    <template v-if="modified" v-slot:actions>
      <div class="pc-width100 d-flex justify-end pr-3 pb-3">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary darken-1"
              text
              @click.stop="$emit('cancelChanges', 'session')"
              >cancel this session changes</v-btn
            >
          </template>
          <span
            >Cancel the changes made in this session<br />
            but retain those made earlier today.</span
          >
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary darken-1"
              text
              @click.stop="$emit('cancelChanges', 'all')"
              >cancel all changes</v-btn
            >
          </template>
          <span
            >Cancel all changes made in this<br />
            session and any made earlier today.</span
          >
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary darken-1"
              text
              @click.stop="$emit('saveChanges')"
              >SAVE CHANGES</v-btn
            >
          </template>
          <span
            >Save the changes. The changes will<br />
            be applied during tonights update.</span
          >
        </v-tooltip>
      </div>
    </template>
  </pc-card>
</template>

<script>
import pc from '@pc'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'

export default {
  name: 'categoryDragTree',

  components: {
    pcCard,
    pcToolbar,
  },

  props: {
    categoryTree: Array,
    clearActive: String,
    modified: Boolean,
  },

  created() {
    this.initialise()
  },

  watch: {
    clearActive() {
      this.active = []
    },

    categoryTree() {
      this.initialise()
    },
  },

  methods: {
    initialise() {
      this.active = []
      this.items = this.categoryTree
      this.openItems = [this.categoryTree[0]]
    },

    main() {},

    async updateActive(activeNodes) {
      if (activeNodes.length <= 1) {
        return
      } else {
        const newItem = activeNodes[activeNodes.length - 1]
        if (newItem.isCategory) {
          this.active = [newItem]
        }
        if (
          pc
            .query()
            .addFilter('parentId', newItem.parentId, 'ne')
            .run(activeNodes).length
        )
          this.active = [newItem]
      }
    },

    // Triggered on item being dragged
    dragStart(dragItem, event) {
      this.$emit('dragstart', {
        isDragging: true,
        dragItem,
        activeItems: [...this.active],
        event,
      })
      event.dataTransfer.setData('text/plain', dragItem.id)
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
    },

    // Triggered on item being dragged
    dragEnd(dragItem, event) {
      event.preventDefault()
      this.$emit('dragend', {
        isDragging: false,
        dragItem,
        activeItems: [...this.active],
        event,
      })
    },
  },

  data() {
    return {
      items: [],
      active: [],
      openItems: [],
      open: ['category'],

      cardDef: pcCardDef('dragCard', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef('dragToolbar', 'Drag zone', {
        dense: true,
        color: 'primary lighten-2',
        actions: [],
      }),
    }
  },
}
</script>

<style scoped>
.tree {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
</style>
