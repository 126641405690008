<template>
  <v-tooltip
    v-if="tooltipDef.show"
    content-class="ma-0 pa-0"
    :activator="tooltipDef.activatorElement"
    :color="tooltipDef.color"
    v-model="tooltipDef.show"
    :top="tooltipDef.top"
    :bottom="tooltipDef.bottom"
    :left="tooltipDef.left"
    :right="tooltipDef.right"
    :position-x="tooltipDef.xPos"
    :position-y="tooltipDef.yPos"
  >
    <slot name="tooltip"></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'pcTooltip',
  props: {
    tooltipDef: Object,
  },
  data: () => ({}),
}
</script>

<style></style>
