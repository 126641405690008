<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChart = true"
        value="bar"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Sales chart"
      />
      <pc-action-icon
        @click="displayChart = false"
        value="line"
        icon="mdi-view-list"
        small
        top
        tooltip="Sales figures"
      />
    </template>
    <template v-slot:default>
      <pc-chart v-if="displayChart" :chartDef="chartDef" @tooltip="tooltip" />
      <pc-simple-table
        v-else
        :simpleTableDef="simpleTableDef"
        @tooltip="tooltip"
        @fieldclick="showCustomerDashboard"
      >
        <template v-slot:sales>
          <pc-spark-chart :sparkChartDef="sparkChartDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

export default {
  name: 'contactGroupTop10Customers',

  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
    pcSimpleTable,
    pcSparkChart,
  },

  props: {
    group: Object,
  },

  created() {
    this.main()
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },
  },

  watch: {
    group() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.group) {
        this.getData()
        this.updateChart()
        this.updateList()
      }
    },

    getData() {
      const { top10Records, restSales, totalSales } = this.group.top10Customers

      this.customers = top10Records.map(customer => ({
        ...customer,
        percentage: pc.percentage(customer.sales, totalSales),
      }))

      this.customers.push({
        id: 'All',
        name: 'All other customers',
        sales: this.group.top10Customers.restSales,
        salesByMonth: this.group.top10Customers.restSalesByMonth,
        percentage: pc.percentage(restSales, totalSales),
      })
    },

    updateChart() {
      this.chartDef
        .setLabels(this.customers.map(customer => customer.name))
        .clearDatasets()
        .addDataset(
          this.customers.map(
            customer => pc.toFixed2(customer.percentage),
            'Customer sales'
          )
        )
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .render()
    },

    updateList() {
      this.simpleTableDef.data = this.customers
    },

    tooltip(payload) {
      const customer =
        payload.event === 'tooltip'
          ? payload.data
          : this.customers[payload.dataIndex]
      const titleColor =
        payload.event === 'tooltip'
          ? undefined
          : payload.dataset.backgroundColor[payload.dataIndex]
      const title = customer.name
      const subTitle = `Sales £${customer.sales.toFixed(
        2
      )} - ${customer.percentage.toFixed(2)}%`

      this.sparkChartDef
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .clearDatasets()
        .addDataset(pc.arrayFixed2(customer.salesByMonth), 'Sales', titleColor)

      this.chartDef.setSparkChart(this.sparkChartDef)
      this.sparkChartDef.render()
    },

    showActionIf(field, item) {
      return item.id !== 'All'
    },

    showCustomerDashboard(payload) {
      this.$emit('showCustomerDashboard', {
        customerId: payload.data.id,
        from: 'contactGroupDashboard',
        screen: 'summay',
      })
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      customers: [],
      displayChart: true,

      displayBoxDef: pcDisplayBoxDef(
        'top10RecordsDisplayBox',
        'Top 10 customers',
        {
          height: '100%',
        }
      ),

      chartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setOptions([['cutout', '50%']])
        .setLabels(pc.periodMonthNames('mmm', period.monthKeys)),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        fields: [
          pcSimpleTableFieldDef('name', 'text', 'Customer'),
          pcSimpleTableFieldDef(
            `salesByMonth[${period.monthsInPeriod - 1}]`,
            'currency',
            `${pc.monthKeyName(
              'mmm yy',
              period.monthKeys[period.monthsInPeriod - 1]
            )}`
          ),
          pcSimpleTableFieldDef('sales', 'currency', 'Total sales', {
            tooltipSlot: 'sales',
          }),
          pcSimpleTableFieldDef('percentage', 'percentage', '%'),
          pcSimpleTableFieldDef(
            'lastInvoiced',
            'lastInvoicedDate',
            'Last<br />invoiced'
          ),
          pcSimpleTableFieldDef('', 'action', '', {
            icon: 'mdi-view-dashboard',
            click: 'click',
            showActionIf: this.showActionIf,
            tooltipText: 'View customer dashboard',
            tooltipPosition: 'left',
          }),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
