<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="total"
        allName="Company"
        :count="false"
        @selected="groupSelected"
      />
      <pc-help class="pl-3" />
    </template>
    <template v-slot:default>
      <span v-if="simpleTableDef.data.length === 0">No Sales</span>
      <pc-simple-table v-else :simpleTableDef="simpleTableDef" @expand="expand">
        <template v-slot:expand>
          <pc-simple-table :simpleTableDef="invoiceSimpleTableDef" />
        </template>
      </pc-simple-table>
    </template>
  </pc-display-box>
</template>

<script>
import db from '@db'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef'
import pcHelp from '@pcComponents/pcHelp.vue'

export default {
  name: 'sales30DaysStatistics',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    pcHelp,
    contactGroupSelect,
  },

  props: {
    sales30DayData: Object,
  },

  watch: {
    sales30DayData() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.groupSelected('total')
    },

    groupSelected(groupId) {
      if (this.sales30DayData) {
        this.sales30DayData.groups.find(group => {
          if (group.id === groupId) {
            this.simpleTableDef.data = group.days
            return true
          }
          this.simpleTableDef.data = []
          return false
        })
      }
    },

    expand(payload) {
      const addCustomerName = invoice => {
        const customer = db.cached(
          'customers',
          db.cachedQuery().addFind('id', invoice.customerId)
        )
        invoice.customerName = customer ? customer.name : 'Customer not found'
        return invoice
      }
      this.invoiceSimpleTableDef.data = payload.data.invoices.map(
        addCustomerName
      )
    },
  },

  data() {
    return {
      period: db.cached('period'),

      displayBoxDef: pcDisplayBoxDef('statistics', 'Sales statistics', {
        height: '100%',
        minWidth: '400px',
      }),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        expand: true,
        fields: [
          pcSimpleTableFieldDef('date', 'text', 'Day'),
          pcSimpleTableFieldDef('numberInvoices', 'int', 'Invoices', {
            blankIfZero: true,
          }),
          pcSimpleTableFieldDef(
            'valueInvoices',
            'currency',
            'Invoice<br />value',
            { decimalPlaces: 0, blankIfZero: true }
          ),
          pcSimpleTableFieldDef('numberCredits', 'int', 'Credit<br />notes', {
            blankIfZero: true,
          }),
          pcSimpleTableFieldDef(
            'valueCredits',
            'currency',
            'Credit<br />value',
            { decimalPlaces: 0, blankIfZero: true }
          ),
          pcSimpleTableFieldDef('total', 'currency', 'Total', {
            decimalPlaces: 0,
            blankIfZero: true,
          }),
          pcSimpleTableFieldDef('', 'expand', ''),
        ],
      }),

      invoiceSimpleTableDef: pcSimpleTableDef('', {
        class: 'blue lighten-5',
        headerClass: 'blue lighten-3',
        height: '',
        maxHeight: '400px',
        fields: [
          pcSimpleTableFieldDef('customerName', 'text', 'Customer'),
          pcSimpleTableFieldDef('invoiceNumber', 'text', 'Invoice No.'),
          pcSimpleTableFieldDef('goods', 'currency', 'Invoice<br />value'),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
