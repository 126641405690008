<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-chart :chartDef="chartDef" @tooltip="tooltip" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'coTop10Chart',

  components: {
    pcChart,
    pcDisplayBox,
  },

  props: {
    recordType: String,
    top10Records: Array,
  },

  computed: {
    title() {
      const name =
        this.recordType === 'category' ? 'categories' : this.recordType + 's'
      return `Top 10 ${name} chart`
    },
  },

  watch: {
    top10Records() {
      this.main()
    },
  },

  methods: {
    main() {
      this.displayBoxDef.title = this.title
      this.updateChart(this.top10Records)
    },

    updateChart(top10Records) {
      this.chartDef
        .setLabels(top10Records.map(customer => customer.name))
        .addDataset(
          top10Records.map(
            record => pc.toFixed2(record.percentage),
            `${pc.capitalise(this.recordType)} sales`
          )
        )
        .render()
    },

    tooltip(payload) {
      const data = this.top10Records[payload.dataIndex]
      const title =
        this.recordType === 'product'
          ? `${data.code} - ${data.name}`
          : data.name
      const titleColor = pc.getProperty('tag', data)
      const subTitle = `Sales £${data.sales.toFixed(
        2
      )} - ${data.percentage.toFixed(2)}%`

      this.sparkChartDef
        .setTitle(title, titleColor)
        .setSubTitle(subTitle)
        .clearDatasets()
        .addDataset(pc.arrayFixed2(data.salesByMonth), 'Sales')

      this.chartDef.setSparkChart(this.sparkChartDef)
      this.sparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      weekDays: [],

      displayBoxDef: pcDisplayBoxDef('top10RecordsDisplayBox', '', {
        height: '100%',
      }),

      chartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm', period.monthKeys)),

      sparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),
    }
  },
}
</script>

<style scoped></style>
