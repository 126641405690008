import { gql } from 'graphql-tag'

export const contactGroupCategoriesGql = gql`
  query($dbQuery: DbQuery) {
    contactGroupCategories(dbQuery: $dbQuery) {
      categoryId
      categoryName
      sales
      salesByDays
      salesByMonth
    }
  }
`
