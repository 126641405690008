<template>
  <pc-lister
    v-if="invoicesDef.show"
    :list-def="wsInvoicesDef"
    :list-items="wsInvoicesData"
  >
    <template v-slot:expanded-item>
      <tr>
        <td colspan="99">
          <pc-lister
            :list-def="wsInvoiceProductsDef"
            :list-items="wsInvoiceProductsData"
          >
          </pc-lister>
        </td>
      </tr>
    </template>
  </pc-lister>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import { daysOverClass } from '@appModules/ws.js'

import pcLister from '@pcComponents/pcLister.vue'

export default {
  name: 'wsCustomerInvoices',

  components: {
    pcLister,
  },

  // options:
  //  customerId  - must be present
  //  show:       - Toggle
  //  expand:     - set True if displaying in expanded table row
  //  width       - Ideal width if you want it restricted
  //  maxWidth    - Absolute max width
  props: {
    invoicesDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    customerId() {
      return this.invoicesDef.customerId
    },
  },

  watch: {
    customerId() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.wsInvoicesData = await db.request(
        db.query('invoice.invoiceDrillDown'),
        db.args(
          db
            .queryArgs('invoices')
            .addFilter('customerId', this.customerId)
            .addSort('date', true)
        )
      )
    },

    invoiceDaysOverFieldClass(payload) {
      return daysOverClass(pc.getProperty('paymentProfile', payload.data))
    },

    async viewInvoiceProducts(payload) {
      payload.props.expand(!payload.props.isExpanded)
      if (!payload.props.isExpanded) {
        if (!payload.data.id) {
          this.wsInvoiceProductsData = [] // Credit note with no id for lines
        } else {
          this.wsInvoiceProductsData = await db.request(
            db.query('invoice.invoiceLineDrillDown'),
            db.args(
              db
                .queryArgs('invoiceLines')
                .addFilter('invoiceId', payload.data.id)
                .addSort('productCode')
            )
          )
        }
      }
    },
  },

  data() {
    return {
      invoices: [],
      invoiceLines: [],

      wsInvoicesData: [],
      wsInvoicesDef: {
        cardDef: pcCardDef('wsInvoices', false, {
          expand: this.invoicesDef.expand,
          elevation: '0',
          width: this.invoicesDef.width,
          maxWidth: this.invoicesDef.maxWidth,
        }),
        toolbarDef: pcToolbarDef('wsInvoicesToolbar', this.invoicesDef.title, {
          show: this.invoicesDef.title ? true : false,
          dense: true,
          color: 'primary lighten-2',
        }),
        table: pcTableDef(
          'wsInvoicesTable',
          'invoiceNumber',
          ['date'],
          [true],
          { depth: 2, rowClick: this.viewInvoiceProducts }
        ),
        fields: [
          pcFieldDef('invoiceNumber', 'text', '', 'Invoice no.'),
          pcFieldDef('date', 'ISODate', '', 'Invoice date', true),
          pcFieldDef('goods', 'currency', '', 'Goods', true, {
            show: { xs: false, sm: true },
          }),
          pcFieldDef('vat', 'currency', 'tax', 'VAT', true, {
            show: { xs: false, sm: true },
          }),
          pcFieldDef('total', 'currency', '', 'Total'),
          pcFieldDef('due', 'currency', '', 'Due', false, { blankZero: true }),
          pcFieldDef('dueDate', 'ISODate', '', 'Due by', true, {
            blankIfNot: 'due',
            show: { xs: false, md: true },
          }),
          pcFieldDef('daysOver', 'number', '', 'Days over', true, {
            blankZero: true,
            blankIfNot: 'due',
            show: { xs: false, md: true },
            itemClass: this.invoiceDaysOverFieldClass,
          }),
        ],
      },
      wsInvoiceProductsData: [],
      wsInvoiceProductsDef: {
        cardDef: pcCardDef('wsInvoiceProducts', false, {
          expand: true,
          maxWidth: '800px',
        }),
        table: pcTableDef(
          'wsInvoiceProducts',
          'itemCode',
          ['itemCode'],
          [false],
          { depth: 3 }
        ),
        fields: [
          pcFieldDef('productCode', 'text', '', 'Code'),
          pcFieldDef('productName', 'text', '', 'Name', true, {
            width: '100%',
          }),
          pcFieldDef('quantity', 'number', '', 'Quantity', false),
          pcFieldDef('price', 'currency', '', 'Price', false),
          pcFieldDef('goods', 'currency', '', 'Value', false),
        ],
      },
    }
  },
}
</script>

<style scoped></style>
