<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="company"
        allName="Company"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <pc-simple-table :simpleTableDef="simpleTableDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'categoryStatistics',

  components: {
    pcDisplayBox,
    pcSimpleTable,
    contactGroupSelect,
  },

  props: {
    categorySummaryDef: Object,
  },

  created() {
    this.main()
  },

  computed: {
    isCategory() {
      return this.category && this.category.id ? true : false
    },

    category() {
      return this.categorySummaryDef.category
    },

    contactGroups() {
      return this.categorySummaryDef.groups
    },
  },

  watch: {
    categorySummaryDef() {
      this.main()
    },

    groupId() {
      this.main()
    },
  },

  methods: {
    main() {
      if (this.isCategory) {
        const group = pc
          .query()
          .addFind('id', this.groupId)
          .run(this.contactGroups)
        this.displayBoxDef.title = 'Category sales statistics'
        this.simpleTableDef.data = group.statistics
      }
    },

    groupSelected(groupId) {
      this.groupId = groupId
    },
  },

  data() {
    return {
      period: db.cached('period'),
      groupId: 'company',

      displayBoxDef: pcDisplayBoxDef('statistics', 'Sales statistics', {
        height: '100%',
      }),

      simpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('month', 'text', 'Month'),
          pcSimpleTableFieldDef('sales', 'currency', 'Sales', {
            decimalPlaces: 0,
          }),
          pcSimpleTableFieldDef(
            'numberCustomers',
            'int',
            'Number<br />customers'
          ),
          pcSimpleTableFieldDef(
            'customerAverage',
            'currency',
            'Customer<br />average'
          ),
          pcSimpleTableFieldDef(
            'numberInvoices',
            'int',
            'Number<br />invoices'
          ),
          pcSimpleTableFieldDef(
            'invoiceAverage',
            'currency',
            'Invoice<br />average'
          ),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
