import { render, staticRenderFns } from "./productDetails.vue?vue&type=template&id=1c63379e&scoped=true&"
import script from "./productDetails.vue?vue&type=script&lang=js&"
export * from "./productDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c63379e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDivider,VSimpleTable})
