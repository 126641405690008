<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-chart :chartDef="chartDef" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'itemSalesChart',

  props: {
    group: Object,
    item: Object,
  },

  components: {
    pcChart,
    pcDisplayBox,
  },

  watch: {
    item: {
      immediate: true,
      handler() {
        this.main()
      },
    },
  },

  methods: {
    main() {
      const item = this.item
      if (item) {
        this.displayBoxDef.title = `Sales £${pc.toFixed0(item.sales)}`
        this.chartDef
          .clearDatasets()
          .addDataset(
            pc.arrayFixed2(item.salesByMonth),
            'Sales',
            getColor('blue', 3)
          )
        this.chartDef.render()
      } else {
        this.displayBoxDef.title = 'Sales'
        this.chartDef.clearDatasets()
        this.chartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Sales', {
        title: `Sales`,
      }),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
