import pc from '@pc'

function getPropertyBreakpointValue(def, propertyPath) {
  const getDefBreakpoint = function(property) {
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    if (typeof property !== 'object') return undefined
    if (Object.hasOwnProperty.call(property, this.breakpoint))
      return this.breakpoint
    let useBreakpoint = undefined
    const breakpointIndex = breakpoints.indexOf(this.breakpoint)
    for (var i = breakpointIndex; i >= 0; i--) {
      if (Object.hasOwnProperty.call(property, breakpoints[i])) {
        useBreakpoint = breakpoints[i]
        break
      }
    }
    return useBreakpoint || 'xs'
  }.bind(this)

  const property = pc.getProperty(propertyPath, def)
  if (typeof property !== 'object' || property === null) return property
  const all = Object.hasOwnProperty.call(property, 'all')
  let value = property[getDefBreakpoint(property)]
  if (typeof value === 'string' && all) return `${property.all} ${value}`.trim()
  return value
}

export { getPropertyBreakpointValue as default }
