<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <div class="pb-1 pl-3 pr-3 subtitle-2">
      {{ productCodeAndName }}
    </div>
    <v-divider></v-divider>
    <div class="pt-1 pl-3 subtitle-2">Categories</div>
    <v-simple-table dense class="grey lighten-5">
      <tbody>
        <template v-for="(category, index) in productCategoryLevels">
          <tr :key="index">
            <td>
              <span class="caption">{{
                ['Top level', 'Sub level', 'Sub sub level'][index]
              }}</span>
            </td>
            <td class="pl-4">{{ category.name }}</td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <v-divider></v-divider>
    <div class="pt-1 pl-3 subtitle-2">Prices</div>
    <v-simple-table dense class="grey lighten-5">
      <tbody>
        <tr>
          <td>
            <span class="caption">Trade price:</span>
          </td>
          <td class="pl-4 text-right">{{ tradePrice }}</td>
        </tr>
        <tr>
          <td>
            <span class="caption">RRP:</span>
          </td>
          <td class="pl-4 text-right">{{ RRP }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'productDetails',

  components: {
    pcDisplayBox,
  },

  props: {
    productSummaryDef: Object,
  },

  computed: {
    product() {
      return this.productSummaryDef.product
    },

    productCodeAndName() {
      return this.product && this.product.code
        ? `${this.product.code} - ${this.product.name}`
        : ''
    },

    productCategoryLevels() {
      return this.product ? this.product.categories : []
    },

    tradePrice() {
      return this.product && this.product.code
        ? `£${pc.toFixed2(this.product.tradePrice)}`
        : ''
    },

    RRP() {
      return this.product && this.product.code
        ? `£${pc.toFixed2(this.product.RRP)}`
        : ''
    },
  },

  methods: {},

  data() {
    return {
      displayBoxDef: pcDisplayBoxDef('productDetails', 'Product details', {
        height: '50%',
      }),
    }
  },
}
</script>

<style scoped></style>
