import pc from '@pc'
import db from '@db'
import { getColors } from '@pcModules/pcColors.js'

const filterByGroup = (contactGroupId, productCustomers) =>
  pc
    .query()
    .addFilterIf(contactGroupId !== 'company', 'contactGroupId', contactGroupId)
    .run(productCustomers)

const filterBySales = (salesField, records) =>
  pc
    .query()
    .addFilter(salesField, 0, 'ne')
    .addSort(salesField, true)
    .run(records)

const getIds = (idField, records) =>
  records.map(record => pc.getProperty(idField, record))

const getCustomerData = (salesByCustomer, contactGroupId, salesField) => {
  const boughtAll = filterByGroup(contactGroupId, salesByCustomer)
  const boughtAllIds = getIds('customerId', boughtAll)
  const neverBought = db.cached(
    'customers',
    db
      .cachedQuery()
      .addExclude('id', boughtAllIds)
      .addFilter('sales', 0, 'ne')
      .addFilterIf(
        contactGroupId !== 'company',
        'contactGroupId',
        contactGroupId
      )
      .addSort('sales', true)
  )
  const boughtInPeriod = filterBySales(salesField, boughtAll)
  const boughtInPeriodIds = getIds('customerId', boughtInPeriod)
  const previouslyBought = pc
    .query()
    .addExclude('customerId', boughtInPeriodIds)
    .run(boughtAll)

  return {
    neverBought,
    previouslyBought,
    bought: boughtInPeriod,
  }
}

export const getTop10Data = (product, contactGroupId, salesField) => ({
  productCustomers: getCustomerData(
    product.salesByCustomer,
    contactGroupId,
    salesField
  ),
  categoryCustomers: getCustomerData(
    product.productCategory.salesByCustomer,
    contactGroupId,
    salesField
  ),
})

export const formatBoughtCustomers = (productCustomers, salesFieldName) => {
  const totalSales = pc.sumArrayProp(
    pc.getProperty(salesFieldName),
    productCustomers
  )
  const totalSalesByMonth = pc.sumArraysProp(
    pc.prop('salesByMonth'),
    productCustomers
  )

  const bought = productCustomers.slice(0, 10).map((productCustomer, index) => {
    return {
      tag: getColors('impact')[index],
      customerId: productCustomer.customerId,
      name: productCustomer.customerName,
      sales: pc.getProperty(salesFieldName, productCustomer),
      salesByMonth: productCustomer.salesByMonth,
      percentage: totalSales
        ? (pc.getProperty(salesFieldName, productCustomer) / totalSales) * 100
        : 0,
      lastInvoiced: productCustomer.lastInvoiced,
    }
  })

  const boughtTop10Sales = pc.sumArrayProp(pc.prop('sales'), bought)
  const boughtTop10SalesByMonth = pc.sumArraysProp(
    pc.prop('salesByMonth'),
    bought
  )

  const allOtherCustomerSales = totalSales - boughtTop10Sales
  const allOtherCustomerSalesByMonth = totalSalesByMonth.map(
    (total, index) => total - boughtTop10SalesByMonth[index]
  )

  allOtherCustomerSales &&
    bought.push({
      tag: getColors('impact')[bought.length],
      customerId: 'All',
      name: 'All other customers',
      sales: allOtherCustomerSales,
      salesByMonth: allOtherCustomerSalesByMonth,
      percentage: totalSales ? (allOtherCustomerSales / totalSales) * 100 : 0,
      lastInvoiced: '',
    })

  return bought
}
