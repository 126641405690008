<template>
  <v-tooltip
    v-if="tooltip ? true : false"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        icon
        :color="color"
        :disabled="disabled"
        :small="small"
        @click.stop="$emit('click', value)"
      >
        <v-icon :small="small">{{ icon }}</v-icon>
      </v-btn>
    </template>
    {{ tooltip }}
  </v-tooltip>
  <v-btn
    v-else
    icon
    :color="color"
    :disabled="disabled"
    @click.stop="$emit('click', value)"
    :small="small"
  >
    <v-icon :small="small">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'pcActionIcon',
  props: {
    value: String,
    icon: String,
    color: String,
    small: Boolean,
    tooltip: String,
    disabled: Boolean,
    top: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
  },
}
</script>
