import pc from '@pc'
import db from '@db'
import { productsGql, contactGroupProductsGql } from './getProducts.gql.js'

export const getProducts = async contactGroupId => {
  const period = db.cached('period')

  const updateProductSales = (product, contactGroupProduct) => ({
    ...product,
    sales: contactGroupProduct.sales,
    salesByDays: contactGroupProduct.salesByDays,
    salesByMonth: contactGroupProduct.salesByMonth,
    activeCustomers: contactGroupProduct.activeCustomers,
    activeCustomersByDays: contactGroupProduct.activeCustomersByDays,
    activeCustomersByMonth: contactGroupProduct.activeCustomersByMonth,
    lastInvoiced: contactGroupProduct.lastInvoiced,
  })

  const zeroProductSales = product => ({
    ...product,
    sales: 0,
    salesByDays: pc.createArray(0, 4),
    salesByMonth: pc.createArray(0, period.monthsInPeriod),
    activeCustomers: 0,
    activeCustomersByDays: pc.createArray(0, 4),
    activeCustomersByMonth: pc.createArray(0, period.monthsInPeriod),
    lastInvoiced: '0000-00-00',
  })

  const updateWithContactGroupSales = async (products, contactGroupId) => {
    const contactGroupSalesByProduct = await db.request(
      contactGroupProductsGql,
      db.args(
        db
          .queryArgs('contactGroupProducts')
          .addFilter('contactGroupId', contactGroupId)
      )
    )

    return products.map(product => {
      const contactGroupProduct = contactGroupSalesByProduct.find(
        contactGroupSales => contactGroupSales.productCode === product.code
      )
      return contactGroupProduct
        ? updateProductSales(product, contactGroupProduct)
        : zeroProductSales(product)
    })
  }

  const products = await db.request(
    productsGql,
    db.args(db.queryArgs('products'))
  )
  return contactGroupId === 'company'
    ? products
    : await updateWithContactGroupSales(products, contactGroupId)
}
