import { render, staticRenderFns } from "./coTop10CustomersList.vue?vue&type=template&id=0ede6306&scoped=true&"
import script from "./coTop10CustomersList.vue?vue&type=script&lang=js&"
export * from "./coTop10CustomersList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ede6306",
  null
  
)

export default component.exports