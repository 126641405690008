<template>
  <div v-if="words" class="pt-3 pl-6 pr-3">{{ words }}</div>
</template>

<script>
import pc from '@pc'

export default {
  name: 'top10Summary',

  components: {},

  props: {
    top10Data: Object,
  },

  watch: {
    top10Data() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.words = this.computeWords()
    },

    computeWords() {
      const {
        period,
        periodCaption,
        salesFieldName,
        activeCustomersFieldName,
        product,
        productCustomers,
        categoryCustomers,
        contactGroup,
      } = this.top10Data
      const category = product.productCategory

      const totalActive = contactGroup.activeCustomers
      const activeInPeriod = pc.getProperty(
        activeCustomersFieldName,
        contactGroup
      )
      const productCustomersInPeriod = productCustomers.bought.length
      const categoryCustomersInPeriod = categoryCustomers.bought.length
      const additionaCategoryCustomersInPeriod =
        categoryCustomersInPeriod - productCustomersInPeriod

      if (!totalActive) return 'There are no active customers.'

      let words = `There ${
        totalActive > 1 ? 'are a total of' : 'is'
      } ${totalActive} active ${pc.plural(
        'customer',
        totalActive
      )} in the period ${period.text.substr(8)}`

      if (salesFieldName !== 'sales') {
        if (activeInPeriod > 0) {
          if (totalActive > 1) {
            words = `${words}. Of these ${activeInPeriod} ${
              activeInPeriod > 1 ? 'were' : 'was'
            }`
          } else {
            words = `${words} and was`
          }
          words = `${words} active in the ${periodCaption.toLowerCase()}`
        } else {
          if (totalActive > 1) {
            words = `${words}, none were`
          } else {
            words = `${words}, but was not`
          }
          words = `${words} active in the ${periodCaption.toLowerCase()}`
        }
      }

      if (productCustomersInPeriod > 0) {
        if (activeInPeriod > 1) {
          words = `${words} and ${productCustomersInPeriod} ${
            productCustomersInPeriod > 1 ? 'have' : 'has'
          } purchased the product`
        } else {
          words = `${words} and has purchased the product`
        }
      } else {
        if (activeInPeriod > 1) {
          words = `${words} but none have purchased the product`
        } else {
          if (activeInPeriod)
            words = `${words} but has not purchased the product`
        }
      }

      if (additionaCategoryCustomersInPeriod > 0) {
        words = `${words}${
          productCustomersInPeriod ? ' and a further' : ', however'
        } ${additionaCategoryCustomersInPeriod} ${
          additionaCategoryCustomersInPeriod > 1 ? 'have' : 'has'
        }
           purchased a product from the product category ${category.name}`
      }

      words = `${words}.`
      return words
    },
  },

  data() {
    return {
      words: '',
    }
  },
}
</script>

<style scoped></style>
