import pc from '@pc'
import db from '@db'
import { productUpdates } from '../categoryMaintenance.qgl.js'

const formatProduct = product => ({
  id: product.code,
  code: product.code,
  name: `${product.code} - ${product.name}`,
  category: { ...product.category },
  parentId: null,
  isProduct: true,
  isCategory: false,
  isSystem: false,
  isModifed: false,
  isAdded: false,
  isDeleted: false,
  hasChildren: false,
  hasProducts: false,
  children: [],
})

const readProductData = async () => {
  const products = db.cached('products').map(formatProduct)
  const updatesRecord = await db.request(productUpdates)
  const updates = updatesRecord.products
  return { products, updates }
}

const formatProducts = ({ products, updates }) => ({
  products: products.map(formatProduct),
  updates,
})

const processProductUpdates = ({ products, updates }) => {
  const updateProduct = update => {
    const product = pc
      .query()
      .addFind('code', update.code)
      .run(products)
    if (product) {
      product.category = update.category
      product.isModified = true
    } else {
      console.log(`Failed to find code ${update.code} in processProductUpdates`)
    }
  }

  updates.forEach(updateProduct)
  return products
}

export const getProducts = pc.pipeAsync(
  readProductData,
  formatProducts,
  processProductUpdates
)
