import pc from '@pc'
import { getItem } from '../getItem.js'
import { updateProductFields } from './updateProductFields.js'

export const processDraggedProducts = (
  tree,
  dropItem,
  dragItem,
  activeItems
) => {
  const productsDragged = activeItems.includes(dragItem)
    ? activeItems
    : [...activeItems, dragItem]
  const parentItem = getItem(tree, dragItem.parentId)
  const draggedIds = productsDragged.map(product => product.id)
  const updateProduct = updateProductFields(dropItem)

  parentItem.children = pc
    .query()
    .addExclude('id', draggedIds)
    .run(parentItem.children)
  parentItem.hasProducts = parentItem.children.length > 0

  dropItem.children = pc
    .query()
    .addSort('code')
    .run([...dropItem.children, ...productsDragged.map(updateProduct)])
  dropItem.hasProducts = true
}
