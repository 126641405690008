import Vue from 'vue'
import App from '@appViews/App.vue'
import router from '@app/router'
import store from '@app/store'
import vuetify from '@app/plugins/vuetify'
import '@app/plugins/vuelidate.js'
import '@app/plugins/vueObserveVisibility.js'

/**
 * @author Stuart Willmott <sw@programcoders.com>
 */

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
