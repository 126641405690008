<template>
  <th :class="headingClass">
    <span v-html="field.heading"></span>
  </th>
</template>

<script>
export default {
  name: 'pcSimpleTableField',

  props: {
    simpleTableDef: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    field() {
      return this.simpleTableDef.fields[this.index]
    },

    fieldType() {
      return this.field.type
    },

    isFirstField() {
      return this.index === 0
    },

    isLastField() {
      return this.index + 1 === this.simpleTableDef.fields.length
    },

    headingClass() {
      const paddingRight =
        this.fieldType === 'lastInvoicedDate' ? 'pr-3' : 'pr-0'
      const paddingLastRight =
        this.fieldType === 'lastInvoicedDate' ? 'pr-6' : 'pr-3'
      return [
        this.isFirstField
          ? 'pl-3'
          : `pl-${this.simpleTableDef.paddingBetweenFields}`,
        this.isLastField ? paddingLastRight : paddingRight,
        this.simpleTableDef.headerClass,
        this.field.headingClass,
      ]
    },
  },

  watch: {},

  methods: {},

  data() {
    return {
      showTooltip: false,
    }
  },
}
</script>

<style scoped></style>
