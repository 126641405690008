import pc from '@pc'
import { getItem } from '../getItem.js'
import { updateProductFields } from './updateProductFields.js'

export const processDraggedCategory = (tree, dropItem, dragItem) => {
  const updateChildren = category => {
    category.isModified = true
    for (let i = 0; i < category.children.length; i++) {
      const item = category.children[i]
      if (item.isCategory) {
        item.level = category.level + 1
        item.grandParentId = category.parentId
        item.parentId = category.id
        updateChildren(item)
      } else {
        updateProductFields(category)(item)
      }
    }
  }

  const parentItem = getItem(tree, dragItem.parentId)

  dragItem.grandParentId = dropItem.id === 'category' ? '' : dropItem.parentId
  dragItem.parentId = dropItem.id
  dragItem.level = dropItem.level + 1

  updateChildren(dragItem)
  parentItem.children = pc
    .query()
    .addExclude('id', [dragItem.id])
    .run(parentItem.children)
  dropItem.children = pc
    .query()
    .addSort('name')
    .run([...dropItem.children, dragItem])
}
