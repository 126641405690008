import { gql } from 'graphql-tag'

export const signup = gql`
  mutation($record: SigninInput) {
    signup(record: $record) {
      id
      authenticated
      errorType
      errorMessage
    }
  }
`
