<template>
  <v-row
    id="companyDashboard"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
      <company-preferences
        :openDialog="preferencesDialog"
        @closed="preferencesDialogClosed"
      ></company-preferences>

      <v-tabs class="pc-size100" height="0px">
        <v-tabs-items v-model="tab" class="pc-col">
          <v-tab-item class="pc-col mt-0">
            <pc-card :card-def="dashboardCardDef">
              <template v-slot:title>
                <pc-toolbar :toolbar-def="toolbarDef"></pc-toolbar>
              </template>

              <template v-slot:text>
                <v-row
                  class="fill-height mt-0"
                  justify="center"
                  align="stretch"
                >
                  <v-col
                    id="companyDashboardContent"
                    :cols="12"
                    class="pc-col d-flex pl-3 pr-0 pt-0 pb-0"
                  >
                    <div>
                      <pc-nav-drawer
                        class="pt-0"
                        :navDrawerDef="navDrawerDef"
                        v-on:selected="
                          menuItem => (menuIdSelected = menuItem.id)
                        "
                      />
                    </div>
                    <companySummary
                      :companySummaryDef="companySummaryDef"
                      :categoryDrillData="summaryCategoryDrillData"
                    />
                    <turnover
                      :turnoverDef="turnoverDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <sales-30-days
                      v-if="sales30DayDef.show"
                      :sales30DayDef="sales30DayDef"
                    />
                    <co-top-10
                      v-if="top10Def.show"
                      :top10Def="top10Def"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <paymentAnalysis
                      v-if="paymentAnalysisDef.show"
                      class="mt-0"
                      :paymentAnalysisDef="paymentAnalysisDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                  </v-col>
                </v-row>
              </template>
            </pc-card>
          </v-tab-item>
          <v-tab-item class="pc-col pt-3">
            <customer-dashboard
              :dashboardCustomer="customer"
              @exit="exitCustomerDashboard"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import db from '@db'
import { LocalStorage } from '@pcModules/localStorage.js'
import { whitespaceLocalStorageSchema } from '@appModules/ws.js'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import companySummary from '@appViews/companyDashboard/companySummary/companySummary.vue'
import turnover from '@appViews/companyDashboard/turnover/turnover.vue'
import sales30Days from '@appViews/companyDashboard/sales30Days/sales30Days.vue'
import coTop10 from '@appViews/companyDashboard/coTop10/coTop10.vue'
import companyPreferences from '@appViews/companyDashboard/companyPreferences'
import customerDashboard from '@appViews/customerDashboard/customerDashboard.vue'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import {
  pcNavDrawerDef,
  pcNavDrawerItem,
} from '@pcComponents/defs/pcNavDrawerDef.js'

import pcNavDrawer from '@pcComponents/pcNavDrawer.vue'
import paymentAnalysis from '@sharedComponents/paymentAnalysis/paymentAnalysis.vue'
import { companyGql } from './companyDashboard.gql.js'

export default {
  name: 'companyDashboard',
  components: {
    pcCard,
    pcToolbar,
    pcNavDrawer,
    companySummary,
    turnover,
    sales30Days,
    coTop10,
    companyPreferences,
    paymentAnalysis,
    customerDashboard,
  },

  directives: {},

  async created() {
    await this.initialise()
    this.main()
  },

  computed: {
    title() {
      return `Company dashboard - ${
        {
          summary: 'Summary',
          turnover: 'Turnover analysis',
          sales30Days: '30 days sales',
          top10: 'Top 10',
          payment: 'Payment analysis',
        }[this.menuIdSelected]
      }`
    },
  },

  watch: {
    menuIdSelected: {
      immediate: true,
      handler: function(value) {
        this.companySummaryDef.show = value === 'summary'
        this.turnoverDef.show = value === 'turnover'
        this.sales30DayDef.show = value === 'sales30Days'
        this.top10Def.show = value === 'top10'
        this.paymentAnalysisDef.show = value === 'payment'
        this.toolbarDef.title = this.title
      },
    },
  },

  methods: {
    async initialise() {
      const whitespaceLocalStorage = new LocalStorage(
        'whitespace',
        whitespaceLocalStorageSchema
      )
      this.openingWindow = whitespaceLocalStorage.getProperty(
        'companyDashboard.openingWindow'
      )
      this.menuIdSelected = this.openingWindow || 'summary'
    },

    async main() {
      const company = await db.request(companyGql)
      this.company = company
      this.paymentAnalysisDef = {
        ...this.paymentAnalysisDef,
        contactGroup: company,
      }
    },

    updateSummaryPieChart(data) {
      if (
        data.monthIndex === -1 &&
        data.total &&
        data.categoryId === 'category'
      ) {
        this.summaryCategoryDrillData = data
      }
    },

    preferences() {
      this.preferencesDialog = true
    },

    preferencesDialogClosed(openingWindow) {
      this.preferencesDialog = false
      this.openingWindow = openingWindow
    },

    showCustomerDashboard(payload) {
      this.customer = db.cached(
        'customers',
        db.cachedQuery().addFind('id', payload.customerId)
      )
      this.tab = 1
    },

    exitCustomerDashboard() {
      this.tab = 0
    },
  },

  data() {
    return {
      tab: 0,
      company: undefined,
      customer: undefined,
      period: db.cached('period'),
      preferencesDialog: false,
      openingWindow: '',
      menuIdSelected: 'summary',
      summaryCategoryDrillData: {},

      textHeight: '',
      textWidth: '',

      dashboardCardDef: pcCardDef('companyDashboard', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef(
        'companyDashboardToolbar',
        'Company dashboard',
        {},
        [
          pcToolbarActionDef(
            'preferences',
            'mdi-cog-outline',
            this.preferences,
            'Set preferences'
          ),
        ]
      ),

      navDrawerDef: pcNavDrawerDef(
        'optionsNavDrawer',
        [
          pcNavDrawerItem('summary', 'mdi-view-dashboard', 'Summary'),
          pcNavDrawerItem('turnover', 'mdi-chart-bar', 'Turnover analysis'),
          pcNavDrawerItem(
            'sales30Days',
            'mdi-calendar-month-outline',
            'Sales last 30 days'
          ),
          pcNavDrawerItem('top10', 'mdi-sort-descending', 'Top 10'),
          pcNavDrawerItem('payment', 'mdi-receipt', 'Payment analysis'),
        ],
        { width: '200px', expandOnHover: true, miniVariant: true }
      ),

      companySummaryDef: {
        show: false,
      },

      turnoverDef: {
        show: false,
        categoryDrillHook: this.updateSummaryPieChart,
      },

      sales30DayDef: {
        show: false,
      },

      top10Def: {
        show: false,
      },

      paymentAnalysisDef: {
        show: false,
      },
    }
  },
}
</script>

<style scoped></style>
