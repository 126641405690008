import { gql } from 'graphql-tag'

const invoices = gql`
  query($dbQuery: DbQuery) {
    invoices(dbQuery: $dbQuery) {
      isInvoice
      date
      invoiceNumber
      customerId
      contactGroupId
      goods
    }
  }
`
const products = gql`
  query($dbQuery: DbQuery) {
    products(dbQuery: $dbQuery) {
      code
      name
      sales
      salesByMonth
      lastInvoiced
    }
  }
`
const queries = {
  invoices,
  products,
}

export { queries as companyDashboard }
