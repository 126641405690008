import pc from '@pc'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { getColors } from '@pcModules/pcColors'

const pieOptions = () => ({
  cutout: '0%',
  elements: {
    center: {
      text: '',
      //color: '#FF6384', // Default is #000000
      fontStyle: 'Roboto', // Default is Arial
      sidePadding: 20, // Default is 20 (as a percentage)
      minFontSize: 15, // Default is 20 (in px), set to false and text will not wrap.
      lineHeight: 25, // Default is 25 (in px), used for when text wraps
    },
  },
})

const barOptions = () => ({
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
})

const lineOptions = () => ({
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
})

const chartTypeOptions = chartType => {
  const index = ['pie', 'bar', 'line'].indexOf(chartType)
  return index === -1 ? {} : [pieOptions, barOptions, lineOptions][index]()
}

const getChartDef = type => {
  const domId = pc.uid()
  return {
    chart: {
      type: type,
      show: true,
      domId: pc.uid(),
      containerClass: 'pl-2 pr-2',
      onClick: null,
      tooltipCallbacks: '',
      customTooltip: null,
      gradient: false,
      titleColor: '#90CAF9',
      title: '',
      subTitle: '',
    },
    card: {
      ...pcCardDef(`spark-chart-card${domId}`, false, {
        elevation: 4,
        titleClass: 'body-2 justify-center pl-2 pr-2',
        subTitleClass: 'caption font-weight-bold justify-center pl-2 pr-2',
        width: '300px',
        height: '200px',
        cardClass: 'grey lighten-5 pb-1',
        //chartClass: 'pl-1 pr-1 pb-1 grey lighten-5',
      }),
    },
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
          font: {
            size: 18,
          },
          text: '',
        },
        legend: {
          display: false,
          onHover: null,
        },
        tooltip: {
          enabled: true,
          external: null,
          callbacks: {},
          backgroundColor: '#808080',
          titleColor: '#FFFFFF',
          titleFont: {
            size: 16,
          },
          bodyColor: '#FFFFFF',
          bodyFont: {
            size: 14,
          },
        },
      },
      ...chartTypeOptions(type),
    },
    pc: {},
  }
}

/** ---------------------------------------------------------------------------
 * Class representing a chart
 * @param {string} type chart type ('bar', 'line', 'pie')
 * @return {sparkChartDef}
 */
class SparkChartDef {
  constructor(type) {
    const def = getChartDef(type)
    this.chart = def.chart
    this.card = def.card
    this.data = def.data
    this.options = def.options
    this.pc = def.pc
    this.chart.gradient = def.chart.gradient
    this.chartInstance = undefined
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setProperties :: (string, [[string, a]]) -> class
   * @desc Sets properties in the sparkChartDef class
   * @param {string} section Section of the sparkChartDef to be updated ('chart', 'card', 'sparkChart', 'options', 'data')
   * @param {array[]} properties Array of properties to set
   * @param {array[]} properties.property A property be to set
   * @param {string} properties.property.path Property path e.g. 'data.datasets'
   * @param {*} properties.property.value Value to set the property
   * @return {class} sparkChartDef instance to chain next action
   */
  setProperties(section, properties) {
    this[section] = pc.setProperties(properties, this[section])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setTitle :: (string, boolean, number, array) -> class
   * @desc Sets the title properties and optionally other title properties
   * @param {string} title Chart title
   * @param {boolean} [show=true] Show the title
   * @param {number} [fontSize=18] Fontsize of the titel
   * @param {array[]} [properties=[]] Array of properties to set
   * @param {array[]} properties.property A property be to set
   * @param {string} properties.property.path Property path e.g. property name without 'plugins.title'
   * @param {*} properties.property.value Value to set the label property
   * @return {class} sparkChartDef instance to chain next action
   */
  setTitle(title, backgroundColor = '#90CAF9', fontSize = '16px') {
    this.setChart([['title', title]])
    this.setCard([
      [
        'titleStyle',
        `background-color: ${backgroundColor}; color: ${
          pc.isDarkColor(backgroundColor) ? 'white' : 'black'
        }; font-weight: bold; font-size: ${fontSize};`,
      ],
    ])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setTitle :: (string, boolean, number, array) -> class
   * @desc Sets the title properties and optionally other title properties
   * @param {string} title Chart title
   * @param {boolean} [show=true] Show the title
   * @param {number} [fontSize=18] Fontsize of the titel
   * @param {array[]} [properties=[]] Array of properties to set
   * @param {array[]} properties.property A property be to set
   * @param {string} properties.property.path Property path e.g. property name without 'plugins.title'
   * @param {*} properties.property.value Value to set the label property
   * @return {class} sparkChartDef instance to chain next action
   */
  setSubTitle(subTitle) {
    this.setChart([['subTitle', subTitle]])
    return this
  }

  setSize(width, height) {
    this.setCard([
      ['width', `${width}px`],
      ['height', `${height}px`],
    ])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setLegend :: (string, boolean) -> class
   * @desc Sets the legend display property
   * @param {boolean} [show=true] Show the title
   * @return {class} sparkChartDef instance to chain next action
   */
  setLegend(show) {
    this.setOptions([['plugins.legend.display', show]])
    return this
  }
  /** ---------------------------------------------------------------------------
   * @summary setLabels :: (array) -> class
   * @desc Sets the chart labels
   * @param {array[]} labels An array of label strings
   * @return {class} sparkChartDef instance to chain next action
   */
  setLabels(labels) {
    this.setData([['labels', labels]])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary dataset :: (object) -> class
   * @desc Pushes a new dataset object to the chart datasets
   * @param {object} dataset A dataset object - see newDataset
   * @return {class} sparkChartDef instance to chain next action
   */
  dataset(dataset) {
    this.data.datasets.push(dataset)
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary setChart :: (array) -> class
   * @desc Sets properties in the 'chart' section
   * @param {array} properties An array of properties to set
   * @return {class} sparkChartDef instance to chain next action
   */
  setChart(properties) {
    return this.setProperties('chart', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setCard :: (array) -> class
   * @desc Sets properties in the 'card' section
   * @param {array} properties An array of properties to set
   * @return {class} sparkChartDef instance to chain next action
   */
  setCard(properties) {
    return this.setProperties('card', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setData :: (array) -> class
   * @desc Sets properties in the 'data' section
   * @param {array} properties An array of properties to set
   * @return {class} sparkChartDef instance to chain next action
   */
  setData(properties) {
    return this.setProperties('data', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setOptions :: (array) -> class
   * @desc Sets properties in the 'optionst' section
   * @param {array} properties An array of properties to set
   * @return {class} sparkChartDef instance to chain next action
   */
  setOptions(properties) {
    return this.setProperties('options', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary setPc :: (array) -> class
   * @desc Sets properties in the 'pc' section - this section is for general data
   * @param {array} properties An array of properties to set
   * @return {class} sparkChartDef instance to chain next action
   */
  setPc(properties) {
    return this.setProperties('pc', properties)
  }

  /** ---------------------------------------------------------------------------
   * @summary show ::  -> boolean - returns or sets the chart show status
   */
  get show() {
    return this.chart.show
  }

  set show(visible) {
    this.setChart([['show', !!visible]])
    this.setCard([['show', !!visible]])
  }

  /** ---------------------------------------------------------------------------
   * @summary clearDatasets :: () -> class
   * @desc Clears all the datasets
   * @return {class} sparkChartDef instance to chain next action
   */
  clearDatasets() {
    this.setData([['datasets', []]])
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary newDataset :: () -> object
   * @desc Returns a dataset object - add to the chart with the datset method
   * @return {object} Datset object
   */
  newDataset() {
    return {
      type: this.chart.type,
      label: '',
      backgroundColor: [],
      data: [],
      fill: false,
    }
  }

  /** ---------------------------------------------------------------------------
   * @summary addDataset :: (data, label, colors, properties) -> object
   * @desc Creates and adds a new dataset to the datasets array
   * @param {array} data Dataset data
   * @param {string} [label=''] Label for dataset
   * @param {number|array} [colors='default'] A single color code, gradient of array or either
   * @param {array} [properties=[]] Other dataset properties
   * @return {class} sparkChartDef instance to chain next action
   */
  addDataset(data, label = '', colors = 'default', properties = []) {
    if (colors === 'default') {
      if (this.chart.type === 'pie') colors = getColors('impact')
      if (this.chart.type === 'bar') colors = getColors('impact')[0]
      if (this.chart.type === 'line') colors = getColors('impact')[0]
    }
    this.data.datasets.push(
      pc.setProperties(
        [
          ...[
            ['data', data],
            ['label', label],
            ['backgroundColor', colors],
            ['borderColor', colors],
          ],
          ...properties,
        ],
        this.newDataset
      )
    )
    return this
  }

  /** ---------------------------------------------------------------------------
   * @summary getDataset :: (number) -> object
   * @desc Returns the indexed dataset
   * @param {number} index Dataset index
   * @return {object} The dataset object
   */
  getDataset(index) {
    return this.data.datasets[index]
  }

  instance = function(chartInstance) {
    this.chartInstance = chartInstance
  }.bind(this)

  /** ---------------------------------------------------------------------------
   * @summary render :: () -> object
   * @desc Emits a render event to the chart
   * @return {class} sparkChartDef instance to chain next action
   */
  render() {
    pc.$eventBus.$emit(`spark-chart-${this.chart.domId}`, {
      event: 'render',
      data: {},
    })
    return this
  }

  update() {
    pc.$eventBus.$emit(`spark-chart-${this.chart.domId}`, {
      event: 'update',
      data: {},
    })
    return this
  }
}

export const pcSparkChartDef = type => new SparkChartDef(type)
