<template>
  <v-row justify="center">
    <v-card width="500px">
      <v-img :src="require('../assets/logo.jpg')" contain class="pt-5"></v-img>
      <v-card color="#385F73" dark>
        <v-card-title class="text-h5">
          Cookies policy
        </v-card-title>

        <v-card-subtitle
          >We only use cookies to help keep this site secure and prevent
          fraudulent activity. By registering on this site, you consent to
          cookies.</v-card-subtitle
        >
      </v-card>

      <v-card-text class="mt-0 pt-0">
        <v-form ref="form">
          <pc-text :parent-id="uid" :field="formSchema.email"></pc-text>
          <pc-text :parent-id="uid" :field="formSchema.password"></pc-text>
          <pc-text
            :parent-id="uid"
            :field="formSchema.confirmPassword"
          ></pc-text>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="loading">
        <v-flex xs10 offset-xs1>
          <div class="pb-8 text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-flex>
      </v-card-actions>
      <v-card-actions v-else class="pb-3">
        <v-flex class="pl-4" xs4>
          <v-btn @click="cancel">Cancel</v-btn>
        </v-flex>
        <v-flex class="pr-4" xs12>
          <v-btn block color="primary" @click="submit()">Sign up</v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { mixinFormValidator } from '@app/mixins/mixinFormValidator'
import pcText from '@pcComponents/pcText.vue'
import { initUser } from '@appModules/user'

export default {
  name: 'signup',
  mixins: [mixinFormValidator],
  components: {
    'pc-text': pcText,
  },
  methods: {
    cancel() {
      this.$emit('logout')
    },

    async submit() {
      if (this.validateForm()) {
        const user = await this.$store.dispatch('signupUser', {
          meta: {},
          data: {
            email: this.formSchema.email.value,
            password: this.formSchema.password.value,
          },
        })
        if (user.authenticated) {
          this.loading = true
          await initUser()
          await this.$store.dispatch('setIsUser', true)
          this.$router.push('/companyDashboard')
        } else {
          this.loading = false
          switch (user.errorType) {
            case 'email':
              this.formSchema.email.errors = [user.errorMessage]
              break
            case 'password':
              this.formSchema.password.errors = [user.errorMessage]
              this.formSchema.confirmPassword.errors = []
              break
            default:
              this.formSchema.password.errors = [user.errorMessage]
          }
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      formSchema: {
        email: {
          present: { label: 'Email', email: true },
          valid: { required, email },
        },
        password: {
          present: { label: 'Password', password: true },
          valid: { required, minLength: minLength(6) },
          customErrMsg: {
            minLength: 'Password must be a minimum of 6 characters',
          },
        },
        confirmPassword: {
          present: { label: 'Confirm Password', password: true },
          valid: {
            required,
            sameAsPassword: sameAs(function() {
              return this.formSchema.password.value
            }),
          },
        },
      },
    }
  },
}
</script>

<style scoped></style>
