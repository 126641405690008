<template>
  <v-row
    v-if="customerSummaryDef.show"
    id="customerSummary"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <div class="grid-container pb-0">
        <div class="customer-details">
          <customerDetails :customer="customer" />
        </div>

        <div class="outstanding">
          <outstanding :customer="customer" />
        </div>

        <div class="sales-chart">
          <sales-chart :customer="customer" />
        </div>

        <div class="statistics">
          <statistics :customer="customer" />
        </div>

        <div class="sales-by-category-chart">
          <sales-by-category-chart
            :customer="customer"
            :categories="categories"
          />
        </div>

        <div class="sales-by-category-list">
          <sales-by-category-list
            :customer="customer"
            :categories="categories"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { getColors } from '@pcModules/pcColors.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'

import customerDetails from './customerDetails.vue'
import outstanding from './outstanding.vue'
import statistics from './statistics.vue'
import salesChart from './salesChart.vue'
import salesByCategoryChart from './salesByCategoryChart.vue'
import salesByCategoryList from './salesByCategoryList.vue'

export default {
  name: 'customerSummary',
  components: {
    customerDetails,
    outstanding,
    statistics,
    salesChart,
    salesByCategoryChart,
    salesByCategoryList,
  },

  props: {
    customerSummaryDef: Object,
    categoryDrillData: Object,
  },

  created() {
    this.main()
  },

  computed: {
    customerId() {
      return this.customerSummaryDef.customerId
    },
  },

  watch: {
    customerId() {
      this.main()
    },

    categoryDrillData(value) {
      this.categories = this.getCategoryData(value)
    },
  },

  methods: {
    async main() {
      if (this.customerId) {
        this.customer = await db.request(
          db.query('customerDashboard.customer'),
          db.args(db.queryArgs('customers').addFind('id', this.customerId))
        )
      }
    },

    getCategoryData(data) {
      if (!data.isCategories || !data.total || !data.categoryId === 'category')
        return []

      const boughtLevel1Categories = pc
        .query()
        .addFilter('bought', true)
        .addSort('sales', true)
        .run(data.data)

      const totalSales = pc.sumArrayProp(
        pc.prop('sales'),
        boughtLevel1Categories
      )

      const addPercentageAndColor = (record, index) => {
        record.percentage = pc.percentage(record.sales, totalSales)
        record.tag = this.chartColors[index]
        return record
      }

      return boughtLevel1Categories.map(addPercentageAndColor)
    },
  },

  data() {
    return {
      customer: {},
      categories: [],
      chartColors: getColors('impact'),

      customerSummaryCardDef: pcCardDef('customerSummary', false, {
        loaderType: 'card',
        elevation: '0',
      }),

      customerSummaryToolbarDef: pcToolbarDef(
        'customerSummaryToolbar',
        'Customer summary',
        {
          dense: true,
          color: 'primary lighten-2',
        }
      ),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'customer-details'
    'outstanding'
    'statistics'
    'sales-chart'
    'sales-by-category-list'
    'sales-by-category-chart';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'customer-details outstanding'
      'statistics sales-chart'
      'sales-by-category-list sales-by-category-chart';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, minmax(100px, 1fr));
    grid-template-areas:
      'customer-details sales-chart sales-by-category-chart'
      'outstanding statistics sales-by-category-list';
  }
}
.customer-details {
  grid-area: customer-details;
  overflow-y: auto;
}
.outstanding {
  grid-area: outstanding;
  overflow-y: auto;
}
.sales-chart {
  grid-area: sales-chart;
  overflow-y: auto;
}
.statistics {
  grid-area: statistics;
  overflow-y: auto;
}
.sales-by-category-chart {
  grid-area: sales-by-category-chart;
  overflow-y: auto;
}
.sales-by-category-list {
  grid-area: sales-by-category-list;
  overflow-y: auto;
}
</style>
