<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <paid-invoices-profile
      :large="true"
      :legend="true"
      :clickable="true"
      :profiles="paidProfiles"
      v-on="$listeners"
    />
  </pc-display-box>
</template>

<script>
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import paidInvoicesProfile from '@appViews/sharedComponents/paidInvoicesProfile.vue'

export default {
  name: 'paidInvoicesTable',

  components: {
    pcDisplayBox,
    paidInvoicesProfile,
  },

  props: {
    group: Object,
  },

  created() {},

  watch: {},

  computed: {
    paidProfiles() {
      return this.group ? this.group.paidInvoicesProfile : undefined
    },
  },

  methods: {},

  data() {
    return {
      displayBoxDef: pcDisplayBoxDef('payment', 'Paid invoices profile'),
    }
  },
}
</script>

<style scoped>
.profile {
  width: 25px;
  height: 25px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
}
.month-name {
  height: 9px;
  width: 25px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 1px;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  transform: rotate(-60deg);
}
</style>
