import createEasyFirestore from 'vuex-easy-firestore'

function getStoreModules() {
  const files = require.context('./modules', false, /\.js$/)
  const modules = {}

  files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
  })
  return modules
}

function getStoreDbPlugins(firebase) {
  const files = require.context('./dbPlugins', false, /\.js$/)
  const dbPlugins = []

  files.keys().forEach(key => {
    dbPlugins.push(createEasyFirestore(files(key).default, { logging: process.env.NODE_ENV !== 'production', firebase }))
  })
  return dbPlugins
}

export { getStoreModules, getStoreDbPlugins }