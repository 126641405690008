import { gql } from 'graphql-tag'

const customerSalesByMonth = gql`
  query($dbQuery: DbQuery) {
    customers(dbQuery: $dbQuery) {
      id
      salesByMonth
    }
  }
`

const productCustomerSalesByMonth = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      salesByMonth
      quantityByMonth
    }
  }
`

const customerSummary = gql`
  query($dbQuery: DbQuery) {
    customers(dbQuery: $dbQuery) {
      id
      sales
      salesByMonth
      invoices
      nextDueDaysToPay
      nextDueAmount
      nextDueInvoiceNumber
      nextDueDate
      daysOver
      dueDate
      overdue
      paymentProfile
    }
  }
`

const queries = {
  customerSalesByMonth,
  productCustomerSalesByMonth,
  customerSummary,
}

export { queries as tooltip }
