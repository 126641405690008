import pc from '@pc'

export function runQuery(query, data) {
  let sortField

  const applyFilters = filters => record =>
    filters.reduce(applyFilter(record), true)

  const applyFilter = record => (result, filter) => {
    if (!result) return result
    const fieldValue = pc.getProperty(filter.fieldPath, record)
    const comparator = filter.comparator || 'eq'

    if (typeof comparator === 'function') return comparator(record, fieldValue)
    switch (comparator) {
      case 'eq':
        result = fieldValue == filter.value
        break
      case 'ne':
        result = fieldValue != filter.value
        break
      case 'gt':
        result = fieldValue > filter.value
        break
      case 'gte':
        result = fieldValue >= filter.value
        break
      case 'lt':
        result = fieldValue < filter.value
        break
      case 'lte':
        result = fieldValue <= filter.value
        break
      default:
        console.log(`Invalid comparator: ${filter.comparator} in query.filter`)
    }
    return result
  }

  const queryFind = records => {
    const record = records.find(applyFilters(query.find))
    return record ? [record] : []
  }

  function queryInclude(records) {
    const fieldPath = pc.getProperty('include.fieldPath', query)
    if (!fieldPath) return records
    return records.filter(record => {
      const fieldValue = pc.getProperty(fieldPath, record)
      return query.include.values.reduce((include, value) => {
        if (fieldValue === value) include = true
        return include
      }, false)
    })
  }

  function queryExclude(records) {
    const fieldPath = pc.getProperty('exclude.fieldPath', query)
    if (!fieldPath) return records
    return records.filter(record => {
      const fieldValue = pc.getProperty(fieldPath, record)
      return query.exclude.values.reduce((include, value) => {
        if (fieldValue === value) include = false
        return include
      }, true)
    })
  }

  const queryFilter = records => {
    if (!query.filter.length) return records
    return records.filter(applyFilters(query.filter))
  }

  const queryLimit = records =>
    query.limit ? records.slice(0, query.limit) : records

  const queryLimitAfterSort = records =>
    query.limitAfterSort ? records.slice(0, query.limitAfterSort) : records

  const queryMap = records => (query.map ? records.map(query.map) : records)

  const querySort = records => {
    if (!query.sort.fieldPath) return records
    const sortMethod = query.sort.sortFn || sortRecords
    sortField = query.sort.fieldPath
    return query.sort.descend
      ? records.sort(sortMethod).reverse()
      : records.sort(sortMethod)
  }

  const sortRecords = (a, b) => {
    const valueA = pc.getProperty(sortField, a)
    const valueB = pc.getProperty(sortField, b)
    if (valueA < valueB) return -1
    if (valueA > valueB) return 1
    return 0
  }

  const querySum = records => {
    if (query.sum.length) {
      // Create a record of the field to be summed, Initial value = 0
      const sumRecord = query.sum.reduce((sumRecord, fieldPath) => {
        return pc.setProperty(fieldPath, 0, sumRecord)
      }, {})
      // Run all records into the sumRecord created above
      const result = records.reduce((sum, record) => {
        query.sum.forEach(fieldPath => {
          sum = pc.setProperty(
            fieldPath,
            pc.getProperty(fieldPath, sum) + pc.getProperty(record, fieldPath),
            sum
          )
        })
        return sum
      }, sumRecord)
      return [result]
    }
    return records
  }

  const queryReduce = records =>
    query.reduce
      ? records.reduce(query.reduce.reduceFn, query.reduce.initialValue)
      : records

  if (query.find.length) return queryFind(data, query)

  const result = pc.pipeSync(
    queryInclude,
    queryExclude,
    queryFilter,
    queryLimit,
    queryMap,
    querySort,
    queryLimitAfterSort,
    querySum,
    queryReduce
  )(data)

  if (query.clone) {
    return pc.cloneJSON(result)
  }
  return result
}
