export const getColors = (style = 'rainbow') => {
  let number = 80
  let stepping = 2
  let startIndex = 4
  let startSlice = startIndex
  let colors = []

  if (style === 'pastel') return _pastelColorsDef

  while (colors.length < number && startSlice >= 0) {
    let start = startSlice
    while (colors.length < number && start < 10) {
      colors = colors.concat(_getColorSlice(start, style))
      start += stepping
    }
    startSlice += 1
  }
  return colors.slice(0, number)
}

const _impactIndex = [
  'blue',
  'red',
  'green',
  'yellow',
  'light-blue',
  'indigo',
  'pink',
  'light-green',
  'amber',
  'cyan',
  'purple',
  'lime',
  'orange',
  'teal',
  'deep-purple',
  'deep-orange',
]

const _colorIndex = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'blue-grey',
  'grey',
]

const _getColorSlice = (index, style) => {
  const colorArray = style === 'rainbow' ? _colorIndex : _impactIndex
  return colorArray.reduce((colors, color) => {
    colors.push(getColor(color, index))
    return colors
  }, [])
}
const _getColorIndex = colorName => _colorIndex.indexOf(colorName)
export const getColor = (color, index) =>
  _colorDefs[_getColorIndex(color)][index]

const _colorDefs = [
  // red
  [
    '#FFEBEE',
    '#FFCDD2',
    '#EF9A9A',
    '#E57373',
    '#EF5350',
    '#F44336',
    '#E53935',
    '#D32F2F',
    '#C62828',
    '#B71C1C',
    '#FF8A80',
    'a#FF5252',
    '#FF1744',
    '#D50000',
  ],
  [
    // pink
    '#fce4ec',
    '#f8bbd0',
    '#f48fb1',
    '#f06292',
    '#ec407a',
    '#e91e63',
    '#d81b60',
    '#c2185b',
    '#ad1457',
    '#880e4f',
    '#ff80ab',
    '#ff4081',
    '#f50057',
    '#c51162',
  ],
  // purple
  [
    '#f3e5f5',
    '#e1bee7',
    '#ce93d8',
    '#ba68c8',
    '#ab47bc',
    '#9c27b0',
    '#8e24aa',
    '#7b1fa2',
    '#6a1b9a',
    '#4a148c',
    '#ea80fc',
    '#e040fb',
    '#d500f9',
    '#aa00ff',
  ],
  // deep purple
  [
    '#ede7f6',
    '#d1c4e9',
    '#b39ddb',
    '#9575cd',
    '#7e57c2',
    '#673ab7',
    '#5e35b1',
    '#512da8',
    '#4527a0',
    '#311b92',
    '#b388ff',
    '#7c4dff',
    '#651fff',
    '#6200ea',
  ],
  // indigo
  [
    '#e8eaf6',
    '#c5cae9',
    '#9fa8da',
    '#7986cb',
    '#5c6bc0',
    '#3f51b5',
    '#3949ab',
    '#303f9f',
    '#283593',
    '#1a237e',
    '#8c9eff',
    '#536dfe',
    '#3d5afe',
    '#304ffe',
  ],
  // blue
  [
    '#E3F2FD',
    '#BBDEFB',
    '#90CAF9',
    '#64B5F6',
    '#42A5F5',
    '#2196F3',
    '#1E88E5',
    '#1976D2',
    '#1565C0',
    '#0D47A1',
    '#82B1FF',
    '#448AFF',
    '#2979FF',
    '#2962FF',
  ],
  // light-blue
  [
    '#e1f5fe',
    '#b3e5fc',
    '#81d4fa',
    '#4fc3f7',
    '#29b6f6',
    '#03a9f4',
    '#039be5',
    '#0288d1',
    '#0277bd',
    '#01579b',
    '#80d8ff',
    '#40c4ff',
    '#00b0ff',
    '#0091ea',
  ],
  // cyan
  [
    '#e0f7fa',
    '#b2ebf2',
    '#80deea',
    '#4dd0e1',
    '#26c6da',
    '#00bcd4',
    '#00acc1',
    '#0097a7',
    '#00838f',
    '#006064',
    '#84ffff',
    '#18ffff',
    '#00e5ff',
    '#00b8d4',
  ],
  // teal
  [
    '#e0f2f1',
    '#b2dfdb',
    '#80cbc4',
    '#4db6ac',
    '#26a69a',
    '#009688',
    '#00897b',
    '#00796b',
    '#00695c',
    '#004d40',
    '#a7ffeb',
    '#64ffda',
    '#1de9b6',
    '#00bfa5',
  ],
  //  green
  [
    '#E8F5E9',
    '#C8E6C9',
    '#A5D6A7',
    '#81C784',
    '#66BB6A',
    '#4CAF50',
    '#43A047',
    '#388E3C',
    '#2E7D32',
    '#1B5E20',
    '#B9F6CA',
    '#69F0AE',
    '#00E676',
    '#00C853',
  ],
  // light-green
  [
    '#f1f8e9',
    '#dcedc8',
    '#c5e1a5',
    '#aed581',
    '#9ccc65',
    '#8bc34a',
    '#7cb342',
    '#689f38',
    '#558b2f',
    '#33691e',
    '#ccff90',
    '#b2ff59',
    '#76ff03',
    'a#64dd17',
  ],
  // lime
  [
    '#f9fbe7',
    '#f0f4c3',
    '#e6ee9c',
    '#dce775',
    '#d4e157',
    '#cddc39',
    '#c0ca33',
    '#afb42b',
    '#9e9d24',
    '#827717',
    '#f4ff81',
    '#eeff41',
    '#c6ff00',
    '#aeea00',
  ],
  // yellow
  [
    '#fffde7',
    '#fff9c4',
    '#fff59d',
    '#fff176',
    '#ffee58',
    '#ffeb3b',
    '#fdd835',
    '#fbc02d',
    '#f9a825',
    '#f57f17',
    '#ffff8d',
    '#ffff00',
    '#ffea00',
    '#ffd600',
  ],
  // amber
  [
    '#fff8e1',
    '#ffecb3',
    '#ffe082',
    '#ffd54f',
    '#ffca28',
    '#ffc107',
    '#ffb300',
    '#ffa000',
    '#ff8f00',
    '#ff6f00',
    '#ffe57f',
    '#ffd740',
    'a#ffc400',
    '#ffab00',
  ],
  // orange
  [
    '#fff3e0',
    '#ffe0b2',
    '#ffcc80',
    '#ffb74d',
    '#ffa726',
    '#ff9800',
    '#fb8c00',
    '#f57c00',
    '#ef6c00',
    '#e65100',
    '#ffd180',
    '#ffab40',
    '#ff9100',
    '#ff6d00',
  ],
  // deep-orange
  [
    '#ff5722',
    '#fbe9e7',
    '#ffccbc',
    '#ffab91',
    '#ff8a65',
    '#ff7043',
    '#f4511e',
    '#e64a19',
    '#d84315',
    '#bf360c',
    '#ff9e80',
    '#ff6e40',
    '#ff3d00',
    '#dd2c00',
  ],
  // brown
  [
    '#795548',
    '#EFEBE9',
    '#D7CCC8',
    '#BCAAA4',
    '#A1887F',
    '#8D6E63',
    '#6D4C41',
    '#5D4037',
    '#4E342E',
    '#3E2723',
  ],
  // blue-grey
  [
    '#607D8B',
    '#ECEFF1',
    '#CFD8DC',
    '#B0BEC5',
    '#90A4AE',
    '#78909C',
    '#546E7A',
    '#455A64',
    '#37474F',
    '#263238',
  ],
  // grey
  [
    '#9E9E9E',
    '#FAFAFA',
    '#F5F5F5',
    '#EEEEEE',
    '#E0E0E0',
    '#BDBDBD',
    '#757575',
    '#616161',
    '#424242',
    '#212121',
  ],
]

const _pastelColorsDef = [
  '#f3d1dc',
  '#f6A7c1',
  '#fcf0cf',
  '#fdcf76',
  '#b16e4b',
  '#89aeb2',
  '#97f2f3',
  '#f1e0b0',
  '#f1cdb0',
  '#e7cfc8',
  '#d2a3a9',
  '#e6dce5',
  '#ebc3c1',
  '#ecad8f',
  '#af6e4e',
  '#c8b4ba',
  '#f3ddb3',
  '#c1cd97',
  '#e18d96',
  '#909090',
  '#38908f',
  '#b2ebe0',
  '#5e96ae',
  '#ffbfa3',
  '#e08963',
  '#70ae98',
  '#ecbe7a',
  '#e58b88',
  '#9dabdd',
  '#d9effc',
  '#f9e1e0',
  '#feadb9',
  '#bc85a3',
  '#9799ba',
  '#bc85a3',
  '#adddce',
  '#70ae98',
  '#e6b655',
  '#f0a35e',
  '#ca7e8d',
  '#8ac0de',
  '#f0cdd5',
  '#f5c9b2',
  '#eab159',
  '#9e6b55',
  '#e17e76',
  '#e3baa8',
  '#aa7b6f',
  '#fed797',
  '#we8364',
]
