<template>
  <v-row justify="center">
    <v-card width="500px">
      <v-img :src="require('../assets/logo.jpg')" class="pt-5" contain></v-img>
      <v-card-text class="mt-0 pt-0">
        <v-form ref="form">
          <pc-text :parent-id="uid" :field="formSchema.email"></pc-text>
          <pc-text :parent-id="uid" :field="formSchema.password"></pc-text>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="loading">
        <v-flex xs10 offset-xs1>
          <div class="pb-8 text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-flex>
      </v-card-actions>
      <v-card-actions v-else>
        <v-flex class="pl-4 pb-12" xs4>
          <v-btn @click="cancel">Cancel</v-btn>
        </v-flex>
        <v-flex class="pr-4" xs12>
          <v-btn block color="primary" @click="submit()">Sign in</v-btn>
          <p class="text-xs-center mt-2">
            No account ? -
            <router-link :to="'/signup'">sign up for free</router-link>
          </p>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { mixinFormValidator } from '@app/mixins/mixinFormValidator'
import pcText from '@pcComponents/pcText.vue'
import { initUser } from '@appModules/user'

export default {
  name: 'signin',
  mixins: [mixinFormValidator],
  components: {
    'pc-text': pcText,
  },
  methods: {
    cancel() {
      this.$emit('logout')
    },

    async submit() {
      if (this.validateForm()) {
        const user = await this.$store.dispatch('signinUser', {
          meta: {},
          data: {
            email: this.formSchema.email.value,
            password: this.formSchema.password.value,
          },
        })
        if (user.authenticated) {
          this.loading = true
          //document.cookie = `jwt=${user.token}; path=/`
          //document.cookie = `jwtRefresh=${user.refreshToken}; path=/`
          await initUser()
          await this.$store.dispatch('setIsUser', true)
          this.$router.push('/companyDashboard')
        } else {
          this.loading = false
          switch (user.errorType) {
            case 'email':
              this.formSchema.email.errors = [user.errorMessage]
              break
            case 'password':
              this.formSchema.password.errors = [user.errorMessage]
              break
            default:
              this.formSchema.password.errors = [user.errorMessage]
          }
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      formSchema: {
        email: {
          initValue:
            process.env.NODE_ENV !== 'production'
              ? 'stuart@willmott.uk.net'
              : '',
          present: { email: true, label: 'Email' },
          valid: { required, email },
        },
        password: {
          initValue: process.env.NODE_ENV !== 'production' ? 'astro16' : '',
          present: {
            password: true,
            label: 'Password',
          },
          valid: {
            required,
            minLength: minLength(6),
          },
          customErrMsg: {
            minLength: 'Password must be a minimum of 6 characters',
          },
        },
      },
    }
  },
}
</script>

<style scoped></style>
