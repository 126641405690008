<template>
  <v-row
    id="coverageCustomers"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <pc-toolbar :toolbarDef="listToolbarDef">
        <template v-slot:toolbarActions> </template>
      </pc-toolbar>

      <pc-lister
        v-show="isBought"
        :list-def="isCompany ? boughtCompanyListDef : boughtGroupListDef"
        :list-items="boughtCustomers"
      >
        <template v-slot:sales>
          <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
        </template>
        <template v-slot:itemSales>
          <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
        </template>
      </pc-lister>
      <pc-lister
        v-show="!isBought"
        :list-def="isCompany ? notBoughtCompanyListDef : notBoughtGroupListDef"
        :list-items="notBoughtCustomers"
      >
        <template v-slot:sales>
          <pc-spark-Chart :sparkChartDef="turnoverSparkChartDef" />
        </template>
      </pc-lister>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcTableDef } from '@pcComponents/defs/pcTableDef.js'
import { pcFieldDef } from '@pcComponents/defs/pcFieldDef.js'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'
import { lastInvoicedClass } from '@appModules/ws.js'
import {
  pcLineActionsDef,
  pcLineActionDef,
} from '@pcComponents/defs/pcLineActionsDef.js'

import pcLister from '@pcComponents/pcLister.vue'
//import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import pcSparkChart from '@pcComponents/pcSparkChart.vue'
import { getCustomers } from './getCustomers.js'

export default {
  name: 'coverageCustomers',

  components: {
    //pcCard,
    pcToolbar,
    pcLister,
    pcSparkChart,
  },

  props: {
    group: Object,
    item: Object,
  },

  computed: {
    listTitle() {
      return this.item
        ? this.isBought
          ? `${this.boughtCustomers.length} Customers bought ${this.item.name}`
          : `${this.group.activeCustomers -
              this.boughtCustomers.length} Active customers have not bought ${
              this.item.name
            }`
        : ''
    },

    isCompany() {
      return this.group.id === 'company'
    },
  },

  watch: {
    item() {
      if (this.item) {
        this.main()
      } else {
        this.boughtCustomers = []
        this.notBoughtCustomers = []
      }
    },

    listTitle(title) {
      this.listToolbarDef.title = title
    },
  },

  methods: {
    async main() {
      const { boughtCustomers, notBoughtCustomers } = await getCustomers(
        this.group,
        this.item
      )
      this.boughtCustomers = boughtCustomers
      this.notBoughtCustomers = notBoughtCustomers
    },

    listToolbarActions(action) {
      if (action.id === 'csv') {
        let listDef, data
        if (this.isBought) {
          listDef = this.isCompany
            ? this.boughtCompanyListDef
            : this.boughtGroupListDef
          data = this.boughtCustomers
        } else {
          listDef = this.isCompany
            ? this.notBoughtCompanyListDef
            : this.notBoughtGroupListDef
          data = this.notBoughtCustomers
        }
        pc.csvFromLister(listDef, data)
      } else {
        this.isBought = action.id === 'bought'
      }
    },

    lastInvoicedBoughtClass({ data }) {
      return lastInvoicedClass(
        data.item.lastInvoiced,
        this.period.periodEndDate
      )
    },

    lastInvoicedNotBoughtClass({ data }) {
      return lastInvoicedClass(data.lastInvoiced, this.period.periodEndDate)
    },

    showCustomerDashboard(payload) {
      this.$emit('showCustomerDashboard', {
        customerId: payload.data.id,
        from: 'contactGroupDashboard',
        screen: 'coverage',
      })
    },

    salesTooltipHandler({ field, data }) {
      const totalSales = pc.toFixed2(
        field.id === 'sales' ? this.group.sales : this.item.sales
      )
      const sales = pc.toFixed2(
        field.id === 'sales' ? data.sales : data.item.sales
      )
      const entityName = field.id === 'sales' ? this.group.name : this.item.name
      const salesByMonth = pc.arrayFixed2(
        field.id === 'sales' ? data.salesByMonth : data.item.salesByMonth
      )
      const subTitle = `£${sales} sales (${pc.toFixed2(
        pc.percentage(sales, totalSales)
      )}%) of ${entityName} sales`

      this.turnoverSparkChartDef
        .clearDatasets()
        .setTitle(data.name)
        .setSubTitle(subTitle)
        .addDataset(salesByMonth, '#90CAF9')
        .setLabels(pc.periodMonthNames('mmm', this.period.monthKeys))
        .render()
    },
  },

  data: function() {
    const period = db.cached('period')
    return {
      period,
      isBought: true,
      boughtCustomers: [],
      notBoughtCustomers: [],

      listToolbarDef: pcToolbarDef('listToolbar', '', {
        dense: true,
        color: 'primary lighten-2',
        actions: [
          pcToolbarActionDef(
            'bought',
            'mdi-account-multiple-check-outline',
            this.listToolbarActions,
            'View customers who bought',
            'white'
          ),
          pcToolbarActionDef(
            'notBought',
            'mdi-account-multiple-remove-outline',
            this.listToolbarActions,
            'View customers who have not bought',
            'white'
          ),
          pcToolbarActionDef(
            'csv',
            'mdi-file-excel',
            this.listToolbarActions,
            'Create CSV file',
            'white'
          ),
        ],
      }),

      boughtCompanyListDef: {
        cardDef: pcCardDef('listCard', false),
        table: pcTableDef('listTable', undefined, ['item.sales'], [true], {
          rowClick: this.showCustomerDashboard,
        }),
        fields: [
          pcFieldDef('customerName', 'text', 'name', 'Customer', true),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'itemSales',
            'currency',
            'item.sales',
            'Item Sales',
            true,
            {
              tooltipDef: pcTooltipDef(
                'salesTooltip',
                this.salesTooltipHandler
              ),
            }
          ),
          pcFieldDef(
            'itemLastInvoiced',
            'ISODate',
            'item.lastInvoiced',
            'Item last invoiced',
            true,
            {
              itemClass: this.lastInvoicedClass,
              headingClass: 'pr-7',
            }
          ),
          pcFieldDef('companyRanking', 'ranking', 'ranking', 'Company ranking'),
          pcFieldDef('totalSales', 'currency', 'sales', 'Total sales', true, {
            tooltipDef: pcTooltipDef('salesTooltip', this.salesTooltipHandler),
          }),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedBoughtClass,
            headingClass: 'pr-7',
          }),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },

      boughtGroupListDef: {
        cardDef: pcCardDef('listCard', false),
        table: pcTableDef('listTable', undefined, ['item.sales'], [true]),
        fields: [
          pcFieldDef('name', 'text', '', 'Customer', true),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'itemSales',
            'currency',
            'item.sales',
            'Item Sales',
            true,
            {
              tooltipDef: pcTooltipDef(
                'salesTooltip',
                this.salesTooltipHandler
              ),
            }
          ),
          pcFieldDef(
            'itemLastInvoiced',
            'ISODate',
            'item.lastInvoiced',
            'Item last invoiced',
            true,
            {
              itemClass: this.lastInvoicedBoughtClass,
              headingClass: 'pr-7',
            }
          ),
          pcFieldDef(
            'ranking',
            'ranking',
            'contactGroupRanking',
            'Group ranking'
          ),
          pcFieldDef('sales', 'currency', '', 'Total sales', true, {
            tooltipDef: pcTooltipDef('salesTooltip', this.salesTooltipHandler),
          }),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedClass,
            headingClass: 'pr-7',
          }),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },

      notBoughtCompanyListDef: {
        cardDef: pcCardDef('listCard', false),
        table: pcTableDef('listTable', undefined, ['name'], [false]),
        fields: [
          pcFieldDef('name', 'text', '', 'Customer'),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef('ranking', 'ranking', '', 'Company ranking'),
          pcFieldDef('sales', 'currency', '', 'Other sales', true, {
            tooltipDef: pcTooltipDef('salesTooltip', this.salesTooltipHandler),
          }),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedNotBoughtClass,
            headingClass: 'pr-7',
          }),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },

      notBoughtGroupListDef: {
        cardDef: pcCardDef('listCard', false),
        table: pcTableDef('listTable', undefined, ['name'], [false]),
        fields: [
          pcFieldDef('name', 'text', '', 'Customer'),
          pcFieldDef(
            'phoneNumbers',
            'phonenumbers',
            'phoneNumbers',
            'Phone numbers',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'firstName',
            'contacts',
            'contacts',
            'Contact first name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'lastName',
            'contacts',
            'contacts',
            'Contact last name',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'emailAddress',
            'contacts',
            'contacts',
            'Contact email',
            false,
            {
              csvOnly: true,
            }
          ),
          pcFieldDef(
            'ranking',
            'ranking',
            'contactGroupRanking',
            'Group ranking'
          ),
          pcFieldDef('sales', 'currency', '', 'Other sales', true, {
            tooltipDef: pcTooltipDef('salesTooltip', this.salesTooltipHandler),
          }),
          pcFieldDef('lastInvoiced', 'ISODate', '', 'Last invoiced', true, {
            itemClass: this.lastInvoicedNotBoughtClass,
            headingClass: 'pr-7',
          }),
        ],
        lineActions: pcLineActionsDef('', {}, [
          pcLineActionDef(
            'customerDashboard',
            'mdi-view-dashboard',
            this.showCustomerDashboard,
            'View customer dashboard'
          ),
        ]),
      },

      customerSummaryId: '',
      turnoverSparkChartDef: pcSparkChartDef('bar'),
    }
  },
}
</script>

<style scoped></style>
