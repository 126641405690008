export const getItem = (tree, id) => {
  let item

  const searchChildren = children => {
    for (let i = 0; i < children.length; i++) {
      if (item) break
      if (children[i].id === id) {
        item = children[i]
        break
      }
      if (children[i].children.length) {
        searchChildren(children[i].children)
      }
    }
    return item
  }

  return id === 'category' ? tree[0] : searchChildren(tree[0].children)
}
