import { render, staticRenderFns } from "./customerRanking.vue?vue&type=template&id=058e4c87&scoped=true&"
import script from "./customerRanking.vue?vue&type=script&lang=js&"
export * from "./customerRanking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058e4c87",
  null
  
)

export default component.exports