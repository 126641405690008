<template>
  <v-row
    id="contactGroupDashboard"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
      <preferences
        :openDialog="preferencesDialog"
        v-on:closed="preferencesDialogClosed"
      ></preferences>

      <v-tabs class="pc-size100" height="0px">
        <v-tabs-items v-model="tab" class="pc-col">
          <v-tab-item class="pc-col mt-0">
            <pc-card :card-def="dashboardCardDef">
              <template v-slot:title>
                <pc-toolbar :toolbar-def="toolbarDef">
                  <template v-slot:toolbarActions>
                    <div style="width: 300px;">
                      <contact-group-select
                        allKey="company"
                        allName="Company"
                        :count="false"
                        :dark="true"
                        width="275px"
                        :excludeNoSales="true"
                        v-on:selected="groupSelected"
                      />
                    </div>
                  </template>
                </pc-toolbar>
              </template>

              <template v-slot:text>
                <v-row
                  class="fill-height pl-3 mt-0"
                  justify="center"
                  align="stretch"
                >
                  <pc-nav-drawer
                    :navDrawerDef="navDrawerDef"
                    v-on:selected="menuItem => (menuIdSelected = menuItem.id)"
                  />
                  <v-col class="pc-col d-flex pl-0 pr-3 pt-0 pb-0">
                    <contactGroupSummary
                      :summaryDef="summaryDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <contactGroupCoverage
                      :contactGroupCoverageDef="contactGroupCoverageDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <customerDashboard
                      v-if="contactGroupCustomersDef.show"
                      class="mt-0"
                      :contactGroupId="contactGroupId"
                    />
                    <paymentAnalysis
                      v-if="contactGroupPaymentAnalysisDef.show"
                      class="mt-0"
                      :paymentAnalysisDef="contactGroupPaymentAnalysisDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <!-- <category-top-10 :categoryTop10Def="categoryTop10Def" /> -->
                  </v-col>
                </v-row>
              </template>
            </pc-card>
          </v-tab-item>
          <v-tab-item class="pc-col pt-3">
            <customer-dashboard
              :dashboardCustomer="customer"
              @exit="exitCustomerDashboard"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
//import pc from '@pc'
import db from '@db'
import { LocalStorage } from '@pcModules/localStorage.js'
import { whitespaceLocalStorageSchema } from '@appModules/ws.js'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import contactGroupSummary from '@appViews/contactGroupDashboard/summary/contactGroupSummary.vue'
import customerDashboard from '@appViews/customerDashboard/customerDashboard.vue'
import contactGroupCoverage from './coverage/contactGroupCoverage.vue'
import paymentAnalysis from '@sharedComponents/paymentAnalysis/paymentAnalysis.vue'
import preferences from '@appViews/contactGroupDashboard/preferences'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import {
  pcNavDrawerDef,
  pcNavDrawerItem,
} from '@pcComponents/defs/pcNavDrawerDef.js'

import pcNavDrawer from '@pcComponents/pcNavDrawer.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect'
import { contactGroupGql, companyGql } from './contactGroupDashboard.gql.js'

export default {
  name: 'contactGroupDashboard',

  components: {
    pcCard,
    pcToolbar,
    pcNavDrawer,
    contactGroupSummary,
    contactGroupCoverage,
    customerDashboard,
    preferences,
    contactGroupSelect,
    paymentAnalysis,
  },

  async created() {
    this.initialise()
    this.main()
  },

  watch: {
    contactGroup() {
      this.main()
    },

    menuIdSelected(value) {
      this.summaryDef = {
        ...this.summaryDef,
        show: value === 'summary',
      }
      this.contactGroupCoverageDef = {
        ...this.contactGroupCoverageDef,
        show: value === 'coverage',
        contactGroup: this.contactGroup,
      }
      this.contactGroupCustomersDef = {
        ...this.contactGroupCustomersDef,
        show: value === 'customers',
      }
      this.contactGroupPaymentAnalysisDef = {
        ...this.contactGroupCustomersDef,
        show: value === 'payment',
      }
    },
  },

  methods: {
    initialise() {
      const whitespaceLocalStorage = new LocalStorage(
        'whitespace',
        whitespaceLocalStorageSchema
      )
      this.openingWindow = whitespaceLocalStorage.getProperty(
        'contactGroupDashboard.openingWindow'
      )
      this.menuIdSelected = this.openingWindow || 'summary'
    },

    async main() {
      if (this.contactGroup) {
        this.summaryDef.contactGroup = this.contactGroup
        this.contactGroupCoverageDef.contactGroup = this.contactGroup
        this.contactGroupCustomersDef.contactGroup = this.contactGroup
        this.contactGroupPaymentAnalysisDef.contactGroup = this.contactGroup
      }
    },

    async groupSelected(contactGroupId) {
      const group =
        contactGroupId === 'company'
          ? await db.request(
              companyGql,
              db.args(
                db
                  .queryArgs('salesByProduct')
                  .addSort('sales')
                  .addLimitAfterSort(10)
              )
            )
          : await db.request(
              contactGroupGql,
              db
                .args()
                .addQueryArgs(
                  db.queryArgs('contactGroups').addFind('id', contactGroupId)
                )
                .addQueryArgs(
                  db
                    .queryArgs('salesByProduct')
                    .addSort('sales', true)
                    .addLimitAfterSort(10)
                )
            )

      this.contactGroupId = contactGroupId
      this.contactGroup = group
    },

    showCustomerDashboard(payload) {
      this.customer = db.cached(
        'customers',
        db.cachedQuery().addFind('id', payload.customerId)
      )
      this.tab = 1
    },

    exitCustomerDashboard() {
      this.tab = 0
    },

    preferences() {
      this.preferencesDialog = true
    },

    preferencesDialogClosed(openingWindow) {
      this.preferencesDialog = false
      this.openingWindow = openingWindow
    },
  },

  data() {
    return {
      tab: 0,
      period: db.cached('period'),
      contactGroup: undefined,
      customer: undefined,

      contactGroupId: 'company',
      preferencesDialog: false,
      openingWindow: '',
      menuIdSelected: '',

      dashboardCardDef: pcCardDef('contactGroupDashboard', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef(
        'contactGroupDashboardToolbar',
        'Contact group dashboard',
        {},
        [
          pcToolbarActionDef(
            'preferences',
            'mdi-cog-outline',
            this.preferences,
            'Set preferences'
          ),
        ]
      ),

      navDrawerDef: pcNavDrawerDef(
        'optionsNavDrawer',
        [
          pcNavDrawerItem('summary', 'mdi-view-dashboard', 'Summary'),
          pcNavDrawerItem('customers', 'mdi-account', 'Customers'),
          pcNavDrawerItem('coverage', 'mdi-shape', 'Coverage'),
          pcNavDrawerItem('payment', 'mdi-receipt', 'Payment analysis'),
        ],
        { width: '200px', expandOnHover: true, miniVariant: true }
      ),

      dashboardExitActionDef: pcToolbarActionDef(
        'back',
        'mdi-arrow-left',
        this.exitDashboard,
        'Exit dashboard'
      ),

      summaryDef: {
        show: false,
        contactGroup: undefined,
      },

      contactGroupCoverageDef: {
        show: false,
        contactGroup: undefined,
      },

      contactGroupCustomersDef: {
        show: false,
        contactGroup: undefined,
      },

      contactGroupPaymentAnalysisDef: {
        show: false,
        contactGroup: undefined,
      },
    }
  },
}
</script>

<style scoped></style>
