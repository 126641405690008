<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="total"
        allName="Company"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
      <pc-action-icon
        @click="chartLineType"
        value="customer"
        icon="mdi-account"
        small
        top
        tooltip="Show average customer turnover"
      />
      <pc-action-icon
        @click="chartLineType"
        value="invoice"
        icon="mdi-receipt"
        small
        top
        tooltip="Show average invoice value"
      />
    </template>
    <template v-slot:default>
      <pc-chart :chartDef="chartDef" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'activeCustomersChart',
  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
    pcActionIcon,
  },

  methods: {
    groupSelected(groupId, groupName) {
      const group =
        groupId === 'total'
          ? this.period
          : db.cached('contactGroups', db.cachedQuery().addFind('id', groupId))

      this.groupId = groupId
      this.groupName = groupName
      this.displayBoxDef.title = `${groupName} active customers`

      this.chartDef.clearDatasets()

      this.lineType === 'customer'
        ? this.chartDef.addDataset(
            pc.arrayFixed2(
              group.salesByMonth.map((sales, index) =>
                pc.safeDivide(sales, group.activeCustomersByMonth[index])
              )
            ),
            'Average customer turnover',
            getColor('red', 3),
            [['type', 'line']]
          )
        : this.chartDef.addDataset(
            pc.arrayFixed2(
              group.salesByMonth.map((sales, index) =>
                pc.safeDivide(sales, group.invoicesByMonth[index])
              )
            ),
            'Average invoice amount',
            getColor('purple', 3),
            [['type', 'line']]
          )

      this.chartDef.addDataset(
        group.activeCustomersByMonth,
        'Active customers',
        getColor('blue', 3)
      )

      this.chartDef.render()
    },

    chartLineType(type) {
      this.lineType = type
      this.groupSelected(this.groupId, this.groupName)
    },
  },

  data() {
    const period = db.cached('period')
    return {
      groupId: '',
      groupName: '',
      lineType: 'customer',
      period,
      displayBoxDef: pcDisplayBoxDef('activeCustomersDisplayBox', ''),

      chartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
