<template>
  <v-toolbar
    v-if="toolbarDef.show"
    id="toolbarDef.id"
    :dense="toolbarDef.dense"
    :flat="toolbarDef.flat"
    text
    :color="toolbarDef.color"
    :class="toolbarDef.class"
  >
    <v-toolbar-title :class="toolbarDef.titleClass">{{
      stringOrCall(toolbarDef.title, toolbarDef)
    }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- Display the toolbar actions if any -->
    <slot name="toolbarActions"></slot>
    <div v-for="action in toolbarDef.actions" :key="action.id">
      <v-tooltip v-if="action.tooltip ? true : false" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :color="stringOrCall(action.iconColor, action)"
            v-on="on"
            @click.native="toolbarAction(action)"
            :disabled="action.disabled"
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </template>
        {{ stringOrCall(action.tooltip, action) }}
      </v-tooltip>
      <v-btn
        v-else
        icon
        :color="stringOrCall(action.iconColor, action)"
        v-on="on"
        @click.native="toolbarAction(action)"
        :disabled="action.disabled"
      >
        <v-icon>{{ action.icon }}</v-icon>
      </v-btn>
    </div>

    <v-fab-transition v-if="toolbarDef.addItem">
      <v-btn
        color="accent"
        small
        fab
        dark
        absolute
        bottom
        right
        @click.stop="addItem(toolbarDef)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-toolbar>
</template>

<script>
/**
 * @module pcToolbar - Vue Component
 * @description Parameter driven toolbar
 */
import pc from '@pc'

export default {
  name: 'pcToolbar',
  components: {},
  /**
   * @property {Object} toolbarDef
   *  The schema describing the toolbar
   * @property {String} toolbarDef.id
   *  Identifies the toolbarDef instance and is used in event name
   * @property {String} [toolbar.color="primary lighten-1"]
   * Background color of toolbar;
   *  @property {String} [toolbar.class=""]
   * Toolbar class
   * @property {String} [toolbar.titleClass="white--text"]
   * Title class
   * @property {(String|Function)} toolbarDef.title
   *  The toolbar title. If function the function must return a string
   *  and the function receives the toolbarDef object as a parameter
   * @property {Object[]} [toolbarDef.actions=[]]
   *  An array of action objects describing icon buttons to be displayed on the
   * right end of the toolbar
   * @property {String} toolbarDef.actions[].name
   *  Name of the action. Must be unique within the actions
   * @property {String} toolbarDef.actions[].icon
   *  The icon name
   * @property {(String|Function)} [toolbarDef.actions[].iconColor=white]
   *  Defaults to "white" if not supplied. If function it must return a string
   *  and receives the action object as a parameter
   * @property {(String|Function)} [toolbarDef.actions[].tooltip]
   *  The tooltip for the action. If "" or not provided no tooltip is included.
   *  If function it must return a string and receives the action object as a parameter
   * @property {(String|Function)} toolbarDef.actions[].action
   *  Provide a string to emit an event when the action is clicked. The event name
   *  will be toolbarDef.id + toolbarDef.action.action the event payload will be the
   *  action object. For a function the action object will be passed as a parameter
   * @property {(String|Function)} [listDef.addItem=false]
   *  Display a fab button with add sign.
   *  Provide a string to emit an event when the button is clicked. The event name
   *  will be toolbarDef.id + toolbarDef.addItem the event payload will be toolbarDef
   *  For a function the payload will conatin the same properties as the event.
   */
  props: {
    toolbarDef: Object,
  },
  methods: {
    stringOrCall(item, payload) {
      if (typeof item === 'function') {
        return item(payload)
      } else {
        return item ? item : ''
      }
    },
    eventOrCall(item, payload) {
      typeof item === 'function'
        ? item(payload)
        : pc.$eventBus.$emit(item, payload)
    },
    toolbarAction(action) {
      this.eventOrCall(action.action, action)
    },
    addItem(toolbar) {
      this.eventOrCall(toolbar.addItem, toolbar)
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped></style>
