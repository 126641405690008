<template>
  <v-dialog
    v-model="dialog"
    :height="dialogCardDef.height"
    :width="dialogCardDef.width"
  >
    <pc-card :cardDef="dialogCardDef">
      <template v-slot:title
        ><pc-toolbar :toolbarDef="dialogToolbarDef"
      /></template>

      <template v-slot:subTitle>
        <div class="pa-3 pb-0">
          Select the screen you would like the customer dashboard to display
          when it is opened.
        </div>
      </template>

      <template v-slot:text>
        <v-radio-group v-model="openingWindow" class="pl-3">
          <v-radio
            v-for="option in windows"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          ></v-radio>
        </v-radio-group>
      </template>

      <template v-slot:actions>
        <v-col>
          <v-btn color="blue darken-1" text @click="closed">
            Cancel
          </v-btn>
          <v-btn
            class="align-self-end"
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-col>
      </template>
    </pc-card>
  </v-dialog>
</template>

<script>
import { LocalStorage } from '@pcModules/localStorage.js'
import { whitespaceLocalStorageSchema } from '@appModules/ws.js'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'

export default {
  name: 'preferences',
  mixins: [],
  components: {
    pcCard,
    pcToolbar,
  },

  props: {
    openDialog: Boolean,
    closeButton: {
      type: Boolean,
      deafult: true,
    },
  },

  created() {
    this.initialise()
    this.main()
  },

  computed: {},

  watch: {
    openDialog(value) {
      this.dialog = value
    },

    // Show close button based on props
    closeButton(value) {
      if (value) {
        this.wsFindCustomersDef.toolbarDef.actions = [
          pcToolbarActionDef(
            'close',
            'mdi-close',
            this.closed,
            'Close find customer'
          ),
        ]
      } else this.wsFindCustomersDef.toolbarDef.actions = []
    },
  },

  methods: {
    initialise() {
      this.whitespaceLocalStorage = new LocalStorage(
        'whitespace',
        whitespaceLocalStorageSchema
      )
      this.openingWindow = this.whitespaceLocalStorage.getProperty(
        'customerDashboard.openingWindow'
      )
    },

    main() {},

    save() {
      this.whitespaceLocalStorage.setProperty(
        'customerDashboard.openingWindow',
        this.openingWindow
      )
      this.closed()
    },

    closed() {
      this.dialog = false
      this.$emit('closed', this.openingWindow)
    },
  },

  data() {
    return {
      dialog: false,
      dialogCardDef: pcCardDef('dialogCard', false, {
        height: '450px',
        width: '400px',
        actionClass: '',
      }),
      dialogToolbarDef: pcToolbarDef(
        'dialogToolbar',
        'Customer dashboard preferences',
        { dense: true }
      ),
      whitespace: {},
      openingWindow: 'turnover',
      windows: [
        { label: 'Summary', value: 'summary' },
        { label: 'Turnover', value: 'turnover' },
        { label: 'Invoices', value: 'invoices' },
        { label: 'Categories', value: 'categories' },
        { label: 'Products', value: 'products' },
        { label: 'Ranking', value: 'ranking' },
        { label: 'Top 10', value: 'top10' },
      ],
    }
  },
}
</script>

<style scoped></style>
