<template>
  <v-row
    id="categoryMaintenance"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-dialog v-model="showDialog" persistent width="400px">
      <v-card>
        <v-toolbar dense text color="accent">
          <v-toolbar-title class="white--text">Category name</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text icon color="white" @click.native="cancelAction">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-3 pb-0">
          <v-text-field
            label="Category name"
            v-model="categoryName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.stop="cancelAction"
            >cancel</v-btn
          >
          <v-btn
            color="primary darken-1"
            text
            @click.stop="saveAction(categoryName)"
            >{{ actionType === 'edit' ? 'SAVE' : 'ADD CATEGORY' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col :cols="12" class="pc-col d-flex pt-0 pb-1">
      <pc-card :card-def="cardDef">
        <template v-slot:title>
          <pc-toolbar :toolbar-def="toolbarDef" />
        </template>

        <template v-slot:text>
          <v-row class="pl-3 mt-0 fill-height" align="stretch">
            <v-col :cols="6" class="pc-col d-flex pt-0 pb-0 pl-0 pr-0">
              <categoryDropTree
                :categoryTree="categoryTree"
                :dragstart="dragstart"
                :dragend="dragend"
                @processDrag="processDrag"
                @action="action"
              />
            </v-col>
            <v-col :cols="6" class="pc-col d-flex pt-0 pb-0 pl-0">
              <categoryDragTree
                @dragstart="payload => (dragstart = payload)"
                @dragend="payload => (dragend = payload)"
                @cancelChanges="cancelChanges"
                @saveChanges="saveChanges"
                :categoryTree="categoryTree"
                :clearActive="categoryDragClearActive"
                :modified="modified"
              />
            </v-col>
          </v-row>
        </template>
      </pc-card>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'

import { getCategoryTree } from './getCategoryTree/index.js'
import categoryDropTree from './categoryDropTree.vue'
import categoryDragTree from './categoryDragTree.vue'
import { processDrag } from './processDrag/index.js'
import { updateCategory, addCategory, deleteCategory } from './categoryCRUD.js'
import { getCategoryAndProductUpdates } from './getCategoryAndProductUpdates.js'
import {
  updateProductUpdates,
  updateCategoryUpdates,
} from './categoryMaintenance.qgl.js'

export default {
  name: 'categoryMaintenance',

  components: {
    pcCard,
    pcToolbar,
    categoryDropTree,
    categoryDragTree,
  },

  async created() {
    this.initialise()
    this.main()
  },

  beforeRouteLeave(to, from, next) {
    this.modified
      ? window.confirm(
          'You have unsaved changes which will be lost if you continue'
        )
        ? next()
        : next(false)
      : next()
  },

  watch: {
    modified(isModified) {
      if (isModified) {
        pc.$eventBus.$emit(
          'protectUnloadOn',
          'You have unsaved changes which will be lost if you leave this page'
        )
      } else {
        pc.$eventBus.$emit('protectUnloadOff')
      }
    },
  },

  methods: {
    async initialise() {
      this.modified = false
      this.dragstart = {}
      this.dragend = {}
      this.categoryDragClearActive = ''
      this.item = {}
      this.showDialog = false
      this.ctionType = false
      this.categoryName = ''
      this.categoryTree = [{}]
      this.deletedCategories = []
      this.categoryTree = [await getCategoryTree()]
    },

    async main() {},

    processDrag(payload) {
      processDrag(this.categoryTree, payload)
      this.categoryDragClearActive = pc.uid()
      this.modified = true
    },

    action({ action, item }) {
      this.item = item
      this.actionType = action
      this.categoryName = action === 'edit' ? item.name : ''
      if (action === 'delete') {
        this.saveAction()
      } else this.showDialog = true
    },

    cancelAction() {
      this.showDialog = false
    },

    async saveAction(name) {
      this.showDialog = false
      this.modified = true
      if (this.actionType === 'edit')
        updateCategory(this.categoryTree, this.item, name)

      if (this.actionType === 'add')
        addCategory(this.categoryTree, this.item, name)

      if (this.actionType === 'delete') {
        const item = deleteCategory(this.categoryTree, this.item)
        if (item) this.deletedCategories.push(item)
      }
    },

    async cancelChanges(cancel) {
      this.categoryTree = []
      if (cancel === 'all') await this.updateServer([], [])
      this.initialise()
    },

    async saveChanges() {
      this.modified = false
      const { categoryUpdates, productUpdates } = getCategoryAndProductUpdates(
        this.categoryTree,
        this.deletedCategories
      )
      await this.updateServer(categoryUpdates, productUpdates)
    },

    async updateServer(categoryUpdates, productUpdates) {
      await db.request(updateCategoryUpdates, {
        record: { categories: categoryUpdates },
      })
      await db.request(updateProductUpdates, {
        record: { products: productUpdates },
      })
    },
  },

  data() {
    return {
      modified: false,
      dragstart: {},
      dragend: {},
      categoryDragClearActive: '',

      item: {},
      showDialog: false,
      actionType: false,
      categoryName: '',

      categoryTree: [{}],
      deletedCategories: [],

      cardDef: pcCardDef('categoryMaintenance', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef(
        'categoryMaintenanceToolbar',
        'Category maintenance'
      ),
    }
  },
}
</script>

<style scoped></style>
