import pc from '@pc'

class PcFieldDef {
  constructor(id, type, dataPath, heading, sort = true, other = {}) {
    type = type.toLowerCase()
    if (
      [
        'text',
        'number',
        'currency',
        'isodate',
        'quartile',
        'ranking',
        'phonenumbers',
        'contacts',
      ].indexOf(type) === -1
    ) {
      console.log(`Invalid field type: ${type} in field id: ${id}`)
    }
    if (!id) {
      console.log('Must specify an id for a pcLister field')
    }

    this.id = id
    this.type = type !== 'isodate' ? type : 'ISODate'
    this.dataPath = dataPath ? dataPath : id
    this.heading = heading
    this.sort = sort
    if (type === 'number' || type === 'ranking') this.currencySymbol = ''
    if (type === 'text') this.align = 'start'
    if (type === 'quartile') this.align = 'center'

    Object.entries(other).forEach(([key, value]) => {
      if (Object.hasOwnProperty.call(this, key)) {
        this[key] = value
      } else {
        console.log(`Invalid property: ${key} in field id ${id}`)
      }
    })

    if (this.tooltipDef) {
      this.events.mouseover = {
        callback: this.tooltipDef.showHook,
        entity: 'tooltip',
      }
      this.events.mouseleave = {
        callback: this.tooltipDef.showHook,
        entity: 'tooltip',
      }
      this.events.keydown = {
        callback: this.tooltipDef.showHook,
        entity: 'tooltip',
      }
    }
  }
  id = ''
  dataPath = ''
  type = 'text'
  show = { xs: true }
  heading = ''
  width = ''
  align = 'end'
  sort = true
  click = ''
  headingAlign = ''
  headingClass = ''
  cellClass = ''
  itemClass = ''
  fontSize = ''
  formatFn = null
  blankZero = false
  blankIfNot = ''
  decimalPlaces = 0
  currencySymbol = '£'
  negativeClass = ''
  events = {} //each event is: {callback: fn, entity: 'string'} entity is optional
  dropdownDef = null
  tooltipDef = null
  csvOnly = false
  csv = function(record, extraLine, lineNumber) {
    const fieldValue = pc.getProperty(this.dataPath || this.id, record)
    switch (this.type) {
      case 'number':
      case 'currency':
        if (extraLine) return '""'
        return 0 + fieldValue
      case 'ranking':
        if (extraLine) return '""'
        return fieldValue === 99999 ? 0 : 0 + fieldValue
      case 'isodate':
        if (extraLine) return '""'
        return `"${fieldValue}"`
      case 'quartile':
        if (extraLine) return '""'
        return `"${fieldValue}"`
      case 'phonenumbers':
        return this._phoneNumbers(fieldValue, lineNumber)
      case 'contacts':
        return this._contacts(fieldValue, lineNumber)
      default:
        if (extraLine) return '""'
        return `"${fieldValue}"`
    }
  }
  _phoneNumbers = function(phoneNumbers, lineNumber) {
    if (lineNumber > phoneNumbers.length) return '""'
    const phoneNumber = phoneNumbers[lineNumber - 1]
    return (
      '"' + `${phoneNumber.phoneType} ${phoneNumber.phoneNumber}`.trim() + '"'
    )
  }
  _contacts = function(contacts, lineNumber) {
    if (lineNumber > contacts.length) return '""'
    const contact = contacts[lineNumber - 1]
    const fieldValue = contact[this.id]
    return '"' + `${fieldValue ? fieldValue : ''}`.trim() + '"'
  }
}

export const pcFieldDef = (
  id,
  type,
  dataPath,
  heading,
  sort = true,
  other = {}
) => new PcFieldDef(id, type, dataPath, heading, sort, other)
