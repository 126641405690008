const _pcNavDrawerDef = () => ({
  id: '',
  if: true, // Can use breakpoint object
  show: true, // Can use breakpoint object
  permanent: true,
  right: false,
  width: '256px', // % or px
  height: '100%',
  expandOnHover: false,
  miniVariant: false,
  miniVariantWidth: 56,
  menuItems: [],
})

const pcNavDrawerDef = function(id, menuItems, other = {}) {
  const newNavDrawer = _pcNavDrawerDef()
  if (!id) {
    console.log('Must specify an id for a pcNavDrawer')
  }

  newNavDrawer.id = id
  newNavDrawer.menuItems = menuItems

  Object.entries(other).forEach(([key, value]) => {
    if (Object.hasOwnProperty.call(newNavDrawer, key)) {
      newNavDrawer[key] = value
    } else {
      console.log(`Invalid property: ${key} in pcNavDrawer id ${id}`)
    }
  })
  return newNavDrawer
}

const pcNavDrawerItem = function(id, icon, text, expand = false, props = {}) {
  return {
    id,
    icon,
    text,
    expand,
    props,
  }
}

export { pcNavDrawerDef, pcNavDrawerItem }
