import * as R from 'ramda'
import { ordinal } from './number.js'

const _MS_PER_DAY = 86400000

const _getMonthNames = length =>
  new Array(12)
    .fill('')
    .map((_, month) =>
      new Date(2021, month, 1).toLocaleString('default', { month: length })
    )

const _getDayNames = length =>
  new Array(7).fill('').reduce((names, _, index) => {
    const date = new Date(2021, 0, index + 1)
    const day = date.getDay()
    names[day] = date.toLocaleString('default', { weekday: length })
    return names
  }, new Array(7).fill(''))

const _dateObject = date =>
  typeof date === 'string' ? dateFromYMDString(date) : date

export const shortMonthNames = _getMonthNames('short')
export const longMonthNames = _getMonthNames('long')
export const shortDayNames = _getDayNames('short')
export const longDayNames = _getDayNames('long')

/** ---------------------------------------------------------------------------
 * @function dateSpec
 * @summary dateSpec :: Date || String -> Object
 * @desc Returns an object with the specification of a date
 * @param {Date|String} date object of string in format 'YYYY-MM-DD'
 * @return {Object} Returns an object with the following numeric properites {year, month, day, weekDay} Note: months are (1 - 12)
 */
export const dateSpec = date => {
  if (date === '' || date === '0000-00-00')
    return {
      year: 0,
      month: 0,
      day: 0,
      weekDay: 0,
      shortMonthName: '',
      longMonthName: '',
      shortDayName: '',
      longDayName: '',
    }

  const newDate = _dateObject(date)
  return {
    year: newDate.getFullYear(),
    month: newDate.getMonth() + 1,
    day: newDate.getDate(),
    weekDay: newDate.getDay(),
    shortMonthName: shortMonthNames[newDate.getMonth()],
    longMonthName: longMonthNames[newDate.getMonth()],
    shortDayName: shortDayNames[newDate.getDay()],
    longDayName: longDayNames[newDate.getDay()],
  }
}

/** ---------------------------------------------------------------------------
 * @function dateFromYMD
 * @summary dateFromYMD :: Number -> Number -> Number -> Date
 * @desc Returns a date object from the year(full), month(1-12) and day
 * @param {Number} year - full year
 * @param {Number} month - 1-12 the month with be decremented by 1
 * @param {Number} day - day number in month
 * @return {Date} date object in local time zone
 */
export const dateFromYMD = R.curry(
  (year, month, day) => new Date(year, month - 1, day)
)

/** ---------------------------------------------------------------------------
 * @function dateFromYMDString
 * @summary dateFromYMDString :: String -> Date
 * @desc Returns a date object from a date string in the format YYYY-MM-DD
 * @param {String} date - 'YYYY-MM-DD' e.g. '2021-06-03'
 * @return {Date} date object in local time zone
 */
export const dateFromYMDString = date =>
  dateFromYMD(
    +date.substring(0, 4),
    +date.substring(5, 7),
    +date.substring(8, 10)
  )

/** ---------------------------------------------------------------------------
 * @function dateDiffDays
 * @summary dateDiffDays :: Date -> Date -> Number
 * @desc Returns the number of days between two dates
 * @param {Date} fromDate
 * @param {Date} toDate
 * @return {Number} number of days difference
 */
export const dateDiffDays = R.curry((fromDate, toDate) => {
  const d1 = _dateObject(fromDate)
  const d2 = _dateObject(toDate)
  return Math.floor((d2 - d1) / _MS_PER_DAY)
})

/** ---------------------------------------------------------------------------
 * @function dateToString
 * @summary dateToString :: String -> Date || String -> String
 * @desc Returns a formatted date string
 * @param {String} format the day format
 * @param {String | Date} date to convert to string
 * @return {String} formatted date string - eg 3rd June 2021
 */
export const dateToString = R.curry((format, date) => {
  const spec = dateSpec(date)
  const dateElements = {
    d: spec.day.toString(),
    do: ordinal(spec.day),
    dd: spec.day.toString().padStart(2, '0'),
    ddd: spec.shortDayName,
    dddd: spec.longDayName,
    mm: spec.month.toString().padStart(2, '0'),
    mmm: spec.shortMonthName,
    mmmm: spec.longMonthName,
    yy: (spec.year % 100).toString().padStart(2, '0'),
    yyyy: spec.year.toString().padStart(4, '0'),
  }
  const separator = format.includes('/')
    ? '/'
    : format.includes('-')
    ? '-'
    : ' '
  const elements = format.split(separator)
  return elements.reduce((dateString, element) => {
    dateString = dateString.length ? dateString + separator : dateString
    const dateElement = dateElements[element]
    dateString = dateElement
      ? dateString + dateElement
      : dateString + '?' + element
    return dateString
  }, '')
})

/** ---------------------------------------------------------------------------
 * @function dateToYMD
 * @summary dateToYMD :: Date -> String
 * @desc Returns the date object as a string in the format YYYY-MM-DD
 * @param {Date} date to convert to string
 * @return {String} YYYY-MM-DD
 */

export const dateToYMD = date => dateToString('yyyy-mm-dd', date)

/** ---------------------------------------------------------------------------
 * @function dateToDMY
 * @summary dateToDMY :: Date -> String
 * @desc Returns the date object as a string in the format DD/MM/YYYY
 * @param {Date} date to convert to string
 * @return {String} DD-MM-YYYY
 */
export const dateToDMY = date => dateToString('dd/mm/yyyy', date)

/** ---------------------------------------------------------------------------
 * @function daysInMonth
 * @summary daysInMonth :: Number -> Number -> Number
 * @desc Returns the number of days in the given month and year +/-
 * @param {Number} year - Year number
 * @param {Number} month - 1-12
 * @return {Number} number of days in the month
 */
export const daysInMonth = R.curry((year, month) =>
  new Date(year, month, 0).getDate()
)

/** ---------------------------------------------------------------------------
 * @function dateMonthEnd
 * @summary dateMonthEnd :: Number -> Number -> Date
 * @desc Returns a new date object being the last day on the specified month and year
 * @param {Number} years - year of date
 * @param {Number} month - month of date
 * @return {Date} date object for last day of month and year
 */ export const dateMonthEnd = R.curry((year, month) =>
  dateFromYMD(year, month + 1, 0)
)

/** ---------------------------------------------------------------------------
 * @function dateYearsAdjust
 * @summary dateYearsAdjust :: Number -> Date -> Date
 * @desc Returns a new date object adjusted by years +/-
 * @param {Number} years - number of years to adjust the date +/-
 * @param {Date} date - date to adjust
 * @return {Date} adjusted date object
 */
export const dateYearsAdjust = R.curry((years, date) => {
  const { year, month, day } = dateSpec(date)
  return dateFromYMD(year + years, month, day)
})

/** ---------------------------------------------------------------------------
 * @function dateMonthsAdjust
 * @summary dateMonthsAdjust :: Number -> Date -> Date
 * @desc Returns a new date object adjusted by months +/- if the new month has less days than the date the new date will be the month end date
 * @param {Number} months - number of months to adjust the date +/-
 * @param {Date} date - date to adjust
 * @return {Date} adjusted date object
 */
export const dateMonthsAdjust = R.curry((months, date) => {
  const { year, month, day } = dateSpec(date)
  const totalMonths = month + months
  const isLastDayOfMonth = day === daysInMonth(year, month)
  const newYear = totalMonths > 12 ? year + Math.trunc(totalMonths / 12) : year
  const newMonth =
    totalMonths > 12
      ? totalMonths - Math.trunc(totalMonths / 12) * 12
      : totalMonths
  const lastDayInNewMonth = daysInMonth(newYear, newMonth)
  const newDay = isLastDayOfMonth
    ? lastDayInNewMonth
    : Math.min(day, lastDayInNewMonth)
  return dateFromYMD(newYear, newMonth, newDay)
})

/** ---------------------------------------------------------------------------
 * @function dateDaysAdjust
 * @summary dateDaysAdjust :: Number -> Date -> Date
 * @desc Returns a new date object adjusted by the number of days +/-
 * @param {Number} days - number of days to adjust the date +/-
 * @param {Date} date - date to adjust
 * @return {Date} adjusted date object
 */
export const dateDaysAdjust = R.curry((days, date) => {
  const { year, month, day } = dateSpec(date)
  return dateFromYMD(year, month, day + days)
})

/** ---------------------------------------------------------------------------
 * @function dateMonthKey
 * @summary dateMonthKey :: Date || String -> String
 * @desc Returns a key of the offset from date object or date string 'YYYY-MM-DD' in the format YYYYMM
 * @param {Date | String} date - for which month key is required. Either a date object or 'YYYY-MM-YY
 * @return {Date} adjusted date object
 */
export const dateMonthKey = date =>
  dateToString('yyyy-mm', date).replace('-', '')

/** ---------------------------------------------------------------------------
 * @function shortMonthName
 * @summary shortMonthName :: Number || Date || String -> String
 * @desc Returns the short month name for the month number or date object or date string 'YYYY-MM-DD'
 * @param {Number | Date | String} date - for which the short month name is required. Either month number 1-12 or a date object or string 'YYYY-MM-YY
 * @return {String} short month name
 */
export const shortMonthName = monthOrDate =>
  typeof monthOrDate === 'number'
    ? shortMonthNames[monthOrDate - 1]
    : dateSpec(monthOrDate).shortMonthName

/** ---------------------------------------------------------------------------
 * @function longMonthName
 * @summary longMonthName :: Number || Date || String -> String
 * @desc Returns the long month name for the month number or date object or date string 'YYYY-MM-DD'
 * @param {Number | Date | String} date - for which the slong month name is required. Either month number 1-12 or a date object or string 'YYYY-MM-YY
 * @return {String} long month name
 */
export const longMonthName = monthOrDate =>
  typeof monthOrDate === 'number'
    ? longMonthNames[monthOrDate - 1]
    : dateSpec(monthOrDate).longMonthName

/** ---------------------------------------------------------------------------
 * @function shortDayName
 * @summary shortDayName :: Number || Date || String -> String
 * @desc Returns the short date name for the weekDay number or date object or date string 'YYYY-MM-DD'
 * @param {Number | Date | String} date - for which the short day name is required. Either weekDay number 0-6 or a date object or string 'YYYY-MM-YY
 * @return {String} short day name
 */
export const shortDayName = weekDayOrDate =>
  typeof weekDayOrDate === 'number'
    ? shortDayNames[weekDayOrDate]
    : dateSpec(weekDayOrDate).shortDayName

/** ---------------------------------------------------------------------------
 * @function longDayName
 * @summary longDayName :: Number || Date || String -> String
 * @desc Returns the long date name for the weekDay number or date object or date string 'YYYY-MM-DD'
 * @param {Number | Date | String} date - for which the long day name is required. Either weekDay number 0-6 or a date object or string 'YYYY-MM-YY
 * @return {String} long day name
 */
export const longDayName = weekDayOrDate =>
  typeof weekDayOrDate === 'number'
    ? longDayNames[weekDayOrDate]
    : dateSpec(weekDayOrDate).longDayName

/** ---------------------------------------------------------------------------
 * @function monthKeyName
 * @summary monthKeyName :: String -> [String] -> [String]
 * @desc Returns month name for the given monthKey in the format specified
 * @param {String} format - the format of the returned month
 * @param {String} monthKey - month keys
 * @return {String} month name
 */
export const monthKeyName = R.curry((format, monthKey) =>
  dateToString(
    format,
    dateFromYMD(0 + monthKey.substr(0, 4), 0 + monthKey.substr(4, 2), 1)
  )
)

/** ---------------------------------------------------------------------------
 * @function periodMonthNames
 * @summary periodMonthNames :: String -> [String] -> [String]
 * @desc Returns an array of month names for the given monthKeys array in the format specified
 * @param {String} format - the format of the returned months
 * @param {Array} monthKeys - array of month keys
 * @return {Array} Array of the month names
 */
export const periodMonthNames = R.curry((format, monthKeys) =>
  monthKeys.map(monthKey => monthKeyName(format, monthKey))
)

export default {
  shortMonthNames,
  longMonthNames,
  shortDayNames,
  longDayNames,
  dateSpec,
  dateFromYMD,
  dateFromYMDString,
  dateDiffDays,
  dateToString,
  dateToYMD,
  dateToDMY,
  daysInMonth,
  dateMonthEnd,
  dateYearsAdjust,
  dateMonthsAdjust,
  dateDaysAdjust,
  dateMonthKey,
  shortMonthName,
  longMonthName,
  shortDayName,
  longDayName,
  monthKeyName,
  periodMonthNames,
}
