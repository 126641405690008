class PcSimpleTableDef {
  constructor(id, other = {}) {
    this.id = id
    this.dense = true
    this.class = ''
    this.width = ''
    this.height = '100%'
    this.maxHeight = 0
    this.headers = true
    this.fixedHeader = true
    this.headerClass = 'grey lighten-4'
    this.paddingBetweenFields = 1
    this.expand = false
    this.rowClick = false
    this.rows = []
    this.fields = []
    this.data = []

    Object.entries(other).forEach(([key, value]) => {
      if (Object.hasOwnProperty.call(this, key)) {
        this[key] = value
      } else {
        console.log(`Invalid property: ${key} in pcSimpleTable id ${id}`)
      }
    })

    // Convert maxHeight to number incase it has been passed
    // as string
    if (typeof this.maxHeight === 'string') {
      if (this.maxHeight.slice(-2) === 'px') {
        this.maxHeight = Number(
          this.maxHeight.substring(0, this.maxHeight.length - 2)
        )
      }
    }
  }
}

const pcSimpleTableDef = function(id, other = {}) {
  return new PcSimpleTableDef(id, other)
}

class PcSimpleTableFieldDef {
  constructor(path, type, heading, other = {}) {
    this.path = path
    this.type = type
    this.heading = heading
    this.headingClass = ''
    this.fieldClass = ''
    this.fieldStyle = ''
    this.blankIfZero = false
    this.decimalPlaces = 2
    this.click = false
    this.hover = false
    this.icon = ''
    this.iconColor = 'primary'
    this.showActionIf = null
    this.tooltipPosition = 'top'
    this.tooltipText = ''
    this.tooltipSlot = ''
    this.tooltipColor = 'transparent'

    Object.entries(other).forEach(([key, value]) => {
      if (Object.hasOwnProperty.call(this, key)) {
        this[key] = value
      } else {
        console.log(
          `Invalid property: ${key} in pcSimpleTableField path ${path}`
        )
      }
    })

    if (
      this.type === 'int' ||
      this.type === 'number' ||
      this.type === 'currency' ||
      this.type === 'percentage' ||
      this.type === 'date' ||
      this.type === 'lastInvoicedDate' ||
      this.type === 'expand' ||
      this.type === 'action'
    ) {
      this.fieldClass = 'text-right ' + this.fieldClass
      this.headingClass = 'text-right ' + this.headingClass
    } else {
      this.fieldClass = 'text-left ' + this.fieldClass
      this.headingClass = 'text-left ' + this.headingClass
    }
  }
  get isClickEvent() {
    return this.click ? 'click' : null
  }

  get isExpandEvent() {
    return this.type === 'expand' ? 'expand' : null
  }

  get isTooltipEvent() {
    return this.tooltipText || this.tooltipSlot ? 'tooltip' : null
  }
}

const pcSimpleTableFieldDef = function(path, type, heading = '', other = {}) {
  return new PcSimpleTableFieldDef(path, type, heading, other)
}

export { pcSimpleTableDef, pcSimpleTableFieldDef }
