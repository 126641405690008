<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChartType"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Stacked bar chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="line"
        icon="mdi-chart-line"
        small
        top
        tooltip="Line chart"
      />
      <pc-action-icon
        @click="toggleHidden"
        value=""
        icon="mdi-eye-off"
        small
        top
        tooltip="Show/Hide datasets"
      />
    </template>
    <template v-slot:default>
      <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
      <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

export default {
  name: 'sales30DaysGroupChart',
  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
  },

  props: {
    sales30DayData: Object,
  },

  created() {
    this.lineChartDef.show = false
  },

  computed: {
    displayedChartDef() {
      if (this.barChartDef.show) return this.barChartDef
      return this.lineChartDef
    },
  },

  watch: {
    sales30DayData() {
      this.main()
    },
  },

  methods: {
    main() {
      this.updateChart()
    },

    updateChart() {
      const labels = [...this.sales30DayData.groups[0].days]
        .reverse()
        .map(day => day.date)
      const groups = pc
        .query()
        .addSort('total', true)
        .run(
          this.sales30DayData.groups.reduce((groups, group, index) => {
            if (index) groups.push(group)
            return groups
          }, [])
        )

      this.barChartDef.setLabels(labels)
      this.lineChartDef.setLabels(labels)

      groups.forEach((contactGroup, index) => {
        const data = [...contactGroup.days]
          .reverse()
          .map(day => day.total.toFixed(2))

        this.barChartDef.addDataset(
          data,
          contactGroup.name,
          this.chartImpactColors[index]
        )
        this.lineChartDef.addDataset(
          data,
          contactGroup.name,
          this.chartImpactColors[index]
        )
      })
      this.barChartDef.render()
      this.lineChartDef.render()
    },

    displayChartType(type) {
      this.barChartDef.show = type === 'bar'
      this.lineChartDef.show = type === 'line'
    },

    toggleHidden() {
      this.displayedChartDef.toggleHidden()
    },
  },

  data() {
    return {
      period: db.cached('period'),
      chartImpactColors: getColors('impact'),
      chartPastelColors: getColors('pastel'),

      displayBoxDef: pcDisplayBoxDef(
        'sales30DayGroupChart',
        'Sales by contact group',
        {
          height: '100%',
        }
      ),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),
    }
  },
}
</script>

<style scoped></style>
