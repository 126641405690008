<template>
  <v-row
    v-show="show"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <div class="grid-container pb-0">
        <div class="categories-not-bought">
          <customer-top-10-categories-not-bought
            :customer="customer"
            :categories="categoriesNotBought"
          />
        </div>
        <div class="categories-bought">
          <customer-top-10-categories-bought
            :customer="customer"
            :categories="categoriesBought"
            @order-by="updateCategories"
          />
        </div>
        <div class="categories-bought-chart">
          <customer-top-10-chart
            recordType="category"
            :orderBy="categoriesOrderBy"
            :top10Records="categoriesBought"
          />
        </div>
        <div class="products-not-bought">
          <customer-top-10-products-not-bought
            :customer="customer"
            :products="productsNotBought"
          />
        </div>
        <div class="products-bought">
          <customer-top-10-products-bought
            :customer="customer"
            :products="productsBought"
            @order-by="updateProducts"
          />
        </div>
        <div class="products-bought-chart">
          <customer-top-10-chart
            recordType="product"
            :orderBy="productsOrderBy"
            :top10Records="productsBought"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { getColors } from '@pcModules/pcColors.js'
import customerTop10CategoriesNotBought from '@appViews/customerDashboard/customerTop10/customerTop10CategoriesNotBought.vue'
import customerTop10CategoriesBought from '@appViews/customerDashboard/customerTop10/customerTop10CategoriesBought.vue'
import customerTop10ProductsNotBought from '@appViews/customerDashboard/customerTop10/customerTop10ProductsNotBought.vue'
import customerTop10ProductsBought from '@appViews/customerDashboard/customerTop10/customerTop10ProductsBought.vue'
import customerTop10Chart from '@appViews/customerDashboard/customerTop10/customerTop10Chart.vue'

export default {
  name: 'customerSummary',
  components: {
    customerTop10CategoriesNotBought,
    customerTop10CategoriesBought,
    customerTop10ProductsNotBought,
    customerTop10ProductsBought,
    customerTop10Chart,
  },

  props: {
    show: Boolean,
    customerId: String,
  },

  created() {
    this.main()
  },

  watch: {
    customerId() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.customerId) {
        this.customer = db.cached(
          'customers',
          db.cachedQuery().addFind('id', this.customerId)
        )
        this.updateCategories()
        this.updateProducts()
      }
    },

    async updateCategories(orderBy = 'ranking') {
      this.categoriesOrderBy = orderBy
      const categories = await this.getCategoriesTop10(orderBy)
      this.categoriesBought = categories.bought
      this.categoriesNotBought = categories.notBought
    },

    async updateProducts(orderBy = 'ranking') {
      this.productsOrderBy = orderBy
      const products = await this.getProductsTop10(orderBy)
      this.productsBought = products.bought
      this.productsNotBought = products.notBought
    },

    sumRecords(records) {
      return records.reduce(
        (totals, record) => {
          totals[0] += record.sales
          totals[1] = totals[1].map(
            (month, index) => (month += record.salesByMonth[index])
          )
          return totals
        },
        [0, new Array(this.period.monthsInPeriod).fill(0)]
      )
    },

    async getCategoriesTop10() {
      // Get all categories and sort by ranking
      const categories = db.cached(
        'categories',
        db
          .cachedQuery()
          .addFilter('id', 'category', 'ne')
          .addClone()
      )
      const categoryCustomers = await db.request(
        db.query('customerDashboard.categoryCustomers'),
        db.args(
          db
            .queryArgs('categoryCustomers')
            .addFilter('customerId', this.customerId)
        )
      )

      const [totalSales, totalSalesByMonth] = this.sumRecords(categoryCustomers)

      categoryCustomers.forEach(categoryCustomer => {
        const category = categories.find(
          category => category.id === categoryCustomer.categoryId
        )
        if (category) {
          category.bought = true
          category.sales = categoryCustomer.sales
          category.salesByMonth = categoryCustomer.salesByMonth
          category.lastInvoiced = categoryCustomer.lastInvoiced
        }
      })

      let bought = pc
        .query()
        .addFilter('bought', true)
        .addFilter('hasChildren', false)
        .addSort(this.categoriesOrderBy, this.categoriesOrderBy === 'sales')
        .addLimitAfterSort(10)
        .run(categories)

      const [boughtTop10Sales, boughtTop10SalesByMonth] = this.sumRecords(
        bought
      )

      bought.push({
        name: 'All other categories',
        ranking: 0,
        sales: totalSales - boughtTop10Sales,
        salesByMonth: totalSalesByMonth.map(
          (month, index) => month - boughtTop10SalesByMonth[index]
        ),
        lastInvoiced: '',
      })

      bought = bought.map((record, index) => {
        record.tag = this.chartColors[index]
        record.percentage = totalSales ? (record.sales / totalSales) * 100 : 0
        return record
      })

      const notBought = pc
        .query()
        .addFilter('bought', true, 'ne')
        .addFilter('ranking', 0, 'ne')
        .addSort('ranking')
        .addLimitAfterSort(10)
        .run(categories)

      return { bought, notBought }
    },

    async getProductsTop10() {
      // Get all products and sort by ranking
      const data = await db.request(
        db.query('customerDashboard.productsAndProductCustomers'),
        db
          .args()
          .addQueryArgs(
            db
              .queryArgs('products')
              .addFilter('ranking', 0, 'ne')
              .addSort('ranking')
          )
          .addQueryArgs(
            db
              .queryArgs('productCustomers')
              .addFilter('customerId', this.customerId)
          )
      )

      const [totalSales, totalSalesByMonth] = this.sumRecords(
        data.productCustomers
      )

      data.productCustomers.forEach(productCustomer => {
        const product = data.products.find(
          product => product.code === productCustomer.productCode
        )
        if (product) {
          product.bought = true
          product.quantity = productCustomer.quantity
          product.sales = productCustomer.sales
          product.salesByMonth = productCustomer.salesByMonth
          product.lastInvoiced = productCustomer.lastInvoiced
        }
      })

      data.bought = pc
        .query()
        .addFilter('bought', true)
        .addSort(this.productsOrderBy, this.productsOrderBy === 'sales')
        .addLimitAfterSort(10)
        .run(data.products)

      const [boughtTop10Sales, boughtTop10SalesByMonth] = this.sumRecords(
        data.bought
      )

      data.bought.push({
        code: '',
        name: 'All other products',
        ranking: 0,
        sales: totalSales - boughtTop10Sales,
        salesByMonth: totalSalesByMonth.map(
          (month, index) => month - boughtTop10SalesByMonth[index]
        ),
        lastInvoiced: '',
      })

      data.bought = data.bought.map((record, index) => {
        record.tag = this.chartColors[index]
        record.percentage = totalSales ? (record.sales / totalSales) * 100 : 0
        return record
      })

      data.notBought = pc
        .query()
        .addFilter('bought', true, 'ne')
        .addFilter('ranking', 0, 'ne')
        .addSort('ranking')
        .addLimitAfterSort(10)
        .run(data.products)

      return data
    },
  },

  data() {
    return {
      period: db.cached('period'),
      customer: {},
      categoriesOrderBy: '',
      categoriesBought: [],
      categoriesNotBought: [],
      productsOrderBy: '',
      productsBought: [],
      productsNotBought: [],

      chartColors: getColors('impact'),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, 100%);
  grid-template-areas:
    'categories-not-bought'
    'products-not-bought'
    'categories-bought'
    'categories-bought-chart'
    'products-bought'
    'products-bought-chart';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: 0.1fr 0.4fr 0.1fr 0.4fr;
    grid-template-rows: repeat(3, 100%);
    grid-template-areas:
      'categories-not-bought categories-not-bought products-not-bought products-not-bought'
      'categories-bought categories-bought categories-bought categories-bought-chart'
      'products-bought products-bought products-bought products-bought-chart';
  }
}
@media only screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: repeat(2, minmax(100px, 1fr));
    grid-template-areas:
      'categories-not-bought categories-bought categories-bought-chart'
      'products-not-bought products-bought products-bought-chart';
  }
}
.categories-not-bought {
  grid-area: categories-not-bought;
  overflow-y: auto;
}
.categories-bought {
  grid-area: categories-bought;
  overflow-y: auto;
}
.categories-bought-chart {
  grid-area: categories-bought-chart;
  overflow-y: auto;
}
.products-not-bought {
  grid-area: products-not-bought;
  overflow-y: auto;
}
.products-bought {
  grid-area: products-bought;
  overflow-y: auto;
}
.products-bought-chart {
  grid-area: products-bought-chart;
  overflow-y: auto;
}
</style>
