import { gql } from 'graphql-tag'

export const companyGql = gql`
  query($dbQuery: DbQuery) {
    company(dbQuery: $dbQuery) {
      outstandingProfile {
        overdue {
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
        due {
          profile
          invoices
          creditNotes
          invoicesAmount
          creditNotesAmount
          total
          customers
        }
      }
      paidInvoicesProfile {
        profileIndex
        months {
          count
          profileIndex
          monthIndex
        }
      }
    }
  }
`
