<template>
  <v-card
    v-if="nuggetDef.if"
    v-show="nuggetDef.show"
    :id="`${nuggetDef.id}Body`"
    :color="nuggetDef.color"
    :height="nuggetDef.height"
    :max-height="nuggetDef.height"
    :width="nuggetDef.width"
    :rounded="nuggetDef.rounded"
    :elevation="nuggetDef.elevation"
    outlined
    class="pt-0 pa-0"
    :class="nuggetDef.class"
  >
    <v-card
      :id="`${nuggetDef.id}Title`"
      :color="nuggetDef.titleColor"
      class="mt-n4 ml-3 mr-3 mx-auto pt-1 pb-1 text-center font-weight-bold rounded-xl"
      :class="nuggetDef.titleClass"
      :elevation="nuggetDef.titleElevation"
    >
      {{ nuggetDef.title }}
    </v-card>
    <v-card-text
      class="pt-2 text-center body-2 white--text font-weight-bold"
      :class="nuggetDef.bodyClass"
    >
      <span v-html="nuggetDef.body"></span>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'pcNugget',

  components: {},

  props: {
    nuggetDef: Object,
  },

  computed: {},

  watch: {},

  methods: {},

  data() {
    return {}
  },
}
</script>

<style scoped></style>
