<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <pc-action-icon
        @click="displayChartType"
        value="pie"
        icon="mdi-chart-pie"
        small
        top
        tooltip="Pie chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="bar"
        icon="mdi-chart-bar"
        small
        top
        tooltip="Stacked bar chart"
      />
      <pc-action-icon
        @click="displayChartType"
        value="line"
        icon="mdi-chart-line"
        small
        top
        tooltip="Line chart"
      />
      <pc-action-icon
        @click="toggleHidden"
        value=""
        icon="mdi-eye-off"
        small
        top
        tooltip="Show/Hide datasets"
      />
    </template>
    <pc-chart
      :chartDef="pieChartDef"
      @instance="pieChartDef.instance"
      @tooltip="pieTooltip"
    />
    <pc-chart :chartDef="barChartDef" @instance="barChartDef.instance" />
    <pc-chart :chartDef="lineChartDef" @instance="lineChartDef.instance" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColors } from '@pcModules/pcColors.js'
import { pcSparkChartDef } from '@pcComponents/defs/pcSparkChartDef.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcActionIcon from '@pcComponents/pcActionIcon.vue'

import { contactGroupCategoriesGql } from './contactGroupSalesByCategoryChart.gql'

export default {
  name: 'contactGroupSalesByCategoryChart',

  components: {
    pcChart,
    pcDisplayBox,
    pcActionIcon,
  },

  props: {
    group: Object,
  },

  computed: {
    periodFromToShort() {
      const startMonth = pc.monthKeyName('mmm yy', this.period.monthKeys[0])
      const endMonth = pc.monthKeyName(
        'mmm yy',
        this.period.monthKeys[this.period.monthKeys.length - 1]
      )
      return `${startMonth} - ${endMonth}`
    },

    displayedChartDef() {
      if (this.pieChartDef.show) return this.pieChartDef
      if (this.barChartDef.show) return this.barChartDef
      return this.lineChartDef
    },
  },

  watch: {
    group: {
      immediate: true,
      async handler(group) {
        if (group && group.id) {
          this.displayChartType('pie')
          this.contactGroupCategories =
            group.id === 'company'
              ? this.getCatgeorySalesFromCachedCategories()
              : await this.getCategorySalesFromContactGroupCategories(group)
          this.updatePieChart()
          this.updateBarAndLineChart()
        }
      },
    },
  },

  methods: {
    main() {},

    getCatgeorySalesFromCachedCategories() {
      return db.cached('categories', db.cachedQuery().addFilter('level', 1))
    },

    async getCategorySalesFromContactGroupCategories(group) {
      const records = await db.request(
        contactGroupCategoriesGql,
        db.args(
          db
            .queryArgs('contactGroupCategories')
            .addFilterIf(group.id !== 'company', 'contactGroupId', group.id)
            .addFilter('categoryLevel', 1)
            .addFilter('sales', 0, 'ne')
            .addSort('sales', true)
        )
      )
      return records.map(record => ({
        ...record,
        name: record.categoryName,
      }))
    },

    updatePieChart() {
      this.chartData = this.contactGroupCategories.reduce(
        (chartData, contactGroupCategory) => {
          chartData.ids.push(contactGroupCategory.categoryId)
          chartData.labels.push(contactGroupCategory.name)
          chartData.values.push(contactGroupCategory.sales)
          chartData.total += contactGroupCategory.sales
          return chartData
        },
        { ids: [], labels: [], values: [], total: 0 }
      )
      this.pieChartDef
        .clearDatasets()
        .setPc([['ids', this.chartData.ids]])
        .setLabels(this.chartData.labels)
        .addDataset(this.chartData.values, 'Turnover', this.chartColors)
        .setOptions([['elements.center.text', this.periodFromToShort]])
        .render()
    },

    updateBarAndLineChart() {
      this.barChartDef.clearDatasets()
      this.lineChartDef.clearDatasets()
      this.chartData = this.contactGroupCategories.forEach(
        (contactGroupCategory, index) => {
          if (contactGroupCategory.sales) {
            this.barChartDef.addDataset(
              pc.arrayFixed2(contactGroupCategory.salesByMonth),
              contactGroupCategory.name,
              this.chartColors[index]
            )
            this.lineChartDef.addDataset(
              pc.arrayFixed2(contactGroupCategory.salesByMonth),
              contactGroupCategory.name,
              this.chartColors[index]
            )
          }
        }
      )
    },

    displayChartType(type) {
      this.pieChartDef.show = type === 'pie'
      this.barChartDef.show = type === 'bar'
      this.lineChartDef.show = type === 'line'
    },

    toggleHidden() {
      this.displayedChartDef.toggleHidden()
    },

    pieTooltip(payload) {
      const categoryId = this.pieChartDef.pc.ids[payload.dataIndex]
      const contactGroupCategory = pc
        .query()
        .addFind('categoryId', categoryId)
        .run(this.contactGroupCategories)
      const title = contactGroupCategory.name
      const color = this.pieChartDef.data.datasets[0].backgroundColor[
        payload.dataIndex
      ]
      const labels = pc.periodMonthNames('mmm', this.period.monthKeys)
      const percent = pc.toFixed2(
        pc.percentage(contactGroupCategory.sales, this.group.sales)
      )
      const subTitle = `£${pc.toFixed2(
        contactGroupCategory.sales
      )} (${percent}%) of total sales`

      this.pieSparkChartDef
        .clearDatasets()
        .setTitle(title, color)
        .setSubTitle(subTitle)
        .setLabels(labels)
        .addDataset(
          pc.arrayFixed2([...contactGroupCategory.salesByMonth]),
          '',
          color
        )

      this.pieChartDef.setSparkChart(this.pieSparkChartDef)
      this.pieSparkChartDef.render()
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,
      chartColors: getColors('impact'),

      displayBoxDef: pcDisplayBoxDef('categoryPieChart', 'Sales by category'),

      pieChartDef: pcChartDef2('pie')
        .setChart([
          ['containerClass', 'grey lighten-5'],
          ['tooltipSparkChart', true],
        ])
        .setTitle('', false)
        .setOptions([['cutout', '50%']]),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      lineChartDef: pcChartDef2('line')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),

      pieSparkChartDef: pcSparkChartDef('bar').setLabels(
        pc.periodMonthNames('mmm', period.monthKeys)
      ),

      contactGroupCategories: [],
      chartData: {},
    }
  },
}
</script>

<style scoped></style>
