import { queries } from './dbQueryLibrary'
import pc from '@pc'

export class DbQueryLibrary {
  constructor() {
    this.queries = queries
  }

  query(queryPath) {
    const query = pc.getProperty(queryPath, this.queries)
    if (!query) console.log(`Library query: ${queryPath} does not exist`)
    return query
  }
}

const queryLibrary = new DbQueryLibrary()

export { queryLibrary }
