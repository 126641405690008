import { gql } from 'graphql-tag'

const cached = gql`
  query($dbQuery: DbQuery) {
    multiQuery(dbQuery: $dbQuery) {
      period {
        periodStartDate
        periodEndDate
        text
        monthsInPeriod
        monthKeys
        salesByDaysSpec {
          captions
          dates
        }
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        activeCustomers
        activeCustomersByDays
        activeCustomersByMonth
        activeProducts
        activeProductsByDays
        activeProductsByMonth
        customerAverage
        customerAverageByDays
        customerAverageByMonth
        invoiceAverage
        invoiceAverageByDays
        invoiceAverageByMonth
        outstanding {
          legend
          future
          numberInvoices
          valueInvoices
          numberCreditNotes
          valueCreditNotes
          due
        }
        totalOutstanding
        paymentStats {
          monthKey
          numberInvoicesRaised
          valueInvoicesRaised
          numberCreditNotesRaised
          valueCreditNotesRaised
          valueCreditNotesUnallocated
          valueInvoicesDue
          paid {
            number {
              onTime
              upto7Days
              upto14Days
              upto21Days
              over21Days
              total
              paymentDays
              averagePaymentDays
            }
            value {
              onTime
              upto7Days
              upto14Days
              upto21Days
              over21Days
              total
            }
          }
          unpaid {
            number {
              onTime
              upto7Days
              upto14Days
              upto21Days
              over21Days
              total
            }
            value {
              onTime
              upto7Days
              upto14Days
              upto21Days
              over21Days
              total
            }
          }
        }
      }
      customers(dbQuery: $dbQuery) {
        id
        name
        phoneNumbers {
          phoneType
          phoneNumber
        }
        contacts {
          firstName
          lastName
          emailAddress
        }
        contactGroupId
        ranking
        contactGroupRanking
        sales
        salesByDays
        salesByMonth
        products
        productsByMonth
        productCoveragePercentage
        productCoveragePercentageByMonth
        averageMonthlySpend
        monthSpendComparison
        monthSpendComparisonPercentage
        lastInvoiced
        outstanding
        paymentProfile
        overdue
        daysOver
      }
      products(dbQuery: $dbQuery) {
        code
        name
        category {
          level1
          level2
          level3
        }
        ranking
        quantity
        sales
        activeCustomers
        lastInvoiced
      }
      contactGroups(dbQuery: $dbQuery) {
        id
        name
        numberCustomers
        activeCustomers
        activeCustomersByDays
        activeCustomersByMonth
        sales
        salesByDays
        salesByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        customerAverage
        customerAverageByDays
        customerAverageByMonth
        invoiceAverage
        invoiceAverageByDays
        invoiceAverageByMonth
      }
      categories(dbQuery: $dbQuery) {
        id
        parentId
        name
        level
        system
        hasChildren
        ranking
        rankingByMonth
        sales
        salesByDays
        salesByMonth
        activeCustomers
        activeCustomersByDays
        activeCustomersByMonth
        invoices
        invoicesByDays
        invoicesByMonth
        lastInvoiced
      }
    }
  }
`

const queries = {
  cached,
}

export { queries as cache }
