<template>
  <div class="div" :style="`${this.show ? '' : 'display: none !important;'}`">
    <v-skeleton-loader
      v-if="cardDef.loading"
      :loading="true"
      :height="cardHeight"
      :style="skeletonStyle"
      class="mx-auto pc-size100"
      :type="cardDef.loaderType"
    >
    </v-skeleton-loader>

    <v-card
      v-if="cardDef.if"
      v-show="cardDef.show && !cardDef.loading"
      :id="`${cardDef.id}Title${uid}`"
      :disabled="disabled"
      :color="color"
      :elevation="elevation"
      :outlined="outlined"
      :flat="flat"
      class="mx-auto pc-flex d-flex flex-column"
      :class="cardClass"
      :style="cardStyle"
      :height="cardHeight"
      :max-height="maxCardHeight"
      :width="cardWidth"
      :max-width="maxCardWidth"
    >
      <v-card-title
        :id="`${cardDef.id}Title${uid}`"
        class="pa-0 d-flex"
        :class="titleClass"
        :style="titleStyle"
      >
        <slot name="title"></slot>
      </v-card-title>

      <v-card-subtitle
        :id="`${cardDef.id}SubTitle${uid}`"
        class="pa-0 d-flex"
        :class="`mt-0 ${subTitleClass}`"
        :style="subTitleStyle"
      >
        <slot name="subTitle"></slot>
      </v-card-subtitle>

      <v-card-text
        v-if="cardDef.expand"
        :id="`${cardDef.id}Text${uid}`"
        class=""
      >
        <v-card :id="`${cardDef.id}CardTextWapper${uid}`" class="">
          <slot name="text"></slot>
        </v-card>
      </v-card-text>

      <v-card-text
        v-else
        :id="`${cardDef.id}Text${uid}`"
        class="mt-0 pa-0 card-text pc-flex d-flex flex-grow-1 flex-column"
        :class="textClass"
        :style="textStyle"
        v-resize="setTextHeight"
      >
        <v-card
          :id="`${cardDef.id}CardTextWapper${uid}`"
          class="d-flex flex-grow-1 text-wrapper"
          :width="textWidth"
          :height="textHeight"
        >
          <slot name="text"></slot>
        </v-card>
      </v-card-text>

      <v-card-actions
        :id="`${cardDef.id}Actions${uid}`"
        class="pa-0 pc-flex d-flex flex-column"
        :class="actionClass"
        :style="actionStyle"
      >
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import pc from '@pc'
import resize from 'vue-resize-directive'
import getPropertyBreakpointValue from '@pcComponents/modules/getPropertyBreakpointValue'

export default {
  name: 'pcCard',
  components: {},

  directives: {
    resize,
  },

  created() {
    this.$emit('uid', this.uid)
  },

  mounted() {
    pc.forceNextTick(this.setTextHeight)
  },

  props: {
    cardDef: Object,
  },

  computed: {
    show() {
      return this.getPropertyBreakpointValue(this.cardDef, 'show')
    },
    disabled() {
      return this.getPropertyBreakpointValue(this.cardDef, 'disabled')
    },
    color() {
      return this.getPropertyBreakpointValue(this.cardDef, 'color')
    },
    elevation() {
      return this.getPropertyBreakpointValue(this.cardDef, 'elevation')
    },
    outlined() {
      return this.getPropertyBreakpointValue(this.cardDef, 'outlined')
    },
    flat() {
      return this.getPropertyBreakpointValue(this.cardDef, 'flat')
    },
    cardHeight() {
      return this.getPropertyBreakpointValue(this.cardDef, 'height')
    },
    maxCardHeight() {
      return this.getPropertyBreakpointValue(this.cardDef, 'maxHeight')
    },
    cardWidth() {
      return this.getPropertyBreakpointValue(this.cardDef, 'width')
    },
    maxCardWidth() {
      return this.getPropertyBreakpointValue(this.cardDef, 'maxWidth')
    },
    cardClass() {
      return this.getPropertyBreakpointValue(this.cardDef, 'cardClass')
    },
    cardStyle() {
      return this.getPropertyBreakpointValue(this.cardDef, 'cardStyle')
    },
    titleClass() {
      return this.getPropertyBreakpointValue(this.cardDef, 'titleClass')
    },
    titleStyle() {
      return this.getPropertyBreakpointValue(this.cardDef, 'titleStyle')
    },
    subTitleClass() {
      return this.getPropertyBreakpointValue(this.cardDef, 'subTitleClass')
    },
    subTitleStyle() {
      return this.getPropertyBreakpointValue(this.cardDef, 'subTitleStyle')
    },
    textClass() {
      return this.getPropertyBreakpointValue(this.cardDef, 'textClass')
    },
    textStyle() {
      return this.getPropertyBreakpointValue(this.cardDef, 'textStyle')
    },
    actionClass() {
      return this.getPropertyBreakpointValue(this.cardDef, 'actionClass')
    },
    actionStyle() {
      return this.getPropertyBreakpointValue(this.cardDef, 'actionStyle')
    },
    skeletonStyle() {
      return `width: ${pc.getBreakpointValue(
        this.cardDef.width,
        this.breakpoint
      )}; maxWidth: ${pc.getBreakpointValue(
        this.cardDef.maxWidth,
        this.breakpoint
      )};`
    },
  },
  watch: {},
  methods: {
    getPropertyBreakpointValue,
    setTextHeight() {
      if (this.show) {
        const wrapperElement = pc.getElementById(
          `${this.cardDef.id}CardTextWapper${this.uid}`
        )
        if (wrapperElement) {
          this.textHeight = wrapperElement.parentElement.offsetHeight
          this.textWidth = wrapperElement.parentElement.offsetWidth
          if (typeof this.cardDef.fnTextResize === 'function') {
            this.cardDef.fnTextResize({
              width: wrapperElement.parentElement.offsetWidth,
              height: wrapperElement.parentElement.offsetHeight,
            })
          }
        }
      }
    },
  },
  data() {
    return {
      uid: pc.uid(),
      textWidth: '',
      textHeight: '',
    }
  },
}
</script>

<style scoped>
.div {
  height: 100%;
  width: 100%;
}
.card-text {
  overflow-y: hidden;
}
.text-wrapper {
  position: relative;
  min-width: 0;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}
</style>
