import pc from '@pc'
import db from '@db'
import { categoryUpdates } from '../categoryMaintenance.qgl.js'

const formatCategory = category => ({
  id: category.id,
  name: category.name,
  level: category.level,
  grandParentId: category.grandParentId,
  parentId: category.parentId,
  isCategory: true,
  isProduct: false,
  isSystem: category.system,
  isModified: false,
  isAdded: false,
  isDeleted: false,
  hasChildren: category.hasChildren,
  hasProducts: false,
  children: [],
})

const readCategoryData = async () => {
  const categories = db.cached('categories')
  const categoryUpdatesRecord = await db.request(categoryUpdates)
  const updates = categoryUpdatesRecord.categories
  return { categories, updates }
}

const formatCategories = ({ categories, updates }) => ({
  categories: categories.map(formatCategory),
  updates,
})

const processCategoryModifiedAddUpdates = ({ categories, updates }) => {
  const getCategory = id => {
    const category = pc
      .query()
      .addFind('id', id)
      .run(categories)
    if (!category)
      console.log(`Failed to find category Id ${id} in processCategoryUpdates`)
    return category
  }

  const processModified = update => {
    const category = getCategory(update.id)
    if (!category) return
    category.name = update.name
    category.level = update.level
    category.grandParentId = update.grandParentId
    category.parentId = update.parentId
    category.isModified = true
  }

  const processAdded = update => {
    const hasChildren =
      pc
        .query()
        .addFilter('parentId', update.id)
        .run(updates).length > 0

    categories.push(
      formatCategory({ ...update, children: [], hasChildren, isSystem: false })
    )
  }

  const updateCategory = update => {
    if (update.isModified) processModified(update)
    if (update.isAdded) processAdded(update)
  }

  updates.forEach(updateCategory)
  return { categories, updates }
}

const processCategoryDeletedUpdates = ({ categories, updates }) => {
  const deletedIds = updates.reduce((ids, update) => {
    update.isDeleted && ids.push(update.id)
    return ids
  }, [])
  return pc
    .query()
    .addExclude('id', deletedIds)
    .run(categories)
}

export const getCategories = pc.pipeAsync(
  readCategoryData,
  formatCategories,
  processCategoryModifiedAddUpdates,
  processCategoryDeletedUpdates
)
