<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <pc-chart :chartDef="pieChartDef" />
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'

export default {
  name: 'CustomerCoveragePie',
  components: {
    pcChart,
    pcDisplayBox,
  },

  props: {
    group: Object,
    item: Object,
  },

  computed: {},

  watch: {
    item() {
      this.main()
    },
  },

  methods: {
    async main() {
      if (this.item) {
        const customersNotBought =
          this.group.activeCustomers - this.item.activeCustomers

        const boughtLabel = `Bought ${this.item.activeCustomers} (${pc.toFixed2(
          pc.percentage(this.item.activeCustomers, this.group.activeCustomers)
        )}%)`

        const notBoughtLabel = `Not bought ${customersNotBought} (${pc.toFixed2(
          pc.percentage(customersNotBought, this.group.activeCustomers)
        )}%)`

        this.pieChartDef
          .clearDatasets()
          .setLabels([boughtLabel, notBoughtLabel])
          .addDataset([this.item.activeCustomers, customersNotBought], '', [
            getColor('purple', 3),
            getColor('grey', 5),
          ])
          .render()
      } else {
        this.pieChartDef.clearDatasets().render()
        this.pieChartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      period,

      displayBoxDef: pcDisplayBoxDef(
        'customerCoveragePie',
        'Active customers coverage in the period'
      ),

      pieChartDef: pcChartDef2('pie')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),
    }
  },
}
</script>

<style scoped></style>
