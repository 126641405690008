<template>
  <v-row
    v-if="sales30DayDef.show"
    id="sales30Days"
    class="fill-height mt-0"
    justify="center"
    align="stretch"
  >
    <v-col cols="12" class="pc-size100 pt-0 pb-0">
      <div class="grid-container pb-0">
        <div class="statistics">
          <sales-30-days-statistics :sales30DayData="sales30DayData" />
        </div>
        <div class="sales-chart">
          <sales-30-day-chart :sales30DayData="sales30DayData" />
        </div>
        <div class="sales-group-chart">
          <sales-30-day-group-chart :sales30DayData="sales30DayData" />
        </div>
        <div class="weekday-chart">
          <weekday-chart :sales30DayData="sales30DayData" />
        </div>
        <div class="group-pie-chart">
          <contact-group-chart :sales30DayData="sales30DayData" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcToolbarDef } from '@pcComponents/defs/pcToolbarDef.js'

import sales30DaysStatistics from './Sales30DaysStatistics.vue'
import sales30DayChart from './sales30DayChart.vue'
import sales30DayGroupChart from './sales30DayGroupChart.vue'
import weekdayChart from './weekdayChart.vue'
import contactGroupChart from './contactGroupChart.vue'
import { getColors } from '@pcModules/pcColors'

export default {
  name: 'sales30Days',

  components: {
    sales30DaysStatistics,
    sales30DayChart,
    sales30DayGroupChart,
    weekdayChart,
    contactGroupChart,
  },

  props: {
    sales30DayDef: Object,
  },

  created() {
    this.main()
  },

  methods: {
    async main() {
      const data = await this.getSalesData()
      this.sales30DayData = data
    },

    async getSalesData() {
      return await pc.pipeAsync(
        this.getInvoices,
        this.processInvoices,
        this.reduceTo30Days,
        this.updateWeekdays,
        this.reduceWeekdaysToTradingDays
      )({
        invoices: [],
        groups: [],
        weekDays: new Array(7).fill(0).map((_, index) => ({
          name: pc.longDayName(index),
          color: this.chartColors[index],
          number: 0,
          value: 0,
          average: 0,
          days: [], // Array of objects {date, value}
        })),
        total: 0,
      })
    },

    async getInvoices(data) {
      // Start with 44 days to account for possible 6 weekends
      // and 2 bank holidays where there my be no trade
      const date42DaysAgo = pc.dateToYMD(
        pc.dateDaysAdjust(-44, this.period.periodEndDate)
      )

      data.invoices = await db.request(
        db.query('companyDashboard.invoices'),
        db.args(
          db.queryArgs('invoices').addFilter('date', date42DaysAgo, 'gte')
        )
      )
      return data
    },

    processInvoices(data) {
      data.groups = data.invoices.reduce((groups, invoice) => {
        this.addInvoiceTo('total', groups, invoice)
        this.addInvoiceTo(invoice.contactGroupId, groups, invoice)
        return groups
      }, [])
      return data
    },

    reduceTo30Days(data) {
      const groupDays = new Array(data.groups.length).fill(0).map(() => [])
      data.groups[0].days.forEach((day, dayIndex) => {
        if (day.total) {
          groupDays.forEach((days, groupIndex) => {
            const day = data.groups[groupIndex].days[dayIndex]
            // Transfer data from total record in case group did not
            // have any sales on that day
            if (groupIndex) day.date = data.groups[0].days[dayIndex].date
            days.push(data.groups[groupIndex].days[dayIndex])
            data.groups[groupIndex].total += day.total
          })
        }
      })

      data.groups.map((group, index) => {
        group.days = groupDays[index].slice(0, 30)
        return group
      })
      return data
    },

    updateWeekdays(data) {
      data.groups[0].days.forEach(day => {
        data.weekDays[day.dayOfWeek].number += 1
        data.weekDays[day.dayOfWeek].value += day.total
        data.weekDays[day.dayOfWeek].average =
          data.weekDays[day.dayOfWeek].value /
          data.weekDays[day.dayOfWeek].number
        data.weekDays[day.dayOfWeek].days.push({
          date: day.date,
          value: day.total,
        })
      })
      return data
    },

    reduceWeekdaysToTradingDays(data) {
      data.weekDays = data.weekDays.reduce((days, day) => {
        if (day.value) days.push(day)
        return days
      }, [])
      return data
    },

    addInvoiceTo(targetId, groups, invoice) {
      const getTargetName = targetId => {
        if (targetId === 'total') return 'Total'
        const contactGroup = db.cached(
          'contactGroups',
          db.cachedQuery().addFind('id', targetId)
        )
        return contactGroup ? contactGroup.name : 'Not found'
      }
      // Takes the invoice date and reverts to
      // Friday if invoice is Saturady or Sunday
      const getInvoiceDate = invoiceDate => {
        let date = pc.dateFromYMDString(invoiceDate)
        const dayOfWeek = date.getDay()

        if (dayOfWeek === 0 || dayOfWeek === 6) {
          date.setDate(date.getDate() - (dayOfWeek ? 1 : 2))
        }
        return date
      }

      let target = groups.find(target => target.id === targetId)

      // Get the target from the group or add new one if not found
      if (!target) {
        target = {
          id: targetId,
          name: getTargetName(targetId),
          total: 0,
          days: new Array(45).fill(0).map(() => ({
            date: '',
            dayOfWeek: 0,
            numberInvoices: 0,
            valueInvoices: 0,
            numberCredits: 0,
            valueCredits: 0,
            total: 0,
            invoices: [],
          })),
        }
        groups.push(target)
      }

      let invoiceDate = getInvoiceDate(invoice.date)
      const dayOfWeek = invoiceDate.getDay()
      const dayIndex = pc.dateDiffDays(invoiceDate, this.period.periodEndDate)
      const day = target.days[dayIndex]

      if (!day.date) {
        day.date = invoiceDate.toDateString().slice(0, -5)
        day.dayOfWeek = dayOfWeek
      }

      if (invoice.isInvoice) {
        day.numberInvoices += 1
        day.valueInvoices += invoice.goods
      } else {
        day.numberCredits += 1
        day.valueCredits += invoice.goods
      }
      day.total += invoice.goods
      day.invoices.push(invoice)
    },
  },

  data() {
    return {
      period: db.cached('period'),
      chartColors: getColors('pastel'),

      sales30DayData: null,

      sales30DayCardDef: pcCardDef('sales30days', false, {
        loaderType: 'card',
        elevation: '0',
      }),

      sales30DayToolbarDef: pcToolbarDef(
        'sales30DaysToolbar',
        'Sales last 30 days',
        {
          dense: true,
          color: 'primary lighten-2',
        }
      ),
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 12px 12px;
  padding: 12px;
  height: 100%;
  overflow-y: auto;
}
.grid-container {
  grid-template-columns: 100%;
  grid-template-rows: 100% 100% 100% 100% 100%;
  grid-template-areas: 'statistics' 'sales-chart' 'weekday-chart' 'sales-group-chart' 'group-pie-chart';
}
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 100% 100% 100%;
    grid-template-areas: 'statistics statistics' 'sales-chart weekday-chart' 'sales-group-chart group-pie-chart';
  }
}
@media only screen and (min-width: 1300px) {
  .grid-container {
    grid-template-columns: 450px 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'statistics sales-chart weekday-chart' 'statistics sales-group-chart group-pie-chart';
  }
}
.statistics {
  grid-area: statistics;
  overflow-y: auto;
}
.sales-chart {
  grid-area: sales-chart;
  overflow-y: auto;
}
.sales-group-chart {
  grid-area: sales-group-chart;
  overflow-y: auto;
}
.weekday-chart {
  grid-area: weekday-chart;
  overflow-y: auto;
}
.group-pie-chart {
  grid-area: group-pie-chart;
  overflow-y: auto;
}
</style>
