<template>
  <v-simple-table dense class="grey lighten-5">
    <template v-slot:default>
      <tbody>
        <tr
          v-for="(profile, index) in outstanding"
          :key="index"
          :class="
            `${clickable ? 'click-pointer' : ''} ${profile.class} ${
              profile.textClass
            }`
          "
          v-on:click="$emit('outstandingProfile', profile)"
        >
          <td class="font-weight-bold">
            {{ profile.legend }}
          </td>
          <td class="pl-4 text-right font-weight-bold">
            £{{ profile.due.toFixed(2) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import {
  OUTSTANDING_COLOR_CLASS,
  OUTSTANDING_TEXT_COLOR_CLASS,
} from '@appModules/ws.js'

export default {
  name: 'outstandingProfile',

  components: {},

  props: {
    clickable: {
      default: false,
      type: Boolean,
    },
    profiles: Object,
  },

  created() {
    this.main()
  },

  watch: {
    profiles() {
      this.main()
    },
  },

  methods: {
    initialise() {},

    async main() {
      if (this.profiles) this.outstanding = this.getOutstanding()
    },

    getOutstanding() {
      const overdueLegends = [
        '',
        '1-7 days overdue',
        '8-14 days overdue',
        '15-21 days overdue',
        'Over 21 days overdue',
      ]

      const dueLegends = [
        '',
        'Due within next 7 days',
        'Due in 8 to 14 days',
        'Due in 15 to 21 days',
        'Due after 21 days',
      ]

      const overdueColorProfileMap = [0, 3, 2, 1, 0]
      const dueColorProfileMap = [0, 4, 5, 6, 7]

      const numberOfInvoices = (invoices, creditNotes) => {
        if (!invoices) return ''
        const invoiceLegend = invoices > 1 ? 'Invoices' : 'Invoice'
        return `${invoices} ${invoiceLegend}${creditNotes ? ', ' : ''}`
      }

      const numberOfCreditNotes = creditNotes => {
        if (!creditNotes) return ''
        const creditNoteLegend = creditNotes > 1 ? 'Cr.Notes' : 'Cr.Note'
        return `${creditNotes} ${creditNoteLegend}`
      }

      const numbers = profile =>
        `(${numberOfInvoices(
          profile.invoices,
          profile.creditNotes
        )}${numberOfCreditNotes(profile.creditNotes)}`

      const overdue = [...this.profiles.overdue].reverse().map(profile => ({
        ...profile,
        legend: `${overdueLegends[profile.profile]} ${numbers(profile)})`,
        due: profile.total,
        class: OUTSTANDING_COLOR_CLASS[overdueColorProfileMap[profile.profile]],
        textClass:
          OUTSTANDING_TEXT_COLOR_CLASS[overdueColorProfileMap[profile.profile]],
      }))

      const due = this.profiles.due.map(profile => ({
        ...profile,
        legend: `${dueLegends[profile.profile]} ${numbers(profile)})`,
        due: profile.total,
        class: OUTSTANDING_COLOR_CLASS[dueColorProfileMap[profile.profile]],
        textClass:
          OUTSTANDING_TEXT_COLOR_CLASS[dueColorProfileMap[profile.profile]],
      }))

      return [...overdue, ...due].filter(profile => profile.due)
    },
  },

  data() {
    return {
      outstanding: [],
    }
  },
}
</script>

<style scoped></style>
