import * as R from 'ramda'

export const uid = () =>
  '_' +
  Math.random()
    .toString(36)
    .substr(2, 9)

/** ---------------------------------------------------------------------------
 * @function joinRecords
 * @summary joinRecords :: (a -> Boolean) -> [a] -> [Number]
 * @desc Joins the joining data to the main data using a key. If joining data the joined data in passed to matchFn which retruns and object to augment the record. If no joining data substitutes the returned object from noMatchFn
 * @param {function} mainKeyFn function to return a key from the main data
 * @param {function} joiningKeyFn function to return a key from the joining data
 * @param {object} matchFn function which receives the joined record as an arg and must return an object
 * @param {object} noMatchFn function which receives the main record as an arg and must return an object
 * @param {object} mainData object to be merged if no joining data match
 * @param {object} joiningData object to be merged into the mainData
 * @return {array} an array of joined objects
 */
export const joinRecords = R.curry(
  (mainKeyFn, joiningKeyFn, matchFn, noMatchFn, mainData, joiningData) =>
    mainData.map(record => {
      const mainKey = mainKeyFn(record)
      const joining = joiningData.find(
        joining => mainKey === joiningKeyFn(joining)
      )
      if (!joining) return { ...record, ...noMatchFn(record) }
      const joined = { ...record, ...joining }
      return { ...joined, ...matchFn(joined) }
    })
)

export const tapLog = caption => data => {
  console.log(`${caption}: `, data)
  return data
}

export default {
  uid,
  joinRecords,
  tapLog,
}
