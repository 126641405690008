import { gql } from 'graphql-tag'

const invoiceDrillDown = gql`
  query($dbQuery: DbQuery) {
    invoices(dbQuery: $dbQuery) {
      id
      invoiceNumber
      date
      goods
      tax
      total
      due
      dueDate
      daysOver
      paymentProfile
    }
  }
`

const invoiceLineDrillDown = gql`
  query($dbQuery: DbQuery) {
    invoiceLines(dbQuery: $dbQuery) {
      date
      invoiceNumber
      productCode
      productName
      quantity
      price
      goods
    }
  }
`

const queries = {
  invoiceDrillDown,
  invoiceLineDrillDown,
}

export { queries as invoice }
