import { gql } from 'graphql-tag'

const productCustomers = gql`
  query($dbQuery: DbQuery) {
    productCustomers(dbQuery: $dbQuery) {
      productCode
      customerId
      customerName
      customer {
        sales
        salesByDays
        salesByMonth
      }
      contactGroupId
      category {
        level1
        level2
        level3
      }
      quantity
      quantityByDays
      quantityByMonth
      sales
      salesByDays
      salesByMonth
      invoices
      invoicesByDays
      invoicesByMonth
      lastInvoiced
    }
  }
`

const queries = {
  productCustomers,
}

export { queries as productCustomersClass }
