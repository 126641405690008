export const MapObject = (name, content = []) => {
  let _map = new Map(content)
  const api = {
    [name + 'Set']: (key, value) => _map.set(key, value),
    [name + 'Get']: key => _map.get(key),
    [name + 'Has']: key => _map.has(key),
    [name + 'Delete']: key => _map.delete(key),
    [name + 'Clear']: () => _map.clear(),
    [name + 'Entries']: () => _map.entries(),
    [name + 'ForEach']: fn => _map.forEach(fn),
    [name + 'Keys']: () => _map.keys(),
    [name + 'Values']: () => _map.values(),
    [name + 'Size']: () => _map.size,
    [name + 'Assign']: content => (_map = new Map(content)),
    [name + 'Array']: () => Array.from(_map.values()),
    [name + 'Map']: () => _map,
    [name + 'Api']: () => api,
  }
  return api
}

export default {
  MapObject,
}
