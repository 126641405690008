<template>
  <div v-if="breadcrumbsDef.stacked">
    <v-row v-for="(item, index) in breadcrumbsDef.items" :key="item.id">
      <v-btn
        v-on:click="itemClicked(item)"
        :class="`pointer pl-${index * 3}`"
        text
        :x-small="breadcrumbsDef.xSmall"
        :small="breadcrumbsDef.small"
        :large="breadcrumbsDef.large"
        :x-large="breadcrumbsDef.xLarge"
        :color="breadcrumbsDef.color"
        :disabled="index === breadcrumbsDef.items.length - 1"
      >
        {{ item.text }}
      </v-btn>
    </v-row>
  </div>
  <v-row v-else justify="center" class="">
    <div class="breadcrumb-container">
      <v-btn
        v-for="(item, index) in breadcrumbsDef.items"
        :key="item.id"
        :class="buttonClass(index)"
        v-on:click="itemClicked(item)"
        text
        :x-small="breadcrumbsDef.xSmall"
        :small="breadcrumbsDef.small"
        :large="breadcrumbsDef.large"
        :x-large="breadcrumbsDef.xLarge"
        :color="breadcrumbsDef.color"
        :disabled="index === breadcrumbsDef.items.length - 1"
      >
        {{ `${item.text}` }}
        <span class="black--text"
          ><b>{{ `${index !== breadcrumbsDef.items.length - 1 ? ' / ' : ''}` }}</b></span
        >
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'pcBreadcrumbs',
  components: {},
  props: {
    breadcrumbsDef: Object,
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    buttonClass(index) {
      return `${index ? 'pl-0' : ''} pr-0`.trim();
    },
    itemClicked(item) {
      if (typeof this.breadcrumbsDef.onClick === 'function') {
        this.breadcrumbsDef.onClick(item);
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.breadcrumb-container {
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
}
</style>
