<template>
  <v-row
    id="productDashboard"
    class="fill-height"
    justify="center"
    align="stretch"
  >
    <v-col :cols="12" class="pc-col d-flex pt-0 pb-0">
      <preferences
        :openDialog="preferencesDialog"
        v-on:closed="preferencesDialogClosed"
      ></preferences>

      <v-tabs class="pc-size100" height="0px">
        <v-tabs-items v-model="tab" class="pc-col">
          <v-tab-item class="pc-col mt-0">
            <pc-card :card-def="dashboardCardDef">
              <template v-slot:title>
                <pc-toolbar :toolbar-def="toolbarDef" />
              </template>

              <template v-slot:text>
                <v-row
                  class="fill-height mt-0"
                  justify="center"
                  align="stretch"
                >
                  <find-product
                    class="pl-3"
                    :product="product"
                    v-on:product="productSelected"
                  />
                  <pc-nav-drawer
                    :navDrawerDef="navDrawerDef"
                    v-on:selected="menuItem => (menuIdSelected = menuItem.id)"
                  />
                  <v-col id="product-main" class="pc-col d-flex pl-0 pt-0 pb-0">
                    <productSummary :productSummaryDef="productSummaryDef" />
                    <productCustomers
                      :productCustomersDef="productCustomersDef"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                    <product-top-10
                      :productTop10Def="productTop10Def"
                      @showCustomerDashboard="showCustomerDashboard"
                    />
                  </v-col>
                </v-row>
              </template>
            </pc-card>
          </v-tab-item>
          <v-tab-item class="pc-col pt-3">
            <customer-dashboard
              :dashboardCustomer="customer"
              @exit="exitCustomerDashboard"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { LocalStorage } from '@pcModules/localStorage.js'
import { whitespaceLocalStorageSchema } from '@appModules/ws.js'
import { productQuery } from './productDashboard.gql.js'

import pcCard from '@pcComponents/pcCard.vue'
import pcToolbar from '@pcComponents/pcToolbar.vue'
import productSummary from '@appViews/productDashboard/productSummary/productSummary.vue'
import productCustomers from '@appViews/productDashboard/productCustomers/productCustomers.vue'
import productTop10 from '@appViews/productDashboard/productTop10/productTop10'
import preferences from '@appViews/productDashboard/preferences'
import {
  pcToolbarDef,
  pcToolbarActionDef,
} from '@pcComponents/defs/pcToolbarDef.js'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import {
  pcNavDrawerDef,
  pcNavDrawerItem,
} from '@pcComponents/defs/pcNavDrawerDef.js'

import findProduct from '@appViews/productDashboard/findProduct.vue'
import pcNavDrawer from '@pcComponents/pcNavDrawer.vue'
import customerDashboard from '@appViews/customerDashboard/customerDashboard.vue'

export default {
  name: 'productDashboard',

  components: {
    pcCard,
    pcToolbar,
    pcNavDrawer,
    productSummary,
    productCustomers,
    productTop10,
    preferences,
    findProduct,
    customerDashboard,
  },

  async created() {
    await this.initialise()
    this.main()
  },

  watch: {
    productCode() {
      this.main()
    },

    menuIdSelected(value) {
      this.productSummaryDef = {
        ...this.productSummaryDef,
        show: value === 'summary',
      }
      this.productTop10Def = {
        ...this.productTop10Def,
        show: value === 'top10',
      }
      this.productCustomersDef = {
        ...this.productCustomersDef,
        show: value === 'customers',
      }
    },
  },

  methods: {
    async initialise() {
      const whitespaceLocalStorage = new LocalStorage(
        'whitespace',
        whitespaceLocalStorageSchema
      )
      this.openingWindow = whitespaceLocalStorage.getProperty(
        'productDashboard.openingWindow'
      )
      this.menuIdSelected = this.openingWindow || 'summary'
    },

    async main() {
      if (this.productCode) {
        const product = await db.request(
          productQuery,
          db
            .args()
            .addQueryArgs(
              db.queryArgs('products').addFind('code', this.productCode)
            )
        )

        this.product = product
        this.toolbarDef.title = `${product.code} - ${product.name}`

        // Add zero records for contact groups with no product sales
        const contactGroupIdsWithSales = product.salesByContactGroup.map(
          group => group.contactGroupId
        )
        const contactGroupsWithNoSales = db
          .cached(
            'contactGroups',
            db.cachedQuery().addExclude('id', contactGroupIdsWithSales)
          )
          .map(this.convertToContactGroupProductFormat)

        product.salesByContactGroup = [
          ...product.salesByContactGroup,
          ...contactGroupsWithNoSales,
          this.addCompanyTotal(product),
        ]

        // Add zero records for contact groups with no category sales
        const contactGroupIdsWithCategorySales = product.productCategory.salesByContactGroup.map(
          group => group.contactGroupId
        )
        const contactGroupsWithNoCategorySales = db
          .cached(
            'contactGroups',
            db.cachedQuery().addExclude('id', contactGroupIdsWithCategorySales)
          )
          .map(this.convertToContactGroupCategoryFormat)

        product.productCategory.salesByContactGroup = [
          ...product.productCategory.salesByContactGroup,
          ...contactGroupsWithNoCategorySales,
          this.addCompanyTotal(product.productCategory, false),
        ]

        const data = {
          product,
          period: db.cached('period'),
        }

        this.productSummaryDef = { ...this.productSummaryDef, ...data }
        this.productTop10Def = { ...this.productTop10Def, ...data }
        this.productCustomersDef = { ...this.productCustomersDef, ...data }
      } else {
        this.menuIdSelected = this.openingWindow || 'summary'
      }
      this.productSelectedInternally = false
    },

    addCompanyTotal(record, includeQuantityFields = true) {
      const {
        quantity,
        quantityByDays,
        quantityByMonth,
        sales,
        salesByDays,
        salesByMonth,
        activeCustomers,
        activeCustomersByDays,
        activeCustomersByMonth,
        invoices,
        invoicesByDays,
        invoicesByMonth,
        customerAverage,
        customerAverageByDays,
        customerAverageByMonth,
        invoiceAverage,
        invoiceAverageByDays,
        invoiceAverageByMonth,
        lastInvoiced,
      } = record

      const quantityFields = includeQuantityFields
        ? { quantity, quantityByDays, quantityByMonth }
        : {}

      return {
        contactGroupId: 'company',
        contactGroupName: 'Company',
        ...quantityFields,
        sales,
        salesByDays,
        salesByMonth,
        activeCustomers,
        activeCustomersByDays,
        activeCustomersByMonth,
        invoices,
        invoicesByDays,
        invoicesByMonth,
        customerAverage,
        customerAverageByDays,
        customerAverageByMonth,
        invoiceAverage,
        invoiceAverageByDays,
        invoiceAverageByMonth,
        lastInvoiced,
      }
    },

    convertToContactGroupProductFormat(group) {
      return {
        contactGroupId: group.id,
        contactGroupName: group.name,
        quantity: 0,
        quantityByDays: pc.createArray(0, 4),
        quantityByMonth: pc.createArray(0, this.period.monthsInPeriod),
        sales: 0,
        salesByDays: pc.createArray(0, 4),
        salesByMonth: pc.createArray(0, this.period.monthsInPeriod),
        activeCustomers: 0,
        activeCustomersByDays: pc.createArray(0, 4),
        activeCustomersByMonth: pc.createArray(0, this.period.monthsInPeriod),
        invoices: 0,
        invoicesByDays: pc.createArray(0, 4),
        invoicesByMonth: pc.createArray(0, this.period.monthsInPeriod),
        customerAverage: 0,
        customerAverageByDays: pc.createArray(0, 4),
        customerAverageByMonth: pc.createArray(0, this.period.monthsInPeriod),
        invoiceAverage: 0,
        invoiceAverageByDays: pc.createArray(0, 4),
        invoiceAverageByMonth: pc.createArray(0, this.period.monthsInPeriod),
        lastInvoiced: '',
      }
    },

    convertToContactGroupCategoryFormat(group) {
      return {
        contactGroupId: group.id,
        contactGroupName: group.name,
        sales: 0,
        salesByDays: pc.createArray(0, 4),
        salesByMonth: pc.createArray(0, this.period.monthsInPeriod),
        activeCustomers: 0,
        activeCustomersByDays: pc.createArray(0, 4),
        activeCustomersByMonth: pc.createArray(0, this.period.monthsInPeriod),
        invoices: 0,
        invoicesByDays: pc.createArray(0, 4),
        invoicesByMonth: pc.createArray(0, this.period.monthsInPeriod),
        customerAverage: 0,
        customerAverageByDays: pc.createArray(0, 4),
        customerAverageByMonth: pc.createArray(0, this.period.monthsInPeriod),
        invoiceAverage: 0,
        invoiceAverageByDays: pc.createArray(0, 4),
        invoiceAverageByMonth: pc.createArray(0, this.period.monthsInPeriod),
        lastInvoiced: '',
      }
    },

    productSelected(productRecord) {
      this.productCode = productRecord.code
    },

    exitDashboard() {
      this.$emit('exit')
    },

    preferences() {
      this.preferencesDialog = true
    },

    preferencesDialogClosed(openingWindow) {
      this.preferencesDialog = false
      this.openingWindow = openingWindow
    },

    showCustomerDashboard(payload) {
      this.customer = db.cached(
        'customers',
        db.cachedQuery().addFind('id', payload.customerId)
      )
      this.tab = 1
    },

    exitCustomerDashboard() {
      this.tab = 0
    },
  },

  data() {
    return {
      tab: 0,
      period: db.cached('period'),
      customer: undefined,
      productCode: '',
      product: {},
      productCustomers: [],
      productContactGroups: [],
      categoryCustomers: [],
      selectableContactGroups: undefined,

      contactGroupId: 'total',
      resetGroupSelect: 0,
      preferencesDialog: false,
      openingWindow: '',
      menuIdSelected: 'turnover',
      summaryCategoryDrillData: {},

      textHeight: '',
      textWidth: '',

      dashboardCardDef: pcCardDef('productDashboard', false, {
        loaderType: 'card',
        subTitleClass: 'blue lighten-4',
      }),

      toolbarDef: pcToolbarDef(
        'productDashboardToolbar',
        'Product dashboard',
        {},
        [
          pcToolbarActionDef(
            'preferences',
            'mdi-cog-outline',
            this.preferences,
            'Set preferences'
          ),
        ]
      ),

      navDrawerDef: pcNavDrawerDef(
        'optionsNavDrawer',
        [
          pcNavDrawerItem('summary', 'mdi-view-dashboard', 'Summary'),
          pcNavDrawerItem('customers', 'mdi-account', 'Customers'),
          pcNavDrawerItem('top10', 'mdi-sort-descending', 'Top 10'),
        ],
        { width: '200px', expandOnHover: true, miniVariant: true }
      ),

      dashboardExitActionDef: pcToolbarActionDef(
        'back',
        'mdi-arrow-left',
        this.exitDashboard,
        'Exit dashboard'
      ),

      productSummaryDef: {
        show: false,
      },

      productTop10Def: {
        show: false,
      },

      productCustomersDef: {
        show: false,
      },
    }
  },
}
</script>

<style scoped></style>
