import { gql } from 'graphql-tag'

export const invoicesGql = gql`
  query($dbQuery: DbQuery) {
    invoices(dbQuery: $dbQuery) {
      id
      customerId
      customerName
      isInvoice
      date
      invoiceNumber
      goods
      tax
      total
      dueDate
      due
      amountPaid
      amountCredited
      unallocatedCredit
      fullyPaidDate
      daysToPay
      paymentDays
      daysOver
      paymentProfile
      customer {
        phoneNumbers {
          phoneType
          phoneNumber
        }
        contacts {
          firstName
          lastName
          emailAddress
        }
      }
    }
  }
`
