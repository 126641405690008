<template>
  <v-select
    :style="controlWidth"
    class="pa-0 ma-0 d-inline-block"
    dense=""
    :dark="dark"
    label="Contact group"
    hide-details
    single-line
    :items="contactGroupItems"
    v-model="contactGroup"
  ></v-select>
</template>

<script>
import db from '@db'

export default {
  name: 'contactGroupSelect',

  components: {},

  props: {
    allName: String,
    allKey: String,
    count: Boolean,
    dark: Boolean,
    width: String,
    reset: Number,
    excludeNoSales: Boolean,
  },

  created() {
    this.main()
  },

  computed: {
    controlWidth() {
      return this.width ? `width: ${this.width};` : 'width: 100px;'
    },
  },

  watch: {
    reset() {
      if (this.contactGroup !== this.allKey) {
        this.contactGroup = this.allKey
      }
      //else {
      //  this.$emit('selected', this.allKey)
      //}
    },

    contactGroup(value) {
      this.$emit('selected', value, this.groupName(value))
    },
  },

  methods: {
    async main() {
      const itemText = (name, count) => {
        let text = name
        if (this.includeCount) {
          text = `${text} - ${count} customers`
        }
        return text
      }

      this.includeCount = typeof this.count === 'undefined' ? true : this.count

      const query = this.excludeNoSales
        ? db
            .cachedQuery()
            .addFilter('sales', 0, 'ne')
            .addSort('name')
        : db.cachedQuery().addSort('name')

      this.contactGroupItems = db.cached('contactGroups', query).reduce(
        (groups, group) => {
          groups.push({
            text: itemText(group.name, group.numberCustomers),
            value: group.id,
          })
          return groups
        },
        [
          {
            text: itemText(
              this.allName || 'All',
              db.cached('customers').length
            ),
            value: this.allKey || 'All',
          },
        ]
      )
      this.contactGroup = this.allKey || 'All'
    },

    groupName(id) {
      const group = this.contactGroupItems.find(group => group.value === id)
      return group ? group.text : 'Not found'
    },
  },

  data() {
    return {
      contactGroup: '',
      contactGroupItems: [],
      includeCount: true,
    }
  },
}
</script>

<style scoped></style>
