<template>
  <div>
    <pc-tooltip :tooltip-def="tooltipDef">
      <template v-slot:tooltip>
        <div :style="helpStyle" class="rounded-xl black--text">
          <div class="green lighten-2 text-center subtitle-1">
            {{ help.title }}
          </div>
          <div class="pl-3 pr-3 pt-2">
            <p v-for="(paragraph, index) in help.paragraphs" :key="index">
              {{ paragraph }}
            </p>
          </div>
        </div>
      </template>
    </pc-tooltip>

    <v-icon @mouseover="mouseOver">mdi-help-circle</v-icon>
  </div>
</template>

<script>
import pcTooltip from '@pcComponents/pcTooltip.vue'
import { pcTooltipDef } from '@pcComponents/defs/pcTooltipDef.js'

export default {
  components: { pcTooltip },

  name: 'grid',

  props: {
    id: String,
    width: String,
  },

  created() {
    this.main()
  },

  computed: {
    helpStyle() {
      if (this.width) {
        return `width: ${this.width};`
      } else {
        return 'width: 300px;'
      }
    },
  },

  watch: {},

  methods: {
    main() {},

    mouseOver(event) {
      this.tooltipDef.activatorElement = event.currentTarget
      this.tooltipDef.show = true
    },
  },

  data() {
    return {
      tooltipDef: pcTooltipDef('mytooltip', null, 'top', {
        color: 'green lighten-5',
      }),

      help: {
        title: 'Sales statistics',
        paragraphs: [
          'Displays a table of the last 30 days sales.',
          'Use the selector in the title bar to list total sales or for a contact group.',
          'Click on a day row to see the invoices and credit notes for that day.',
        ],
      },
    }
  },
}
</script>
<style scoped></style>
