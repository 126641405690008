<template>
  <div class="tree">
    <v-treeview
      activatable
      dense
      :open.sync="openCategoryItems"
      :items="categories"
      return-object
      :active.sync="active"
    >
      <template v-slot:label="{ item }">
        <span>{{ item.name }}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import pc from '@pc'
import db from '@db'

export default {
  name: 'categoryTree',

  components: {},

  props: {},

  created() {
    this.main()
  },

  watch: {
    active() {
      this.$emit('categorySelected', this.active[0])
    },
  },

  methods: {
    main() {
      const makeCategoryTree = categories => {
        const getChildren = category => {
          category.children = []
          if (!category.hasChildren) return
          const children = pc
            .query()
            .addFilter('parentId', category.id)
            .addSort('name')
            .run(categories)
          category.children = children
          children.forEach(getChildren)
          return category
        }

        const treeRoot = categories.find(category => category.id === 'category')
        return getChildren(treeRoot)
      }

      const categoryRecords = db.cached('categories')
      this.categories = [makeCategoryTree(categoryRecords)]
      this.openCategoryItems = [this.categories[0]]
    },
  },

  data() {
    return {
      categories: [],
      active: [],
      openCategoryItems: [],
    }
  },
}
</script>

<style scoped>
.tree {
  height: 100%;
  width: 300px;
  border-right: 1px solid black;
  overflow-y: scroll;
}
</style>
