<template>
  <div v-if="words" class="pt-3 pl-6 pr-3">{{ words }}</div>
</template>

<script>
import pc from '@pc'

export default {
  name: 'categoryDBTop10Summary',

  components: {},

  props: {
    top10Data: Object,
  },

  watch: {
    top10Data() {
      this.main()
    },
  },

  methods: {
    async main() {
      this.words = this.computeWords()
    },

    computeWords() {
      const {
        period,
        periodCaption,
        salesFieldName,
        categoryCustomers,
        category,
      } = this.top10Data

      const totalActive = categoryCustomers.customersActiveAll.length
      const activeInPeriod =
        categoryCustomers.customersActiveInSalesPeriod.length
      const categoryCustomersInPeriod =
        categoryCustomers.boughtInSalesPeriod.length

      if (!totalActive) return 'There are no active customers.'

      let words = `There ${
        totalActive > 1 ? 'are a total of' : 'is'
      } ${totalActive} active ${pc.plural(
        'customer',
        totalActive
      )} in the period ${period.text.substr(8)}`

      if (salesFieldName !== 'sales') {
        if (activeInPeriod > 0) {
          if (totalActive > 1) {
            words = `${words}. Of these ${activeInPeriod} ${
              activeInPeriod > 1 ? 'were' : 'was'
            }`
          } else {
            words = `${words} and was`
          }
          words = `${words} active in the ${periodCaption.toLowerCase()}`
        } else {
          if (totalActive > 1) {
            words = `${words}, none were`
          } else {
            words = `${words}, but was not`
          }
          words = `${words} active in the ${periodCaption.toLowerCase()}`
        }
      }

      if (categoryCustomersInPeriod > 0) {
        if (activeInPeriod > 1) {
          words = `${words} and ${categoryCustomersInPeriod} ${
            categoryCustomersInPeriod > 1 ? 'have' : 'has'
          } purchased ${category.name}`
        } else {
          words = `${words} and has purchased ${category.name}`
        }
      } else {
        if (activeInPeriod > 1) {
          words = `${words} but none have purchased ${category.name}`
        } else {
          if (activeInPeriod)
            words = `${words} but has not purchased ${category.name}`
        }
      }

      words = `${words}.`
      return words
    },
  },

  data() {
    return {
      words: '',
    }
  },
}
</script>

<style scoped></style>
