import { gql } from 'graphql-tag'

const invoices = gql`
  query($dbQuery: DbQuery) {
    invoices(dbQuery: $dbQuery) {
      id
      customerName
      invoiceNumber
      date
      total
      due
      dueDate
      daysOver
      paymentProfile
    }
  }
`

const customers = gql`
  query($dbQuery: DbQuery) {
    customers(dbQuery: $dbQuery) {
      id
      name
      dueProfile0
      dueProfile1
      dueProfile2
      dueProfile3
      dueProfile4
      overdue
    }
  }
`

const paidInvoicesProfileCompany = gql`
  query($dbQuery: DbQuery) {
    paidInvoicesProfileCompany(dbQuery: $dbQuery) {
      profileIndex
      months {
        count
        profileIndex
        monthIndex
      }
    }
  }
`

const paidInvoicesProfileCompressed = gql`
  query($dbQuery: DbQuery) {
    paidInvoicesProfileCompressed(dbQuery: $dbQuery) {
      profileIndex
      months {
        count
        profileIndex
        monthIndex
      }
    }
  }
`

const paidInvoicesProfile = gql`
  query($dbQuery: DbQuery) {
    paidInvoicesProfile(dbQuery: $dbQuery) {
      profileIndex
      months {
        count
        profileIndex
        monthIndex
      }
    }
  }
`

const queries = {
  invoices,
  customers,
  paidInvoicesProfile,
  paidInvoicesProfileCompany,
  paidInvoicesProfileCompressed,
}

export { queries as paidInvoicesProfile }
