<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:default>
      <pc-chart :chartDef="chartDef">
        <template v-slot:tooltip>
          <pc-card :cardDef="tooltipCardDef">
            <template v-slot:title>
              {{ tooltipTitle }}
            </template>
            <template v-slot:subTitle>
              {{ tooltipSubTitle }}
            </template>
            <template v-slot:text>
              <pc-simple-table :simpleTableDef="tooltipSimpleTableDef" />
            </template>
          </pc-card>
        </template>
      </pc-chart>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import { pcCardDef } from '@pcComponents/defs/pcCardDef.js'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import {
  pcSimpleTableDef,
  pcSimpleTableFieldDef,
} from '@pcComponents/defs/pcSimpleTableDef.js'

import pcCard from '@pcComponents/pcCard.vue'
import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import pcSimpleTable from '@pcComponents/pcSimpleTable.vue'

export default {
  name: 'weekdayChart',

  components: {
    pcCard,
    pcChart,
    pcDisplayBox,
    pcSimpleTable,
  },

  props: {
    sales30DayData: Object,
  },

  watch: {
    sales30DayData() {
      this.main()
    },
  },

  methods: {
    main() {
      this.updateChart(this.sales30DayData.weekDays)
    },

    updateChart(weekDays) {
      this.chartDef
        .setLabels(weekDays.map(day => day.name))
        .addDataset(
          weekDays.map(day => pc.toFixed0(day.value)),
          'Average sales',
          weekDays.map(day => day.color)
        )
        .render()
    },

    tooltip(payload) {
      const weekDay = this.sales30DayData.weekDays[payload.dataIndex]
      const titleColor = `background-color: ${weekDay.color};`
      const titleTextColor = pc.isDarkColor(titleColor)
        ? 'font-weight: bold; color: white; '
        : 'font-weight: bold; '
      this.tooltipCardDef.titleStyle = titleColor
      this.tooltipCardDef.titleClass =
        'body-2 justify-center pl-2 pr-2 ' + titleTextColor
      this.tooltipTitle = weekDay.name
      this.tooltipSubTitle = `Average £${weekDay.average.toFixed(2)}`
      this.tooltipSimpleTableDef.data = weekDay.days
    },
  },

  data() {
    return {
      displayBoxDef: pcDisplayBoxDef('categoryPieChart', 'Weekday averages', {
        height: '100%',
      }),

      chartDef: pcChartDef2('pie')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),

      tooltipCardDef: pcCardDef('weekdayCard', false, {
        elevation: '2',
        cardClass: '',
        titleClass: 'body-2 justify-center pl-2 pr-2',
        subTitleClass: 'caption font-weight-bold justify-center pl-2 pr-2',
        width: '200px',
        height: '',
      }),

      tooltipTitle: '',
      tooltipSubTitle: '',

      tooltipSimpleTableDef: pcSimpleTableDef('', {
        class: 'grey lighten-5',
        paddingBetweenFields: 3,
        expand: true,
        fields: [
          pcSimpleTableFieldDef('date', 'text', 'Date'),
          pcSimpleTableFieldDef('value', 'currency', 'Sales', {
            decimalPlaces: 2,
          }),
        ],
      }),
    }
  },
}
</script>

<style scoped></style>
