<template>
  <pc-display-box :displayBoxDef="displayBoxDef">
    <template v-slot:actions>
      <contact-group-select
        allKey="company"
        allName="Company"
        :count="false"
        :excludeNoSales="true"
        v-on:selected="groupSelected"
      />
    </template>
    <template v-slot:default>
      <v-row class="pt-1 fill-height" justify="center" align="stretch">
        <v-col :cols="6" class="pc-col d-flex pt-0 pb-0">
          <pc-chart :chartDef="pieChartDef" />
        </v-col>
        <v-col :cols="6" class="pc-col d-flex pt-0 pb-0">
          <pc-chart :chartDef="barChartDef" />
        </v-col>
      </v-row>
    </template>
  </pc-display-box>
</template>

<script>
import pc from '@pc'
import db from '@db'
import { pcChartDef2 } from '@pcComponents/defs/pcChartDef.js'
import { pcDisplayBoxDef } from '@pcComponents/defs/pcDisplayBoxDef.js'
import { getColor } from '@pcModules/pcColors.js'

import pcChart from '@pcComponents/pcChart.vue'
import pcDisplayBox from '@pcComponents/pcDisplayBox.vue'
import contactGroupSelect from '@sharedComponents/contactGroupSelect.vue'

export default {
  name: 'productCategoryCustomerCoverage',
  components: {
    pcChart,
    pcDisplayBox,
    contactGroupSelect,
  },

  props: {
    productSummaryDef: Object,
  },

  computed: {
    isProduct() {
      return this.product && this.product.code ? true : false
    },

    product() {
      return this.productSummaryDef.product
    },

    productCategory() {
      return this.product.productCategory
    },

    categorySalesByContactGroup() {
      return this.productCategory.salesByContactGroup
    },
  },

  watch: {
    productSummaryDef() {
      this.updateDisplay()
    },

    groupId() {
      this.updateDisplay()
    },
  },

  methods: {
    groupSelected(groupId) {
      this.groupId = groupId
    },

    //=======================================================================
    categoryActiveCustomers(groupId) {
      const contactGroupCategory = pc
        .query()
        .addFind('contactGroupId', groupId)
        .run(this.categorySalesByContactGroup)

      return {
        categoryActiveCustomers: contactGroupCategory.activeCustomers,
        categoryActiveCustomersByMonth:
          contactGroupCategory.activeCustomersByMonth,
      }
    },

    totalCompanyOrGroupActiveCustomers(groupId) {
      const group =
        groupId === 'company'
          ? this.period
          : db.cached(
              'contactGroups',
              db.cachedQuery().addFind('id', this.groupId)
            )
      return {
        totalActiveCustomers: group.activeCustomers,
        totalActiveCustomersByMonth: group.activeCustomersByMonth,
      }
    },

    //=======================================================================
    async updateDisplay() {
      if (this.isProduct) {
        const {
          totalActiveCustomers,
          totalActiveCustomersByMonth,
        } = this.totalCompanyOrGroupActiveCustomers(this.groupId)

        const {
          categoryActiveCustomers,
          categoryActiveCustomersByMonth,
        } = this.categoryActiveCustomers(this.groupId)

        const customersNotBought =
          totalActiveCustomers - categoryActiveCustomers

        const boughtLabel = `Bought category ${categoryActiveCustomers} (${pc.toFixed2(
          pc.percentage(categoryActiveCustomers, totalActiveCustomers)
        )}%)`

        const notBoughtLabel = `Not bought ${customersNotBought} (${pc.toFixed2(
          pc.percentage(customersNotBought, totalActiveCustomers)
        )}%)`

        this.pieChartDef
          .clearDatasets()
          .setLabels([boughtLabel, notBoughtLabel])
          .addDataset([categoryActiveCustomers, customersNotBought], '', [
            getColor('purple', 3),
            getColor('grey', 5),
          ])
          .render()

        this.barChartDef
          .clearDatasets()
          .addDataset(
            categoryActiveCustomersByMonth,
            'Bought category',
            getColor('purple', 3),
            [['type', 'line']]
          )
          .addDataset(
            pc.arrayFixed2(totalActiveCustomersByMonth),
            'Active customers',
            getColor('blue', 3),
            [['type', 'bar']]
          )

        this.barChartDef.render()
      }
    },
  },

  data() {
    const period = db.cached('period')
    return {
      groupId: '',
      period,

      displayBoxDef: pcDisplayBoxDef('salesBarChart', 'Sales', {
        height: '50%',
        contentPadding: 'pt-3 pb-0 pl-3 pr-3',
        title: `Category Customer coverage`,
      }),

      pieChartDef: pcChartDef2('pie')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false),

      barChartDef: pcChartDef2('bar')
        .setChart([['containerClass', 'grey lighten-5']])
        .setTitle('', false)
        .setLegend(false)
        .setLabels(pc.periodMonthNames('mmm yy', period.monthKeys)),
    }
  },
}
</script>

<style scoped></style>
