<template>
  <pc-card v-if="show" :cardDef="sparkChartDef.card">
    <!-- Pass on all named slots -->
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
    <template v-if="sparkChartDef.chart.title !== ''" v-slot:title>
      {{ sparkChartDef.chart.title }}
    </template>
    <template v-if="sparkChartDef.chart.subTitle !== ''" v-slot:subTitle>
      {{ sparkChartDef.chart.subTitle }}
    </template>
    <template v-slot:text>
      <div
        :id="`spark-chart-container${sparkChartDef.chart.domId}`"
        class="d-flex flex-grow-1 spark-chart-container"
        :class="sparkChartDef.chart.containerClass"
        :style="containerStyle"
      >
        <canvas :id="`spark-chart-canvas${sparkChartDef.chart.domId}`"></canvas>
      </div>
    </template>
  </pc-card>
</template>

<script>
import pc from '@pc'
import Chart from 'chart.js/auto'
import pcCard from '@pcComponents/pcCard.vue'
import piePlugins from '@pcComponents/modules/piePlugins.js'

const _onResize = pc.debounce((gradient, fn) => gradient && fn(), 100)

export default {
  name: 'pcSparkChart',

  components: {
    pcCard,
  },

  props: {
    sparkChartDef: Object,
  },

  created() {
    this.sparkChartDef.setOptions([['onResize', this.onResize]])
    pc.$eventBus.$on(
      `spark-chart-${this.sparkChartDef.chart.domId}`,
      this.processEvent
    )
  },

  mounted() {
    pc.forceNextTick(() => {
      this.createChart()
    })
  },

  beforeDestroy() {
    if (this.chart && typeof this.chart.destroy === 'function') {
      this.chart.destroy()
    }
    pc.$eventBus.$off(
      `spark-chart-${this.sparkChartDef.chart.domId}`,
      this.processEvent
    )
  },

  computed: {
    show() {
      return this.sparkChartDef.chart.show
    },

    containerStyle() {
      let style = ''
      if (this.sparkChartDef.chart.width)
        style = `width: ${this.sparkChartDef.chart.width};`
      if (this.sparkChartDef.chart.height)
        style = ` ${style} height: ${this.sparkChartDef.chart.height};`
      return style
    },
  },

  watch: {
    show(value) {
      pc.forceNextTick(() => {
        if (value) {
          this.createChart()
        } else {
          if (this.chart) this.chart.destroy()
          this.chart = undefined
        }
      })
    },
  },

  methods: {
    createChart() {
      if (this.sparkChartDef.chart.show) {
        const canvas = pc.getElementById(
          `spark-chart-canvas${this.sparkChartDef.chart.domId}`
        )
        if (canvas) {
          const ctx = canvas.getContext('2d')

          this.chart = new Chart(ctx, {
            type: this.sparkChartDef.chart.type,
            //plugins: [this.plugins[this.sparkChartDef.chart.type]],
            data: [],
            options: this.sparkChartDef.options,
          })

          this.render()
          //pc.runIn(this.render, 25)
        }
      }
    },

    onResize() {
      _onResize(this.sparkChartDef.chart.gradient, this.render)
    },

    colorChart() {
      //this.chart.data = pc.clone(this.sparkChartDef.data)
      if (this.sparkChartDef.chart.gradient) {
        this.chart.data.datasets.forEach(dataset => {
          if (pc.isArray(dataset.backgroundColor)) {
            dataset.backgroundColor = dataset.backgroundColor.map(color =>
              this.createGradient(this.chart, this.sparkChartDef.chart.type, [
                pc.lightenDarkenColor(color, 40),
                pc.lightenDarkenColor(color, 20),
                color,
              ])
            )
          } else {
            dataset.backgroundColor = this.createGradient(
              this.chart,
              this.sparkChartDef.chart.type,
              [
                pc.lightenDarkenColor(dataset.backgroundColor, 40),
                pc.lightenDarkenColor(dataset.backgroundColor, 20),
                dataset.backgroundColor,
              ]
            )
          }
        })
      }
    },

    processEvent(event) {
      switch (event.event) {
        case 'render':
          this.render()
          break
        case 'update':
          this.update()
          break
        case 'hideDataset': {
          this.hideDataset(event.data)
          break
        }
        default: {
          console.log('Invalid chart event: ', event.string())
        }
      }
    },

    render() {
      this.chart.data = pc.clone(this.sparkChartDef.data)
      if (this.chart) {
        this.colorChart()
        try {
          this.chart.options.plugins.title.text = this.sparkChartDef.options.plugins.title.text
          if (this.sparkChartDef.chart.type === 'pie') {
            const centerTitle = this.sparkChartDef.options.elements.center.text
            if (centerTitle) {
              this.chart.options.elements.center.text = this.sparkChartDef.options.elements.center.text
            }
          }
          this.update()
        } catch (err) {
          pc.nop()
        }
      }
    },

    update() {
      try {
        this.chart.update()
        this.$emit('instance', this.chart)
      } catch (err) {
        pc.nop()
      }
    },

    createGradient(chart, chartType, gradientColors) {
      const isLinear = chartType === 'bar' || chartType === 'line'
      const chartArea = chart.chartArea
      if (!chartArea) {
        // This case happens on initial chart load
        return null
      }

      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top
      if (this.chartWidth !== chartWidth || this.chartHeight !== chartHeight) {
        this.gradientCache.clear()
        this.chartWidth = chartWidth
        this.chartHeight = chartHeight
      }

      const gradientCacheKey =
        this.sparkChartDef.chart.type +
        ('' + gradientColors[0]) +
        ('' + gradientColors[1]) +
        ('' + gradientColors[2])
      let gradient = this.gradientCache.get(gradientCacheKey)

      if (!gradient) {
        if (isLinear) {
          gradient = chart.ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          )
        } else {
          const centerX = (chartArea.left + chartArea.right) / 2
          const centerY = (chartArea.top + chartArea.bottom) / 2
          const r = Math.min(
            (chartArea.right - chartArea.left) / 2,
            (chartArea.bottom - chartArea.top) / 2
          )
          gradient = chart.ctx.createRadialGradient(
            centerX,
            centerY,
            0,
            centerX,
            centerY,
            r
          )
        }
        gradient.addColorStop(0, gradientColors[0])
        gradient.addColorStop(0.5, gradientColors[1])
        gradient.addColorStop(1, gradientColors[2])
        this.gradientCache.set(gradientCacheKey, gradient)
      }

      return gradient
    },
  },

  data() {
    return {
      gradientCache: new Map(),
      chart: {},
      chartWidth: 0,
      chartHeight: 0,
      plugins: {
        pie: piePlugins,
        bar: [],
        line: [],
      },
    }
  },
}
</script>

<style scoped>
.spark-chart-container {
  position: relative;
  min-width: 0;
  overflow-y: scroll;
}
</style>
