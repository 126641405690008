<template>
  <v-navigation-drawer
    :permanent="navDrawerDef.permanent"
    :right="navDrawerDef.right"
    :width="navDrawerDef.width"
    :height="navDrawerDef.height"
    :expand-on-hover="navDrawerDef.expandOnHover"
    :mini-variant-width="navDrawerDef.miniVariantWidth"
    :mini-variant.sync="miniVariant"
  >
    <slot>
      <v-list nav dense>
        <div v-for="(item, i) in navDrawerDef.menuItems" :key="i">
          <v-list-item v-if="!item.expand" @click.stop="click(item)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-if="item.expand" :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.subItems"
              :key="subItem.text"
              @click.stop="click(subItem)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="subItem.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </slot>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'pcNavDrawer',

  props: {
    navDrawerDef: Object,
  },

  created() {
    this.miniVariant = this.navDrawerDef.miniVariant
  },

  watch: {
    miniVariant(value) {
      this.$emit('minimised', value)
    },
  },

  methods: {
    click(menuItem) {
      this.$emit('selected', menuItem)
      if (typeof menuItem.fn === 'function') menuItem.fn(menuItem)
    },
  },

  data() {
    return {
      miniVariant: false,
    }
  },
}
</script>

<style scoped></style>
