<template>
  <td :class="fieldClass" :style="fieldStyle" @[isClick].stop="onClick">
    <template v-if="isTooltip">
      <v-tooltip
        :top="isTooltipTop"
        :bottom="isTooltipBottom"
        :right="isTooltipRight"
        :left="isTooltipLeft"
        :color="field.tooltipColor"
        v-model="showTooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="isHTML"
            :class="_class"
            :style="_style"
            @[isExpand].stop="onExpandClick"
          >
            <v-icon v-if="fieldType === 'expand'" :class="expandedClass">
              mdi-chevron-down
            </v-icon>
            <v-icon
              v-if="fieldType === 'action' && showAction"
              :color="field.iconColor"
            >
              {{ field.icon }}
            </v-icon>
            <span v-else v-html="displayField"></span>
          </div>
          <span v-else v-bind="attrs" v-on="on" :class="_class" :style="_style">
            {{ displayField }}
          </span>
        </template>
        <span v-if="isTooltipText && showTooltip">{{ field.tooltipText }}</span>
        <slot
          v-if="isTooltipSlot && showTooltip"
          :name="field.tooltipSlot"
        ></slot>
      </v-tooltip>
    </template>
    <template v-else>
      <div
        v-if="isHTML"
        :class="_class"
        :style="_style"
        @[isExpand].stop="onExpandClick"
      >
        <v-icon v-if="fieldType === 'expand'" :class="expandedClass">
          mdi-chevron-down
        </v-icon>
        <v-icon
          v-if="fieldType === 'action' && showAction"
          :color="field.iconColor"
        >
          {{ field.icon }}
        </v-icon>
        <span v-else v-html="displayField"></span>
      </div>
      <span v-else :class="_class" :style="_style">{{ displayField }}</span>
    </template>
  </td>
</template>

<script>
import pc from '@pc'
import { lastInvoicedClass } from '@appModules/ws.js'

export default {
  name: 'pcSimpleTableField',

  props: {
    simpleTableDef: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    expandedRowIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    field() {
      return this.simpleTableDef.rows.length
        ? this.simpleTableDef.rows[this.rowIndex][this.index]
        : this.simpleTableDef.fields[this.index]
    },

    fieldValue() {
      return pc.getProperty(this.field.path, this.item)
    },

    fieldType() {
      return this.field.type
    },

    isFirstField() {
      return this.index === 0
    },

    isLastField() {
      return this.simpleTableDef.rows.length
        ? this.index + 1 === this.simpleTableDef.rows[this.rowIndex].length
        : this.index + 1 === this.simpleTableDef.fields.length
    },

    isClick() {
      if (!this.field.click) return null
      if (this.fieldType === 'action' && !this.showAction) return null
      return this.field.click
    },

    showAction() {
      if (this.fieldType !== 'action') return false
      if (!this.field.showActionIf) return true
      return this.field.showActionIf(this.field, this.item)
    },

    _class() {
      let _class = ''
      switch (this.fieldType) {
        case 'tag':
          _class = _class + ' tag'
          break
        case 'lastInvoicedDate':
          _class = _class + ` chip ${lastInvoicedClass(this.fieldValue)}`
          break
        default:
          break
      }
      return _class
    },

    _style() {
      let _style = this.isClick ? 'cursor: mdi-cursor-default-click; ' : ''
      switch (this.fieldType) {
        case 'tag':
          _style = _style + `background-color: ${this.fieldValue};`
          break
        default:
          break
      }

      return _style
    },

    fieldClass() {
      const fieldClass = [
        this.isFirstField
          ? 'pl-3'
          : `pl-${this.simpleTableDef.paddingBetweenFields}`,
        this.isLastField ? 'pr-3' : 'pr-0',
      ]

      if (this.isClick) fieldClass.push('click-pointer')

      switch (this.fieldType) {
        case 'int':
        case 'number':
        case 'currency':
        case 'percentage':
          if (this.fieldValue < 0) {
            fieldClass.push(`red--text ${fieldClass}`)
          }
          break
        default:
          break
      }

      fieldClass.push(this.field.fieldClass)
      return fieldClass
    },

    fieldStyle() {
      return this.field.fieldStyle
    },

    displayField() {
      let displayValue = this.fieldValue
      switch (this.fieldType) {
        case 'tag':
          displayValue = ''
          break
        case 'int':
          if (!displayValue && this.field.blankIfZero) {
            displayValue = ''
          } else {
            displayValue = displayValue.toFixed(0)
          }
          break
        case 'number':
          if (!displayValue && this.field.blankIfZero) {
            displayValue = ''
          } else {
            displayValue = displayValue.toFixed(this.field.decimalPlaces)
          }
          break
        case 'percentage':
          if (!displayValue && this.field.blankIfZero) {
            displayValue = ''
          } else {
            displayValue = displayValue.toFixed(this.field.decimalPlaces) + '%'
          }
          break
        case 'currency':
          if (!displayValue && this.field.blankIfZero) {
            displayValue = ''
          } else {
            displayValue = '£' + displayValue.toFixed(this.field.decimalPlaces)
          }
          break
        case 'lastInvoicedDate':
        case 'date':
          if (displayValue) {
            displayValue = pc.dateToDMY(displayValue)
          }
          break
        default:
          break
      }
      return displayValue
    },

    isTooltip() {
      return this.isTooltipText || this.isTooltipSlot
    },

    isTooltipText() {
      return this.field.tooltipText !== ''
    },

    isTooltipSlot() {
      return this.field.tooltipSlot !== ''
    },

    tooltipPosition() {
      if (
        ['top', 'bottom', 'left', 'right'].indexOf(
          this.field.tooltipPosition
        ) !== -1
      ) {
        return this.field.tooltipPosition
      }
      return ''
    },

    isTooltipTop() {
      return this.tooltipPosition === 'top'
    },

    isTooltipBottom() {
      return this.tooltipPosition === 'bottom'
    },

    isTooltipRight() {
      return this.tooltipPosition === 'right'
    },

    isTooltipLeft() {
      return this.tooltipPosition === 'left'
    },

    isExpand() {
      return this.fieldType === 'expand' ? 'click' : null
    },

    expandedClass() {
      return this.expandedRowIndex === this.rowIndex
        ? 'expandOpen'
        : 'expandClosed'
    },

    isHTML() {
      return this.fieldType === 'expand' || this.fieldType === 'action'
    },
  },

  watch: {
    showTooltip(value) {
      if (value) {
        this.$emit('tooltip', {
          event: 'tooltip',
          data: this.item,
          field: this.field,
          slot: this.field.tooltipSlot,
          rowIndex: this.rowIndex,
        })
      }
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', {
        event,
        data: this.item,
        field: this.field,
        rowIndex: this.rowIndex,
      })
    },

    onExpandClick(event) {
      this.$emit('expand', {
        event,
        data: this.item,
        field: this.field,
        rowIndex: this.rowIndex,
      })
    },
  },

  data() {
    return {
      showTooltip: false,
    }
  },
}
</script>

<style scoped>
.expandOpen {
  transform: rotate(-180deg);
  transition-duration: 0.5s;
}
.expandClosed {
  transform: rotate(0deg);
  transition-duration: 0.5s;
}
.tag {
  padding-right: 30px;
}
.chip {
  display: inline-block;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 25px;
}
</style>
