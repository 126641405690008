import pc from '@pc'
import { getItem } from './getItem.js'

const sortCategory = (tree, categoryId) => {
  const category = getItem(tree, categoryId)
  category.children = pc
    .query()
    .addSort('name')
    .run(category.children)
}

export const updateCategory = (tree, item, name) => {
  item.name = name
  if (!item.isAdded) item.isModified = true
  sortCategory(tree, item.parentId)
}

export const addCategory = (tree, item, name) => {
  item.children.push({
    id: pc.uid().substring(0, 10),
    name,
    level: item.level + 1,
    grandParentId: item.parentId,
    parentId: item.id,
    isCategory: true,
    isProduct: false,
    isSystem: false,
    isModified: false,
    isAdded: true,
    isDeleted: false,
    hasChildren: false,
    hasProducts: false,
    children: [],
  })
  sortCategory(tree, item.id)
}

export const deleteCategory = (tree, item) => {
  const parentItem = getItem(tree, item.parentId)
  const wasAdded = item.isAdded
  item.isAdded = false
  item.isModified = false
  item.isDeleted = true
  parentItem.children = pc
    .query()
    .addExclude('id', [item.id])
    .run(parentItem.children)
  parentItem.hasChildren = parentItem.children.length > 0
  return wasAdded ? undefined : item
}
